<template>
   <div class="goods_list_container">
      <!-- 品牌 -->
      <div class="brandList" v-if="params.brandId && params.seriesId">
         <template v-if="!isChoose">
            <div class="brandLeft">
               <div class="brandItem" v-for="(item, index) in brandSearchList.data" :key="index">
                  <img v-if="item.img" :src="item.img" alt="">
                  <div v-else class="no_img"></div>
                  <div class="line"></div>
                  <div class="title">{{ item.title }}</div>
               </div>
            </div>

            <div class="brandRight" @click="AgainSearch">
               <i class="el-icon-refresh-right"></i>
               <text>重新选择</text>
            </div>
         </template>
         <div class="again_choose" v-if="isChoose">
            <el-select v-model="selectBrand" placeholder="请选择品牌" style="width: 376px;height: 51px;" @change="brandChange">
               <el-option v-for="item in brandList.data" :key="item.brandId" :label="item.brandName"
                  :value="item.brandId" />
            </el-select>
            <!-- <el-select class="select_two" @click="selectClick" :disabled="!selectBrand" v-model="selectSeries"
               placeholder="请选择系列" style="width: 376px;height: 51px;" @change="seriesChange">
               <el-option v-for="item in seriesList.data" :key="item.seriesId" :label="item.seriesName"
                  :value="item.seriesId" />
            </el-select> -->
            <template v-if="flag">
                <el-select class="select_two" @click="selectClick" :disabled="!selectBrand" v-model="selectSeries"
                           placeholder="请选择系列" style="width: 376px;height: 51px;" @change="seriesChange">
                    <el-option-group v-for="group in seriesList.data" :key="group.categoryName" :label="group.categoryName">
                        <el-option v-for="item in group.goodsSeriesList" :key="item.seriesId"
                                :label="item.seriesName" :value="item.seriesId" />
                    </el-option-group>
                </el-select>
            </template>
             <template v-else>
                <el-select class="select_two" @click="selectClick" :disabled="!selectBrand" v-model="selectSeries"
                           placeholder="请选择系列" style="width: 376px;height: 51px;" @change="seriesChange">
                    <el-option-group v-for="group in []" :key="group.categoryName" :label="group.categoryName">
                        <el-option v-for="item in group.goodsSeriesList" :key="item.seriesId"
                                :label="item.seriesName" :value="item.seriesId" />
                    </el-option-group>
                </el-select>
            </template>

            <div class="again_btn" @click="toQuickSearch">快速检索</div>
         </div>
      </div>


      <!-- 分类路径 -->
      <categoryHeader ref="cateHeader" :total="goodsData.page.total"></categoryHeader>
      <div class="goods_list_banner">
         <!-- 二级分类或者三级分类的组件 -->
         <GoodsListCate2 :cateItem="cate1.child" :cate2Name="catePathName.path[0]" v-if="showIndex == 1"
            @character="character" ref="goodsCate2">
         </GoodsListCate2>
         <GoodsListCate2 :cateItem="cate2.child" :cate2Name="catePathName.path[1]" v-if="showIndex == 2">
         </GoodsListCate2>
         <GoodsListCate3 :series="true" @chosenAttr="chosenAttr" :categoryid="categoryId3" @contrast="contrast"
            ref="goodsCate3">
         </GoodsListCate3>
      </div>


      <div class="goods_list">
         <!-- 筛选区域 -->
         <div class="sld_screen flex_row_between_center">
            <div class="flex_row_start_center">
               <div @click="filterSort(0)"
                  :class="{ screen_item: true, btn_sort_default: indexNum == 0, flex_row_center_center: true }">
                  {{ L['默认'] }}</div>
               <div
                  :class="{ screen_item: true, flex_row_center_center: true, sld_price_jt_down: indexNum == 4, sld_price_jt_up: indexNum == 3, btn_sort_default: indexNum == 4 || indexNum == 3 }"
                  @click="filterSort(indexNum == 4 ? 3 : 4)">
                  <div class="sld_price">{{ L['价格'] }}</div>
               </div>
               <div @click="filterSort(2)"
                  :class="{ screen_item: true, flex_row_center_center: true, btn_sort: indexNum == 2, btn_sort_default: indexNum == 2 }">
                  {{ "发货日" }}
                  <i class="iconfont iconxiangshangjiantou" style="font-size: 13px"></i>
               </div>
               <div class="screen_item_price ">
                  <span class="price_git">{{ L['价格'] }}</span>
                  <div class="price_section">
                     <div class="input_box">
                        <input type="number" class="sld_rmb" v-model="lowprice" id="lowprice"
                           @input="priceInput('lowprice', $event)" @keydown="keyPress" />
                        <span class="line"></span>
                        <input type="number" class="sld_rmb" v-model="highprice" id="highprice"
                           @input="priceInput('highprice', $event)" @keydown="keyPress" />
                        <span class="line1"></span>
                     </div>

                     <div class="ctrl flex_row_between_center">
                        <a class="clearVal" @click="resetPrice">{{ L['清空'] }}</a>
                        <a class="confirm" @click="confirmPrice">{{ L['确定'] }}</a>
                     </div>
                  </div>
               </div>
               <div class="screen_item_sel flex_row_start_center"
                  v-if="memberInfo.memberType == 2 || memberInfo.memberType == 3">
                  <el-checkbox v-model="labelCode" id="label" @change="changeBox">{{ L['有物料编码'] }}</el-checkbox>

               </div>
            </div>
            <div class="flex-row">
               <div class="sld_goods_num flex_row_center_center">
                  <font color="#121212">{{ L['共'] }}</font><span style="margin: 0 4px;">{{ goodsData.page.total || 0
                  }}</span>
                  <font color="#121212">{{ L['件'] }}</font>
               </div>
               <div class="bar flex_row_center_center">
                  <span class="el-icon-arrow-left" style="cursor: pointer;" @click="recomChange('prev')"></span>
                  <span class="txt21" style="font-size: 13px;">
                     <font color="#285FDE">{{ goodsData.page.total ? goodsData.page.current : 0 }}</font> / <font>
                        {{ Math.ceil(goodsData.page.total / 10) || 0 }}
                     </font>
                  </span>
                  <span class="el-icon-arrow-right" style="cursor: pointer;" @click="recomChange('next')"></span>
               </div>
            </div>
         </div>

         <div id="scrollView"></div>
         <!-- 商品列表 -->
         <div class="sld_goods_list" v-if="goodsData.data.length" id="goods">
            <div class="block13 flex_row_start_center">
               <span class="word89">{{ L['图片'] }}</span>
               <span class="word90">{{ L['产品名称'] }}</span>
               <span class="word91">{{ L['描述'] }}</span>
               <span class="info53">{{ L['生产状态'] }}</span>
               <span class="word92">{{ L['价格'] }}</span>
               <!-- <span class="word93">{{ L['购买数量'] }}</span> -->
               <span class="word93">{{ L['起订量'] }}</span>
               <span class="word94">{{ L['操作'] }}</span>
            </div>
            <div>
               <proListItem v-for="(item, index) in goodsData.data" @contrast="contrast" @addProJ="addProJ" :key="index"
                  :item="item" @openLogin="openLogin"></proListItem>
            </div>
         </div>
      </div>

      <!-- 空数据 -->
      <template v-if="!goodsData.data.length">
         <proEmpty ref="proEmpty"></proEmpty>
      </template>

      <!-- 分页 -->
      <div class="flex_row_center_center sld_pagination">
         <el-pagination @current-change="handleCurrentChange" v-model:currentPage="goodsData.page.current"
            :page-size="goodsData.page.pageSize" layout="prev, pager, next, jumper" :total="goodsData.page.total"
            :hide-on-single-page="false" v-if="goodsData.page.total > 0">
         </el-pagination>
      </div>

      <SldLoginModal :visibleFlag="loginModalVisibleFlag" @closeLoingModal="closeLoingModal" />
      <AtoProject ref="AtoProject"></AtoProject>

      <loadAnimation1 v-show="showLoading"></loadAnimation1>
   </div>
</template>
<script>
import { SearchImgUrl } from '@/utils/config';
import proEmpty from '@/components/proEmpty'
import loadAnimation1 from '@/components/loading_animation1'
import { getCurrentInstance, onMounted, reactive, ref, watchEffect, watch, provide, nextTick } from "vue";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import GoodsListCate2 from "@/components/GoodsListCate2";
import GoodsListCate3 from "@/components/GoodsListCate3";
import SldLoginModal from "@/components/loginModal";
import SldCommonEmpty from "@/components/SldCommonEmpty";
import proListItem from '@/components/ProListItem'
import AtoProject from '@/components/AtoProject'
import categoryHeader from '@/components/categoryHeader'
import { priceSlct, getCategory } from '@/utils/common.js'
import addCompare from '@/components/comparePanel/compare.js'
export default {
   name: "goodsList",
   components: {
      GoodsListCate2,
      GoodsListCate3,
      SldLoginModal,
      SldCommonEmpty,
      proListItem,
      AtoProject,
      categoryHeader,
      loadAnimation1,
      proEmpty
   },
   setup() {
      //变量------------------------------
      const firstLoading = ref(true); //是否第一次加载
      const skeletonData = reactive({ data: [] });
      const store = useStore();
      const memberInfo = ref(store.state.memberInfo);
      const loginModalVisibleFlag = ref(false); //登录弹框是否显示，默认不显示
      const checkList = ref('')
      const id = ref('')
      const showLoading = ref(false)
      const route = useRoute();
      const router = useRouter();
      //初始化的请求参数
      const params = reactive({
         current: 1,
         pageSize: 10
      });
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const goodsData = reactive({ data: [], page: {} });
      const indexNum = ref(0);
      const isEmpty = ref(false);
      const keyword = ref(
         route.query.keyword != undefined && route.query.keyword ? route.query.keyword : ""
      ); //搜索关键词

      const categoryId3 = ref(route.query.categoryId);
      const scrollTop = ref(null);
      const labelCode = ref(false)//物料编码
      const { cate1, cate2, catePathName, cateForm } = getCategory(route)
      const showIndex = ref(0)
      const characterList = ref([])
      const scrollViewTop = ref(0)
      const flag = ref(false)

      const brandSearchList = reactive({
         data: [
            {
               img: '',
               title: ''
            },
            {
               img: '',
               title: ''
            },
            {
               img: '',
               title: ''
            }
         ]
      })


      // 选择的品牌和系列
      const selectBrand = ref('')
      const selectSeries = ref('')
      // 品牌列表
      const brandList = reactive({
         data: []
      })

      // 系列列表
      const seriesList = reactive({
         data: []
      })

      const isChoose = ref(false)

      //方法------------------------------
      const initSkeletonData = () => {
         for (let i = 0; i < 10; i++) {
            skeletonData.data.push({
               goodsName: "",
               goodsImage: "",
               goodsPrice: "",
               saleNum: "",
               activityList: "",
               isFollowGoods: "",
               defaultProductId: "",
            });
         }
      };
      initSkeletonData();

      //初始化数据，进行请求-start
      const getInitData = (params) => {
         let searchParams = { ...params };
         if (route.query.categoryId) {
            searchParams.categoryIds = route.query.categoryId
         }
         proxy.$get("v3/goods/front/goods/goodsList", searchParams).then((res) => {
            goodsData.data = []
            if (res.state == 200) {
               goodsData.data = res.data.list;
               isEmpty.value = res.data.list.length ? false : true;
               goodsData.page = res.data.pagination;
               if (firstLoading.value) {
                  proxy.$refs.cateHeader.init()
               }
               firstLoading.value = false
            } else {
               goodsData.data = []
            }

            if (!goodsData.data.length) {
               nextTick(() => {
                  proxy.$refs.proEmpty.getStoreList()

               })
            }

            nextTick(() => {
               showLoading.value = false
               if (proxy.$refs.goodsCate3) proxy.$refs.goodsCate3.showLoadingMain = false;
            })
         });
      };

      const { highprice, lowprice, confirm, reset } = priceSlct(getInitData, params)

      const categorySearch = (data, val) => {
         //遍历分类列表，找到该categoryId所在的id路径
         for (var i = 0; i < data.length; i++) {
            if (data[i] && data[i].categoryId == val) {
               return [
                  {
                     categoryId: val,
                     categoryName: data[i].categoryName,
                     children: data[i].children,
                  },
               ];
            }
            if (data[i] && data[i].children) {
               var d = categorySearch(data[i].children, val);
               if (d)
                  return d.concat({
                     categoryId: data[i].categoryId,
                     categoryName: data[i].categoryName,
                     children: data[i].children,
                  });
            }
         }
      };
      //获取分类Id数组对应的分类名--end

      const handleCurrentChange = (e) => {
         params.current = Math.floor(e);
         window.scrollTo({
            top: scrollViewTop.value - 200
         })
         getInitData(params);
      };



      const filterSort = (index) => {
         if (indexNum.value == index) {
            return;
         }
         indexNum.value = index;
         params.sort = index
         showLoading.value = true
         proxy.$refs.goodsCate3.showLoadingMain = true;
         refreshSeriesList()
         refreshAttrList()
         getInitData(params)
      };

      const changeBox = (e) => {
         labelCode.value = e
         params.current = 1;
         showLoading.value = true
         proxy.$refs.goodsCate3.selectorParam.isHasCode = labelCode.value
         proxy.$refs.goodsCate3.getInitData()
         if (!goodsData.data.length) {
            proxy.$refs.goodsCate3.clearAttr()
         }
         proxy.$refs.goodsCate3.showLoadingMain = true;
         params.isHasCode = labelCode.value
         proxy.$refs.goodsCate3.getSeries({ categoryId: route.query.categoryId })
         getInitData(params)
      }
      //页数改变的方法-end

      //产品加入购物车未登录
      const openLogin = () => {
         loginModalVisibleFlag.value = true;
      };

      //关闭登录弹框
      const closeLoingModal = () => {
         loginModalVisibleFlag.value = false;
      };

      //加入对比
      const contrast = (item) => {
         addCompare(item)
      }

      //计入我的项目
      const addProJ = (item) => {
         proxy.$refs.AtoProject.proInfo.productIds = `${item.productId}-${item.num}`
         proxy.$refs.AtoProject.visibleFlag = true
      }

      //筛选属性
      const chosenAttr = (commonList, resistorList, accurayList) => {
         if (commonList.length) {
            const sorted = groupBy(commonList, (item) => [item.attrId]);
            let attrList = sorted.map(item => {
               let list = item.map(i => i.attrValId).join('-')
               return list
            })
            params.attributeInfo = attrList.join(',')
         } else {
            delete params.attributeInfo
         }

         if (accurayList.length) {
            let list = accurayList.map(i => i.attrValId).join(',')
            params.accuracyIds = list
         } else {
            delete params.accuracyIds
         }

         if (resistorList.length) {
            let list = resistorList.map(i => i.attrValId).join(',')
            params.resistanceIds = list
         } else {
            delete params.resistanceIds
         }
         showLoading.value = true
         proxy.$refs.goodsCate3.showLoadingMain = true;
         params.current = 1
         setTimeout(() => {
            getInitData(params);
            nextTick(() => {
               proxy.$refs.goodsCate2.getCharacter(params)
            })
         }, 150);
      }

      //筛选分组
      const groupBy = (arr, func) => {
         const groups = {};
         arr.forEach((o) => { //注意这里必须是forEach 大写
            const group = JSON.stringify(func(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
         });
         return Object.keys(groups).map(function (group) {
            return groups[group];
         });
      }

      //获取特性
      const character = (list) => {
         characterList.value = list
         showLoading.value = true
         proxy.$refs.goodsCate3.showLoadingMain = true;
         let refObj = proxy.$refs.goodsCate3
         if (list.length) {
            params.characterInfo = list;
            refObj.selectorParam.characterInfo = list;
         } else {
            delete params.characterInfo;
            refObj.selectorParam.characterInfo = ''
         }

         setTimeout(() => {
            refObj.getInitData()
            refreshSeriesList()
            getInitData(params);
         }, 150);
      }


      const confirmPrice = () => {
         confirm(() => {
            refreshAttrList()
            refreshSeriesList()
         })
      }


      const resetPrice = () => {
         reset(() => {
            //这是 当 例如 3- 这种情况出现 无法清空，执行
            document.getElementById('lowprice').value = ''
            document.getElementById('highprice').value = ''
            refreshAttrList()
            refreshSeriesList()
         })
      }

      window.onscroll = () => {
         if (document.getElementById('goods')) {
            let dom = document.getElementById('goods')
            scrollViewTop.value = dom.offsetTop
         }

         // console.log(scrollViewTop.value, 'scrollViewTop.value ')
      }


      const refreshSeriesList = () => {
         let refObj = proxy.$refs.goodsCate3
         refObj.selectorParam.isHasCode = labelCode.value
         refObj.selectorParam.highPrice = highprice.value
         refObj.selectorParam.lowPrice = lowprice.value
         refObj.selectorParam.sort = indexNum.value

         if (characterList.value.length) {
            refObj.selectorParam.characterInfo = characterList.value;
         } else {
            refObj.selectorParam.characterInfo = ''
         }
         refObj.getSeries({ categoryId: route.query.categoryId })

      }

      const refreshAttrList = () => {
         let refObj = proxy.$refs.goodsCate3
         refObj.selectorParam.isHasCode = labelCode.value
         refObj.selectorParam.highPrice = highprice.value
         refObj.selectorParam.lowPrice = lowprice.value
         refObj.selectorParam.sort = indexNum.value
         if (!goodsData.data.length) {
            refObj.clearAttr()
         } else {
            refObj.getInitData()
         }
      }





      //监听事件--start
      watchEffect(() => {
         if (route.query.keyword != undefined) {
            keyword.value = route.query.keyword;
            params.keyword = keyword.value;
            categoryId3.value = "";
         } else {
            delete params.keyword
         }
         if (route.query.categoryId != undefined) {
            showIndex.value = route.query.g
            categoryId3.value = route.query.categoryId
            keyword.value = "";
            params.categoryIds = route.query.categoryId;
            if (route.query.g == 1 || route.query.g == 2) {
               cateForm(route.query.categoryId, route.query.g)
            }
         } else {
            delete params.categoryIds
         }
      });

      watch(
         () => route.query.categoryId,
         (nv, ov) => {
            if (nv != ov) {
               if (route.query.categoryId && !route.query.keyword) {
                  categoryId3.value = nv;
                  delete params.keyword;
                  deleteObjWhileChange()
                  proxy.$refs.goodsCate3.clearPartSelector()
                  params.categoryIds = route.query.categoryId;
                  showIndex.value = route.query.g
                  if (route.query.g == 1) {
                     cateForm(route.query.categoryId, route.query.g)
                  }
                  showLoading.value = true
                  if (proxy.$refs.goodsCate3) proxy.$refs.goodsCate3.showLoadingMain = true;
               }
               params.current = 1
               getInitData(params);
            }

         }
      );

      watch(() => route.query.keyword, (nv, ov) => {
         if (nv != ov) {
            if (route.query.keyword && !route.query.categoryId) {
               deleteObjWhileChange()
               delete params.categoryIds
               keyword.value = route.query.keyword;
               params.keyword = keyword.value;
               showLoading.value = true
               if (proxy.$refs.goodsCate3) {
                  proxy.$refs.goodsCate3.clearPartSelector()
                  proxy.$refs.goodsCate3.showLoadingMain = true;

               }


            }
            params.current = 1
            getInitData(params);
         }

      }
      );

      // 品牌和系列变化监听
      watch(
         () => route.query.brandId,
         (nv, ov) => {
            if (nv != ov) {
               if (route.query.brandId && route.query.seriesId) {
                  params.brandId = route.query.brandId;
                  showLoading.value = true
                  params.current = 1
                  GetBrandList()
                  GetSeriesList(params.brandId)
                  getInitData(params)
               } else {
                  params.brandId = ''
               }

            }

         }
      );
      watch(
         () => route.query.seriesId,
         (nv, ov) => {
            if (nv != ov) {
               if (route.query.brandId && route.query.seriesId) {

                  params.seriesId = route.query.seriesId;
                  showLoading.value = true
                  params.current = 1
                  GetBrandList()
                  GetSeriesList(route.query.brandId)
                  getInitData(params)
               } else {
                  params.seriesId = ''
               }
            }

         }
      );

      const deleteObjWhileChange = () => {
         delete params.highPrice;
         delete params.lowPrice;
         delete params.sort
         delete params.attributeInfo
         delete params.characterInfo
         delete params.resistanceIds
         delete params.accuracyIds
         labelCode.value = false;
         lowprice.value = highprice.value = null;
         params.current = 1
      }

      const recomChange = (type) => {
         if (type == 'next') {
            if (params.current == Math.ceil(goodsData.page.total / 10)) {
               return
            }
            params.current++
            getInitData(params)
         } else {
            if (params.current == 1) {
               return
            }
            params.current--
            getInitData(params)
         }
      }

      //监听事件--end

      const priceInput = (type, e) => {
         if (type == 'lowprice') {
            lowprice.value = String(e.target.value).slice(0, 8)
            let low = String(e.target.value).split('.')
            if (low[1] && low[1].length >= 2) lowprice.value = Number(e.target.value).toFixed(2)
         }

         if (type == 'highprice') {
            highprice.value = String(e.target.value).slice(0, 8)
            let high = String(e.target.value).split('.')
            if (high[1] && high[1].length >= 2) highprice.value = Number(e.target.value).toFixed(2)
         }
      }

      const keyPress = (e) => {
         let prohibitInput = ["-", "e", "+", "E"];
         if (prohibitInput.includes(e.key)) {
            e.preventDefault();
         }
      }



      router.afterEach(() => {
         showLoading.value = false;
         nextTick(() => {
            if (proxy.$refs.goodsCate3) proxy.$refs.goodsCate3.showLoadingMain = false;
         })
      });

      // 重新选择
      const AgainSearch = () => {
         isChoose.value = true
         // store.commit('isAgainChoose', true)
      }

      // 获取品牌列表
      const GetBrandList = () => {
         proxy
            .$get('v3/goods/front/goods/getBrandlist')
            .then(res => {
               if (res.state == 200) {
                  const item = res.data.list.filter(v => v.brandId == route.query.brandId)
                  brandSearchList.data[0].img = item[0].imageUrl
                  brandSearchList.data[0].title = item[0].brandName
                  brandList.data = res.data.list
               }
            })

      }
      // 获取系列列表
      const GetSeriesList = (brandId) => {
         let params = {
            seriesId: route.query.seriesId,
         };
         proxy
            .$get('v3/goods/front/goods/category/getSeriesImage', params)
            .then(res => {
               if (res.state == 200) {
                brandSearchList.data[2].img = res.data.image
                  brandSearchList.data[2].title = res.data.name
               }
            })

      }

      const GetThirdCategory = (id) => {
         let params = {
            categoryId: id
         }
         proxy
            .$get('v3/goods/front/goods/category/getCategoryImage', params)
            .then(res => {
               if (res.state == 200) {
                  brandSearchList.data[1].img = res.data.categoryImage
                  brandSearchList.data[1].title = res.data.categoryName
               }
            })
      }

      // 获取品牌列表
      const GetChooseBrandList = () => {
         proxy
            .$get('v3/goods/front/goods/getBrandlist')
            .then(res => {
               if (res.state == 200) {
                  brandList.data = res.data.list
               }
            })

      }
      // 获取系列列表
      const GetChooseSeriesList = (brandId) => {
         selectSeries.value = ''
         flag.value = false;
         let params = {
            brandId: brandId,
         };
         proxy
            .$get('v3/goods/front/goods/getSeriesByBrandId', params)
            .then(res => {
               if (res.state == 200) {
                    
                        seriesList.data = res.data
                        flag.value = true
               }
            })

      }


      const brandChange = (val) => {
         selectBrand.value = val
         GetChooseSeriesList(val)
      }
      const seriesChange = (val) => {
          seriesList.data.map((item,index)=>{
                item.goodsSeriesList.map((it,ind)=>{
                    if(it.seriesId == val){
                        id.value = it.categoryId3
                    }
                })
            })
            
         selectSeries.value = val
      }

      // 快速检索按钮
      const toQuickSearch = () => {
          GetThirdCategory(id.value?id.value:categoryId3.value)
         if (!selectBrand.value || !selectSeries.value) {
            ElMessage('请选择品牌和系列')
            return
         }
         
         if (router.currentRoute.value.path == '/goods/list') {
            let query = router.currentRoute.value.query
            // query.brandId = selectBrand.value
            // query.seriesId = selectSeries.value
            router.push({
               path: '/goods/list',
               query: {
                  ...query,
                  brandId: selectBrand.value,
                  seriesId: selectSeries.value,
                  categoryId3: id.value?id.value:categoryId3.value
               }
            })
         } else {
            router.push({
               path: '/goods/list',
               query: {
                  brandId: selectBrand.value,
                  seriesId: selectSeries.value,
               }
            })
         }

         isChoose.value = false


      }
      const selectClick = () => {
         if (!selectBrand.value) {
            ElMessage('请先选择品牌')
            return
         }
      }

      onMounted(() => {
         if (route.query.categoryId) {
            params.categoryIds = route.query.categoryId;
            categoryId3.value = route.query.categoryId
         }
         if (route.query.keyword) {
            params.keyword = route.query.keyword;
         }
         if (route.query.storeId) {
            params.storeId = route.query.storeId;
         }
         if (route.query.goodsIds) {
            params.goodsIds = route.query.goodsIds;
         }

         if (route.query.sort) {
            params.sort = route.query.sort;
            indexNum.value = route.query.sort;
         }
         if (route.query.brandId) {
            params.brandId = route.query.brandId
         }
         if (route.query.seriesId) {
            params.seriesId = route.query.seriesId
         }

         if (route.query.brandId && route.query.seriesId) {
            // 获取品牌数据
            GetBrandList()
            GetThirdCategory(route.query.categoryId3)
            GetSeriesList(route.query.brandId)
            GetChooseSeriesList(route.query.brandId)
            setTimeout(() => {
               selectBrand.value = Number(route.query.brandId)
               selectSeries.value = Number(route.query.seriesId)
            })
         }
         showLoading.value = true
         if (proxy.$refs.goodsCate3) proxy.$refs.goodsCate3.showLoadingMain = true;

         getInitData(params);

      })
      //返回值
      return {
         goodsData,
         handleCurrentChange,
         L,
         indexNum,
         filterSort,
         isEmpty,
         keyword,
         firstLoading,
         skeletonData,
         loginModalVisibleFlag,
         openLogin,
         closeLoingModal,
         categoryId3,
         checkList,
         contrast,
         addProJ,
         chosenAttr,
         changeBox,
         labelCode,
         character,
         highprice,
         lowprice,
         confirm,
         reset,
         cate1, cate2, catePathName, cateForm,
         showIndex,
         memberInfo,
         priceInput,
         showLoading,
         params,
         recomChange,
         confirmPrice,
         resetPrice,
         keyPress,

         brandSearchList,
         // brandInfo,
         // seriesInfo,
         AgainSearch,
         GetBrandList,
         GetSeriesList,
         GetThirdCategory,
         isChoose,
         selectBrand,
         selectSeries,
         brandList,
         seriesList,
         brandChange,
         seriesChange,
         toQuickSearch,
         selectClick,
         GetChooseBrandList,
         GetChooseSeriesList,
        id,
        flag,
      };
   },
   //
};
</script>

<style lang="scss" scoped>
@import "../../style/goodsList.scss";
@import "../../style/base.scss";

.bar {
   margin-left: 10px;
}

.empty_page {
   flex-direction: column;
   padding: 100px;
}

.flex-col {
   display: flex;
   flex-direction: column;
}

.flex-row {
   display: flex;
   flex-direction: row;
}

.sld_pagination {
   margin-top: 30px;
   padding-bottom: 30px;
}

:deep(.el-select-group__title) {
    font-weight: bold !important;
    color: #101010 !important;
    font-size: 14px !important;
}

/*添加css样式*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
}

input[type="number"] {
   -moz-appearance: textfield;
}

.goods_list {
   border: 1px solid #DBDBDB;
}
</style>
<style>
.el-checkbox__input.is-checked .el-checkbox__inner {
   background-color: $colorMain;
   border-color: $colorMain;
}

.el-checkbox__input.is-checked+.el-checkbox__label {
   color: $colorMain;
}
</style>
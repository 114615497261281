<template>
    <div id="banner_right">
        <div class="flex_row_between_center">
            <div class="title_banner">{{L['行业知识']}}</div>
            <div class="more" @click="nav('/information?from=main')">{{L['更多']}}&gt;</div>
        </div>
        <div class="middle">
            <template v-for="item in article.data" :key="item.createId">
            <div :class="{item:true,skeleton_banner_right:firstLoading}" @click="info(item.informationId)">{{item.title?item.title:'  '}}</div>
            </template>
        </div>
        <div class="bottom flex_row_around_center">
        <div class="item flex_column_around_center"
            @click="nav('/store/list')">
                <img class="image" src="@/assets/home_store.png" />
                <span class="name">{{L['供应商']}}</span>
            </div>
        <div class="item flex_column_around_center"
            @click="nav('/prototype/replacement/search')">
                <img class="image" src="@/assets/home_exchange.png" />
                <span class="name">{{L['国产替代']}}</span>
            </div>
        <div class="item flex_column_around_center"
            @click="nav('/goods/new')">
                <img class="image" src="@/assets/home_newProduct.png" />
                <span class="name">{{L['新品速递']}}</span>
            </div>
        <div class="item flex_column_around_center"
            @click="nav('/quickOrder')">
                <img class="image" src="@/assets/home_quickBuy.png" />
                <span class="name">{{L['快速下单']}}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { getCurrentInstance, reactive, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import SldCommonEmpty from "./SldCommonEmpty";
export default {
    name: "CategorySort",
    components: {
        SldCommonEmpty,
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const router = useRouter();
        const article = reactive({ data: [1, 2, 3, 4, 5, 6, 7] });
        const firstLoading = ref(true); //是否第一次加载

        const getList = () => { //获取行业知识列表
            let param = {
                current: 1,
                pageSize: 7
            }
            proxy
                .$get('v3/cms/front/information/informationList', param)
                .then(res => {
                    firstLoading.value = false;
                    if (res.state == 200) {
                        article.data = res.data.list;
                    }
                })
        };

        const nav = (url) => { //行业知识更多
            router.push(url);
        };

        const info = (id) => { //行业知识详情
            let newWin = router.resolve({
                path: '/information/detail',
                query: {
                    infoId: id
                }
            })
            window.open(newWin.href, '_blank');
        };

        onMounted(() => {
            getList();
        });

        return {
            L,
            article,
            nav,
            info,
            firstLoading,
        };
    },
};
</script>
<style lang="scss" scoped>
#banner_right {
    display: none;
    position: absolute;
    top: 45px;
    right: 0px;
    z-index: 9;
    width: 236px;
    height: 457px;
    font-size: 12px;
    padding: 20px;
    background-color: #FFFFFF;

    .title_banner {
        color: #222222;
        font-size: 16px;
        font-weight: 700;
        font-family: Microsoft YaHei;
        cursor: default;
    }
    .more {
        color: #9C9B9B;
        font-size: 13px;
        font-weight: 400;
        font-family: Microsoft YaHei;
        cursor: pointer;
    }

    .middle {
        height: 240px;
        overflow: hidden;
        border-bottom: 1px solid #E8E7E7;
        .item {
            color: $colorTitle1;
            font-size: 13px;
            font-weight: 400;
            font-family: Microsoft YaHei;
            margin-top: 16px;
            margin-bottom: 16px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;

            &:hover {
                color: $colorMain;
            }
        }
        .skeleton_banner_right {
            background: $colorSkeleton;
            height: 25px;
            border-radius: 2px;
            width: 196px;
            margin-top: 8px;
            margin-bottom: 8px;
        }
    }

    .bottom {
        flex-wrap: wrap;

        .item {
            width: 80px;
            height: 71px;
            margin-top: 11px;
            margin-bottom: 2px;

            .image {
                width: 42px;
                height: 42px;
                cursor: pointer;
                -webkit-transition: -webkit-transform 0.5s;
                transition: -webkit-transform 0.5s;
                -moz-transition: transform 0.5s, -moz-transform 0.5s;
                transition: transform 0.5s;
                transition: transform 0.5s, -webkit-transform 0.5s, -moz-transform 0.5s;
                &:hover {
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                    /*-webkit-transform: scale(1.05, 1.05);*/
                    /*transform: scale(1.05, 1.05);*/
                }
            }
            .name {
                color: $colorTitle1;
                font-size: 13px;
                font-weight: 400;
                font-family: Microsoft YaHei;
                cursor: pointer;
            }
            &:hover {
                .name {
                    color: #ff823e;
                }
            }
        }
    }
}
</style>

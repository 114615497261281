<template>
    <div :class="{ main_con: true, skeleton: firstLoading }">
        <div v-if="firstLoadingCarousel">
            <a href="javascript:void(0)" @click="diyNavTo(i)">
                <div></div>
            </a>
        </div>
        <div v-else-if="carouselList.length" class="carousel1">
            <el-carousel height="200px">
                <el-carousel-item v-for="(i, index) in (firstLoadingCarousel ? skeletonCarousel : carouselList)"
                    :key="index">
                    <a href="javascript:void(0)" @click="diyNavTo(i)">
                        <div :style="`background-image:url(${i.imgUrl})`"></div>
                    </a>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="inf_sets_con flex-col">
            <template v-for="(item, index) in (firstLoading ? skeletonData : artList)" :key="index">
                <div class="inf_sets" v-if="item.comList.length">
                    <div class="header1 flex_row_between_center">
                        <div class="t_name">{{ item.categoryName }}</div>
                        <div class="t_more">
                            <a :href="`/information/list?categoryId=${item.categoryId}&g=${item.grade}`">
                                {{L['查看全部']}}>>
                            </a>
                        </div>
                    </div>
                    <div class="main1 flex_row_between_start">
                        <div class="m_left flex-row" v-if="item.comList.length">
                            <div class="sets_item flex_column_between_start"
                                v-for="(item, index) in item.comList.slice(0, 6)" :key="index">
                                <a :href="`/information/detail?infoId=${item.informationId}`"
                                    class="link flex_column_between_start">
                                    <div class="flex-col">
                                        <span class="txt1">{{ item.title }}</span>
                                        <span class="txt2">{{ item.introduction }}</span>
                                    </div>
                                    <div>
                                        <span class="txt3">{{ item.createTime }}</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="m_right" v-if="item.recomList.length">
                            <div class="h_name">{{L['热门推荐']}}</div>
                            <div class="h_list">
                                <div class="h1 flex_row_start_center" v-for="(item1, index1) in item.recomList"
                                    :key="index1">
                                    <a :href="`/information/detail?infoId=${item1.informationId}`" target="_blank"
                                        class="flex_row_start_center">
                                        <span :class="`hBox box${index1 + 1}`">{{ index1 + 1 }}</span>
                                        <span class="hT">{{ item1.title }}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

        </div>
    </div>
</template>

<script>
import { reactive, getCurrentInstance, ref, watchEffect, onMounted, toRef } from "vue";
import { useRoute, useRouter } from 'vue-router'
export default {
    setup() {
        const route = useRoute()
        const router = useRouter()
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const carouselList = ref([])
        const artList = ref([])
        const pageState = reactive({ page: {} })
        const keyword = ref('')
        const params = reactive({
            current: 1,
        })

        const skeletonData = ref([
            {
                categoryName: '',
                comList: [{
                    title: '',
                    introduction: '',
                    createTime: ''
                }, {
                    title: '',
                    introduction: '',
                    createTime: ''
                }, {
                    title: '',
                    introduction: '',
                    createTime: ''
                }],
                recomList: [{
                    title: ''
                }, {
                    title: ''
                }, {
                    title: ''
                }]
            }
        ])
        const skeletonCarousel = ref([''])
        const firstLoading = ref(true)
        const firstLoadingCarousel = ref(true)
        const getCarousel = () => {
            proxy.$get('v3/system/front/firstAdv/detail', {
                code: 'pc_information'
            }).then(res => {
                if (res.state == 200) {
                    firstLoadingCarousel.value = false
                    carouselList.value = JSON.parse(proxy.$quillEscapeToHtml(res.data)).filter(i => i.imgUrl)
                }
            })
        };


        const getArtList = () => {
            params.categoryId = route.query.from ? 0 : route.query.categoryId
            proxy.$get('v3/cms/front/information/list', params).then(res => {
                if (res.state == 200) {
                    firstLoading.value = false
                    artList.value = res.data.list
                    artList.value.map(item => {
                        item.comList = item.informationList
                        item.recomList = item.informationList.filter(l => l.isRecommend == 1).sort((a, b) => b.pageView - a.pageView).slice(0, 10)
                    })

                }
            })
        };

        const toDetail = (item) => {
            router.push({
                path: '/information/detail',
                query: {
                    infoId: item.informationId
                }
            })
        }

        const seeMore = (item) => {
            router.push({
                path: '/information/list',
                query: {
                    categoryId: item.categoryId,
                    g: item.grade
                }
            })
        }

        const search = () => {
            params.current = 1
            params.keyword = keyword.value
        }

        const diyNavTo = (item) => {
            proxy.$diyNavTo(item)

        }



        onMounted(() => {

            getArtList()
            getCarousel()
        })

        return {
            carouselList,
            ...toRef(pageState),
            keyword,
            search,
            artList,
            toDetail,
            seeMore,
            diyNavTo,
            firstLoading,
            firstLoadingCarousel,
            skeletonData,
            skeletonCarousel,
            L
        }
    }
}
</script>

<style lang="scss">
.main_con {
    background: $colorC;

    .link {
        width: 100%;
        height: 100%;
    }

    &.skeleton {
        .t_name {
            width: 231px;
            height: 25px;
            background: $colorSkeleton;
        }

        .txt1 {
            width: 144px;
            height: 18px;
            background: $colorSkeleton;
        }

        .txt2 {
            width: 90px;
            height: 18px;
            background: $colorSkeleton;
        }

        .txt3 {
            width: 137px;
            height: 17px;
            background: $colorSkeleton;
        }

        .hT {
            height: 14px;
            background: $colorSkeleton;
        }

        .h_name {
            height: 23px;
            width: 250px;
        }
    }

    .carousel1 {
        .el-carousel {
            width: 100%;
            margin: 0 auto;
        }

        background: $colorK;

        .el-carousel__item {
            /* width: 1920px;
           /*图片宽度*/
            /* position: absolute;
           left: 50%;
           margin-left: -960px; */
            /* 图片宽度的一半 */
            display: flex;
            justify-content: center;
            align-items: center;

            div {
                background-position: center center;
                width: 1920px;
                height: 200px;
                position: absolute;
                left: 50%;
                top: 0;
                margin-left: -960px;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }

    .inf_sets_con {
        width: 1200px;
        margin: 0 auto;
        padding-bottom: 40px;

        .inf_sets {
            margin-top: 20px;
            background: #fff;

        }

        .header1 {
            height: 56px;
            width: 1200px;
            border-bottom: 1px solid #eee;

            .t_name {
                font-size: 22px;
                font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                font-weight: bold;
                color: #333333;
                padding-left: 20px;
                box-shadow: 2px 0px $colorMain inset;
            }

            .t_more {
                font-size: 14px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #666666;
                cursor: pointer;
                padding-right: 20px;

                a,
                a:link {
                    text-decoration: none;
                    color: unset;
                }

                &:hover {
                    color: $colorMain;

                    a {
                        color: $colorMain;
                    }
                }
            }
        }

        .main1 {
            padding: 20px;

            .m_left {
                width: 770px;
                flex-wrap: wrap;
                justify-content: space-between;

                .sets_item {
                    padding: 20px;
                    width: 373px;
                    height: 142px;
                    transition: all .3s;

                    &:hover {
                        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.09);
                    }


                    .txt1 {
                        font-size: 16px;
                        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                        font-weight: 500;
                        color: #333333;
                        margin-bottom: 8px;
                        word-break: break-all;
                    }

                    .txt2 {
                        font-size: 14px;
                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                        font-weight: 400;
                        color: #858585;
                        line-height: 17px;
                        word-break: break-all;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;

                    }

                    .txt3 {
                        font-size: 14px;
                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                        font-weight: 400;
                        color: #AEAEAE;
                    }
                }
            }

            .m_right {
                padding: 10px;
                width: 373px;

                .h_name {
                    font-size: 22px;
                    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                    font-weight: bold;
                    color: #333333;
                    margin-bottom: 10px;
                }

                .h_list {
                    .h1 {
                        margin-bottom: 10px;

                        .hBox {
                            width: 24px;
                            height: 24px;
                            border-radius: 6px;
                            font-size: 16px;
                            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                            font-weight: bold;
                            color: $colorC;
                            text-align: center;
                            line-height: 24px;

                            background: #9D9D9D;

                            &.box1 {
                                background: #FF6660 !important;
                            }

                            &.box2 {

                                background: #FFA560 !important;
                            }

                            &.box3 {

                                background: #FFB99E !important;
                            }
                        }

                        .hT {
                            margin-left: 8px;
                            width: 252px;
                            font-size: 14px;
                            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                            font-weight: 400;
                            color: #333333;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

    }
}
</style>
<template>
     <div class="main_con">
          <div class="carousel">
               <el-carousel height="457px" v-if="carouselList.length">
                    <el-carousel-item v-for="(i, index) in carouselList" :key="index">
                         <a href="javascript:void(0)" @click="diyNavTo(i)">
                              <div :style="`background-image:url(${i.imgUrl})`"></div>
                         </a>
                    </el-carousel-item>
               </el-carousel>
          </div>
          <div class="products flex-row">
               <div class="l_con">
                    <div class="search flex_row_start_center">
                         <input type="text" :placeholder="L['新品名称/型号']" v-model="keyword">
                         <span class="el-icon-search" @click="search"></span>
                    </div>
                    <div class="cate1">
                         <div class="t1">{{L['产品分类']}}</div>
                         <div class="t1_box">
                              <el-scrollbar>
                                   <div :class="{ item1: true, seled: categories.sel == item.categoryId }"
                                        v-for="(item, index1) in categories.list" @click="querySelect('cate', item)"
                                        :key="index1">
                                        <text>{{ item.categoryName }}</text>
                                   </div>
                                   <div></div>
                              </el-scrollbar>
                         </div>
                    </div>
                    <div class="cate1 cate_pad">
                         <div class="t1">{{L['应用领域']}}</div>
                         <div class="t1_box">
                              <el-scrollbar>
                                   <div :class="{ item1: true, seled: fields.sel == item.fieldId }"
                                        v-for="(item, index2) in fields.list" @click="querySelect('fields', item)"
                                        :key="index2">
                                        <text>{{ item.content }}</text>
                                   </div>
                                   <div></div>
                              </el-scrollbar>
                         </div>
                    </div>
               </div>
               <div class="r_con">
                    <div class="flex_row_start_center" v-if="goodsList.length" style="flex-wrap: wrap;">
                         <div class="item2" v-for="(good, idx) in goodsList" :key="idx" @click="toGoodsDetail(good)">
                              <div class="img1 flex_row_center_center">

                                   <div :style="{ backgroundImage: `url(${good.goodsImage})` }" class="img"></div>
                              </div>
                              <div class="content1 flex-col">
                                   <span class="txt1" :title="good.goodsName">{{ good.goodsName }}</span>
                                   <span class="txt2" :title="good.newProductFeature">{{L['新品特性：']}}{{ good.newProductFeature
                                        }}</span>
                              </div>
                         </div>
                    </div>
                    <div v-else class="empty_con flex_row_center_center">
                         <div class="empty flex_column_center_center">
                              <img src="@/assets/new_empty.png" alt="" srcset="">
                              <p>{{L['暂无符合条件的产品']}}</p>
                         </div>
                    </div>

                    <!-- 分页 -->
                    <div class="flex_row_center_center sld_pagination">
                         <el-pagination @current-change="handleCurrentChange" v-model:currentPage="page.current"
                              :page-size="page.pageSize" layout="prev, pager, next, jumper" :total="page.total"
                              :hide-on-single-page="true">
                         </el-pagination>
                    </div>

               </div>
          </div>
     </div>
</template>

<script>
import { reactive, getCurrentInstance, ref, watchEffect, onMounted, toRefs } from "vue";
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus';
export default {
     setup() {
          const { proxy } = getCurrentInstance();
          const L = proxy.$getCurLanguage()
          const categories = reactive({
               list: [],
               sel: null
          })
          const fields = reactive({
               list: [],
               sel: null
          })
          const goodsList = ref([])
          const pageState = reactive({ page: {} })
          const keyword = ref('')
          const params = reactive({
               current: 1,
               pageSize: 12
          })
          const router = useRouter()
          const carouselList = ref([])
          const getCategories = () => {
               proxy.$get('v3/goods/front/goods/category/categoryList').then(res => {
                    if (res.state == 200) {
                         categories.list = res.data
                    }
               })
          };
          const getFields = () => {
               proxy.$get('v3/seller/front/apply/field/list').then(res => {
                    if (res.state == 200) {
                         fields.list = res.data
                    }
               })
          };

          const getNews = () => {
               proxy.$get('v3/goods/front/goods/newGoodsList', params).then(res => {
                    if (res.state == 200) {
                         goodsList.value = res.data.list
                         pageState.page = res.data.pagination
                    }
               })
          }

          const getCarousel = () => {
               proxy.$get('v3/system/front/firstAdv/detail', {
                    code: 'pc_new_product_area'
               }).then(res => {
                    if (res.state == 200) {
                         carouselList.value = JSON.parse(proxy.$quillEscapeToHtml(res.data)).filter(i => i.imgUrl)
                    }
               })
          };

          const handleCurrentChange = (e) => {
               params.current = Math.floor(e);
               getNews()
          }

          const search = () => {
               params.current = 1
               params.keyword = keyword.value
               getNews()
          }

          const querySelect = (type, item) => {
               if (type == 'cate') {
                    if (categories.sel == item.categoryId) {
                         categories.sel = ''
                         delete params.categoryId1
                    } else {
                         params.categoryId1 = item.categoryId
                         categories.sel = item.categoryId
                    }

               } else {

                    if (fields.sel == item.fieldId) {
                         delete params.fieldId
                         fields.sel = ''
                    } else {
                         params.fieldId = item.fieldId
                         fields.sel = item.fieldId
                    }

               }
               getNews()
          }

          const diyNavTo = (item) => {
               proxy.$diyNavTo(item)
          }

          const toGoodsDetail = (good) => {

               if (!good.seriesId) {
                    ElMessage(L['该新品的系列Id不存在'])
                    return
               }

               let newWin = router.resolve({
                    path: '/goods/series',
                    query: {
                         seriesId: good.seriesId
                    }
               })

               window.open(newWin.href, '_blank')
          }

          onMounted(() => {
               getFields()
               getCategories()
               getNews()
               getCarousel()
          })

          return {
               categories,
               fields,
               goodsList,
               ...toRefs(pageState),
               keyword,
               querySelect,
               search,
               carouselList,
               diyNavTo,
               toGoodsDetail,
               handleCurrentChange,
               L
          }
     }
}
</script>

<style lang="scss">
.cate1 {
     .el-scrollbar {}

     .el-scrollbar__view {
          max-height: 390px;
     }
}

.main_con {
     background: $colorC;
     padding-bottom: 40px;

     .carousel {
          .el-carousel {
               width: 100%;
               margin: 0 auto;
          }

          background: $colorK;

          .el-carousel__item {
               /* width: 1920px;
            /*图片宽度*/
               /* position: absolute;
            left: 50%;
            margin-left: -960px; */
               /* 图片宽度的一半 */
               display: flex;
               justify-content: center;
               align-items: center;

               div {
                    background-position: center center;
                    width: 1920px;
                    height: 457px;
                    position: absolute;
                    left: 50%;
                    top: 0;
                    margin-left: -960px;
                    background-repeat: no-repeat;
                    background-size: contain;
               }
          }
     }

     .products {
          width: 1200px;
          background: #fff;
          padding: 20px;
          margin: 0 auto;

          .l_con {
               .search {
                    width: 212px;
                    height: 36px;
                    background: #FFFFFF;
                    border: 1px solid #E2E2E2;
                    padding-left: 10px;

                    input {
                         height: 30px;
                         width: 160px;
                         font-size: 13px;
                         border: none;
                    }

                    .el-icon-search {
                         font-size: 22px;
                         color: #E2E2E2;
                         margin-left: 5px;

                         &:hover {
                              color: $colorMain;
                         }
                    }
               }

               .cate1 {
                    width: 212px;
                    margin-top: 20px;
                    border: 1px solid #E2E2E2;

                    &.cate_pad {
                         padding-bottom: 10px;
                    }


                    .t1 {
                         padding-left: 20px;
                         height: 36px;
                         background: $colorJ;
                         font-size: 14px;
                         font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                         font-weight: bold;
                         color: #666666;
                         line-height: 36px;
                    }

                    .t1_box {
                         max-height: 400px;
                         background-color: #f1f4fd;
                    }

                    .item1 {
                         position: relative;
                         line-height: 20px;
                         color: #666666;
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                         font-weight: 400;
                         padding: 10px 15px;
                         background: #fff;
                         cursor: pointer;

                         text {
                              display: -webkit-box;
                              word-break: break-all;
                              -webkit-line-clamp: 2;
                              -webkit-box-orient: vertical;
                              overflow: hidden;
                         }

                         &.seled {
                              background-color: #F1F4FD;
                              color: $colorMain;
                         }

                         &:hover {
                              color: $colorMain;
                         }
                    }
               }
          }

          .r_con {

               flex-wrap: wrap;
               flex: 1;

               .item2 {
                    width: 217px;
                    height: 280px;
                    margin-left: 20px;
                    margin-bottom: 20px;
                    cursor: pointer;

                    .img1 {
                         border: 1px solid $colorI;

                         width: 217px;
                         height: 165px;

                         .img {
                              width: 125px;
                              height: 125px;
                              background-position: center center;
                              background-repeat: no-repeat;
                              background-size: cover;
                         }
                    }

                    .content1 {
                         margin-top: 10px;

                         .txt1 {
                              font-size: 16px;
                              font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                              font-weight: bold;
                              color: #333333;
                              margin-bottom: 10px;
                              word-break: break-all;
                              display: -webkit-box;
                              -webkit-box-orient: vertical;
                              -webkit-line-clamp: 2;
                              overflow: hidden;
                         }

                         .txt2 {
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                              font-weight: 400;
                              color: #858585;
                              line-height: 24px;
                              word-break: break-all;
                              display: -webkit-box;
                              -webkit-box-orient: vertical;
                              -webkit-line-clamp: 2;
                              overflow: hidden;
                              text-indent: 15px;
                         }
                    }
               }

               .empty_con {
                    width: 840px;
                    height: 600px;
                    margin-left: 20px;

                    .empty {
                         img {
                              height: 140px;
                              width: 180px;
                         }

                         P {
                              font-size: 16px;
                              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                              font-weight: 500;
                              color: #666666;
                         }
                    }
               }


          }
     }
}
</style>
<template>
     <div class="box88 flex-row" @click="toDetail(item3)">
          <div class="block12 flex-col">
          </div>
          <div class="block13 flex_column_between_start">
               <div class="flex-col">
                    <span class="word45">{{item3.title}}</span>
                    <span class="word46">{{item3.introduction}}</span>
               </div>
               <div class="main9 flex-row">
                    <span class="word47">{{item3.storeName}}</span>
                    <span class="word48">{{item3.createTime}}</span>
               </div>
          </div>
          <div class="block145">
               <span class="iconfont iconkejian label8"></span>
               <span class="info17">{{item3.pageView}}&nbsp;{{L['阅读']}}</span>
          </div>
     </div>
</template>



<script>
     import { reactive, getCurrentInstance, ref, onMounted, toRefs, watch, watchEffect } from "vue";
     import { useRoute, useRouter } from 'vue-router'
     export default {
          props: ['item3', 'storeId'],
          setup(props) {
               const route = useRoute()
               const router = useRouter()
               const storeId = ref(props.storeId);
               const { proxy } = getCurrentInstance();
               const L = proxy.$getCurLanguage()
               const toDetail = (item) => {
                    let query = {
                         infoId: item.informationId
                    }
                    if (storeId.value) {
                         query.storeId = storeId.value;
                    }
                    router.push({
                         path: '/information/detail',
                         query
                    })
               }

               return {
                    toDetail,
                    storeId,
                    L
               }
          }
     }

</script>

<style lang="scss">
     .box88 {
          z-index: auto;
          padding: 13px 20px;
          border-bottom: 1px solid #ddd;
          position: relative;
          cursor: pointer;

          &:last-child {
               border-bottom: none;

          }

          .block12 {
               position: relative;
               width: 1px;
               height: 144px;
               overflow: hidden;
          }

          .block13 {
               margin: 10px 0 0 10px;

               .word45 {

                    color: rgba(51, 51, 51, 1);
                    font-size: 20px;
                    font-family: SourceHanSansCN-Bold;
                    line-height: 24px;
                    text-align: left;
                    align-self: flex-start;
                    cursor: pointer;
                    word-break: break-all;

                    &:hover {
                         color: $colorMain;
                    }
               }

               .word46 {

                    color: rgba(133, 133, 133, 1);
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
                    line-height: 20px;
                    text-align: left;
                    margin-top: 12px;
               }

               .main9 {
                    .word47 {
                         height: 14px;
                         display: block;

                         color: rgba(174, 174, 174, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         white-space: nowrap;
                         line-height: 14px;
                         text-align: left;
                         margin-top: 1px;
                    }

                    .label7 {
                         margin-left: 30px;
                    }

                    .word48 {
                         height: 14px;
                         display: block;

                         color: rgba(174, 174, 174, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         white-space: nowrap;
                         line-height: 14px;
                         text-align: left;
                         margin: 1px 0 0 6px;
                    }
               }
          }

          .block145 {
               position: absolute;
               right: 20px;
               bottom: 10px;

               .label8 {
                    font-size: 16px;
               }

               .info17 {

                    color: rgba(174, 174, 174, 1);
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;

                    text-align: left;
                    margin-left: 10px;
               }
          }
     }
</style>
// 售后列表
<template>
  <div class="sld_after_sales_list">
    <MemberTitle :memberTitle="curTitleId == 2 ? L['我的退货'] : L['我的退款']" style="padding-left:20px"></MemberTitle>
    <div class="after_sales_list_main">
      <div class="after_sales_title flex_row_start_center">

        <div v-for="(titleItem, titleIndex) in afterSalesTitle.data" :key="titleIndex"
          class="after_sales_title_pre cursor_pointer" :class="{ active_title: titleItem.titleId == curTitleId }"
          @click="changeNav(titleIndex)">
          {{ titleItem.content }}
        </div>
      </div>
      <div class="after_sales_list">
        <div class="after_sales_list_title flex_row_start_center">
          <div class="title_goods_des">{{ L['产品明细'] }}</div>
          <div class="title_apply_money">{{ L['退款金额'] }}</div>
          <div class="title_apply_time">{{ L['申请时间'] }}</div>
          <div class="title_apply_type">{{ L['申请类型'] }}</div>
          <div class="title_status">{{ L['状态'] }}</div>
          <div class="title_options">{{ L['操作'] }}</div>
        </div>
        <div class="after_sales_list_con">
          <div class="list_con_pre" v-for="(afterSalesItem, afterSalesIndex) in afterSalesList.data"
            :key="afterSalesIndex">
            <div class="list_con_pre_title flex_row_start_center">
              <p>{{ L['申请时间'] }}：{{ afterSalesItem.applyTime }}</p>
              <p>{{ L['订单号'] }}：{{ afterSalesItem.orderSn }}</p>
              <p>{{ L['退款单号'] }}：{{ afterSalesItem.afsSn }}</p>
              <p class="storeName cursor_pointer" @click="toStore(afterSalesItem.storeId)">{{
                afterSalesItem.storeName
              }}</p>
            </div>
            <div class="list_con">
              <div class="list_pre flex_row_start_center">
                <div class="list_goods_des borderRight flex_row_start_center cursor_pointer"
                  @click="goGoodsDetail(afterSalesItem.productId)">
                  <div class="list_goods_img" :style="{
                    backgroundImage:
                      'url(' + afterSalesItem.goodsImage + ')',
                  }"></div>
                  <div class="goods_des">
                    <p class="goods_name">{{ afterSalesItem.goodsName }}</p>
                    <p class="goods_spec">{{ afterSalesItem.specValues }}</p>
                    <p class="product_code" v-if="afterSalesItem.orderCode">{{L['订货编码：']}}{{ afterSalesItem.orderCode }}</p>

                  </div>
                </div>
                <div class="list_apply_money borderRight flex_row_center_center">
                  {{ afterSalesItem.returnMoneyAmount }}
                </div>
                <div class="list_apply_time borderRight flex_row_center_center">
                  {{ afterSalesItem.applyTime }}
                </div>
                <div class="list_apply_type borderRight flex_row_center_center">
                  {{ afterSalesItem.returnTypeValue }}
                </div>
                <div class="list_status borderRight flex_row_center_center">
                  {{ afterSalesItem.stateValue }}
                </div>
                <div class="list_options flex_column_center_center">
                  <span class="cursor_pointer" @click="goRefundDetail(afterSalesItem)">{{ L['查看详情'] }}</span>
                  <span class="cursor_pointer" v-if="
                    afterSalesItem.returnType == 2 &&
                    afterSalesItem.state == 201
                  " @click="logisticsBill(afterSalesItem)">{{ L['发货'] }}</span>
                </div>
              </div>
            </div>
          </div>
          <SldCommonEmpty v-show="!afterSalesList.data.length" totalWidth="900"></SldCommonEmpty>
          <div class="flex_row_end_center sld_pagination sld_page_bottom" v-if="
            aftersalesDetail.data &&
            aftersalesDetail.data.pagination &&
            aftersalesDetail.data.pagination.total
          ">
            <el-pagination @current-change="handleCurrentChange" v-model:currentPage="current" :page-size="pageSize"
              layout="prev, pager, next, jumper" :total="aftersalesDetail.data.pagination.total"
              :hide-on-single-page="true">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, ref, getCurrentInstance, watchEffect } from "vue";
import { ElMessage, ElPagination } from "element-plus";
import SldCommonEmpty from '../../../components/SldCommonEmpty';
import { useRouter } from "vue-router";
import MemberTitle from "../../../components/MemberTitle";

export default {
  components: {
    ElPagination,
    MemberTitle,
    SldCommonEmpty
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const router = useRouter();
    const afterSalesTitle = reactive({
      data: [
        {
          titleId: 1,
          content: L['仅退款'],
        },
        {
          titleId: 2,
          content: L['退货退款'],
        },
      ],
    });
    const curTitleId = ref(1); //当前选中的标题id
    const afterSalesList = reactive({ data: [] }); //售后列表
    const pageSize = ref(10);
    const current = ref(1);
    const aftersalesDetail = reactive({ data: {} }); //售后列表详情数据

    const toStore = storeId => {
      if (storeId == 0) {
        return
      }
      proxy.$goStoreDetail(storeId);
    };

    onMounted(() => {
      getAfterSalesDet();
    });


    //获取售后订单列表
    const getAfterSalesDet = () => {
      let params = {
        returnType: curTitleId.value,
        current: current.value,
        pageSize: pageSize.value,
      };
      proxy.$get("v3/business/front/after/sale/list", params).then((res) => {
        if (res.state == 200) {
          let result = res.data;
          aftersalesDetail.data = result;
          afterSalesList.data = result.list;
        } else {
          ElMessage.error(res.data);
        }
      });
    };
    //选择类型
    const titleNav = (titleId) => {
      curTitleId.value = titleId;
      getAfterSalesDet();
    };
    //选择页面
    const handleCurrentChange = e => {
      current.value = Math.floor(e);
      getAfterSalesDet();
    };
    //查看售后详情
    const goRefundDetail = (afterSalesItem) => {
      router.push({
        path: "/member/order/refund/detail",
        query: {
          curTitleId: curTitleId.value,
          orderState: afterSalesItem.orderState,
          afsSn: afterSalesItem.afsSn,
        },
      });
    };
    //填写物流单号
    const logisticsBill = (afterSalesItem) => {
      router.push({
        path: "/member/order/logistics",
        query: {
          afsSn: afterSalesItem.afsSn,
        },
      });
    };
    //去商品详情页
    const goGoodsDetail = (productId) => {
      proxy.$goGoodsDetail(productId);
    };
    //切换导航
    const changeNav = (index) => {
      curTitleId.value = index + 1;
      getAfterSalesDet();
    };

    return {
      L,
      afterSalesTitle,
      curTitleId,
      afterSalesList,
      titleNav,
      pageSize,
      current,
      aftersalesDetail,
      handleCurrentChange,
      goRefundDetail,
      logisticsBill,
      goGoodsDetail,
      toStore,
      changeNav,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/base.scss";
@import "../../../style/afterSalesList.scss";

.storeName {
  color: $colorTitle1;

  &:hover {
    color: $colorMain;
  }
}
</style>
<template>
    <div class="brand_content">
        <div class="brand_index">
            <div class="title">品牌搜索</div>
            <div class="pin-center">
                <el-select v-model="selectBrand" placeholder="请选择品牌" style="width: 376px;height: 51px;"
                    @change="brandChange" v-if="brandList.data.length"> 
                    <el-option v-for="item in brandList.data" :key="item.brandId" :label="item.brandName"
                        :value="item.brandId" />
                    
                </el-select>
                <!-- <el-select @click="selectClick" :disabled="!selectBrand" v-model="selectSeries" placeholder="请选择系列"
                    style="width: 376px;height: 51px;" @change="seriesChange">
                    <el-option v-for="item in seriesList.data" :key="item.seriesId" :label="item.seriesName"
                        :value="item.seriesId" />
                </el-select>  -->
                
                <template v-if="flag">
                <el-select placeholder="请选择系列" @click="selectClick" :disabled="!selectBrand" v-model="selectSeries"
                    style="width: 376px;height: 51px;" @change="seriesChange" >
                    <el-option-group  v-for="group in seriesList.data" :key="group.categoryName" :label="group.categoryName" >
                        <el-option   v-for="item in group.goodsSeriesList" :key="item.seriesId" :label="item.seriesName"
                            :value="item.seriesId"  />
                    </el-option-group>
                </el-select>
            </template>
             <template v-else>
                <el-select placeholder="请选择系列" @click="selectClick" :disabled="!selectBrand" v-model="selectSeries"
                    style="width: 376px;height: 51px;" @change="seriesChange" >
                    <el-option-group  v-for="group in []" :key="group.categoryName" :label="group.categoryName" >
                        <el-option   v-for="item in group.goodsSeriesList" :key="item.seriesId" :label="item.seriesName"
                            :value="item.seriesId"  />
                    </el-option-group>
                </el-select>
            </template>
            </div>
            <div class="btn" @click="toQuickSearch">快速检索</div>
        </div>
    </div>
</template>
<script>
import { getCurrentInstance, onMounted, reactive, ref } from "vue";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
export default {
    name: "goodsList",
    components: {
    },
    setup() {
        //变量------------------------------
        const { proxy } = getCurrentInstance();
        const router = useRouter();
        // 选择的品牌和系列
        const selectBrand = ref('')
        const categoryId3 = ref('')
        const selectSeries = ref('')
        // 品牌列表
        const brandList = reactive({
            data: []
        })
        const flag = ref(false)
        // 系列列表
        const seriesList = reactive({
            data: [0]
        })

        // 获取品牌列表
        const GetBrandList = () => {
            proxy
                .$get('v3/goods/front/goods/getBrandlist')
                .then(res => {
                    if (res.state == 200) {
                        brandList.data = res.data.list
                    }
                })

        }
        // 获取系列列表
        const GetSeriesList = (brandId) => {
            selectSeries.value = '';
            flag.value = false;
            let params = {
                brandId: brandId,
            };
            proxy
                .$get('v3/goods/front/goods/getSeriesByBrandId', params)
                .then(res => {
                    if (res.state == 200) {
                        seriesList.data = res.data
                        flag.value = true
                    }
                })

        }

        const brandChange = (val) => {
            selectBrand.value = val
            GetSeriesList(val)
        }
        const seriesChange = (val) => {
            seriesList.data.map((item,index)=>{
                item.goodsSeriesList.map((it,ind)=>{
                    if(it.seriesId == val){
                        categoryId3.value = it.categoryId3
                    }
                })
            })
            selectSeries.value = val;
        }

        // 快速检索按钮
        const toQuickSearch = () => {
            if (!selectBrand.value || !selectSeries.value) {
                ElMessage('请选择品牌和系列')
                return
            }
            if (router.currentRoute.value.path == '/goods/list') {
                let query = router.currentRoute.value.query
                router.push({
                    path: '/goods/list',
                    query: {
                        ...query,
                        brandId: selectBrand.value,
                        seriesId: selectSeries.value,
                    }
                })
            } else {
                router.push({
                    path: '/goods/list',
                    query: {
                        brandId: selectBrand.value,
                        seriesId: selectSeries.value,
                        categoryId3: categoryId3.value
                    }
                })
            }


        }
        const selectClick = () => {
            if (!selectBrand.value) {
                ElMessage('请先选择品牌')
                return
            }
        }

        onMounted(() => {

            GetBrandList()

        })
        //返回值
        return {
            flag,
            brandList,
            seriesList,
            selectBrand,
            selectSeries,
            GetSeriesList,
            seriesChange,
            brandChange,
            GetBrandList,
            toQuickSearch,
            selectClick,
            categoryId3,
        };
    },
    //
};
</script>
 
<style lang="scss" scoped>
.brand_content {
    width: 100%;
    height: 848px;
    // background-color: #ccc;
    overflow: hidden;
    background: url('~@/assets/goods/brand_bg.png') no-repeat;
    background-size: 100% 100%;

    .brand_index {
        width: 906px;
        height: 334px;
        background-color: #fff;
        margin: 114px auto;
        border-radius: 5px;
        padding: 15px 20px;
        font-size: 14px;
        box-shadow: 0 0 10px #eee;

        .title {
            font-size: 14px;
            color: #101010;
        }

        .pin-center {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 50px 40px;

            :deep(.el-select) {
                .select-trigger {
                    width: 100% !important;
                    height: 100% !important;

                    .el-input {
                        width: 100% !important;
                        height: 100% !important;

                        .el-input__inner {
                            width: 100% !important;
                            height: 100% !important;
                        }

                        .el-input__suffix {
                            top: 5px !important;
                        }
                    }
                }

            }

        }

        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 375px;
            height: 38px;
            background-color: #285FDE !important;
            font-size: 16px !important;
            color: #fff !important;
            margin: 0 auto;
            border-radius: 2px;
            cursor: pointer;
        }
    }
}

:deep(.el-select-group__title) {
    font-weight: bold !important;
    color: #101010 !important;
    font-size: 14px !important;
}
</style>
<template>
     <div>
          <!-- 配送至地区 start -->
          <div class="delivery flex_row_start_center">
               <div class="delivery_title">{{ L['配送至'] }}</div>
               <label class="delivery_address flex_row_between_center" @click.stop="addrDialogVisible = !addrDialogVisible"
                    for="invisibleInput">
                    <span>{{ curAddrName ? curAddrName : L['请选择地址'] }}</span>
                    <i class="iconfont iconziyuan11-copy"></i>
               </label>
               <div class="address_dia" v-if="addrDialogVisible">
                    <div class="add_dia_top">
                         <div :class="{ dia_top_item: true, sel_item: addrIdx == 0 }" @click.stop="addrIdx = 0"
                              v-if="logFlag && address_list.data.length">{{L['常用地址']}}</div>
                         <div :class="{ dia_top_item: true, sel_item: addrIdx == 1 }" @click.stop="addrIdx = 1">{{L['其他地址']}}
                         </div>
                    </div>
                    <div class="add_dia_bottom">
                         <div class="usual_addr" v-if="addrIdx == 0 && (logFlag && address_list.data.length)">
                              <el-radio-group v-model="curAddr" @change="changeAddr($event)" text-color="#333333"
                                   fill="#FC1C1C" v-if="address_list.data && address_list.data.length">
                                   <el-radio v-for="(item, index) in address_list.data" :key="index" :label="item.addressId"
                                        class="promotion_radio" @click.stop="() => { }">
                                        {{ item.addressAll }}{{ item.detailAddress }}
                                   </el-radio>
                              </el-radio-group>
                         </div>
                         <div class="other_addr" v-if="addrIdx == 1">
                              <div class="o_add_top">
                                   <div :class="{ o_addr_item: true, o_sel: otherAddrIdx == id }"
                                        v-for="(it, id) in otherTree" :key="id" @click.stop="selRegion(id)">
                                        {{ it.regionName }}</div>
                              </div>
                              <div class="o_add_bottom">
                                   <div :class="{ o_addrC_item: true, o_selected: othTopIdx[otherAddrIdx] == index }"
                                        v-for="(item, index) in othAddrDe" :key="index"
                                        @click.stop="selRegionChild(index, otherAddrIdx)">
                                        {{ item.regionName }}
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
          <!-- 配送至地区 end -->
          <!-- 运费 start -->
          <div class="freight flex_row_start_center">
               <div class="freight_title">{{ L['运费'] }}</div>
               <span v-if="deliverInfo.expressFee">{{L['￥']}}{{ deliverInfo.expressFee }}</span>
               <span v-else>{{L['免运费']}}</span>
          </div>
          <!-- 运费 end -->
     </div>
</template>


<script>
import { ref, reactive, onMounted, watch, getCurrentInstance } from 'vue'
import addrData from '@/assets/area.json'
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
export default {
     props: {
          deliverInfo: Object,
     },

     setup(props, { emit }) {
          const { proxy } = getCurrentInstance();
          const store = useStore()
          const curAddr = ref(-1);
          const curAddrName = ref('')
          const addrIdx = ref(0)
          const otherAddrIdx = ref(0)
          const L = proxy.$getCurLanguage()
          const othTopIdx = reactive({
               0: 0,
               1: 0,
               2: 0
          })
          const otherTree = ref([addrData[othTopIdx["0"]], addrData[othTopIdx["0"]].children[othTopIdx["1"]]])
          const othAddrDe = ref(addrData)
          const addrDialogVisible = ref(false)
          const address_list = reactive({ data: [] });
          const route = useRoute()
          const logFlag = ref(store.state.loginFlag)
          const regionCode = ref(0)
          const currentNum = ref(1)

          //获取地址列表
          const getAddressList = () => {
               proxy.$get("v3/member/front/memberAddress/list", {
                    pageSize: 1000
               }).then(res => {
                    if (res.state == 200) {
                         if (res.data.list.length > 0) {
                              address_list.data = res.data.list;
                              if (address_list.data.findIndex(i => i.isDefault == 1) > 0) {
                                   let index = address_list.data.findIndex(i => i.isDefault == 1)
                                   curAddrName.value = address_list.data[index].addressAll + "" + address_list.data[index].detailAddress
                                   curAddr.value = address_list.data[index].addressId
                                   regionCode.value = address_list.data[index].cityCode
                              } else {
                                   curAddrName.value = address_list.data[0].addressAll + "" + address_list.data[0].detailAddress
                                   curAddr.value = address_list.data[0].addressId
                                   regionCode.value = address_list.data[0].cityCode
                              }

                              if (localStorage.getItem('addressId')) {
                                   let addressID = localStorage.getItem('addressId')
                                   if (res.data.list.filter(i => i.addressId == addressID)[0]) {
                                        let tmp = res.data.list.filter(i => i.addressId == addressID)[0]
                                        curAddr.value = tmp.addressId
                                        curAddrName.value = tmp.addressAll + "" + tmp.detailAddress
                                        regionCode.value = tmp.cityCode
                                   }
                              }
                         } else {
                              curAddrName.value = ''
                              addrIdx.value = 1
                         }
                    }
               })
          };

          //修改选择地址列表
          const changeAddr = (e) => {
               curAddr.value = e;
               let tmp = address_list.data.filter(i => i.addressId == e)[0]
               curAddrName.value = tmp.addressAll + "" + tmp.detailAddress
               addrDialogVisible.value = false
               let cityCode = tmp.cityCode
               let addressId = tmp.addressId
               getUserEx(cityCode, addressId)
          };

          // 选择其他地址的顶部选项
          const selRegion = (index) => {
               otherAddrIdx.value = index
               switch (index) {
                    case 0: {
                         othAddrDe.value = addrData
                         break
                    }
                    case 1: {
                         othAddrDe.value = addrData[othTopIdx["0"]].children
                         break
                    }
                    case 2: {
                         othAddrDe.value = addrData[othTopIdx["0"]].children[othTopIdx["1"]].children
                         break
                    }
               }
               otherTree.value[otherAddrIdx.value] = othAddrDe.value[othTopIdx[otherAddrIdx.value]]
          }

          // 选择其他地址的顶部选项下的子选项
          const selRegionChild = (index, otherIdx) => {
               othTopIdx[otherIdx] = index
               otherTree.value[otherAddrIdx.value] = othAddrDe.value[othTopIdx[otherIdx]]
               if (othAddrDe.value[othTopIdx[otherAddrIdx.value]].children && othAddrDe.value[othTopIdx[otherAddrIdx.value]].children.length) {
                    otherTree.value.splice(otherAddrIdx.value + 1)
                    othTopIdx[otherIdx + 1] = 0
                    selRegion(otherAddrIdx.value + 1)
               } else {
                    let regionCode = otherTree.value[otherAddrIdx.value - 1].regionCode
                    addrDialogVisible.value = false
                    curAddr.value = -1
                    curAddrName.value = ''
                    otherTree.value.map(i => {
                         curAddrName.value += i.regionName
                    })
                    getUserEx(regionCode)
               }
          }

          //用于切换用户的地址，获取信运费等
          const getUserEx = (cityCode) => {

               if (!regionCode.value) {
                    regionCode.value = cityCode
               }


               proxy.$get('v3/goods/front/goods/calculateExpress', {
                    cityCode,
                    productId: route.query.productId,
                    num: currentNum.value
               }).then(res => {
                    if (res.state == 200) {
                         props.deliverInfo.expressFee = res.data.toFixed(2)
                    }
               })
               if (curAddr.value >= 0) {
                    proxy.$post("v3/member/front/memberAddress/changeDefaultAddress", {
                         addressId: curAddr.value,
                         isDefault: 1
                    }).then(() => { }).catch(() => { });
               }
          };
          //end


          const changeNum = (num) => {
               currentNum.value = num
               getUserEx(regionCode.value)
          }

          onMounted(() => {
               if (store.state.loginFlag) {
                    getAddressList()
               } else {
                    addrIdx.value = 1
               }
          })


          return {
               address_list,
               addrDialogVisible,
               addrIdx,
               curAddrName,
               otherAddrIdx,
               logFlag,
               L,
               otherTree,
               changeAddr,
               selRegionChild,
               othAddrDe,
               othTopIdx,
               selRegion,
               curAddr,
               changeNum
          }
     }
}
</script>


<style lang="scss">
@import "@/style/goodsDetail.scss";


.delivery {
     .el-radio__label {
          font-size: 13px;
          width: 320px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-all;
          line-height: 22px;
          margin-top: -5px;
     }

     .promotion_radio {
          margin-bottom: 10px;
          display: flex;
          align-items: flex-start;
          white-space: unset;

     }
}
</style>
// 推荐商品列表组件(购物车下面的推荐商品) @ljp-2021/1/4
<template>
    <div class="sld_recommend_list" :class="{ skelelon_loading: firstLoading }">
        <div class="hot_recommend" v-if="(hotRecommendList.data && hotRecommendList.data.length > 0) || firstLoading">
            <h2>{{ L['新品推荐'] }}</h2>
            <div class="hot_recommend_list flex_row_start_center">
                <div class="hot_recommend_pre flex_column_between_start"
                    v-for="(hotRecListItem, hotRecListIndex) in firstLoading ? 5 : hotRecommendList.data"
                    :key="hotRecListIndex" @click="goGoodsDetail(hotRecListItem.productId)">
                    <div class="recommend_pre_top">
                        <div class="hot_recommend_pre_img"
                            :style="{ backgroundImage: 'url(' + hotRecListItem.goodsImage + ')' }"></div>
                        <div class="hot_recommend_pre_name">{{ hotRecListItem.goodsName }}</div>
                        <div class="hot_recommend_pre_brief">{{ hotRecListItem.specValues }}</div>
                        <div class="hot_recommend_pre_price">
                            <span v-if="useLoginFlag">{{L['￥']}}{{ hotRecListItem.goodsPrice ? hotRecListItem.goodsPrice : '0' }}</span>
                            <span v-else>登录后查看价格</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, getCurrentInstance, ref } from "vue";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
export default {
    name: 'RecommendList',
    props: ["firstLoading"],
    setup() {
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const hotRecommendList = reactive({ data: [] }); //新品推荐商品列表
        const store = useStore();
        const useLoginFlag = ref(store.state.loginFlag);
        //获取新品推荐商品列表
        const getHotRecommendList = () => {
            proxy.$get("v3/goods/front/goods/recommendList").then((res) => {
                if (res.state == 200) {
                    hotRecommendList.data = res.data;
                    hotRecommendList.data.map(item => item.goodsPrice = new Number(item.goodsPrice).toFixed(2))
                } else {
                    ElMessage.error(res.msg);
                }
            });
        };
        getHotRecommendList();
        //去商品详情页
        const goGoodsDetail = (productId) => {
            proxy.$goGoodsDetail(productId);
        };
        return {
            hotRecommendList,
            L,
            goGoodsDetail,
            useLoginFlag
        }
    }
}
</script>

<style lang="scss" scoped>
// 推荐商品 start
.sld_recommend_list {
    &.skelelon_loading {
        .hot_recommend {
            .hot_recommend_list {
                .recommend_pre_top {
                    .hot_recommend_pre_img {
                        width: 208px;
                        height: 208px;
                        color: $colorSkeleton;
                        background: $colorSkeleton;
                    }

                    .hot_recommend_pre_name,
                    .hot_recommend_pre_brief,
                    .hot_recommend_pre_price {
                        margin-top: 10px;
                        width: 120px;
                        height: 17px;
                        color: $colorSkeleton;
                        background: $colorSkeleton;
                    }
                }
            }
        }
    }

    .hot_recommend {
        width: 1200px;
        margin: 0 auto;

        h2 {
            font-size: 23px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 60px;
        }

        .hot_recommend_list {
            flex-wrap: wrap;
            padding-top: 8px;
            background: #FFFFFF;

            .hot_recommend_pre {
                width: 240px;
                height: 340px;
                border-radius: 2px;
                padding: 15px;
                overflow: hidden;
                cursor: pointer;

                &:nth-child(4n) {
                    margin-right: 0;
                }

                &:hover {
                    position: relative;
                    box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
                    transform: translate3d(0, -2px, 0);
                    transition: all .2s linear;
                    z-index: 2;
                }
            }

            .recommend_pre_top {
                width: 210px;
                height: 310px;

                .hot_recommend_pre_img {
                    width: 208px;
                    height: 208px;
                    margin: 0 auto;
                    border-radius: 2px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }

                .hot_recommend_pre_price {
                    margin-top: 5px;
                    margin-bottom: 5px;

                    span {
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: $colorMain2;

                        &:nth-child(2) {
                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            text-decoration: line-through;
                            color: $colorH;
                        }
                    }
                }

                .hot_recommend_pre_name {
                    display: -webkit-box;
                    width: 210px;
                    line-height: 18px;
                    font-size: 14px;
                    font-weight: 400;
                    font-family: Microsoft YaHei;
                    color: $colorF;
                    overflow: hidden;
                    text-overflow: -o-ellipsis-lastline;
                    text-overflow: ellipsis;
                    line-clamp: 2;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    margin-top: 10px;
                }

                .hot_recommend_pre_brief {
                    width: 210px;
                    color: $colorG;
                    margin-top: 8px;
                    margin-bottom: 8px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            .recommend_pre_bottom {
                .hot_recommend_pre_sale {
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: $colorH;

                    span {
                        color: $colorMain2;
                    }
                }

                .hot_recommend_pre_store {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    line-height: 18px;
                    margin: 15px 0;
                    display: block;
                }

                .hot_recommend_pre_activity {
                    .recommend_activity_pre {
                        min-width: 60px;
                        height: 20px;
                        border: 1px solid $colorMain;
                        border-radius: 3px;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: $colorMain2;
                        text-align: center;
                        display: inline-block;
                        line-height: 17px;
                        padding: 0 10px;
                    }
                }
            }
        }
    }
}

// 推荐商品 end
</style>
<template>
    <div class="sld_vendor_item clearfix">
        <div class="sld_vendor fl">
            <div class="sld_vendor_logo sld_img_center">
                <!-- <img class="lazy" :src="item.storeLogo" alt=""> -->
                <coverImage :src="item.storeLogo" width="114" height="114"></coverImage>
            </div>
            <div class="sld_vendor_name">{{ item.storeName }}</div>
            <div class="sld_rate clearfix">
                <el-rate disabled :colors="colors" score-template="{score}" v-model="score">
                </el-rate>
            </div>
            <div class="sld_vendor_top clearfix">
                <button class="fl" @click="topfollow(item.followId, 1)" v-if="collectStoreIndex == 2">
                    {{ L['设为特殊关注']}} </button>
                <button class="fl" @click="topfollow(item.followId, 0)" v-else>
                    {{ L['取消特殊关注']}} </button>

                <button class="fr" @click="unfollow(item.storeId)">{{ L['取消关注']}}
                </button>
            </div>
            <div class="sld_vendor_bottom">
                <router-link :to="`/store/index?vid=${item.storeId}`"><i class="iconfont">&#xe654;</i>{{ L['进入供应商']}}
                </router-link>
            </div>
        </div>
        <div class="main_lbbox fl">
            <div class="sld_vendor_collect">
                <a id="newGoods" :class="1 === storeGoodsType ? 'sld_follow_on' : ''" @click="storeGoodsSwitch(1)"
                    style="cursor: pointer;">{{ L['热销推荐']}}({{ item.goodsList.length }})</a>
                <a id="recommendGoods" :class="{ sld_follow_on: 2 === storeGoodsType }" @click="storeGoodsSwitch(2)"
                    style="cursor: pointer;">{{ L['本月上新']}}({{ item.newGoodsList.length }})</a>
            </div>
            <div class="sld_vendor_goods clearfix">
                <div class="hd">
                    <a href="javascript:;" class="next" @click="slideWin(1)" ref="next">
                        <i class="iconfont">&#xe634;</i>
                    </a>
                    <a href="javascript:;" class="prev" @click="slideWin(0)" ref="prev">
                        <i class="iconfont">&#xe634;</i>
                    </a>
                </div>
                <div class="slide_wrap">
                    <ul class="bd clearfix" v-if="2 == storeGoodsType">
                        <li class="new_goods" v-for="(sub, subindex) in item.newGoodsList" :key="subindex">
                            <router-link :to="`/goods/detail?productId=${sub.defaultProductId}`">
                                <div class="sld_img sld_img_center">
                                    <!-- <img class="lazy" :src="sub.mainImage" alt=""> -->
                                    <coverImage :src="sub.mainImage" width="150" height="150"></coverImage>
                                </div>
                                <p class="goods_name">{{ sub.goodsName }}</p>
                                <p class="goods_price">
                                    {{L['￥']}}{{sub.goodsPrice}}</p>
                            </router-link>
                        </li>
                        <SldCommonEmpty v-show="!item.newGoodsList.length" totalHeight="228" paddingTop="50" />
                    </ul>
                    <ul class="bd clearfix" v-else>
                        <li class="hot_goods" v-for="(sub, subindex) in item.goodsList" :key="subindex">
                            <router-link :to="`/goods/detail?productId=${sub.defaultProductId}`">
                                <div class="sld_img sld_img_center">
                                    <!-- <img class="lazy" :src="sub.mainImage" alt=""> -->
                                    <coverImage :src="sub.mainImage" width="150" height="150"></coverImage>

                                </div>
                                <p class="goods_name">{{ sub.goodsName }}</p>
                                <p class="goods_price">
                                    {{L['￥']}}{{sub.goodsPrice}}</p>
                            </router-link>
                        </li>
                        <SldCommonEmpty v-show="!item.goodsList.length" totalHeight="228" paddingTop="50" />
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SldCommonEmpty from '../components/SldCommonEmpty'
import { getCurrentInstance, ref, onMounted, watch } from 'vue'
import { ElMessage, ElRate } from "element-plus";
export default {
    name: 'collectStoreItem',
    props: ["item", "ItemIndex", 'collectStoreIndex'],
    components: {
        SldCommonEmpty,
        ElRate
    },
    setup(props, { emit }) {
        const { proxy } = getCurrentInstance()
        const L = proxy.$getCurLanguage()
        const storeGoodsType = ref(1)
        const colors = ref(['#E2231A', '#E2231A', '#E2231A'])
        //商品滑动
        const notClickQuick = ref(true)//防止快速点击造成dom混乱
        const slideWin = (ltr) => {
            let ul = document.getElementsByClassName('bd')[props.ItemIndex]
            let liLength = storeGoodsType.value === 2 ? ul.getElementsByClassName('new_goods').length : ul.getElementsByClassName('hot_goods').length
            let isanimate = false
            let nowMg = parseInt(getComputedStyle(ul, null).marginLeft)
            let max = liLength % 4 == 0 ? liLength * 170 : (Math.floor(liLength / 4) + 1) * 4 * 170
            ul.style.width = max + 'px'
            let slide_wrap_width = max - document.getElementsByClassName('slide_wrap')[props.ItemIndex].offsetWidth
            if (notClickQuick.value) {
                notClickQuick.value = false
                if (slide_wrap_width < 0 || isanimate) return;
                isanimate = true;
                let n = 170;
                if (ltr) {
                    nowMg = nowMg - (n * 4)
                    ul.style.marginLeft = (slide_wrap_width >= Math.abs(nowMg) ? nowMg : nowMg + n * 4) + 'px'
                } else {
                    nowMg = nowMg + (n * 4)
                    ul.style.marginLeft = (nowMg <= 0 ? nowMg : 0) + 'px'
                }
                proxy.$refs.next.style.backgroundColor = slide_wrap_width > Math.abs(nowMg) + 4 * 170 ? "#8d8b8b" : "#ccc"
                proxy.$refs.prev.style.backgroundColor = nowMg >= 0 ? "#ccc" : "#8d8b8b"
                isanimate = false;
                setTimeout(function () {
                    notClickQuick.value = true;
                }, 500)
            } else {
                return
            }
        }

        const score = ref(Number(props.item.serviceScore))
        //取消供应商关注
        const unfollow = (storeId) => {
            let params = {
                isCollect: false,
                storeIds: storeId
            }
            proxy.$post('v3/member/front/followStore/edit', params).then(res => {
                if (res.state == 200) {
                    emit('collectStoreUpdate')
                    ElMessage(res.msg)
                }
            })
        }

        //设置或取消特殊关注店铺
        const topfollow = (followId, isTop) => {
            let params = {
                followId: followId,
                isTop: isTop
            }
            proxy.$post('v3/member/front/followStore/editSpecial', params).then(res => {
                if (res.state == 200) {
                    emit('collectStoreUpdate')
                    ElMessage(res.msg)
                }
            })
        }

        watch(() => props.item, (ov, nv) => {
            score.value = Number(ov.serviceScore)
        })

        const storeGoodsSwitch = (index) => {
            storeGoodsType.value = index
        }

        onMounted(() => {
        })

        return { storeGoodsSwitch, topfollow, unfollow, storeGoodsType, slideWin, L, colors, score }
    }
}
</script>
<style lang="scss" scoped>
.fl {
    float: left;
}

.fr {
    float: right;
}

a:link,
a:visited,
a:active {
    text-decoration: none;
}

em,
i {
    font-style: normal;
}

.clearfix {
    zoom: 1;
    display: block;

    &:before {
        display: table;
        content: " ";
    }

    &:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
}

.sld_img_center {
    position: relative;
    width: 80px;
    height: 80px;
    overflow: hidden;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        max-height: 100%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

.sld_collect_wrapper {
    float: left;

    .wrapper_main {
        float: left;
        width: 1007px;
        margin-left: 10px;
        font-family: "微软雅黑";

        .sld_h3_wrap {
            border: 1px solid $colorI;
            background: #ffffff;

            .sld_option {
                height: 52px;
                width: 310px;

                .sld_option_list {
                    margin-top: 18px;
                    transition: all 0.5s;
                    margin-left: 15px;

                    &:hover {
                        color: $colorMain2;
                    }

                    img {
                        vertical-align: top;
                        float: left;
                        margin-top: -3px;
                        margin-right: 3px;
                    }

                    cursor: pointer;
                }

                .sld_option_btn {
                    padding: 5px 20px 5px;
                    margin-top: 13px;
                    margin-right: 10px;
                    font-family: "Microsoft YaHei";
                    background-color: #fff;
                    border: 1px solid #e7e7e7;
                    border-radius: 5px;
                    transition: all 0.2s;
                    cursor: pointer;

                    &:hover {
                        background-color: $colorMain2;
                        color: #fff;
                    }
                }
            }

            .sld_grade_nav {
                height: 52px;
                line-height: 52px;
                margin-left: -1px;

                a {
                    display: inline-block;
                    width: 128px;
                    text-align: center;
                    font-size: 14px;
                    color: $colorTitle1;
                    cursor: pointer;
                    border-right: 1px solid $colorI;

                    &:hover {
                        color: $colorMain2;
                    }
                }

                a.on {
                    color: $colorMain2;
                    height: 53px;
                    background-color: #fff;
                    font-weight: 600;
                }
            }
        }

        .fav_goods_list {
            overflow: hidden;
            width: 100%;

            &>ul {
                margin-top: 5px;
            }

            .sld_follow_goods:hover {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            }

            .sld_follow_goods {
                position: relative;
                float: left;
                width: 240px;
                height: 358px;
                background-color: #fff;
                margin: 0 15px 15px 0;
                border: 1px solid $colorI;
                padding: 13px;
                box-sizing: border-box;

                &:nth-of-type(4n) {
                    margin-right: 0;
                }

                .check_wrap {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 240px;
                    height: 358px;
                    background: rgba(0, 0, 0, 0.1);

                    .border {
                        display: block;
                        position: absolute;
                        right: -1px;
                        top: -1px;
                        width: 241px;
                        height: 360px;
                        z-index: 0;
                        cursor: pointer;

                        .checklogo {
                            position: absolute;
                            top: 0px;
                            right: 0px;
                            width: 31px;
                            height: 31px;
                            visibility: visible;
                            background-image: url(../assets/member/choose.png);
                        }
                    }

                    input[type="checkbox"] {
                        visibility: hidden;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        width: 31px;
                        height: 31px;
                        z-index: 1;
                    }

                    input[type="checkbox"]:checked~.border {
                        border: 2px solid #e60012;

                        .checklogo {
                            position: absolute;
                            top: -2px;
                            right: -2px;
                            width: 31px;
                            height: 31px;
                            background-image: url(../assets/member/chosen.png);
                        }
                    }
                }

                .cg_name {
                    font-size: 12px;
                    line-height: 16px;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    color: #333333;

                    em {
                        display: inline-block;
                        padding: 1px 4px;
                        border: 1px solid $colorMain2;
                        color: $colorMain2;
                        font-size: 12px;
                        -webkit-border-radius: 2px;
                        -moz-border-radius: 2px;
                        border-radius: 2px;
                    }
                }

                .cg_img {
                    width: 214px;
                    height: 214px;

                    .lazy {
                        width: 214px;
                        height: 214px;
                    }
                }

                .cg_btns {
                    position: absolute;
                    bottom: -1px;
                    left: -1px;
                    width: 240px;
                    height: 42px;

                    a {
                        float: left;
                        display: inline-block;
                        width: 50%;
                        height: 42px;
                        text-align: center;
                        color: #666666;
                        font-size: 12px;
                        line-height: 42px;
                        box-sizing: border-box;
                    }

                    a:nth-child(1) {
                        border-top: 1px solid $colorI;

                        &:nth-child(1):hover {
                            color: $colorMain;
                            cursor: pointer;
                        }
                    }

                    a:nth-child(2) {
                        border: 1px solid #eee;
                    }

                    a:nth-child(2):hover {
                        color: $colorMain;
                        cursor: pointer;
                    }
                }

                .cg_price {
                    margin: 10px 0 6px;

                    .fl {
                        color: $colorMain;
                        font-size: 14px;

                        .fl strong {
                            font-size: 18px;
                        }
                    }

                    .fr {
                        font-size: 12px;
                        color: #999;
                        line-height: 20px;

                        em {
                            color: $colorMain2;
                            margin-left: 2px;
                        }
                    }
                }
            }
        }

        .fav_store_list {
            margin-bottom: 100px;
            overflow: hidden;
            width: 100%;

            .fav_store_type {
                height: 50px;
                background: #fff;

                span {
                    margin-right: 2px;
                    display: inline-block;
                    line-height: 50px;
                    width: 118px;
                    text-align: center;
                    font-weight: 600;
                    font-size: 10px;
                    cursor: pointer;
                }

                .on {
                    color: $colorMain2;
                    border-bottom: 3px solid $colorMain2;
                }
            }

            .sld_vendor_item {
                width: 100%;
                height: 300px;
                margin-top: 10px;
                background-color: #fff;
                border: 1px solid $colorI;

                .sld_vendor {
                    position: relative;
                    width: 238px;
                    height: 298px;
                    padding: 46px 60px 0 60px;
                    box-sizing: border-box;
                    border-right: 1px solid #eee;

                    &:hover .sld_vendor_top {
                        opacity: 1;
                        z-index: 2;
                        cursor: pointer;
                    }

                    .sld_rate {
                        margin-top: 8px;
                        text-align: center;
                    }

                    .sld_vendor_bottom {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        text-align: center;
                        width: 100%;
                        border-top: 1px solid #eee;

                        a {
                            width: 100%;
                            height: 46px;
                            text-align: center;
                            line-height: 46px;
                            color: #444444;
                            font-size: 14px;

                            i {
                                font-size: 14px;
                                color: $colorMain2;
                                margin-right: 6px;
                            }
                        }
                    }

                    .sld_vendor_top {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 32px;
                        color: #000;
                        opacity: 0;
                        z-index: -1;
                        transition: all 0.3s;

                        button {
                            width: 49.5%;
                            height: 32px;
                            background-color: $colorD;
                            border: none;
                            cursor: pointer;
                        }
                    }

                    .sld_rate {
                        margin-top: 8px;
                        text-align: center;

                        i {
                            float: left;
                            margin: 0 1px;
                            color: $colorMain;
                        }
                    }

                    .sld_vendor_logo {
                        width: 114px;
                        height: 114px;
                    }

                    .sld_vendor_name {
                        text-align: center;
                        color: #444444;
                        font-size: 13px;
                        margin-top: 10px;
                    }
                }

                .main_lbbox {
                    position: relative;
                    width: 767px;
                    height: 298px;
                    min-width: 767px;
                    overflow: hidden;

                    .sld_vendor_collect {
                        height: 70px;
                        padding: 15px 0 20px 15px;
                        box-sizing: border-box;

                        a {
                            float: left;
                            display: inline-block;
                            width: 120px;
                            height: 34px;
                            line-height: 34px;
                            text-align: center;
                            border: 1px solid $colorI;
                            background-color: #fff;
                            color: #444444;
                            font-size: 14px;

                            &:last-child {
                                border-left: none;
                            }
                        }

                        .sld_follow_on {
                            background-color: $colorMain2;
                            color: #fff;
                        }
                    }

                    .sld_vendor_goods {
                        position: relative;
                        padding: 0 55px;
                        overflow: hidden;

                        .goods_price {
                            font-size: 16px;
                            color: $colorMain2;
                            margin-top: 3px;
                            font-weight: 600;
                        }

                        .goods_name {
                            width: 150px;
                            margin-top: 15px;
                            color: #444444;
                            font-size: 14px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        li {
                            width: 150px;
                            float: left;
                            margin-right: 20px;

                            .sld_img {
                                width: 150px;
                                height: 150px;
                            }
                        }

                        .hd {
                            i {
                                font-size: 20px;
                            }

                            .next,
                            .prev {
                                position: absolute;
                                top: 45px;
                                width: 32px;
                                height: 70px;
                                font-size: 16px;
                                color: #fff;
                                background-color: #8d8b8b;
                                text-align: center;
                                line-height: 70px;
                            }

                            .next {
                                right: 0;
                            }

                            .prev {
                                left: 0;
                                background-color: #ccc;
                                -webkit-transform: rotate(180deg);
                                -moz-transform: rotate(180deg);
                                -ms-transform: rotate(180deg);
                                -o-transform: rotate(180deg);
                                transform: rotate(180deg);
                            }
                        }

                        .slide_wrap {
                            width: 661px;
                            height: 213px;
                            overflow: hidden;

                            .bd {
                                display: flex;
                                transition: all 0.3s;

                                li {
                                    float: left;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss">
.el-pager li.active {
    color: $colorMain;
    cursor: default;
}

.el-pager li:hover {
    color: $colorMain;
}

.el-button--primary {
    color: #fff;
    background-color: $colorMain;
    border-color: $colorMain;
}
</style>
<template>
  <div class='sld_collect_index wd'>
    <!-- 会员基本信息 -->
    <div class="user_info_top flex_align">
      <div class="user_info_left flex_align">
        <img v-if="memberInfo.info.memberAvatar" class="user_info_left_img" :src="memberInfo.info.memberAvatar">
        <i v-else class="iconfont icontouxiang iconImg"></i>
        <div class="user_info_left_message">
          <div class="user_info_left_name">{{ memberInfo.info.memberNickName }} ({{ memberInfo.info.memberName }})</div>
          <!-- memberType 会员类型  1-普通会员；2-设计师；3-企业会员 -->
          <div class="user_info_left_role role_member" v-if="memberInfo.info.memberType == 1">{{ L['个人用户'] }}</div>
          <div class="user_info_left_role role_designer" v-else-if="memberInfo.info.memberType == 2">{{ L['认证设计师'] }}
          </div>
          <div class="user_info_left_role role_enterprise" v-else-if="memberInfo.info.memberType == 3">{{ L['认证企业'] }}
          </div>
          <div class="user_info_left_role role_enterprise" v-else>{{ L['业务员'] }}</div>
        </div>
      </div>
      <div class="user_info_right flex_align">
        <div class="user_info_right_item" :class="{ user_info_right_itemSm: memberInfo.info.memberType == 3 }"
          @click="navTo('/member/coupon')">
          <p>{{ memberInfo.info.couponNum ? memberInfo.info.couponNum : 0 }}</p>
          <i class="iconfont iconwenzhangshouye"></i><span>{{ L['优惠券'] }}</span>
        </div>
        <div class="user_info_right_item" :class="{ user_info_right_itemSm: memberInfo.info.memberType == 3 }"
          v-if="memberInfo.info.memberType == 3" @click="navTo('/member/credit/index')">
          <p>{{ memberInfo.info.billNum ? memberInfo.info.billNum : 0 }}</p>
          <i class="iconfont iconwenzhangshouye"></i><span>{{ L['信用账单'] }}</span>
        </div>
        <div class="user_info_right_item" :class="{ user_info_right_itemSm: memberInfo.info.memberType == 3 }"
          @click="navTo('/member/regular/list')">
          <p>{{ memberInfo.info.frequentBuy ? memberInfo.info.frequentBuy : 0 }}</p>
          <i class="iconfont icontuangouzhuangxiu"></i><span>{{ L['常购清单'] }}</span>
        </div>
        <div class="user_info_right_item" :class="{ user_info_right_itemSm: memberInfo.info.memberType == 3 }"
          @click="navTo('/member/project/list')">
          <p>{{ memberInfo.info.projectNum ? memberInfo.info.projectNum : 0 }}</p>
          <i class="iconfont iconwodexiangmu"></i><span>{{ L['我的项目'] }}</span>
        </div>
      </div>

    </div>
    <!-- 会员订单信息 -->
    <div class="user_order clearfix">
      <div class="my_order fl">
        <h4 class="user_title flex_align">
          <span>{{ L['我的订单'] }}</span>
          <router-link :to="'/member/order/list'" target="_blank">{{ L['查看全部订单'] }}</router-link>
        </h4>
        <ul class="clearfix order_state_nav flex_align">
          <li>
            <router-link :to="'/member/order/list?orderState=10'" target="_blank">
              <i class="iconfont icongerenzhongxin-daishenhe1"></i>
              <p>{{ L['待审核'] }}</p>
              <div class="tag" v-if="memberInfo.info.toAuditOrder > 0">{{ memberInfo.info.toAuditOrder }}</div>
            </router-link>
          </li>
          <li>
            <router-link :to="'/member/order/list?orderState=20'" target="_blank">
              <i class="iconfont icongerenzhongxin-daifukuan1"></i>
              <p>{{ L['待付款'] }}</p>
              <div class="tag" v-if="memberInfo.info.toPaidOrder > 0">{{ memberInfo.info.toPaidOrder }}</div>
            </router-link>
          </li>
          <li>
            <router-link :to="'/member/order/list?orderState=30'" target="_blank">
              <i class="iconfont icongerenzhongxin-daifahuo1"></i>
              <p>{{ L['待发货'] }}</p>
              <div class="tag" v-if="memberInfo.info.toDeliverOrder > 0">{{ memberInfo.info.toDeliverOrder }}</div>
            </router-link>
          </li>
          <li>
            <router-link :to="'/member/order/list?orderState=40'" target="_blank">
              <i class="iconfont icongerenzhongxin-bufenfahuo1"></i>
              <p>{{ L['部分发货'] }}</p>
              <div class="tag" v-if="memberInfo.info.partDeliverOrder > 0">{{ memberInfo.info.partDeliverOrder }}</div>
            </router-link>
          </li>
          <li>
            <router-link :to="'/member/order/list?orderState=41'" target="_blank">
              <i class="iconfont icongerenzhongxin-daishouhuo1"></i>
              <p>{{ L['待收货'] }}</p>
              <div class="tag" v-if="memberInfo.info.toReceivedOrder > 0">{{ memberInfo.info.toReceivedOrder }}</div>
            </router-link>
          </li>
          <li>
            <router-link :to="'/member/order/aftersales'" target="_blank">
              <i class="iconfont icongerenzhongxinshouhou"></i>
              <p>{{ L['退款售后'] }}</p>
              <div class="tag" v-if="memberInfo.info.afterSaleNum > 0">{{ memberInfo.info.afterSaleNum }}</div>
            </router-link>
          </li>
        </ul>
        <div class="user_title_before"></div>
        <h4 class="user_title flex_align no_border"><span>{{ L['进行中的订单'] }}</span></h4>
        <ul class="user_order_nav flex_align">
          <li class="iser_order_nav_img">{{ L['产品详情'] }}</li>
          <li class="iser_order_nav_info"></li>
          <li class="iser_order_nav_price">{{ L['单价'] }}</li>
          <li class="iser_order_nav_num">{{ L['数量'] }}</li>
          <li class="iser_order_nav_count">{{ L['合计'] }}</li>
          <li class="iser_order_nav_state">{{ L['订单状态'] }}</li>
          <li class="iser_order_nav_oprate">{{ L['操作'] }}</li>
        </ul>
        <div v-if="order.list.length > 0">
          <template v-for="({
            createTime, orderAmount, storeName, storeId, orderProductList, purchaseSn, orderSn, orderState,
            orderStateValue, paySn, storeLogo, paymentCode, paymentState
          }, index) in order.list" :key="index">
            <div v-if="index < 4" class="order_item">
              <div class="order_item_info flex_align">
                <div class="order_item_info_left">
                  <span class="order_store" @click="storeInfo(storeId)"><i class="iconfont icondianpu"></i>{{
                    storeName
                  }}</span>
                  <span class="order_chat" @click="chatInfo({ storeName, storeId, storeLogo })"><i
                      class="iconfont iconxiaoxiliebiao"></i></span>
                  <span>{{ L['订单号'] }}：{{ orderSn }}</span>
                  <span v-if="purchaseSn" class="order_item_info_purchaseSn" :title="purchaseSn">{{ L['采购单号'] }}：{{
                    purchaseSn
                  }}</span>
                </div>
                <div class="order_item_info_time">{{ createTime }}</div>
              </div>
              <ul class="flex_align">
                <li class="iser_order_nav_img">
                  <router-link class="img" target="_blank"
                    :to="`/goods/detail?productId=${orderProductList[0].productId}`">
                    <coverImage :src="orderProductList[0].goodsImage" class="img"></coverImage>
                  </router-link>
                </li>
                <li class="iser_order_nav_info flex" @click="detail(orderProductList[0].productId)">
                  <p class="iser_order_nav_store">{{ orderProductList[0].goodsName }}</p>
                  <p>{{ orderProductList[0].specValues }}</p>
                </li>

                <li class="iser_order_nav_price handle_over_flow">
                  <span v-if="orderProductList[0].finalPrice">￥{{ orderProductList[0].finalPrice.toFixed(2) }}</span>
                  <div class=" type2" v-else></div>
                </li>
                <li class="iser_order_nav_num">{{ orderProductList[0].buyNum }}</li>
                <li class="iser_order_nav_count handle_over_flow">
                  <span>￥{{ orderAmount.toFixed(2) }}</span>
                </li>
                <li class="iser_order_nav_state iser_order_nav_line">{{ (orderState == 20 && paymentCode == 'BANK') ?
                  paymentState : orderStateValue
                }}</li>
                <li class="iser_order_nav_oprate iser_order_nav_line">
                  <div v-if="orderState == 20" @click='goPay(paySn)'>{{ L['付款'] }}</div>
                  <div @click="seeOrderDetail(orderSn)">{{ L['查看详情'] }}</div>
                </li>
              </ul>
            </div>
          </template>

        </div>
        <div class="user_order_empty flex_row_center_center" v-else>
          <img src="@/assets/common_empty.png" alt="">
          <p>{{ L['这里空空如也，快去挑选合适的产品吧~'] }}</p>
        </div>
      </div>
      <div class="my_follow fr">

        <!-- 购物车item -->
        <div class="my_follow_item cartItem">
          <h4 class="user_title"><i class="iconfont icongouwuche1"></i>{{ L['购物车'] }} ({{ cartLen }})<router-link
              :to="'/cart/index'" target="_blank">{{ L['查看更多'] }}></router-link>
          </h4>
          <ul class="clearfix">
            <template v-for="(storeItem, storeIndex) in cartData.data.storeCartGroupList" :key="storeIndex">
              <template v-for="(promotItem, promotIndex) in storeItem.storeGoodsList" :key="promotIndex">
                <li class="list_item" v-for="(cartItem, cartIndex) in promotItem.goodsCartList" :key="cartIndex">
                  <a @click="toProductDetail(cartItem.productId)">
                    <img :src="cartItem.goodsImage" alt="">
                  </a>
                </li>
              </template>
            </template>
            <div class="no_footprint" v-show="!cartLen">{{L['暂无商品~']}}</div>
          </ul>
        </div>

        <div class="my_follow_item store">
          <h4 class="user_title"><i class="iconfont iconshangchengguanli2"></i>{{L['常购产品']}} ({{ memberInfo.info.frequentBuy }})
            <router-link :to="'/member/regular/list'" target="_blank">{{ L['查看更多'] }}></router-link>
          </h4>
          <ul class="clearfix">
            <template v-if="frequent.data.length > 0">
              <li class="list_item flex_align_justify" v-for="(item, index) in frequent.data" :key="index">
                <a @click="toProductDetail(item.productId)">
                  <img :src="item.goodsImage" alt="">
                </a>
              </li>
            </template>
            <div class="no_footprint" v-else>{{L['暂无常购产品～']}}</div>
          </ul>
        </div>

        <!-- 关注店铺item -->
        <div class="my_follow_item store">
          <h4 class="user_title"><i class="iconfont icongerenzhongxinguanzhudianpu"></i>{{ L['供应商关注'] }}
            ({{ memberInfo.info.followStoreNum }})
            <router-link :to="'/member/collect?type=store'" target="_blank">{{ L['查看更多'] }}></router-link>
          </h4>
          <ul class="clearfix">
            <template v-if="storeListLoading">
              <div class="no_footprint">{{L['加载中....']}}</div>
            </template>
            <template v-else>
              <div class="no_footprint" v-if="!collectStore.store.length">{{ L['暂无关注供应商～'] }}</div>
              <li class="list_item flex_row_center_center" v-else
                v-for="({ storeLogo, storeId }, index) in collectStore.store" :key="index">
                <router-link :to="`/store/index?vid=${storeId}`" target="_blank">
                  <img v-if="storeLogo" :src="storeLogo" alt="">
                  <i v-else class="iconfont icontouxiang iconImg"></i>
                </router-link>
              </li>
            </template>
          </ul>
        </div>

        <!-- 收藏商品item -->
        <div class="my_follow_item goods">
          <h4 class="user_title"><i class="iconfont icongerenzhongxinguanzhushangpin"></i>{{ L['我的收藏'] }}
            ({{ memberInfo.info.followProductNum }})
            <router-link :to="'/member/collect'" target="_blank">{{ L['查看更多'] }}></router-link>
          </h4>
          <ul class="clearfix">
            <div class="no_footprint" v-if="!collectGoods.goods.length">{{ L['暂无收藏产品～'] }}</div>
            <li class="list_item" v-else v-for="({ productImage, productId }, index) in collectGoods.goods"
              :key="index">
              <a @click="toProductDetail(productId)">
                <img :src="productImage" alt="">
              </a>
            </li>
          </ul>
        </div>

        <!-- 足迹item -->
        <div class="my_follow_item footprint">
          <h4 class="user_title"><i class="iconfont icongerenzhongxinzuji"></i>{{ L['我的足迹'] }}
            ({{ memberInfo.info.lookLogNum }})
            <router-link :to="'/member/footprint'" target="_blank">{{ L['查看更多'] }}></router-link>
          </h4>
          <ul class="clearfix">
            <div class="no_footprint" v-if="!looklog.log.length">{{ L['暂无足迹～'] }}</div>
            <li class="list_item" v-else v-for="({ goodsImage, productId }, index) in looklog.log" :key="index">
              <a @click="toProductDetail(productId)">
                <img :src="goodsImage" alt="">
              </a>
            </li>
          </ul>
        </div>

      </div>
    </div>
    <!-- 新品推荐 -->
    <div class="user_hot_goods clearfix" v-if="hotRecom.goods.length > 0">
      <h3>{{ L['新品推荐'] }}</h3>
      <ul class="clearfix">
        <li v-for="({ goodsImage, storeName, goodsSpec, goodsPrice, productId }, index) in hotRecom.goods" :key="index">
          <a @click="toProductDetail(productId)">
            <div class="img">
              <img class="lazy" :src="goodsImage" alt="">
            </div>
            <p class="hot_goods_space">{{ goodsSpec }}</p>
            <p class="hot_goods_name">{{L['供应商 : ']}}{{ storeName }}</p>
            <p class="hot_goods_price" v-if="Number(goodsPrice)">￥{{ goodsPrice? goodsPrice: 0 }}</p>
            <div v-else>--</div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { reactive, onMounted, getCurrentInstance, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from "vuex";
export default {
  name: 'index',
  setup() {
    const router = useRouter()
    const store = useStore();
    const memberInfo = reactive({ info: {} })
    const looklog = reactive({ log: [] })
    const looklogLen = ref(0)
    const cartData = reactive({ data: [] })
    const cartLen = ref(0);
    const frequent = reactive({ data: [] })
    const collectStore = reactive({ store: [] })
    const collectGoods = reactive({ goods: [] })
    const hotRecom = reactive({ goods: [] })
    const order = reactive({ list: [] })
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const storeListLoading = ref(true)
    const getInitInfo = () => { //获取会员信息数据
      if (store.state.memberInfo.memberId) {
        proxy.$get('v3/member/front/member/centerInfo').then(res => {
          if (res.state == 200) {
            memberInfo.info = res.data
            proxy.$get('v3/msg/front/msg/msgListNum').then(respon => {
              let msgListNumSum = respon.data.map(i => i.msgNum).reduce((prev, total) => total + prev)
              memberInfo.info.msgNum = msgListNumSum;
              store.commit('updateMemberInfo', memberInfo.info); //将会员信息数据存储到vuex的store中
            })
          }
        })
      }
    }

    const getCartItem = () => { //获取购物车数据
      proxy.$get('v3/business/front/cart/cartList').then(res => {
        if (res.state == 200) {
          cartData.data = res.data

          let { storeCartGroupList } = cartData.data
          let storeGoodsList = storeCartGroupList.map(i => i.storeGoodsList).flat(1)

          cartLen.value = storeGoodsList.length
        }
      })
    }

    const getFrequentItem = () => { //获取常购产品数据
      proxy.$get('v3/business/front/orderInfo/frequentList').then(res => {
        if (res.state == 200) {
          frequent.data = res.data.list ? res.data.list : [];
        }
      })
    }

    const getCollectStore = () => { //获取关注店铺数据
      proxy.$get('v3/member/front/followStore/list').then(res => {
        storeListLoading.value = false
        if (res.state == 200) {
          collectStore.store = res.data.list
        }
      })
    }

    const getCollectGoods = () => { //获取收藏商品数据
      proxy.$get('v3/member/front/followProduct/list').then(res => {
        if (res.state == 200) {
          collectGoods.goods = res.data.list
        }
      })
    }

    const getLookLog = () => {  //获取我的足迹数据
      proxy.$get('v3/member/front/productLookLog/list', {
        current: 1,
        pageSize: 3,
        total: 0
      }).then(res => {
        if (res.state == 200) {
          let tmp = []
          res.data.list.forEach(element => {
            tmp.push(...element.productLookLogInfoList)
          });
          looklog.log = tmp.slice(0, 4)
          looklogLen.value = res.data.list[0] ? res.data.list[0].productLookLogInfoList.length : 0
        }
      })
    }

    const getHotReco = () => { //获取新品推荐数据
      proxy.$get('v3/goods/front/goods/recommendList').then(res => {
        if (res.state == 200) {
          hotRecom.goods = res.data
          hotRecom.goods.map(item => item.goodsPrice = new Number(item.goodsPrice).toFixed(2))
        }
      })
    }

    const getOrder = () => {//获取订单
      let params = {
        current: 1,
        pageSize: 4,
      }
      proxy.$get('v3/business/front/orderInfo/list', params).then(res => {
        if (res.state == 200) {
          order.list = res.data.list
          order.list.map(item => item.totalMoney = new Number(item.totalMoney).toFixed(2))
        }
      })
    }

    const storeInfo = (id) => { //店铺详情
      router.push({
        path: '/store/product',
        query: {
          vid: id
        }
      })
    }

    const chatInfo = (item) => {

      let chatInfo = {
        storeId: item.storeId,
        vendorAvatar: item.storeLogo,
        storeName: item.storeName,
        source: `${L['从个人中心进入 供应商']}:${item.storeName}`
      }
      store.commit('saveChatBaseInfo', chatInfo)


      let newWin = router.resolve({
        path: '/service',
        query: {
          vid: item.storeId
        }
      })

      window.open(newWin.href, '_blank')


    }

    const seeOrderDetail = (orderSn) => { //去往订单详情
      router.push({
        path: '/member/order/info',
        query: {
          orderSn: orderSn
        }
      })
    }

    const toProductDetail = (productId) => { //去往商品详情
      let newWin = router.resolve({
        path: '/goods/detail',
        query: {
          productId: productId
        }
      })
      window.open(newWin.href, '_blank');
    }

    const goPay = orderSn => {  //付款
      router.push({
        path: "/buy/pay",
        query: {
          paySn: orderSn,
          payFrom: 2
        }
      });
    };

    const detail = (id) => {
      let newWin = router.resolve({
        path: "/goods/detail",
        query: {
          productId: id
        }
      });
      window.open(newWin.href, '_blank');
    };

    const navTo = (path) => {
      router.push(path);
    }

    onMounted(() => {
      getInitInfo()
      getLookLog()
      getHotReco()
      getOrder()
      getCartItem()
      getFrequentItem()
      getCollectStore()
      getCollectGoods()
    })

    return {
      memberInfo, L, looklog, hotRecom, getOrder, order, seeOrderDetail, toProductDetail,
      looklogLen, cartData, cartLen, collectStore, collectGoods, goPay, storeInfo, detail,
      navTo, chatInfo, getFrequentItem, frequent, storeListLoading
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/style/member/index.scss';
@import '@/style/base.scss';

.user_order_empty {
  padding: 90px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  img{
    width: 180px;
    height: 140px;
  }

  p {
    margin-left: 12px;
    color: #666666;
  }
}

.no_footprint {
  height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
</style>
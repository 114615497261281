import { h, render } from 'vue'
import comparePanel from './comparePanel'
let compareInstance

const addCompare = (object, fn) => {
     if (!compareInstance) {
          const parentNode = document.createElement('div')
          parentNode.id = 'compare_area'
          compareInstance = h(comparePanel)
          render(compareInstance, parentNode)
          document.body.appendChild(parentNode)
          addCompare(object, fn)
     } else {
          const { proxy } = compareInstance.component;
          !proxy.contrastFlag && (proxy.contrastFlag = true)
          const result = proxy.contrast(object)
          fn && fn(result)
     }


}


export default addCompare
<template>
  <div class="store_news">
    <div class="mod5 flex-col">
      <div class="layer2 flex-row">
        <div class="main2 flex-col"></div>
        <span class="txt6">{{L['企业动态']}}</span>
      </div>
    </div>

    <template v-if="newsList.data.length > 0">
      <div class="list">
        <template v-for="item in newsList.data" :key="item.newsId">
          <div class="item flex_row_between_center">
            <div class="title" @click="newsInfo(item.newsId)">{{ item.title }}</div>
            <div class="time">{{ item.createTime }}</div>
          </div>
        </template>
      </div>
    </template>
    <SldCommonEmpty v-else :tip="L['暂无企业动态']" totalWidth="1200px" />

    <el-pagination @current-change="handleCurrentChange" v-model:currentPage="pageData.current"
      :page-size="pageData.pageSize" layout="prev, pager, next, jumper" :total="pageData.total"
      :hide-on-single-page="true" v-if="newsList.data.length > 0"></el-pagination>
  </div>
</template>

<script>
import { reactive, getCurrentInstance, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import CartModel from '@/components/CartModel';
import SldCommonEmpty from '@/components/SldCommonEmpty';

export default {
  name: 'storeNews',
  components: {
    CartModel,
    SldCommonEmpty
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const router = useRouter();
    const route = useRoute();
    const newsList = reactive({ data: [] });
    const pageData = reactive({
      current: 1,
      pageSize: 10,
      total: 0,
    });

    const getList = () => { //获取动态数据
      let param = {
        current: pageData.current,
        pageSize: pageData.pageSize,
        storeId: route.query.vid
      }
      proxy
        .$get('v3/cms/front/companyNews/list', param)
        .then(res => {
          newsList.data = res.data.list;
          pageData.total = res.data.pagination.total;
        })
    };

    const handleCurrentChange = (e) => {
      pageData.current = Math.floor(e);
      getList();
    };

    const newsInfo = (newsId) => {
      let newWin = router.resolve({
        path: '/store/news/info',
        query: {
          vid: route.query.vid,
          newsId: newsId
        }
      })
      window.open(newWin.href, '_blank');
    };

    onMounted(() => {
      getList();
    })

    return {
      L,
      pageData,
      newsList,
      getList,
      handleCurrentChange,
      newsInfo,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/store/news.scss";
</style>

<template>
    <div class="category_header" style="margin-top:10px">
        <!-- 分类路径 -->
        <div class="level_nav_main">
            <div class="level_item flex_row_between_center" v-if="catePathName.path.length">
                <!-- 一级分类，显示该部分 -->
                <div v-if="1 === showIndex" class="flex_row_start_center">
                    <div class="level_nav_item">
                        <div class="menu_drop">
                            <div
                                :class="{ trigger: true, hasChild: cate1.cate.length, 'flex_row_between_center': true }">
                                <span class="trigger_name">{{ catePathName.path[0] }}</span>
                                <i class="el-icon-arrow-down"></i>
                            </div>
                            <div class="menu_drop_main" v-if="cate1.cate.length">
                                <ul class="menu_drop_list">
                                    <li v-for="(item1, index) in cate1.cate" :key="index">
                                        <a @click="goCate3(item1)">
                                            {{ item1.categoryName }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 进入二级分类路径下的页面时，显示该部分 -->
                <div v-else-if="2 === showIndex" class="flex_row_start_center">
                    <div class="level_nav_item">
                        <div class="menu_drop">
                            <div
                                :class="{ trigger: true, hasChild: cate1.cate.length, 'flex_row_between_center': true }">
                                <span class="trigger_name">{{ catePathName.path[0] }}</span>
                                <i class="el-icon-arrow-down"></i>
                            </div>
                            <div class="menu_drop_main" v-if="cate1.cate.length">
                                <ul class="menu_drop_list">
                                    <li v-for="(item1, index) in cate1.cate" :key="index">
                                        <a @click="goCate3(item1)">
                                            {{ item1.categoryName }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="midIcon">
                        <i class="el-icon-arrow-right"></i>
                    </div>
                    <div class="level_nav_item">
                        <div class="menu_drop">
                            <div
                                :class="{ trigger: true, hasChild: cate2.cate.length, 'flex_row_between_center': true }">
                                <span class="trigger_name">{{ catePathName.path[1] }}</span>
                                <i class="el-icon-arrow-down"></i>
                            </div>
                            <div class="menu_drop_main" v-if="cate2.cate.length">
                                <ul class="menu_drop_list">
                                    <li v-for="(item2, index) in cate2.cate" :key="index">
                                        <a @click="goCate3(item2)">{{ item2.categoryName }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 进入三级分类路径下的页面时，显示该部分 -->
                <div v-else class="flex_row_start_center">
                    <div class="flex_row_start_center">
                        <div class="level_nav_item">
                            <div class="menu_drop">
                                <div
                                    :class="{ trigger: true, hasChild: cate1.cate.length, 'flex_row_between_center': true }">
                                    <span class="trigger_name">{{ catePathName.path[0] }}</span>
                                    <i class="el-icon-arrow-down"></i>
                                </div>
                                <div class="menu_drop_main" v-if="cate1.cate.length">
                                    <ul class="menu_drop_list">
                                        <li v-for="(item1, index) in cate1.cate" :key="index">
                                            <a @click="goCate3(item1)">
                                                {{ item1.categoryName }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="midIcon">
                            <i class="el-icon-arrow-right"></i>
                        </div>
                        <div class="level_nav_item">
                            <div class="menu_drop">
                                <div
                                    :class="{ trigger: true, hasChild: cate2.cate.length, 'flex_row_between_center': true }">
                                    <span class="trigger_name">{{ catePathName.path[1] }}</span>
                                    <i class="el-icon-arrow-down"></i>
                                </div>
                                <div class="menu_drop_main" v-if="cate2.cate.length">
                                    <ul class="menu_drop_list">
                                        <li v-for="(item2, index) in cate2.cate" :key="index">
                                            <a @click="goCate3(item2)">
                                                {{ item2.categoryName }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="midIcon">
                            <i class="el-icon-arrow-right"></i>
                        </div>
                        <div class="level_nav_item">
                            <div class="menu_drop">
                                <div
                                    :class="{ trigger: true, hasChild: cate3.cate.length, 'flex_row_between_center': true }">
                                    <span class="trigger_name">{{ catePathName.path[2] }}</span>
                                    <i class="el-icon-arrow-down"></i>
                                </div>
                                <div class="menu_drop_main" v-if="cate3.cate.length">
                                    <ul class="menu_drop_list">
                                        <li v-for="(item3, index) in cate3.cate" :key="index">
                                            <a @click="goCate3(item3)">{{ item3.categoryName }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>


<script>
import { getCurrentInstance, onMounted, reactive, ref, watchEffect, watch, provide } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
    setup() {
        const route = useRoute();
        const router = useRouter();
        const { proxy } = getCurrentInstance();
        const categoryId = ref(route.query.categoryId)
        const storeId = route.query.vid
        const storeCate = ref([])
        const cate1 = reactive({ cate: [], child: {} })
        const cate2 = reactive({ cate: [], child: {} })
        const cate3 = reactive({ cate: [] })
        const catePath = ref([])//导航路径
        const showIndex = ref(0)//显示flag
        const catePathName = reactive({ path: [] })

        const getStoreCategory = () => {
            proxy.$get('v3/goods/front/goods/category/storeCategory', { storeId }).then(res => {
                if (res.state == 200) {
                    storeCate.value = res.data;
                    handlleCate()
                }
            })
        }


        watch(() => route.query.categoryId, (nv, ov) => {
            if (nv != ov) {
                categoryId.value = route.query.categoryId
                handlleCate()
            }
        })



        const handlleCate = () => {
            let categoryList
            if (categorySearch(storeCate.value, categoryId.value)) {
                categoryList = categorySearch(storeCate.value, categoryId.value).reverse()
            }
            if (categoryList && categoryList[0]) {
                showIndex.value = 1
                catePathName.path[0] = categoryList[0].categoryName
                cate1.cate = storeCate.value
                cate1.child = categoryList[0].children
                if (categoryList[1]) {
                    showIndex.value = 2
                    catePathName.path[1] = categoryList[1].categoryName
                    cate2.cate = cate1.child.filter(item => item.categoryId != categoryList[1].categoryId)
                    cate2.child = categoryList[1].children
                    if (categoryList[2]) {
                        showIndex.value = 3
                        catePathName.path[2] = categoryList[2].categoryName
                        cate3.cate = cate2.child.filter(item => item.categoryId != categoryList[2].categoryId)
                    }
                }
            }
        }



        const categorySearch = (data, val) => {//遍历分类列表，找到该categoryId所在的id路径
            for (var i = 0; i < data.length; i++) {
                if (data[i] && data[i].categoryId == val) {
                    return [{
                        categoryId: val,
                        categoryName: data[i].categoryName,
                        children: data[i].children
                    }];
                } if (data[i] && data[i].children) {
                    var d = categorySearch(data[i].children, val);
                    if (d) return d.concat({
                        categoryId: data[i].categoryId,
                        categoryName: data[i].categoryName,
                        children: data[i].children
                    });
                }
            }
        }


        //去往三级分类
        const goCate3 = (item) => {
            let { categoryId, grade, pid } = item;

            router.push({
                path: "/store/product",
                query: {
                    ...route.query,
                    categoryId,
                    g: grade,
                    pid,
                },
            });

        };

        onMounted(() => {
            getStoreCategory()
        })


        return {
            storeCate,
            cate1,
            cate2,
            cate3,
            catePathName,
            catePath,
            showIndex,
            goCate3
        }
    }
}
</script>

<style lang="scss">
.level_nav_main {
    margin-bottom: 10px;
    padding-top: 10px;
    display: flex;

    .sld_goods_num {
        line-height: 36px;
        margin-right: 10px;
        color: $colorTitle1;

        span {
            color: $colorMain;
            font-weight: bold;
            margin: 0 2px;
            font-size: 20px;
            font-family: SourceHanSansCN-Regular;
            line-height: 0;
        }
    }

    .level_item {
        display: flex;
        width: 1200px;
        margin: 0px auto;
        height: 40px;
        color: #848484;
        line-height: 40px;
        font-family: "microsoft yahei";
        position: relative;
        align-items: center;

        .level-right {
            display: inline-block;
            width: 8px;
            height: 1px;
            background-color: #cecece;
            margin: 19px 7px 0;
        }

        .level_link {
            position: relative;
            float: left;
            font-size: 12px;
            color: #848484;
            line-height: 26px;
            font-family: "microsoft yahei";
        }

        .level_nav_item {
            font-size: 12px;
            padding: 6px 0 4px;
            position: relative;

            margin: 0 6px;

            &:first-child {
                margin-left: 0;
            }

            .menu_drop {
                display: flex;
                height: 40px;
                cursor: pointer;
                align-items: center;

                .trigger {
                    position: relative;
                    display: flex;
                    height: 26px;
                    min-width: 89px;
                    padding: 0 4px 0 8px;
                    border: 1px solid $colorD;
                    line-height: 24px;
                    vertical-align: top;
                    background-color: #fff;
                    z-index: 1;

                    .trigger_name {
                        display: inline-block;
                        vertical-align: top;
                        color: $colorTitle1;
                    }

                    &.label {
                        margin-right: 5px;
                        padding-right: 0;

                        &:hover {
                            border: 1px solid $colorMain;

                            span:nth-child(2) {
                                background-color: $colorMain;
                            }

                            .el-icon-close {
                                color: #fff;
                            }
                        }

                        span:nth-child(2) {
                            margin-left: 5px;
                            display: inline-block;
                            width: 24px;
                            height: 24px;
                        }
                    }
                }

                .el-icon-arrow-down {
                    display: inline-block;
                    font-size: 16px;
                    transition: all 0.2s;
                }

                .el-icon-close {
                    float: right;
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    vertical-align: top;
                    margin-top: 4px;
                    margin-right: 3px;
                    transition: all 0.2s;

                    &:before {
                        font-size: 15px;
                    }
                }

                &:hover {
                    .hasChild {
                        height: 30px;
                        border-color: $colorMain;
                        border-bottom: none;
                        z-index: 5;
                    }

                    .el-icon-arrow-down {
                        transform: rotate(180deg);
                        color: $colorMain;
                    }

                    .menu_drop_main {
                        display: block;
                        border-color: $colorMain;

                        z-index: 1;
                    }
                }

                .menu_drop_main {
                    position: relative;
                    display: none;
                    width: 360px;
                    padding: 15px 10px;
                    position: absolute;
                    left: 0;
                    top: 35px;
                    border: 1px solid #e1e1e1;
                    background-color: #fff;
                    z-index: 2;
                    margin-top: 5px;

                    .menu_drop_list li {
                        float: left;
                        width: 65px;
                        height: 24px;
                        overflow: hidden;
                        line-height: 24px;
                        margin-right: 5px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        margin-bottom: 0;

                        a {
                            color: $colorTitle1;
                        }
                    }
                }
            }
        }

        .level_right {
            display: inline-block;
            width: 8px;
            height: 1px;
            background-color: #cecece;
            margin: 11px 5px 4px;
            line-height: 26px;
        }
    }
}
</style>
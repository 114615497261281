<template>
     <div class="goods_series">
          <div class="nav_lf">
               <div class="about flex_row_start_center">
                    <span>
                         <a href="/">{{L['首页']}}</a>
                         <span class="el-icon-arrow-right"></span>
                    </span>
                    <span v-for="(item, index) in  categoryPath" :key="index">
                         <a :href="`/goods/list?categoryId=${item.id}&g=${item.grade}&pid=${item.pid}`">
                              {{ item.name }}
                         </a>
                         <i class="el-icon-arrow-right" v-if="index < categoryPath.length - 1"></i>
                    </span>
                    <span style="color: #333333;"></span>
               </div>
          </div>

          <div class="main_serie">
               <div class="series_detail outer15">
                    <div class="block2 flex_row"><span class="word19">{{ serDetail.seriesName }}</span></div>
                    <div class="block3 flex-row"
                         :class="{ lgWidth: !(serDetail.seriesAttributeList && serDetail.seriesAttributeList.length) }">
                         <div class="main1 flex_row_start_start">
                              <div ref="previewBox" class="section3 flex_column_center_center" @mousemove="move($event)"
                                   @mouseleave="out($event)" @mouseenter="enter($event)">
                                   <img class="img1" referrerpolicy="no-referrer" :src="serDetail.seriesImage" />
                                   <!-- 遮罩 start-->
                                   <div class="mask" ref="maskBox" v-show="maskShow"></div>
                                   <!-- 遮罩 end -->
                                   <!-- 底部放大镜icon图标 start -->
                                   <div class="magnifier_icon flex_row_center_center" v-show="!maskShow">
                                        <i class="iconfont iconsousuo"></i>
                                   </div>
                                   <!-- 底部放大镜icon图标 end -->
                                   <!-- 右侧的放大后的图片 start -->
                                   <div class="goods_picture_big" ref="zoomBox" v-show="maskShow">
                                        <div class="default_image_big"
                                             :style="{ backgroundImage: 'url(' + serDetail.seriesImage + ')' }"
                                             ref="pictureBig"></div>
                                        <!-- 右侧的放大后的图片 end -->
                                   </div>
                              </div>
                              <div class="word20_box"
                                   v-if="serDetail.seriesAttributeList && serDetail.seriesAttributeList.length">
                                   <span class="word20">{{L['参数描述']}}</span>
                                   <div class="section4 flex-col">
                                        <div class="bd3 flex-row">
                                             <el-scrollbar style="border: 1px solid #F2F2F2;">
                                                  <div class="wrap_con flex-row"
                                                       :class="{ noBorder: (i + 1) == serDetail.seriesAttributeList.length }"
                                                       v-for="(item, i) in serDetail.seriesAttributeList" :key="i">
                                                       <div class="wrap2">{{ item.attributeName }}</div>
                                                       <div class="wrap3">{{ item.attributeValue }}</div>
                                                  </div>
                                                  <div></div>
                                             </el-scrollbar>
                                        </div>
                                   </div>
                              </div>
                              <div class="word21" v-if="serDetail.description">
                                   <div class="word21_title">{{L['详情介绍：']}}</div>
                                   <div class="word21_desc" v-html="serDetail.description"></div>
                              </div>
                         </div>
                         <div class="main1-2">
                              <div class="main2 flex-col" :v-loading="loading"
                                   :class="{ lgWidth: !(serDetail.seriesAttributeList && serDetail.seriesAttributeList.length) }">
                                   <object :data="serDetail.specInstruction" type="application/pdf" width="100%"
                                        height="100%">
                                        <iframe :src="serDetail.specInstruction" width="100%" height="100%"
                                             style="border: none;">
                                             This browser does not support PDFs. Please download the PDF to view it:
                                             <a href="JavaScript：void(0)">Download PDF</a>
                                        </iframe>
                                   </object>
                              </div>
                         </div>

                    </div>
               </div>

               <div class="param_chosen">
                    <GoodsListCate3 :series="false" @chosenAttr="chosenAttr" :categoryid="categoryId" :seriesPage="true"
                         ref="goodsCate3">
                         <div class="count_series flex_row_center_center">{{L['共']}}<span style="margin-bottom: 2px;">{{
                         goodsData.page.total
                         }}</span>{{L['件']}}
                         </div>
                    </GoodsListCate3>
               </div>
               <div class="goods_list">
                    <!-- 筛选区域 -->
                    <div class="sld_screen flex_row_between_center">
                         <div class="flex_row_start_center">
                              <div @click="filterSort(0)"
                                   :class="{ screen_item: true, btn_sort_default: indexNum == 0, flex_row_center_center: true }">
                                   {{ L['默认'] }}</div>
                              <div :class="{ screen_item: true, flex_row_center_center: true, sld_price_jt_down: indexNum == 4, sld_price_jt_up: indexNum == 3, btn_sort_default: indexNum == 4 || indexNum == 3 }"
                                   @click="filterSort(indexNum == 4 ? 3 : 4)">
                                   <div class="sld_price">{{ L['价格'] }}</div>
                              </div>
                              <div @click="filterSort(2)"
                                   :class="{ screen_item: true, flex_row_center_center: true, btn_sort: indexNum == 2, btn_sort_default: indexNum == 2 }">
                                   {{ L['发货日'] }}
                                   <i class="iconfont iconxiangshangjiantou" style="font-size: 12px"></i>
                              </div>
                              <div class="screen_item_price ">
                                   <span class="price_git">{{ L['价格'] }}</span>
                                   <div class="price_section">
                                        <div class="input_box">
                                             <input type="number" class="sld_rmb" v-model="lowprice" id="lowprice"
                                                  @input="priceInput('lowprice', $event)" />
                                             <span class="line"></span>
                                             <input type="number" class="sld_rmb" v-model="highprice" id="highprice"
                                                  @input="priceInput('highprice', $event)" />
                                             <span class="line1"></span>
                                        </div>

                                        <div class="ctrl flex_row_between_center">
                                             <a class="clearVal" @click="resetPrice">{{ L['清空'] }}</a>
                                             <a class="confirm" @click="confirmPrice">{{ L['确定'] }}</a>
                                        </div>
                                   </div>
                              </div>
                              <div class="screen_item_sel flex_row_start_center"
                                   v-if="memberInfo.memberType == 2 || memberInfo.memberType == 3">
                                   <input type="checkbox" v-model="labelCode" id="label" @change="changeBox">
                                   <label for="label">
                                        <span>{{L['有物料编码']}}</span>
                                   </label>
                              </div>
                         </div>
                         <div class="flex-row">
                              <div class="sld_goods_num flex_row_center_center">
                                   {{L['共']}}<span style="margin-bottom: 2px;">{{ goodsData.page.total || 0 }}</span>{{L['件']}}
                              </div>
                              <div class="bar flex_row_center_center">
                                   <span class="el-icon-arrow-left" @click="recomChange('prev')"></span>
                                   <span class="txt21" style="font-size: 13px;">
                                        <font color="#285FDE">{{ goodsData.page.total ? goodsData.page.current : 0 }}
                                        </font> /
                                        <font>
                                             {{ Math.ceil(goodsData.page.total / 20) || 0 }}
                                        </font>
                                   </span>
                                   <span class="el-icon-arrow-right" @click="recomChange('next')"></span>
                              </div>
                         </div>

                    </div>
                    <!-- 筛选区域end -->
                    <!-- 商品列表 -->
                    <div class="sld_goods_list" v-if="goodsData.data.length">
                         <div class="block13 flex_row_start_center">
                              <span class="word89">{{L['图片']}}</span>
                              <span class="word90">{{L['规格型号']}}</span>
                              <span class="word91">{{L['描述']}}</span>
                              <span class="info53">{{L['生产状态']}}</span>
                              <span class="word92">{{L['价格']}}</span>
                              <span class="word93">{{L['购买数量']}}</span>
                              <span class="word94">{{L['操作']}}</span>
                         </div>
                         <div>
                              <proListItem v-for="(item, index) in goodsData.data" @contrast="contrast" :key="index"
                                   @addProJ="addProJ" :item="item">
                              </proListItem>
                         </div>
                         <!-- 分页 -->
                         <div class="flex_row_center_center sld_pagination">
                              <el-pagination @current-change="handleCurrentChange"
                                   v-model:currentPage="goodsData.page.current" :page-size="goodsData.page.pageSize"
                                   layout="prev, pager, next, jumper" :total="goodsData.page.total"
                                   :hide-on-single-page="true">
                              </el-pagination>
                         </div>
                    </div>
                    <proEmpty v-else ref="proEmpty"></proEmpty>
               </div>
          </div>



          <AtoProject ref="AtoProject"></AtoProject>
     </div>
</template>
<script>
import proEmpty from "../../components/proEmpty";
import GoodsListCate3 from "@/components/GoodsListCate3";
import proListItem from '@/components/ProListItem'
import { getCurrentInstance, onMounted, reactive, ref, watchEffect, watch, toRefs, nextTick, } from "vue";
import AtoProject from '@/components/AtoProject'
import { useRoute, useRouter } from "vue-router";
import { priceSlct, filter } from '@/utils/common.js'
import { useStore } from 'vuex'
import addCompare from '@/components/comparePanel/compare.js'

export default {
     components: {
          GoodsListCate3,
          proListItem,
          AtoProject,
          proEmpty
     },
     setup() {
          const route = useRoute();
          const router = useRouter();
          const store = useStore()
          const { proxy } = getCurrentInstance();
          const L = proxy.$getCurLanguage();
          const seriesId = ref(route.query.seriesId ? route.query.seriesId : 2)
          const categoryId = ref(route.query.categoryId)
          const state = reactive({
               pdfUrl: 'https://mozilla.github.io/pdf.js/web/compressed.tracemonkey-pldi-09.pdf',
               serDetail: {}
          })
          const memberInfo = store.state.memberInfo
          const loading = ref(false)

          const categoryPath = ref([])
          //初始化的请求参数
          const params = reactive({
               current: 1,
               seriesId: seriesId.value
          });
          const goodsData = reactive({ data: [], page: {} });
          const scrollTop = ref(null);
          //初始化数据，进行请求-start
          const getInitData = () => {
               let searchParams = { ...params };
               proxy.$get("v3/goods/front/goods/goodsList", searchParams).then((res) => {
                    if (res.state == 200) {
                         goodsData.data = res.data.list;
                         if (!categoryId.value) {
                              categoryId.value = goodsData.data.length ? goodsData.data[0].categoryId3 : ''
                              getSeries()
                         }
                         goodsData.page = res.data.pagination;
                    } else {
                         goodsData.data = []
                    }

                    if (!goodsData.data.length) {
                         nextTick(() => {
                              proxy.$refs.proEmpty.getStoreList()

                         })
                    }
               });
          };

          const { highprice, lowprice, confirm, reset } = priceSlct(getInitData, params)
          const { indexNum, filterSort, labelCode } = filter(params, getInitData, proxy)

          const changeBox = (e) => {
               params.current = 1;
               scrollTop.value = document.body.scrollTop || document.documentElement.scrollTop;
               params.isHasCode = labelCode.value
               proxy.$refs.goodsCate3.selectorParam.isHasCode = labelCode.value
               if (!goodsData.data.length) {
                    proxy.$refs.goodsCate3.clearAttr()
               }
               proxy.$refs.goodsCate3.getInitData()
               getInitData(params)
          }

          //加入对比
          const contrast = (item) => {
               addCompare(item)
          }




          const refreshAttrList = () => {
               let refObj = proxy.$refs.goodsCate3
               refObj.selectorParam.isHasCode = labelCode.value
               refObj.selectorParam.highPrice = highprice.value
               refObj.selectorParam.lowPrice = lowprice.value
               refObj.selectorParam.sort = indexNum.value
               if (!goodsData.data.length) {
                    refObj.clearAttr()
               } else {
                    refObj.getInitData()
               }
          }

          const confirmPrice = () => {
               confirm(() => {
                    refreshAttrList()
               })
          }


          const resetPrice = () => {
               reset(() => {
                    //这是 当 例如 3- 这种情况出现 无法清空，执行
                    document.getElementById('lowprice').value = ''
                    document.getElementById('highprice').value = ''
                    refreshAttrList()
               })
          }

          const getSeries = () => {
               let param = {
                    seriesId: seriesId.value,
               }
               if (categoryId.value) {
                    param.categoryId = categoryId.value
               }

               proxy.$get('v3/goods/front/goods/seriesDetail', param).then(res => {
                    if (res.state == 200) {
                         state.serDetail = res.data
                         let { categoryId1, categoryId2, categoryId3, categoryName1, categoryName2, categoryName3 } = res.data

                         categoryPath.value = [{ name: categoryName1, id: categoryId1, grade: 1, pid: 0 },]
                         if (categoryId2) {
                              categoryPath.value[1] = { name: categoryName2, id: categoryId1, grade: 2, pid: categoryId1 }
                         }

                         if (categoryId3) {
                              categoryPath.value[2] = { name: categoryName3, id: categoryId3, grade: 3, pid: categoryId2 }
                         }

                    }
               })
          }

          const handleCurrentChange = (e) => {
               params.current = Math.floor(e);
               getInitData(params)
          }

          const recomChange = (type) => {
               if (type == 'next') {
                    if (params.current == Math.ceil(goodsData.page.total / 20)) {
                         return
                    }
                    params.current++
                    getInitData(params)
               } else {
                    if (params.current == 1) {
                         return
                    }
                    params.current--
                    getInitData(params)
               }
          }

          //筛选属性
          const chosenAttr = (commonList, resistorList, accurayList) => {
               if (commonList.length) {
                    const sorted = groupBy(commonList, (item) => [item.attrId]);
                    let attrList = sorted.map(item => {
                         let list = item.map(i => i.attrValId).join('-')
                         return list
                    })
                    params.attributeInfo = attrList.join(',')
               } else {
                    delete params.attributeInfo
               }

               if (accurayList.length) {
                    let list = accurayList.map(i => i.attrValId).join(',')
                    params.accuracyIds = list
               } else {
                    delete params.accuracyIds
               }

               if (resistorList.length) {
                    let list = resistorList.map(i => i.attrValId).join(',')
                    params.resistanceIds = list
               } else {
                    delete params.resistanceIds
               }
               params.current = 1
               getInitData(params);
          }

          //筛选分组
          const groupBy = (arr, func) => {
               const groups = {};
               arr.forEach((o) => { //注意这里必须是forEach 大写
                    const group = JSON.stringify(func(o));
                    groups[group] = groups[group] || [];
                    groups[group].push(o);
               });
               return Object.keys(groups).map(function (group) {
                    return groups[group];
               });
          }

          //计入我的项目
          const addProJ = (item) => {
               proxy.$refs.AtoProject.proInfo.productIds = `${item.productId}-${item.num}`
               proxy.$refs.AtoProject.visibleFlag = true
          }

          const maskShow = ref(false); //遮罩是否显示
          const previewBox = ref(null); // 左侧主图元素信息
          const maskBox = ref(null); //遮罩盒子的信息
          const zoomBox = ref(null); //左侧主图的父元素的信息
          const pictureBig = ref(null); //大图的信息

          //获取元素距离父元素的顶部及左边的距离
          const offset = (el) => {
               let top = el.offsetTop;
               let left = el.offsetLeft;
               if (el.offsetParent) {
                    el = el.offsetParent;
                    top += el.offsetTop;
                    left += el.offsetLeft;
               }
               return {
                    left: left,
                    top: top,
               };
          };

          const zoom = ref(10); //缩放级别 min: 2 max:10
          //鼠标移动
          const move = (e) => {
               //主图父元素的信息 宽，高
               let previewsBox = previewBox.value;
               let previewBoxWidth = previewsBox.offsetWidth;
               let previewBoxHeight = previewsBox.offsetHeight;
               //主图父元素距离顶部的距离
               let previewsBoxLeft = offset(previewsBox).left;
               let previewsBoxTop = offset(previewsBox).top;

               // 遮罩盒子的信息宽，高
               let masksBox = maskBox.value;
               let maskBoxWidth = masksBox.offsetWidth;
               let maskBoxHeight = masksBox.offsetHeight;
               //鼠标距离屏幕距离
               let moveX = e.clientX;
               let moveY = e.clientY;

               //获取左侧大图父元素的信息
               let zoomsBox = zoomBox.value;
               let zoomBoxWidth = zoomsBox.offsetWidth;
               let zoomBoxHeight = zoomsBox.offsetHeight;

               // 获取大图元素的信息宽，高
               let pictureBigBox = pictureBig.value;
               let pictureBigWidth = pictureBigBox.offsetWidth;
               let pictureBigHeight = pictureBigBox.offsetHeight;

               //获取滚动条的高度
               let scroll = document.documentElement.scrollTop || document.body.scrollTop;

               //主图距离父元素的left及top值
               let left = moveX - previewsBoxLeft - maskBoxWidth / 2;
               let top;
               if (scroll > 0) {
                    top = moveY - previewsBoxTop + scroll - maskBoxHeight / 2;
               } else {
                    top = moveY - previewsBoxTop - maskBoxHeight / 2;
               }

               //移动限制最大宽度，及最大高度
               let maxWidth = previewBoxWidth - maskBoxWidth;
               let maxHeight = previewBoxHeight - maskBoxHeight;

               left = left < 0 ? 0 : left > maxWidth ? maxWidth : left;
               top = top < 0 ? 0 : top > maxHeight ? maxHeight : top;

               //比列
               let parcentX = left / maxWidth;
               let parcentY = top / maxHeight;

               //遮罩层的定位值
               maskBox.value.style.left = left + "px";
               maskBox.value.style.top = top + "px";

               //图片的定位置
               zoomBox.value.style.left = left - maxWidth / 3 + "px";
               zoomBox.value.style.top = top - maxHeight / 3 + "px";

               //大图元素的定位值
               pictureBig.value.style.left =
                    parcentX * (zoomBoxWidth - pictureBigWidth) + "px";
               pictureBig.value.style.top =
                    parcentY * (zoomBoxHeight - pictureBigHeight) + "px";
               pictureBig.value.style.width =
                    (previewBoxWidth / maskBoxWidth) * zoomBoxWidth + "px";
               pictureBig.value.style.height =
                    (previewBoxHeight / maskBoxHeight) * zoomBoxHeight + "px";
          };
          //鼠标移出
          const out = () => {
               maskShow.value = false;
               window.removeEventListener("touchmove", handleScroll, { passive: false });
               window.removeEventListener("wheel", handleScroll, { passive: false });
          };
          //鼠标移入
          const enter = () => {
               maskShow.value = true;
               window.addEventListener('touchmove', handleScroll, { passive: false });
               window.addEventListener('wheel', handleScroll, { passive: false });
          };

          const handleScroll = (e) => {
               if (e.deltaY > 0 && zoom.value < 10) {
                    // 背景图缩小 -- 效果放大
                    zoom.value++;
                    pictureBig.value.style.transform = 'scale(' + zoom.value / 10 + ')';
               } else if (e.deltaY < 0 && zoom.value > 2) {
                    // 背景图放大 -- 效果缩小
                    zoom.value--;
                    pictureBig.value.style.transform = 'scale(' + zoom.value / 10 + ')';
               }
               event.preventDefault();
          };

          const priceInput = (type, e) => {
               if (type == 'lowprice') {
                    lowprice.value = String(e.target.value).slice(0, 8)
                    let low = String(e.target.value).split('.')
                    if (low[1] && low[1].length >= 2) lowprice.value = Number(e.target.value).toFixed(2)

               }

               if (type == 'highprice') {
                    highprice.value = String(e.target.value).slice(0, 8)
                    let high = String(e.target.value).split('.')
                    if (high[1] && high[1].length >= 2) highprice.value = Number(e.target.value).toFixed(2)

               }
          }



          onMounted(() => {
               if (categoryId.value) {
                    getSeries()
               }
               getInitData()
          })

          return {
               L,
               ...toRefs(state),
               loading,
               contrast,
               addProJ,
               getInitData,
               goodsData,
               indexNum,
               highprice,
               lowprice,
               confirm,
               reset,
               indexNum,
               filterSort,
               labelCode,
               changeBox,
               categoryId,
               categoryPath,
               memberInfo,
               chosenAttr,
               handleCurrentChange,
               maskShow,
               previewBox,
               maskBox,
               zoomBox,
               pictureBig,
               move,
               out,
               enter,
               handleScroll,
               zoom,
               params,
               recomChange,
               refreshAttrList,
               confirmPrice,
               resetPrice,
               priceInput
          }
     }
}
</script>


<style lang="scss" scoped>
.bar {
     margin-left: 10px;

     .el-icon-arrow-left,
     .el-icon-arrow-right {
          color: $colorTitle1;
          font-weight: bold;
          font-size: 12px;
          cursor: pointer;
          margin-top: 1px;

          &:hover {
               color: $colorMain;
          }
     }
}

.goods_series {
     background-color: #F7F7F7;
     padding-bottom: 50px;
}

.flex-col {
     display: flex;
     flex-direction: column;
}

.flex-row {
     display: flex;
     flex-direction: row;
}


.nav_lf {
     width: 100%;
     height: 40px;
     background: #fff;

     .about {
          width: 1200px;
          height: 40px;
          margin: 0 auto;

          a {
               font-size: 13px;
               font-family: SourceHanSansCN-Regular, SourceHanSansCN;
               font-weight: 400;
               color: #686868;
          }

          .el-icon-arrow-right {
               margin: 0 3px;
          }
     }
}

.main_serie {
     width: 1200px;
     margin: 15px auto 0;

     .outer15 {


          background-color: rgba(255, 255, 255, 1);
          align-self: center;
          padding-top: 20px;
          padding-bottom: 20px;

          .block2 {
               padding-left: 20px;
               height: 22px;


               .word19 {
                    font-size: 22px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #0D0E10;
               }
          }

          .block3 {
               width: 1180px;
               // height: 1046px;
               align-items: flex-start;
               justify-content: space-between;
               margin: 20px 0 0 20px;

               &.lgWidth {
                    .main1 {
                         width: 400px;
                         margin-right: 0;

                         .section3 {
                              width: 400px;
                              height: 400px;
                         }
                    }

                    .main2 {
                         width: 760px;
                    }

                    .word21 {
                         width: 390px !important;
                         margin-right: 0;

                         .word21_desc {
                              width: 390px !important;
                         }
                    }
               }

               .main1 {
                    width: 588px;
                    flex-wrap: wrap;
                    margin-right: 10px;

                    .section3 {
                         position: relative;
                         width: 294px;
                         height: 294px;
                         border: 1px solid #f2f2f2;
                         overflow: hidden;
                         margin-right: 15px;

                         &:hover {
                              overflow: unset;
                         }

                         .img1 {
                              max-width: 100%;
                              max-height: 100%;
                         }

                         .mask {
                              position: absolute;
                              top: 0;
                              left: 0;
                              width: 120px;
                              height: 120px;
                              z-index: 10;
                              background: #ffffff;
                              opacity: 0.2;
                              display: none;
                         }

                         .magnifier_icon {
                              position: absolute;
                              bottom: 0;
                              right: 0;
                              width: 27px;
                              height: 27px;
                              background: rgba(0, 0, 0, 0.3);
                              z-index: 10;
                         }

                         .goods_picture_big {
                              width: 200px;
                              height: 200px;
                              // border: 1px solid $colorI;
                              border-radius: 50%;
                              background-position: -178px 26px;
                              background-repeat: no-repeat;
                              background-color: rgb(255, 255, 255);
                              box-shadow: rgba(0, 0, 0, .15) 3px 3px 10px;
                              cursor: none;
                              position: absolute;
                              left: 0;
                              top: 0;
                              z-index: 50;
                              overflow: hidden;

                              .default_image_big {
                                   position: absolute;
                                   width: 1200px;
                                   height: 1200px;
                                   background-size: contain;
                                   background-repeat: no-repeat;
                                   background-position: center;
                              }
                         }
                    }

                    .word20_box {
                         width: 277px;
                         height: 305px
                    }

                    .word20 {
                         display: block;
                         width: 72px;
                         height: 18px;
                         line-height: 18px;
                         color: #5574BC;
                         font-size: 16px;
                         font-family: Microsoft YaHei;
                         font-weight: bold;
                         overflow-wrap: break-word;
                         text-align: left;
                         white-space: nowrap;
                         align-self: flex-start;
                    }

                    .section4 {
                         position: relative;
                         width: 278px;
                         margin-top: 16px;

                         .bd3 {
                              height: 260px;
                              overflow: hidden;

                              .wrap_con {
                                   width: 276px;
                                   min-height: 35px;
                                   line-height: 23px;
                                   font-size: 14px;
                                   font-family: Microsoft YaHei;
                                   font-weight: 400;
                                   // padding-bottom: 10px;
                                   word-break: break-all;

                                   .wrap2 {
                                        width: 122px;
                                        color: #80818A;
                                        background: $colorK;
                                        padding: 6px;
                                        border-bottom: 1px dashed #E5E5E5;
                                   }

                                   .wrap3 {
                                        color: #333333;
                                        flex: 1;
                                        padding-top: 6px;
                                        padding-left: 8px;
                                        padding-right: 6px;
                                        padding-bottom: 6px;
                                        border-bottom: 1px dashed #E5E5E5;
                                   }

                                   &.noBorder {

                                        .wrap2,
                                        .wrap3 {
                                             border-bottom: none;
                                        }
                                   }
                              }
                         }
                    }

                    .word21 {
                         width: 588px;
                         margin-top: 40px;
                         margin-right: 10px;

                         .word21_title {
                              line-height: 43px;
                              color: #333333;
                              font-size: 22px;
                              font-family: Microsoft YaHei;
                              font-weight: 400;
                         }

                         .word21_desc {
                              width: 588px;
                              line-height: 28px;
                              color: #525252;
                              font-size: 14px;
                              font-family: Microsoft YaHei;
                              font-weight: 400;
                              word-break: break-all;
                              margin-top: 10px;
                         }
                    }
               }

               .main1-2 {
                    position: relative;
               }

               .main2 {
                    width: 562px;
                    height: 830px;
                    margin-right: 20px;
                    overflow: hidden;
                    background: #f7f7f7;
                    justify-content: flex-start;
                    align-items: flex-end;
                    position: relative;
                    overflow: auto;
               }
          }
     }

     .param_chosen {
          margin-top: 20px;
          margin-bottom: -20px;

          .count_series {
               font-size: 12px;
               font-family: Microsoft YaHei;
               color: #121212;
               white-space: nowrap;

               span {
                    font-size: 14px;
                    color: $colorMain !important;
                    font-weight: bold;
                    margin: 0 2px;
                    font-size: 20px;
                    font-family: SourceHanSansCN-Regular;
               }
          }
     }
}

.goods_list {
     width: 1200px;
     margin: 0 auto;
     margin-top: 10px;
     padding-left: 0px;
     padding-right: 0px;

     .sld_screen {
          -moz-user-select: none;
          /*火狐*/
          -webkit-user-select: none;
          /*webkit浏览器*/
          -ms-user-select: none;
          /*IE10*/
          user-select: none;
          padding-right: 20px;
          line-height: 36px;
          position: relative;
          height: 38px;
          background-color: #fff;
          box-sizing: border-box;
          background: #F2F5FD;

          .btn_sort {
               i {
                    color: #fff;
               }

          }

          .screen_item {
               width: 75px;
               height: 38px;
               font-size: 12px;
               font-family: SourceHanSansCN-Regular, SourceHanSansCN;
               font-weight: 400;
               color: #333333;
               border-right: 1px solid #f4f4f4;
               cursor: pointer;


               &.btn_sort_default {
                    background-color: $colorMain;
                    color: #fff;
               }

               .arrow {
                    transform: rotate(180deg);
               }

               .sld_price {
                    position: relative;
                    margin-right: 10px;

                    &::after,
                    &::before {
                         position: absolute;
                         content: "";
                         right: -12px;
                         width: 0;
                         height: 0;
                         border-width: 4px;
                         border-style: solid;
                    }

                    &::before {
                         top: 50%;
                         transform: translateY(3px);
                         border-color: #afafaf transparent transparent transparent;
                    }

                    &::after {
                         top: 50%;
                         -webkit-transform: translateY(-12px);
                         -moz-transform: translateY(-12px);
                         -ms-transform: translateY(-12px);
                         -o-transform: translateY(-12px);
                         transform: translateY(-9px);
                         border-color: transparent transparent #afafaf transparent;
                    }
               }
          }

          .screen_item_price {
               height: 38px;
               padding: 0 12px;
               border-right: 1px solid #f4f4f4;
               display: flex;

               .price_git {
                    display: inline-block;
                    line-height: 38px;
               }
          }

          .screen_item_sel {
               padding: 0 14px;
               border-right: 1px solid #f4f4f4;
               height: 38px;
               cursor: pointer;

               .sel_icon {
                    width: 13px;
                    height: 13px;
                    border: 1px solid #666666;
                    margin-right: 10px;

                    .icon-finish {
                         font-size: 12px;
                         transform: scale(0.8);
                    }
               }

               label,
               input {
                    cursor: pointer;
               }

               #label {
                    margin-right: 10px;

                    &:checked+label {
                         span {
                              color: $colorMain;
                         }
                    }
               }
          }

          .good_type {
               p a input {
                    vertical-align: middle;
                    margin-top: 3px;
               }
          }



          i {
               color: #afafaf;
          }

          &>a {
               margin: 0 20px 0 15px;

               &:hover {
                    color: $colorMain;

                    i {
                         color: $colorMain;
                    }
               }
          }

          &>a,
          &>input,
          &>div {
               line-height: 26px;
          }

          .sld_goods_num {
               line-height: 36px;
               margin-right: 10px;

               span {
                    color: $colorMain;
                    font-weight: bold;
                    margin: 0 2px;
                    font-size: 20px;
                    font-family: SourceHanSansCN-Regular;
                    line-height: 0;
               }
          }

          .sld_price_jt_down {
               position: relative;

               .sld_price {
                    &::before {
                         border-color: #fff transparent transparent transparent;
                    }

                    &::after {
                         border-color: transparent transparent #afafaf transparent;
                    }
               }
          }

          .sld_price_jt_up {
               position: relative;

               .sld_price {
                    &::before {
                         border-color: #afafaf transparent transparent transparent;
                    }

                    &::after {
                         border-color: transparent transparent #fff transparent;
                    }
               }
          }

          .goods_page {
               line-height: 26px;

               a,
               p {
                    display: inline-block;

                    em {
                         color: $colorMain2;
                    }
               }

               a {
                    padding: 0 6px;

                    i {
                         font-size: 12px;
                         color: #666666;
                    }

                    i.prev {
                         display: inline-block;
                         -webkit-transform: rotate(90deg);
                         -moz-transform: rotate(90deg);
                         -ms-transform: rotate(90deg);
                         -o-transform: rotate(90deg);
                         transform: rotate(90deg);
                    }

                    i.next {
                         display: inline-block;
                         -webkit-transform: rotate(-90deg);
                         -moz-transform: rotate(-90deg);
                         -ms-transform: rotate(-90deg);
                         -o-transform: rotate(-90deg);
                         transform: rotate(-90deg);
                    }
               }
          }
     }

     .sld_goods_list {
          width: 1200px;
          margin: 0px 0 25px;
          background-color: #fff;
          position: relative;

          .sld_goods_screen {
               padding-left: 30px;

               .gs_img {
                    width: 106px;
                    text-align: center;
               }
          }

          &.skeleton_sld_goods_list {
               li {
                    .sld_img {
                         background: $colorSkeleton;
                    }
               }

               .sld_h32_hide {
                    background: $colorSkeleton;
                    width: 100%;
               }

               .sld_goods_price {
                    display: inline-block;
                    background: #eee;
                    width: 70px;
                    height: 19px;
                    color: transparent;
               }

               p.clearfix {
                    .fr {
                         color: #999;
                         width: 55px;
                         height: 18px;
                         background: $colorSkeleton;
                         color: transparent;
                    }
               }

               .tag {
                    .sld_follow {
                         background: #eee;
                         width: 60px;
                         height: 23px;
                         color: transparent;
                         cursor: pointer;
                    }
               }
          }

          li {
               float: left;
               width: 233px;
               height: 380px;
               padding: 29px 11px 20px 11px;
               box-sizing: border-box;
               margin: 0 8px 11px 0;

               &:hover {
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
               }

               &:nth-child(5n) {
                    margin-right: 0;
               }

               .sld_img {
                    width: 214px;
                    height: 214px;
               }
          }

          p.clearfix {
               padding: 8px 0 10px;

               .fr {
                    color: #999;

                    em {
                         color: $colorMain2;
                    }
               }
          }

          .sld_goods_price {
               color: $colorMain2;
               font-size: 14px;
               padding: 0px;

               em {
                    font-size: 18px;
               }
          }

          .sld_h32_hide {
               height: 32px;
               overflow: hidden;
          }

          a {
               color: #606060;
               margin-bottom: 4px;
          }

          .sld_goods_name {
               height: 32px;
               line-height: 16px;
               overflow: hidden;
               word-break: break-all;
               text-overflow: ellipsis;
               display: -webkit-box;
               -webkit-box-orient: vertical;
               -webkit-line-clamp: 2;
               font-family: MicrosoftYaHei;
          }

          .tag {
               margin: 8px 0;

               .tag_b {
                    background: linear-gradient(45deg, #ff6c00, #ffc053);
               }

               span {
                    display: inline-block;
                    line-height: 15px;
                    padding: 0 3px;
                    border-radius: 2px;
                    color: #fff;
                    margin-right: 2px;
               }

               .sld_follow {
                    cursor: pointer;
                    background-color: transparent;
                    color: inherit;
                    border: none;
                    outline: none;

                    i {
                         font-size: 20px;
                         vertical-align: middle;
                         margin-right: 6px;
                    }

                    &.on {
                         color: $colorMain2;
                    }
               }
          }
     }
}

.price_section {
     border: 1px solid transparent;
     margin-left: 10px;
     padding: 4px;
     position: relative;
     z-index: 88;

     .ctrl {
          display: none;
          padding-top: 8px;
          position: absolute;
          width: 162px;
          z-index: 88;

          a {
               height: 23px;
               padding: 0 10px;

               &:nth-child(1) {
                    color: #005aa0;
               }

               &:nth-child(2) {
                    color: #333333;
                    line-height: 21px;
                    border: 1px solid $colorD;
               }

               cursor: pointer;
          }
     }

     .input_box {
          position: relative;
          margin-top: 1px;

          input[type="number"] {
               position: relative;
               width: 71px;
               height: 26px;
               line-height: 24px;
               border: 1px solid #e5e5e5;
               padding-left: 15px;
               box-sizing: border-box;
               outline: none;
          }

          .line {
               display: inline-block;
               width: 12px;
               height: 2px;
               background-color: #a9a9a9;
               margin: 0 6px;
               vertical-align: middle;

               &::after {
                    top: 1px;
                    left: 88px;
                    position: absolute;
                    top: 0;
                    left: 5px;
                    content: "￥";
                    font-size: 12px;
                    color: #a9a9a9;
                    line-height: 24px;
                    z-index: 0;
               }
          }
     }

     &:hover {
          height: 78px;
          border-color: #999;
          background-color: #fff;
     }

     &:hover .ctrl {
          display: flex;
     }
}

.block13 {
     width: 874px;
     height: 43px;
     margin: 0px 0 0 61px;
     padding: 15px 0;
     font-weight: boldl;

     .word89 {
          height: 14px;
          display: block;
          overflow-wrap: break-word;
          color: #121212;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular;
          white-space: nowrap;
          line-height: 14px;
          text-align: left;
          margin-top: 1px;
     }

     .word90 {
          height: 14px;
          display: block;
          overflow-wrap: break-word;
          color: #121212;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular;
          white-space: nowrap;
          line-height: 14px;
          text-align: left;
          margin-left: 67px;
     }

     .word91 {
          height: 14px;
          display: block;
          overflow-wrap: break-word;
          color: #121212;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular;
          white-space: nowrap;
          line-height: 14px;
          text-align: left;
          margin-left: 228px;
     }

     .info53 {
          height: 14px;
          display: block;
          overflow-wrap: break-word;
          color: #121212;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular;
          white-space: nowrap;
          line-height: 14px;
          text-align: left;
          margin-left: 161px;
     }

     .word92 {
          height: 14px;
          display: block;
          overflow-wrap: break-word;
          color: #121212;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular;
          white-space: nowrap;
          line-height: 14px;
          text-align: left;
          margin-left: 85px;
     }

     .word93 {
          height: 14px;
          display: block;
          overflow-wrap: break-word;
          color: #121212;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular;
          white-space: nowrap;
          line-height: 14px;
          text-align: left;
          margin-left: 67px;
     }

     .word94 {
          height: 14px;
          display: block;
          overflow-wrap: break-word;
          color: #121212;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular;
          white-space: nowrap;
          line-height: 14px;
          text-align: left;
          margin-left: 170px;
     }
}

.group11 {

     background-color: #fff;
     margin-top: 20px;
     justify-content: flex-start;
     padding: 15px;
     position: relative;

     .mod13 {
          z-index: auto;
          background-color: $colorK;
     }

     .section13 {
          z-index: auto;
          border-bottom: 1px solid #d8d8d8;
          padding: 15px 0;
          justify-content: flex-start;
     }

     .icon8 {
          width: 16px;
          height: 16px;
          color: $colorMain;
          margin-right: 10px;
          margin-left: 20px;
     }

     .word49 {
          width: 448px;
          height: 14px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 14px;
          font-family: SourceHanSansCN-Regular;
          white-space: nowrap;
          line-height: 14px;
          text-align: left;
          margin-top: 1px;
     }

     .section15 {
          width: 103px;
          height: 91px;
          margin-top: 43px;
          margin-bottom: 20px;
     }

     .word50 {
          width: 114px;
          height: 16px;
          display: block;
          overflow-wrap: break-word;
          color: $colorMain;
          font-size: 16px;
          text-decoration: underline;
          font-family: SourceHanSansCN-Regular;
          white-space: nowrap;
          line-height: 16px;
          margin-bottom: 20px;
     }

     .mod14 {
          height: 56px;

          width: 56px;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 580px;
          top: 105px;
     }

     .label13 {
          width: 23px;
          height: 20px;
     }
}

.panelFixed {
     width: 100%;
     position: fixed;
     bottom: 0;
     left: 0;
}

.contrast_panel {
     height: 290px;
}

.contrast {
     // position: absolute;
     z-index: 999;
     bottom: 0;
     left: 0;
     display: flex;
     justify-content: center;

     .wrap22 {
          height: 288px;
          background: #fff;
          width: 1200px;
          border: 2px solid $colorD;

          .mod30 {
               .bd38 {
                    padding: 0 20px;
                    align-items: center;
                    justify-content: space-between;
                    height: 54px;
                    border-bottom: 1px solid $colorD;

                    .txt51 {
                         color: rgba(51, 51, 51, 1);
                         font-size: 16px;
                    }

                    .txt52 {
                         color: rgba(40, 95, 222, 1);
                         font-size: 14px;
                         cursor: pointer;
                    }
               }

               .bd40 {
                    height: 228px;

                    .layer113 {
                         width: 240px;
                         position: relative;
                         border-right: 1px dashed $colorH;

                         .info70 {
                              position: absolute;
                              color: rgba(40, 95, 222, 1);
                              font-size: 14px;
                              top: 13px;
                              right: 13px;
                         }

                         .outer22 {
                              width: 150px;
                              height: 179px;
                              margin-top: 20px;

                              .mod31 {
                                   position: relative;
                                   width: 150px;
                                   height: 150px;
                                   overflow: hidden;
                                   background: #f7f7f7f7;

                                   .box22 {
                                        position: absolute;
                                        left: 7px;
                                        top: 14px;
                                        width: 137px;
                                        height: 125px;
                                        background: #f7f7f7f7;
                                   }
                              }
                         }
                    }
               }
          }

          .word149 {
               width: 220px;
               overflow: hidden;
               white-space: nowrap;
               text-overflow: ellipsis;
               color: rgba(51, 51, 51, 1);
               font-size: 14px;
               margin-top: 15px;
          }

          .txt54 {
               position: absolute;
               left: 54px;
               top: 31px;
               color: rgba(204, 204, 204, 1);
               font-size: 74px;
          }

          .word151 {
               color: rgba(153, 153, 153, 1);
               font-size: 14px;
               margin-top: 15px;
          }

          .outer28 {
               width: 90px;
               height: 78px;
               margin: 69px 0 0 74px;
          }

          .outer29 {
               height: 34px;
               background: $colorMain;
               width: 90px;
               justify-content: center;
               align-items: center;
          }

          .word152 {
               color: rgba(255, 255, 255, 1);
               font-size: 14px;
          }

          .word153 {
               width: 90px;
               color: rgba(51, 51, 51, 1);
               font-size: 14px;
               margin-top: 30px;
               text-align: center;
          }

          .mod32 {
               position: absolute;
               left: 240px;
               top: 60px;
               width: 1px;
               height: 228px;
               background: #f7f7f7f7;
          }

          .mod33 {
               position: absolute;
               left: 480px;
               top: 60px;
               width: 1px;
               height: 228px;
               background: #f7f7f7f7;
          }
     }
}
</style>
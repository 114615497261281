<!-- // 商品详情页面 -->
<template>
	<div class="sld_goods_detail">
		<!-- 内容区顶部固定 start -->
		<div class="contain_con" v-if="containCon">
			<div class="contain_content flex_row_center_center">
				<div class="contain_con_right">
					<div class="goods_description_title flex_row_between_center">
						<div class="description_title_left flex_row_start_center">
							<span class="cursor_pointer" :class="{ description_active: goodsDesctionType == 'detail' }"
								@click="goodsDescType('detail')">{{ L['产品详情'] }}</span>
							<!-- <span class="cursor_pointer" :class="{ description_active: goodsDesctionType == 'information' }"
								@click="goodsDescType('information')">{{ L['行业知识'] }}</span> -->

							<span class="cursor_pointer" :class="{ description_active: goodsDesctionType == 'comment' }"
								@click="goodsDescType('comment')"
								v-if="goodsCommentsInfo.data && goodsCommentsInfo.data.commentsCount">{{ L['评价']
								}}&nbsp;
								({{ goodsCommentsInfo.data && goodsCommentsInfo.data.commentsCount }})</span>

							<span class="cursor_pointer" :class="{ description_active: goodsDesctionType == 'service' }"
								@click="goodsDescType('service')">{{ L['产品服务'] }}</span>
						</div>
						<div class="description_title_right flex_row_end_center">
							<div class="mobile_order flex_row_start_center cursor_pointer">
								<span>{{ L['手机下单'] }}</span>
								<div class="goods_code" id="qrcodeTop"></div>
								<div class="mobile_order_model flex_column_center_center">
									<div class="model_goods_code" id="qrcodeBig1">
										<img v-if="qrCodeImage" :src="'data:image/png;base64,' + qrCodeImage" />
									</div>
									<p>{{ L['手机下单'] }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 内容区顶部固定 end -->
		<div class="goods_detail_content" v-if="goodsDetail.data">
			<!-- 商品所属分类 ，联系客服，关注店铺 start-->
			<div class="goods_about_con">
				<div class="goods_about flex_row_between_center">
					<div class="goods_classify">
						<span v-for="(item, index) in goodsDetail.data.categoryPath" :key="index">
							<router-link
								:to="`/goods/list?categoryId=${goodsDetail.data.categoryIdPath[index]}&g=${index + 1}&pid=${index == 0 ? 0 : goodsDetail.data.categoryIdPath[index - 1]}`">
								{{ item }}
							</router-link>
							<i class="el-icon-arrow-right"></i>
						</span>
						<span style="color: #333333;">{{ goodsDetail.data.goodsName }}</span>
					</div>
					<div class="goods_about_right flex_row_between_center">
						<router-link target="_blank" :to="`/store/index?vid=${goodsDetail.data.storeInf.storeId}`"
							class="goods_about_store flex_row_between_center" v-if="goodsDetail.data.storeInf">
							<span>{{ goodsDetail.data.storeInf.storeName }}</span>
						</router-link>
						<div class="contact_service focus_store flex_row_center_center" @click="focusStore"
							v-if="goodsDetail.data.storeInf">
							<i class="iconfont iconguanzhu-copy" v-if="goodsDetail.data.storeInf.isFollowStore"></i>
							<i class="iconfont iconheart1" v-else></i>
							<span>
								{{ goodsDetail.data.storeInf.isFollowStore ? L['已关注'] : '关注供应商' }}
							</span>
						</div>
						<div class="contact_service flex_row_center_center" @click="kefu">
							<i class="iconfont icontongyong-lianxikefutubiao"></i>
							<span>{{ L['联系客服'] }}</span>
						</div>
						<div class="contact_service flex_row_center_center" v-if="goodsDetail.data.isNew == 1">
							<i class="el-icon-user-solid"></i>
							<span>{{ L['技术负责人'] }}</span>
							<div class="contact_info flex_column_center_center">
								<p>{{ L['微信'] }}</p>
								<img :src="goodsDetail.data.technicalLeaderWechat" alt="">
								<p v-if="goodsDetail.data.technicalSupportPhone">{{
									goodsDetail.data.technicalSupportPhone }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 商品所属分类 ，联系客服，关注店铺 end-->
			<!-- 商品主要信息 start -->
			<div class="main_con1">
				<!-- 商品相关 start -->
				<div class="goods_des">
					<!-- 商品图片列表 start -->
					<div class="goods_des_left">

						<!-- 主图start -->
						<detailImage :firstLoading="firstLoading" :info="goodsDetail.data.defaultProduct"
							:videoInfo="goodsDetail.data.goodsVideo"></detailImage>
						<!-- 主图end -->


						<!-- 商品分享和收藏 start -->
						<div class="collection_share_btn flex_row_start_start" v-if="goodsDetail.data.state == 3">
							<div class="collection_btn flex_row_start_center cursor_pointer" @click="collectGoods">

								<i class="iconfont iconwujiaoxing" v-if="goodsDetail.data.followGoods"></i>
								<i class="iconfont iconwujiaoxingkong" v-else></i>
								<span>{{
									goodsDetail.data.followGoods ? L['已收藏'] : L['收藏']
								}}</span>
							</div>
							<div class="share_btn">
								<div class="share_btn_con flex_row_start_center">
									<img src="@/assets/goods/share.png" alt="" />
									<span>{{ L['分享'] }}</span>
								</div>
								<div class="share_list1">
									<div class="share_btn_pre flex_row_start_center cursor_pointer">
										<img src="@/assets/goods/share.png" alt="" />
										<span>{{ L['分享'] }}</span>
									</div>
									<div class="share_btn_pre flex_row_start_center cursor_pointer" @click="share('qzone')">
										<img src="@/assets/goods/qq_share.png" alt="" />
										<span>{{ L['QQ'] }}</span>
									</div>
									<div class="share_btn_pre flex_row_start_center cursor_pointer" @click="share('sina')">
										<img src="@/assets/goods/sina_share.png" alt="" />
										<span>{{ L['新浪'] }}</span>
									</div>
									<div class="share_btn_pre flex_row_start_center cursor_pointer" @click="share('weixin')"
										@mouseover="share('weixin')">
										<img src="@/assets/goods/wx_share.png" alt="" />
										<span>{{ L['微信'] }}</span>
									</div>
									<div class="wx_share_code flex_column_center_center" v-if="wxShareCode">
										<div id="share_wx_qrcode" class="wx_share_code_img"></div>
										<p class="wx_share_code_title">{{ L['分享到微信'] }}</p>
									</div>
								</div>
							</div>
							<div class="compare_btn flex_row_start_center" @click="contrast">
								<span class="iconfont iconchanpinxiangqing-vs"></span>
								<span style="margin-left:6px;">{{ L['加入对比'] }}</span>
							</div>
						</div>
						<!-- 商品分享和收藏 end -->

					</div>
					<!-- 商品图片列表 end -->
					<!-- 商品详细信息 start -->
					<div class="m_item_inner">
						<div class="item_info">
							<div class="flex_row_start_start">
								<div class="newTag" v-if="goodsDetail.data.isNew">{{ L['新品'] }}</div>

								<div :class="{ p_ad: true, skeleton_p_ad: firstLoading }"
									v-if="firstLoading ? true : goodsDetail.data.goodsName">
									{{ goodsDetail.data.goodsName }}
								</div>

							</div>



							<div v-if="goodsDetail.data.isNew && goodsDetail.data.listTime" class="listTime">
								<span>{{ L['上市时间：'] }}</span>{{ goodsDetail.data.listTime }}
							</div>

							<!-- 商品未下架即正常商品 start -->
							<div v-if="goodsDetail.data.state == 3 || firstLoading" class="normal_product">


								<!-- 促销活动信息 -->

								<seckillHeader v-if="isSeckill" :promotionId="goodsDetail.data.defaultProduct.promotionId"
									@refresh="getInitDataStatic" @getSeckillInfo="getSeckillInfo">
								</seckillHeader>
								<!-- 促销活动信息end -->



								<div class="summary">
									<div class="sld_summary_item summary_goods clearfix">
										<div class="sld_summary_goods_left">
											<div class="goods_price">
												<!-- 在售价 -->
												<div class="price_line" :class="{ flex_row_start_center: true }">
													<span class="price_title">{{ L['价格'] }}</span>
													<template v-if="useLoginFlag">
														<strong :class="{ p_price: true, skeleton_p_price: firstLoading }"
															v-if="isSeckill && seckillInfo.state == 2">
															<span>￥</span>
															<span>
																<span>{{
																	seckillInfo.seckillPrice &&
																	seckillInfo.seckillPrice.toFixed(2)
																}}</span>
															</span>
														</strong>

														<div class="flex_row_start_center"
															v-else-if="specProductList.length">
															<strong
																:class="{ p_price: true, skeleton_p_price: firstLoading }">
																<span>￥</span>
																<span>
																	<span>{{ Number(priceRange.min).toFixed(2)
																	}}</span>
																</span>
															</strong>
															<strong class="p_price">&nbsp;&nbsp;~&nbsp;&nbsp;</strong>
															<strong
																:class="{ p_price: true, skeleton_p_price: firstLoading }">
																<span>￥</span>
																<span>
																	<span>{{ Number(priceRange.max).toFixed(2)
																	}}</span>
																</span>
															</strong>
														</div>


														<strong :class="{ p_price: true, skeleton_p_price: firstLoading }"
															v-else>
															<span>￥</span>
															<span>
																<span>{{ productPrice && productPrice.toFixed(2)
																}}</span>
															</span>
														</strong>
													</template>

													<strong v-else :class="{ p_price: true }">
														<span>登录后查看价格</span>
													</strong>





												</div>


												<!-- 优惠券start -->
												<div class="coupon flex_row_start_center"
													v-show="couponList.list.length > 0">
													<div class="coupon_title">{{ L['优惠券'] }}</div>
													<div class="coupon_list flex_row_start_center">
														<span class="coupon_pre_rules cursor_pointer"
															v-for="(couponItem, couponIndex) in couponList.list"
															:key="couponIndex" v-show="couponIndex < 6"
															@click="openCouponModel">{{
																couponItem.couponContent
															}}</span>


														<span class="more_coupon" @click="openCouponModel">{{
															L['全部'] }}
															></span>

													</div>


													<!-- 优惠券弹框 start -->
													<el-dialog v-model="couponModel" :title="L['领取优惠券']" width="880px"
														lock-sroll="false">
														<div class="coupon_model" lock-sroll="false">
															<div class="model_coupon_list">
																<CouponItem
																	v-for="(couponItem, couponIndex) in couponList.list"
																	:key="couponIndex" :coupon_item="couponItem"
																	:class="sld_coupon_item"
																	@refreshCouponList="openCouponModel">
																</CouponItem>
															</div>
															<div class="flex_row_end_center sld_pagination sld_page_bottom"
																v-if="couponList.pagination.total">
																<el-pagination @current-change="handleCurrentChangeCoupon"
																	v-model:currentPage="couponList.current" :page-size="6"
																	layout="prev, pager, next, jumper"
																	:total="couponList.pagination.total"
																	:hide-on-single-page="true">
																</el-pagination>
															</div>
														</div>
													</el-dialog>
													<!-- 优惠券弹框 end -->
												</div>
												<!-- 优惠券end -->



												<div class="line_state flex_row_start_center">
													<div>
														<span
															:class="{ state_title: true, skeleton_state_title: firstLoading }">生产状态：</span>
														<span
															:class="{ state_value: true, skeleton_state_value: firstLoading }">{{
																goodsDetail.data.produceStateValue
															}}</span>
													</div>
													<div>
														<span
															:class="{ state_title: true, skeleton_state_title: firstLoading }">发货日：</span>
														<span
															:class="{ state_value: true, skeleton_state_value: firstLoading }">{{
																goodsDetail.data.deliverTime
															}}{{ L['天'] }}</span>
													</div>


													<div v-if="goodsDetail.data.saleUnitDes">
														<span
															:class="{ state_title: true, skeleton_state_title: firstLoading }">售卖单位：</span>
														<span
															:class="{ state_value: true, skeleton_state_value: firstLoading }">{{
																goodsDetail.data.saleUnitDes
															}}</span>

													</div>

													<div>
														<span
															:class="{ state_value: true, skeleton_state_value: firstLoading }"
															v-if="goodsDetail.data.minBuyNum">
															&nbsp;&nbsp;
															起订量：{{ goodsDetail.data.minBuyNum }}&nbsp;</span>
													</div>

													<div class="flex_row_start_center"
														v-if="goodsDetail.data.defaultProduct && goodsDetail.data.defaultProduct.orderCode && !goodsDetail.data.specProductList.length">
														<p class="orderCode">{{ L['订货编码：'] }}</p>
														<p class="orderCode">{{
															goodsDetail.data.defaultProduct.orderCode }}</p>
													</div>

													<div class="flex_row_start_center"
														v-if="goodsDetail.data.defaultProduct && goodsDetail.data.defaultProduct.productCode">
														<p class="orderCode">{{ L['物料编码：'] }}</p>
														<p class="orderCode productCode"
															:title="goodsDetail.data.defaultProduct.productCode">
															{{
																goodsDetail.data.defaultProduct.productCode
															}}</p>
													</div>

												</div>

											</div>
										</div>
									</div>



								</div>
                                <div class="series flex_row_start_center"
									v-if="firstLoading ? true : goodsDetail.data.seriesId">
									<div>
										<span class="series_title">{{ L['型号'] }}</span>
									</div>
									<span style="margin-left: 10px;"
										>{{
											goodsDetail.data.goodsSpec?goodsDetail.data.goodsSpec:'--'
										}}</span>
								</div>
								<div class="series flex_row_start_center"
									v-if="firstLoading ? true : goodsDetail.data.seriesId">
									<div>
										<span class="series_title">{{ L['系列'] }}</span>
									</div>
									<span :class="{ series_go: true, skeleton_series_go: firstLoading }"
										@click="toSeries">{{
											goodsDetail.data.seriesName
										}}</span>
								</div>

								<!-- 运费start -->
								<detailAddr v-if="(!firstLoading) && goodsDetail.data.deliverInfo"
									:deliverInfo="goodsDetail.data.deliverInfo" ref="detailAddr"></detailAddr>
								<!-- 运费end -->




								<!-- 规格 start -->
								<spec :specs="specList" :specProductList="specProductList"
									:defaultProduct="goodsDetail.data.defaultProduct" @selectSpecVal="selectSpecVal"
									:staticInfo="goodsDetail.data"
									v-if="!firstLoading && specProductList && specProductList.length"
									:minBuyNum="goodsDetail.data.minBuyNum" ref="spec" @changeNum="changeNum">
								</spec>
								<!-- 规格 end -->


								<!-- 单规格数量start -->
								<template v-else>
									<!-- 数量加减及库存 start -->
									<div v-if="!firstLoading" class="quantity_inventory flex_row_start_center">
										<div class="quantity_title">{{ L['数量'] }}</div>
										<div class="quantity_edit flex_row_start_center">
											<span @click="editNum('reduce')" ref="reduce" :style="disStyle">-</span>
											<input type="number" v-model="currentSpecNum"
												@input="editNum('edit', cartItem, $event)"
												@blur="editNum('blur', $event)" />
											<span @click="editNum('add')" ref="add">+</span>
										</div>
										<div class="inventory"
											v-if="goodsDetail.data.defaultProduct && goodsDetail.data.defaultProduct.productStock && goodsDetail.data.produceState != 2">
											{{ L['库存'] }}{{ goodsDetail.data.defaultProduct.productStock }}件
										</div>
									</div>
									<!-- 数量加减及库存 end-->

									<div class="series flex_row_between_center" v-if="firstLoading ? true : true">
										<div class="flex_row_start_center">
											<div>
												<span class="series_title">{{ L['合计'] }}</span>
											</div>
											<span v-if="useLoginFlag" :class="{ series_total: true, skeleton_series_total: firstLoading }">
												<span style="font-size: 16px;margin-right: 1px">{{ L['¥']
												}}</span>
												<span>{{ totalPrice || 0 }}</span>
											</span>

											<span v-else>登录后查看价格</span>
										</div>
									</div>
								</template>
								<!-- 单规格数量end -->


							</div>
							<!-- 商品未下架即正常商品 end -->

							<template v-if="!firstLoading">
								<!-- 立即购买 加入购物车 收藏 ，分享 start-->

								<!-- 产品已下架 start -->
								<div class="options_btn" v-if="goodsDetail.data.state && goodsDetail.data.state != 3">
									<p class="option_desc">{{ L['产品已下架，欢迎挑选其他产品~'] }}</p>
									<div class="goods_off_shelves">{{ L['产品已下架'] }}</div>

								</div>
								<!-- 产品已下架 end -->

								<!-- 产品已下架 start -->
								<div class="options_btn"
									v-else-if="!(goodsDetail.data.specProductList && goodsDetail.data.specProductList.length) && goodsDetail.data.defaultProduct.productStock == 0">
									<div class="goods_off_shelves">{{ L['库存不足'] }}</div>
								</div>
								<!-- 产品已下架 end -->

								<!-- 普通(活动)正常商品 start -->
								<div class="options_btn flex_row_start_center" v-else>
									<div class="add_cart flex_row_center_center" @click="addCart"
										v-if="goodsDetail.data.isVirtualGoods == 1">
										<span>{{ L['加入购物车'] }}</span>
									</div>
									<div class="button_fff flex_row_center_center" @click="clickAddTo">
										{{ L['加入我的项目'] }}
									</div>
									<div class="button_fff flex_row_center_center" @click="addSampleCart"
										v-if="memberInfo.memberType == 2 || (memberInfo.memberType == 3 && memberInfo.enterpriseMaster != 1)">
										{{ L['加入样品申请单'] }}
									</div>
								</div>
								<!-- 普通(活动)正常商品 end -->

								<!-- 立即购买 加入购物车 收藏 ，分享 end-->
							</template>
						</div>
					</div>
					<!-- 商品详细信息 end -->
				</div>
				<!-- 商品相关 end -->
				<!-- 相关推荐start -->
				<div class="recom_product" v-if="recommendeList.data.length && false">
					<div class="mod3">
						<div class="section5 flex-col">
							<div class="section6 flex-row">
								<span class="info13">{{ L['类似产品推荐'] }}</span>
								<div class="block8">
									<span class="el-icon-arrow-left" @click="recomChange('prev')"></span>
									<span class="txt21" style="font-size: 15px">{{ recommendIndex + 1 }}/{{
										recommendeList.data.length
									}}</span>
									<span class="el-icon-arrow-right" @click="recomChange('next')"></span>
								</div>
							</div>
						</div>
						<div class="section7 flex-row">
							<el-carousel arrow="never" v-if="recommendeList.data.length" :autoplay="false" ref="carousel"
								:initial-index="recommendIndex" @change="changeCarousel">
								<el-carousel-item v-for="(item, index) in recommendeList.data" :key="index">
									<div class="carousel_con flex_row_start_center">
										<div class="item_set flex-col" v-for="(item1, index1) in item" :key="index1">
											<a :href="`/goods/detail?productId=${item1.defaultProductId}`"
												class="link flex-col">
												<div class="group12">
													<div class="bd17 flex-col">
														<div class="bd18">
															<img :src="item1.goodsImage" />
														</div>
														<div class="bd119">
															<span class="txt22">{{ item1.goodsSpec }}</span>
															<span class="txt22">{{ item1.goodsName }}</span>
															<span class="txt23">{{ L['¥'] }}{{
																item1.goodsPrice &&
																item1.goodsPrice.toFixed(2)
															}}</span>
														</div>

													</div>
												</div>
											</a>
										</div>
									</div>
								</el-carousel-item>
							</el-carousel>
						</div>
					</div>
				</div>
				<!-- 相关推荐end -->
				<!-- 店铺，及各种信息的切换 start -->
				<div class="container flex_row_start_start" ref="container" id="container">
					<!-- 商品详情 评价 商品服务 店铺热销  start-->
					<div class="goods_description">
						<div class="goods_description_title flex_row_between_center">
							<div class="description_title_left flex_row_start_center">
								<span class="cursor_pointer" :class="{ description_active: goodsDesctionType == 'detail' }"
									@click="goodsDescType('detail')">{{ L['产品介绍'] }}</span>
								<!-- <span class="cursor_pointer"
									:class="{ description_active: goodsDesctionType == 'information' }"
									@click="goodsDescType('information')">{{ L['行业知识'] }}</span> -->
								<span class="cursor_pointer" :class="{ description_active: goodsDesctionType == 'comment' }"
									@click="goodsDescType('comment')"
									v-if="goodsCommentsInfo.data && goodsCommentsInfo.data.commentsCount">{{ L['评价']
									}}&nbsp;({{
	goodsCommentsInfo.data
	&& goodsCommentsInfo.data.commentsCount
}})</span>

								<span class="cursor_pointer" :class="{
											description_active: goodsDesctionType == 'service',
										}" @click="goodsDescType('service')">{{ L['产品服务'] }}</span>
							</div>
							<div class="description_title_right flex_row_end_center">
								<div class="mobile_order flex_row_start_center cursor_pointer">
									<span>{{ L['手机下单'] }}</span>
									<div class="goods_code" id="qrcode"></div>
									<div class="mobile_order_model flex_column_center_center">
										<div class="model_goods_code" id="qrcodeBig">
											<img v-if="qrCodeImage" :src="'data:image/png;base64,' + qrCodeImage" />
										</div>
										<p>{{ L['手机下单'] }}</p>
									</div>
								</div>
							</div>
						</div>
						<div class="goods_description_con">
							<!-- 商品详情,规格参数,及详情富文本 start-->
							<div class="goods_des_con" v-if="goodsDesctionType == 'detail'">
								<div v-if="goodsDetail.data.brandName ||
									(goodsDetail.data.goodsParameterList &&
										goodsDetail.data.goodsParameterList.length > 0) ||
									goodsDetail.data.topTemplateContent ||
									goodsDetail.data.goodsDetails ||
									goodsDetail.data.bottomTemplateContent ||
									goodsDetail.data.foreignList && goodsDetail.data.foreignList.length ||
									goodsDetail.data.newProductFeature || goodsDetail.data.specInstruction">
									<div class="brand" v-if="goodsDetail.data.brandName">
										<span>{{ L['品牌'] }}: </span>
										<span>{{ goodsDetail.data.brandName }}</span>
									</div>
									<div
										v-if="goodsDetail.data.goodsParameterList && goodsDetail.data.goodsParameterList.length > 0">
										<div class="goods_parameter_list"
											:class="{ 'goods_paramter_more': !parameterShow }">
											<div class="goods_parameter_pre"
												v-for="(parameterItem, paramterIndex) in goodsDetail.data.goodsParameterList"
												:key="paramterIndex">
												<span>{{ parameterItem.parameterName }}: </span>
												<span>{{ parameterItem.parameterValue }}</span>
											</div>
										</div>
										<div class="collapse_unfold flex_row_center_center cursor_pointer"
											v-if="goodsDetail.data.goodsParameterList.length > 16" @click="changeopenParameter">
											<span>{{ !parameterShow ? L['查看全部'] : L['收起全部'] }}</span>
											<i class="iconfont iconziyuan11-copy" v-if="!parameterShow"></i>
											<i class="iconfont iconziyuan11-copy-copy" v-else></i>
										</div>
									</div>

									<!-- 新品特性 start -->
									<div class="goods_html common_problem" v-if="goodsDetail.data.isNew == 1">
										<div class="common_problem_title">{{ L['新品特性：'] }}</div>
										<div class="goods_htmls" v-html="goodsDetail.data.newProductFeature"></div>
									</div>
									<!-- 新品特性 end -->

									<!-- 应用领域 start -->
									<div class="goods_html common_problem"
										v-if="goodsDetail.data.fieldContent && goodsDetail.data.isNew == 1">
										<div class="common_problem_title">{{ L['应用领域：'] }}</div>
										<div class="goods_htmls" v-html="goodsDetail.data.fieldContent"></div>
									</div>
									<!-- 应用领域 end -->

									<div v-if="goodsDetail.data.specInstruction">
										<div class="common_problem" style="padding-left: 20px;margin-bottom:0;">
											<div class="common_problem_title" style="">{{ L['产品规格书：'] }}</div>
										</div>
										<div class="instruction_pdf" v-if="goodsDetail.data.specInstruction">
											<object :data="goodsDetail.data.specInstruction" type="application/pdf"
												width="100%" height="100%">
												<iframe :src="goodsDetail.data.specInstruction" width="100%" height="100%"
													style="border: none;">
													This browser does not support PDFs. Please download the PDF
													to view it:
													<a href="JavaScript：void(0)">Download PDF</a>
												</iframe>
											</object>
										</div>
									</div>


									<div v-if="goodsDetail.data.topTemplateContent != undefined && goodsDetail.data.topTemplateContent"
										class="goods_html" :style="goodsDetail.data.topTemplateContentBg">
										<div class="goods_htmls ql-editor" v-html="goodsDetail.data.topTemplateContent">
										</div>
									</div>
									<div v-if="goodsDetail.data.goodsDetails != undefined && goodsDetail.data.goodsDetails"
										class="goods_html" :style="goodsDetail.data.goodsDetailsBg">
										<div class="goods_htmls ql-editor" v-html="goodsDetail.data.goodsDetails">
										</div>
									</div>
									<div v-if="goodsDetail.data.bottomTemplateContent != undefined && goodsDetail.data.bottomTemplateContent"
										class="goods_html" :style="goodsDetail.data.bottomTemplateContentBg">
										<div class="goods_htmls ql-editor" v-html="goodsDetail.data.bottomTemplateContent">
										</div>
									</div>

									<!-- 可替代型号 start -->
									<div class="goods_html foreignList"
										v-if="goodsDetail.data.foreignList && goodsDetail.data.foreignList.length">
										<div class="foreignList_title">{{ L['可替代型号：'] }}</div>
										<div class="foreign_list_table">
											<div class="table_title flex_row_start_center">
												<div class="storeName flex_row_start_center">{{ L['供应商'] }}</div>
												<div class="goodsSpec flex_row_start_center">{{ L['规格型号'] }}
												</div>
											</div>
											<div class="table_td flex_row_start_center"
												v-for="(item, index) in goodsDetail.data.foreignList" :key="index">
												<div class="storeName flex_row_start_center">{{ item.storeName }}
												</div>
												<div class="goodsSpec flex_row_start_center">{{ item.goodsSpec }}
												</div>
											</div>
										</div>
									</div>
									<!-- 可替代型号 end -->

									<!-- 常见问题 start -->
									<div class="goods_html common_problem" v-if="goodsDetail.data.problemContent">
										<div class="common_problem_title">{{ L['常见问题：'] }}</div>
										<div class="goods_htmls" v-html="goodsDetail.data.problemContent"></div>
									</div>
									<!-- 常见问题 end -->

								</div>
								<div v-else>
									<SldCommonEmpty :tip="L['该产品暂无详情~']" totalWidth="934px" :totalHeight="530"
										paddingTop="110" />
								</div>
							</div>
							<!-- 商品详情,规格参数,及详情富文本 end-->

							<!-- 商品服务 start -->
							<div class="goods_server_list" v-else-if="goodsDesctionType == 'service'">
								<div v-if="goodsDetail.data.serviceLabels && goodsDetail.data.serviceLabels.length > 0">
									<div class="goods_server_pre"
										v-for="(serverItem, serverIndex) in goodsDetail.data.serviceLabels"
										:key="serverIndex">
										<div class="server_pre_top flex_row_start_center">
											<span class="server_pre_tips"></span>
											<span class="server_pre_name">{{ serverItem.labelName }}</span>
										</div>
										<div class="server_pre_content">
											{{ serverItem.description }}
										</div>
									</div>
								</div>
								<div v-else>
									<SldCommonEmpty :tip="L['暂无产品服务~']" totalWidth="934px" :totalHeight="560"
										paddingTop="140" />
								</div>
							</div>
							<!-- 商品服务 end -->
							<!-- 行业知识 start -->
							<div class="goods_comments" v-else-if="goodsDesctionType == 'information'">
								<template v-if="artList.list.length">
									<informationItem :item3="item3" v-for="(item3, index3) in artList.list" :key="index3">
									</informationItem>
								</template>
								<template v-else>
									<SldCommonEmpty totalWidth="934px"></SldCommonEmpty>
								</template>
								<!-- 分页 -->
								<div class="flex_row_center_center sld_pagination">
									<el-pagination @current-change="handleCurrentChange"
										v-model:currentPage="artList.page.current" :page-size="artList.page.pageSize"
										layout="prev, pager, next, jumper" :total="artList.page.total"
										:hide-on-single-page="true">
									</el-pagination>
								</div>
							</div>
							<!-- 行业知识 end -->

							<!-- 商品评价 start -->
							<div class="goods_comments" v-if="goodsDesctionType == 'comment'">
								<div class="goods_comments_top">
									<div class="goods_comments_left">
										<div class="comments_title flex_row_between_center">
											<div class="comments_tips">{{ L['评分'] }}:</div>
											<el-rate disabled :colors="colors" score-template="{score}" v-model="score">
											</el-rate>
										</div>
										<div class="good_comment flex_row_center_center">
											<span>{{ L['好评率'] }}:</span>
											<span>{{ goodsCommentsInfo.data.highPercent }}</span>
										</div>
									</div>
									<div class="goods_comments_nav">
										<div class="comments_nav_pre"
											:class="{ comments_nav_pre_active: evaluationType == '' }"
											@click="getEvaluation('')">
											{{ L['全部'] }}({{
												goodsCommentsInfo.data.commentsCount
											}})
										</div>
										<div class="comments_nav_pre" :class="{
													comments_nav_pre_active: evaluationType == 'hasPic',
												}" @click="getEvaluation('hasPic')">
											{{ L['有图'] }}({{ goodsCommentsInfo.data.hasPicCount }})
										</div>
										<div class="comments_nav_pre" :class="{
											comments_nav_pre_active: evaluationType == 'high',
										}" @click="getEvaluation('high')">
											{{ L['好评'] }}({{ goodsCommentsInfo.data.highCount }})
										</div>
										<div class="comments_nav_pre" :class="{
											comments_nav_pre_active: evaluationType == 'middle',
										}" @click="getEvaluation('middle')">
											{{ L['中评'] }}({{ goodsCommentsInfo.data.middleCount }})
										</div>
										<div class="comments_nav_pre" :class="{
											comments_nav_pre_active: evaluationType == 'low',
										}" @click="getEvaluation('low')">
											{{ L['差评'] }}({{ goodsCommentsInfo.data.lowCount }})
										</div>
									</div>
								</div>
								<div class="goods_comments_list" v-if="goodsCommentsInfo.data &&
									goodsCommentsInfo.data.list &&
									goodsCommentsInfo.data.list.length > 0
									">
									<div class="goods_comments_pre flex_row_start_start" v-for="(
																													                        goodsCommentsItem, goodsCommentsIndex
																													                      ) in goodsCommentsInfo.data.list" :key="goodsCommentsIndex">
										<div class="goods_comments_avatar" :style="{
												backgroundImage:
													'url(' + goodsCommentsItem.memberAvatar + ')',
											}"></div>
										<div class="list_des">
											<div class="list_top flex_row_between_start">
												<div class="list_left">
													<div class="list_top_tile flex_row_center_center">
														<div class="list_top_tips">
															{{ goodsCommentsItem.memberName }}
														</div>
														<el-rate v-model="goodsCommentsItem.score" disabled
															text-color="#ff9900" allow-half="true">
														</el-rate>
													</div>
													<div class="spec">
														{{ goodsCommentsItem.specValues }}
													</div>
												</div>
												<div class="list_top_right">
													{{ goodsCommentsItem.createTime }}
												</div>
											</div>
											<div class="list_pre">
												<p class="CommentContent">{{ goodsCommentsItem.content }}</p>
												<div class="list_pre_img_list flex_row_start_center"
													v-if="goodsCommentsItem.images.length">
													<div class="imgCon1"
														v-for="(commentsImgItem, commentsImgIndex) in goodsCommentsItem.images"
														:key="commentsImgIndex">

														<el-image :src="commentsImgItem"
															:preview-src-list="[commentsImgItem]" class="list_pre_img"
															fit="contain"></el-image>
													</div>
												</div>
												<p v-if="goodsCommentsItem.replyContent" class="replyContent">
													<span class="seller">{{ L['商家回复:'] }}</span>
													<span class="contentRe">{{ goodsCommentsItem.replyContent
													}}</span>
												</p>
											</div>
										</div>
									</div>
								</div>
								<div class="flex_row_end_center sld_pagination sld_page_bottom"
									v-if="goodsCommentsInfo.data.pagination.total">
									<el-pagination @current-change="EvaluateCurrentChange"
										v-model:currentPage="evaluationCurrent" :page-size="evaluationPageSize"
										layout="prev, pager, next, jumper" :total="goodsCommentsInfo.data.pagination.total"
										:hide-on-single-page="true">
									</el-pagination>
								</div>
								<div v-if="goodsCommentsInfo.data.list &&
									goodsCommentsInfo.data.list.length == 0
									" class="flex_column_center_center empty_data">
									<SldCommonEmpty :tip="L['该商品暂无评论~']" totalWidth="934px" />
								</div>
							</div>
							<!-- 商品评价 end -->

						</div>
					</div>
					<!-- 商品详情 评价 商品服务 店铺热销  end-->
				</div>
				<!-- 店铺，及各种信息的切换 end -->
			</div>
			<!-- 商品主要信息 end -->
		</div>

		<SldLoginModal :visibleFlag="loginModalVisibleFlag" @closeLoingModal="closeLoingModal" @refreshInfo="refreshInfo" />
		<AtoProject ref="AtoProject"></AtoProject>
	</div>
</template>

<script>
import detailImage from '@/components/detailImage.vue';
import detailAddr from '@/components/detailAddr'
import spec from '@/components/spec'
import informationItem from "@/components/informationItem";
import { reactive, getCurrentInstance, ref, onMounted, provide, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElRate } from "element-plus";
import { qrcanvas } from "qrcanvas";
import StoreHeaderCat from "../store/StoreHeaderCat";
import { useStore } from "vuex";
import SldLoginModal from "../../components/loginModal";
import SldCommonEmpty from "../../components/SldCommonEmpty";
import AtoProject from '@/components/AtoProject'
import addCompare from '@/components/comparePanel/compare.js'
import seckillHeader from '../../components/seckillHeader.vue';
import CouponItem from "@/components/CouponItem";

export default {
	name: "goods-detail",
	components: {
		ElRate,
		StoreHeaderCat,
		SldLoginModal,
		SldCommonEmpty,
		AtoProject,
		informationItem,
		spec,
		detailAddr,
		detailImage,
		seckillHeader,
		CouponItem
	},
	setup() {
		const firstLoading = ref(true); //是否第一次加载
		const router = useRouter();
		const route = useRoute();
		const store = useStore();
		const memberInfo = ref(store.state.memberInfo);
		const imgSource = ref('')
		const vid = ref(0); //店铺id
		const { proxy } = getCurrentInstance();
		const L = proxy.$getCurLanguage();
		const goodsDetail = reactive({ data: {} }); //商品详情数据
		const productId = ref(""); //货品id

		const currentSpecNum = ref(1); //商品编辑数量，默认数量为1
		const recommendeList = reactive({ data: [] }); //看了又看商品
		const goodsDesctionType = ref("detail"); //商品详情,评价,商品服务,店铺热销,默认显示商品详情
		const cartListData = reactive({ data: store.state.cartListData }); //获取vux的store中的购物车数据
		const loginModalVisibleFlag = ref(false); //登录弹框是否显示，默认不显示
		const container = ref(null); // 商品详情页底部内容区
		const containerTop = ref(0); //商品详情页底部内容区的top值
		const containCon = ref(false); //固定内容区头部
		const wxShareCode = ref(false); //微信分享二维码是否显示
		const parameterShow = ref(false); //规格参数查看是否查看全部，默认为否
		const score = ref(0) //好评率
		const colors = ref(['#E2231A', '#E2231A', '#E2231A'])//星星颜色
		const productPrice = ref(0)
		const qrCodeImage = ref('')
		const numCheckedTure = ref(true); //购买数量满足条件

		const specList = ref([])
		const specProductList = ref([])
		const goodsCommentsInfo = reactive({ data: {} })
		const priceRange = reactive({ max: 0, min: 0 })



		const evaluationType = ref(""); //商品评价类型,默认显示全部
		const evaluationCurrent = ref(1); //评价列表默认第一页
		const evaluationPageSize = ref(5); //评价列表默认一页显示5条数据

		const isSeckill = ref(false) //是否开启秒杀
		const couponList = reactive({ list: [], pagination: {}, current: 1 }); //获取店铺优惠券列表
		const couponModel = ref(false); //优惠券弹框是否显示
		const seckillInfo = ref({})

		const useLoginFlag = ref(store.state.loginFlag)

		onMounted(() => {
			getInitDataStatic(route.query.productId);
			productId.value = route.query.productId;
			containerTop.value = offset(container.value).top;
			window.addEventListener("scroll", scrollHandle); //绑定页面滚动事件

		});

		const totalPrice = computed(() => {
			if (goodsDetail.data && goodsDetail.data.defaultProduct) {
				let { productPrice } = goodsDetail.data.defaultProduct
				if (productPrice && currentSpecNum.value) {
					return Number(currentSpecNum.value * productPrice).toFixed(2)
				} else {
					return 0
				}
			}
		})


		const scrollHandle = async (e) => {
			let elementScrollTop = e.srcElement.scrollingElement.scrollTop; //获取页面滚动高度
			if (document.getElementById('container') && elementScrollTop > document.getElementById('container').offsetTop) {
				containCon.value = true;
				await proxy.$nextTick()
				if (!qrCodeImage.value) {
					getCode();
					// getBigcode(goodsDetail.data.shareLink, 'top')
				}

			} else {
				containCon.value = false;
			}
		};

		//获取元素距离父元素的顶部及左边的距离
		const offset = (el) => {
			let top = el.offsetTop;
			let left = el.offsetLeft;
			if (el.offsetParent) {
				el = el.offsetParent;
				top += el.offsetTop;
				left += el.offsetLeft;
			}
			return {
				left: left,
				top: top,
			};
		};

		const changeNum = (num) => {
			proxy.$refs.detailAddr.changeNum(num)
		}



		//获取商品详情数据
		const getInitDataStatic = (proId) => {
			productId.value = proId
			let params = {
				productId: proId,
			};
			proxy.$get("v3/goods/front/goods/details", params).then((res) => {
				if (res.state == 200) {
					qrCodeImage.value = res.data.qrCodeImage
					if (res.data.qrCodeImage) {
						let canvas = qrcanvas({
							data: "下单", //二维码内容
							size: 20,
							colorDark: "red",
                        });
						if (document.getElementById("qrcode")) {
							document.getElementById("qrcode").innerHTML = "";
							document.getElementById("qrcode").appendChild(canvas);
						}
					}

					let staticData = ['goodsBrief', 'goodsDetails', 'topTemplateContent', 'bottomTemplateContent', 'seriesId',
						'goodsBrief', 'goodsId', 'goodsName', 'goodsParameterList', 'goodsVideo', 'state', 'minBuyNum', 'produceState', 'produceStateValue', 'isNew',
						'serviceLabels', 'deliverTime', 'technicalLeaderWechat', 'technicalSupportPhone', 'isRequireInquire']

					staticData.forEach(item => {
						goodsDetail.data[item] = res.data[item]
					})

					currentSpecNum.value = res.data.minBuyNum || 1

					if (goodsDetail.data.topTemplateContent) {
						goodsDetail.data.topTemplateContent = proxy.$quillEscapeToHtml(goodsDetail.data.topTemplateContent);
						//处理背景样式
						if (goodsDetail.data.topTemplateContent.indexOf('<p style="display:none;" data-background="') != -1) {
							let bg = goodsDetail.data.topTemplateContent.split('<p style="display:none;" data-background="')[1].split('">')[0]
							goodsDetail.data.topTemplateContentBg = bg;
						}
					}
					if (goodsDetail.data.goodsDetails) {
						goodsDetail.data.goodsDetails = proxy.$quillEscapeToHtml(goodsDetail.data.goodsDetails);
						//处理背景样式
						if (goodsDetail.data.goodsDetails.indexOf('<p style="display:none;" data-background="') != -1) {
							let bg = goodsDetail.data.goodsDetails.split('<p style="display:none;" data-background="')[1].split('">')[0]
							goodsDetail.data.goodsDetailsBg = bg;
						}
					}
					if (goodsDetail.data.bottomTemplateContent) {
						goodsDetail.data.bottomTemplateContent = proxy.$quillEscapeToHtml(goodsDetail.data.bottomTemplateContent);
						//处理背景样式
						if (goodsDetail.data.bottomTemplateContent.indexOf('<p style="display:none;" data-background="') != -1) {
							let bg = goodsDetail.data.bottomTemplateContent.split('<p style="display:none;" data-background="')[1].split('">')[0]
							goodsDetail.data.bottomTemplateContentBg = bg;
						}
					}
					vid.value = res.data.storeInf.storeId;
					document.title = goodsDetail.data.goodsName
					proxy.$sldStatEvent({ behaviorType: 'gpv', goodsId: goodsDetail.data.goodsId, storeId: vid.value });
				} else {
					ElMessage.error(res.msg);
				}
			}).then(() => {
				getInitDataDynamic(productId.value)
				getCouponList();
				getRecommend();
				addLog();
				getArtList()
				getEvaluation();
                suncode()
			});
		};
        const suncode = () =>{
            let params = {};
            params.page = 'standard/product/detail';
            params.goodsId = goodsDetail.data.goodsId;
            params.productId = productId.value;
			
			proxy.$get("v3/goods/common/sunCode", params).then((res) => {
				if (res.state == 200) {
                    qrCodeImage.value = res.data
				}
			});
        }
		const getInitDataDynamic = (proId, updateType) => {
			let params = {
				productId: proId,
			};
			proxy.$get("v3/goods/front/goods/details2", params).then(res => {
				if (res.state == 200) {
					let dynamicData = ['defaultProduct', 'categoryId1', 'categoryId2', 'categoryId3',
						'deliverInfo', 'categoryPath', 'categoryIdPath',
						'effectSpecValueIds', 'foreignList', 'technicalLeaderWechat', 'technicalSupportPhone',
						'followGoods', 'specs', 'specInstruction', 'problemContent', 'specProductList',
						'storeInf', 'sales', 'saleUnit', 'saleUnitDes', 'fieldContent',
						'state', 'shareLink', 'goodsSpec', 'isNew', 'newProductFeature',
						'shareImage', 'isVirtualGoods', 'seriesId', 'seriesName', 'listTime']
					dynamicData.forEach((item) => {
						if (item == 'categoryPath') {
							goodsDetail.data.categoryPath = res.data.categoryPath.split('->')
						} else if (item == 'categoryIdPath') {
							goodsDetail.data.categoryIdPath = [res.data.categoryId1, res.data.categoryId2, res.data.categoryId3]
						} else {
							goodsDetail.data[item] = res.data[item]
						}
					})
					productPrice.value = res.data.defaultProduct.productPrice
					if (goodsDetail.data.state != 3) {
						getRecom()
					}
					if (goodsDetail.data.produceState == 2) {
						goodsDetail.data.defaultProduct.productStock = 99999
					}

					specList.value = goodsDetail.data.specs
					specProductList.value = goodsDetail.data.specProductList
					if (specProductList.value.length) {
						let priceMap = goodsDetail.data.specProductList.map(i => i.productPrice)
						priceRange.max = Math.max.apply(null, priceMap)
						priceRange.min = Math.min.apply(null, priceMap)
					}

					if (goodsDetail.data.defaultProduct.promotionType == 104) {
						isSeckill.value = true
					}


					firstLoading.value = false;
				}
			}).then(() => {
				if (!qrCodeImage.value) {
					getCode();
					// getBigcode(goodsDetail.data.shareLink)
				}
			})
		}


		//获取店铺优惠券列表
		const getCouponList = () => {
			let params = {
				storeId: vid.value,
				current: couponList.current,
				pageSize: 6,
			};
			proxy.$get("v3/promotion/front/coupon/storeCouponList", params).then((res) => {
				if (res.state == 200) {
					let result = res.data;
					couponList.list = result.list;
					couponList.pagination = result.pagination
				}
			});
		};


		const handleCurrentChangeCoupon = (e) => {
			couponList.current = e
			getCouponList();
		}

		//打开优惠券弹框
		const openCouponModel = () => {
			couponModel.value = true;
			getCouponList();
		};



		const editNum = (type) => {
			let { productStock } = goodsDetail.data.defaultProduct
			let maxBuyNum = Math.min(productStock, 99999)
			switch (type) {
				case 'reduce': {
					if (currentSpecNum.value <= 1) {
						return
					} else {
						currentSpecNum.value--
					}
					break
				}
				case 'edit': {
					if ((!/^[1-9]\d*$/.test(currentSpecNum.value) && currentSpecNum.value)) {
						currentSpecNum.value = 1
					} else if (currentSpecNum.value > maxBuyNum) {
						currentSpecNum.value = maxBuyNum;
						ElMessage('超过购买限制');
					}
					break
				}
				case 'add': {

					currentSpecNum.value++
					if (currentSpecNum.value > maxBuyNum) {
						currentSpecNum.value = maxBuyNum;
						ElMessage('超过购买限制');
					}
					break;
				}
				case 'blur': {
					if (currentSpecNum.value <= 1) {
						currentSpecNum.value = 1
					}
					break;
				}


			}

			changeNum(currentSpecNum.value)
		}


		const recomOffShop = reactive({ data: [] })
		const getRecom = () => {
			proxy.$get('v3/goods/front/goods/goodsList', { storeId: goodsDetail.data.categoryId1 }).then(res => {
				if (res.state == 200) {
					let top = Math.floor(Math.random() * (res.data.list.length - 8)) + 8
					let end = top - 8
					recomOffShop.data = res.data.list.filter(item => item.defaultProductId != goodsDetail.data.defaultProduct.productId).slice(end, top)
				}
			})
		}

		//添加足迹
		const addLog = () => {
			let params = {
				productId: productId.value,
			};
			proxy.$post("v3/member/front/productLookLog/add", params).then((res) => {
				if (res.state == 200) { }
			});
		};


		//跳转去商品详情页
		const goGoodsDetail = (productId) => {
			proxy.$goGoodsDetail(productId);
		};

		//获取二维码
		const getCode = () => {
			proxy.$nextTick(() => {
				let canvas = qrcanvas({
					data: "下单", //二维码内容
					size: 20,
					colorDark: "red",
				});
				if (document.getElementById("qrcodeTop")) {
					document.getElementById("qrcodeTop").innerHTML = "";
					document.getElementById("qrcodeTop").appendChild(canvas);
				}
				if (document.getElementById("qrcode")) {
					document.getElementById("qrcode").innerHTML = "";
					document.getElementById("qrcode").appendChild(canvas);
				}
			});
		};

		//获取商品的shareLink二维码
		const getBigcode = (link, type) => {
			proxy.$nextTick(() => {
				let canvas = qrcanvas({
					data: link, //二维码内容
					size: 135,
					colorDark: "red",
                });
				if (type == 'top') {
					if (document.getElementById("qrcodeBig1")) {
						document.getElementById("qrcodeBig1").innerHTML = "";
						document.getElementById("qrcodeBig1").appendChild(canvas);
					}
				} else {
					if (document.getElementById("qrcodeBig")) {
						document.getElementById("qrcodeBig").innerHTML = "";
						document.getElementById("qrcodeBig").appendChild(canvas);
					}
				}
			});
		}

		//商品数量的编辑


		/**
		  * 选择规格值
		  * @param type:类型  值:choice,规格选择  default:默认
		  * @param specId:父级规格值
		  * @param specValueId:点击的当前的规格值
		  */
		const selectSpecVal = (targetProId) => {
			getInitDataStatic(targetProId)
		};





		//加入购物车
		const addCart = () => {
			if (!numCheckedTure.value) {
				numCheckedTure.value = true;
				return;
			}
			if (store.state.loginFlag) {
				if (goodsDetail.data.specProductList.length) {

					let { chosenSpecList, isLastChosen } = proxy.$refs.spec.returnChosenSpec()
					if (!chosenSpecList.length) {
						ElMessage('请选择规格')
						return
					}
					let numMap = chosenSpecList.map(i => i.buyNum)
					let totalNum = numMap.reduce((total, prev) => total + prev)
					if (totalNum < goodsDetail.data.minBuyNum) {
						ElMessage('数量应大于最小起批量')
						return
					}
					let checkList = chosenSpecList.map(i => {
						return {
							number: i.buyNum,
							productId: i.productId
						}
					})
					proxy.$post('v3/business/front/cart/batchAdd', checkList, 'json').then(res => {
						if (res.state == 200) {
							ElMessage.success(res.msg)
							proxy.$getLoginCartListData()
						} else {
							ElMessage(res.msg)
						}
					})
				} else {

					if (currentSpecNum.value < goodsDetail.data.minBuyNum) {
						ElMessage('数量应大于最小起批量')
						return
					}
					proxy.$post('v3/business/front/cart/add', {
						productId: goodsDetail.data.defaultProduct.productId,
						number: currentSpecNum.value
					}).then(res => {
						if (res.state == 200) {
							ElMessage.success(res.msg)
							proxy.$getLoginCartListData()
						} else {
							ElMessage(res.msg)
						}
					})
				}

			} else {
				//未登录提示登录
				loginModalVisibleFlag.value = true;
			}
		};

		//加入样品申请单
		const addSampleCart = () => {
			if (!numCheckedTure.value) {
				numCheckedTure.value = true;
				return;
			}

			if (store.state.loginFlag) {

				let checkList = []
				if (goodsDetail.data.specProductList.length) {
					let { chosenSpecList } = proxy.$refs.spec.returnChosenSpec()

					if (!chosenSpecList.length) {
						ElMessage('请选择商品')
						return
					}
					let numMap = chosenSpecList.map(i => i.buyNum)
					let totalNum = numMap.reduce((total, prev) => total + prev)
					if (totalNum < goodsDetail.data.minBuyNum) {
						ElMessage('数量应大于最小起批量')
						return
					}
					checkList = chosenSpecList.map(i => {
						return {
							number: i.buyNum,
							productId: i.productId
						}
					})
				} else {
					if (currentSpecNum.value < goodsDetail.data.minBuyNum) {
						ElMessage('数量应大于最小起批量')
						return
					}
					checkList = [{ productId: goodsDetail.data.defaultProduct.productId, number: currentSpecNum.value }]
				}




				proxy.$post('v3/business/front/sampleCart/batchAdd', checkList, 'json').then(res => {
					if (res.state == 200) {
						ElMessage.success(res.msg)
						proxy.$getLoginSampleListData()
					} else {
						ElMessage(res.msg)
					}
				})
			} else {
				//未登录提示登录
				loginModalVisibleFlag.value = true;
			}
		};

		//立即购买
		const goBuy = () => {
			if (store.state.loginFlag) {
				let query = {
					productSets: `${goodsDetail.data.defaultProduct.productId}-${currentSpecNum.value}`
				}

				router.push({
					path: "/buy/confirm",
					query,
				});
				localStorage.setItem('addressId', curAddr.value)
			} else {
				//未登录提示登录
				loginModalVisibleFlag.value = true;
			}
		};
		//关闭登录弹框
		const closeLoingModal = () => {
			loginModalVisibleFlag.value = false;
		};

		//获取看了又看商品(人气数)
		const getRecommend = () => {
			let params = {
				seriesId: goodsDetail.data.seriesId,
				current: 1,
			};
			proxy.$get("v3/goods/front/goods/goodsList", params).then((res) => {
				if (res.state == 200) {
					let result = res.data;
					let arrList = result.list
					let len = 5
					recommendeList.data = (function () {
						var a_len = arrList.length;
						var result = [];
						for (var i = 0; i < a_len; i += len) {
							result.push(arrList.slice(i, i + len));
						}
						return result;
					})()

				} else {
					ElMessage.error(res.msg);
				}
			});
		};

		const recomChange = (type) => {
			proxy.$refs.carousel[type]()
		}
		const recommendIndex = ref(0)
		const changeCarousel = (e) => {
			recommendIndex.value = e
		}

		//切换商品详情,评价,服务,热销
		const goodsDescType = (type) => {
			let elementScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop; //获取页面滚动高度
			let dom = document.getElementById('container')
			if (dom.offsetTop != elementScrollTop) {
				window.scrollTo({
					top: dom.offsetTop - 40,
					behavior: 'smooth'
				})
			}

			if (type == "" || !type) {
				goodsDesctionType.value = "detail";
			} else {
				goodsDesctionType.value = type;
			}
			if (type == "salestore") {
				getSalestore();
			}
		};

		//产品收藏及取消收藏
		const collectGoods = () => {
			if (store.state.loginFlag) {
				//已登录
				let params = {
					isCollect: !goodsDetail.data.followGoods, // true 收藏  false 取消收藏
					productIds: productId.value,
				};
				proxy
					.$post("v3/member/front/followProduct/update", params)
					.then((res) => {
						if (res.state == 200) {
							ElMessage.success(res.msg)
							goodsDetail.data.followGoods = !goodsDetail.data.followGoods;
							if (goodsDetail.data.followGoods) {
								proxy.$sldStatEvent({ behaviorType: 'fav', goodsId: goodsDetail.data.goodsId, storeId: goodsDetail.data.storeInf.storeId })
							}
						} else {
							ElMessage.error(res.msg);
						}
					});
			} else {
				//未登录提示登录
				loginModalVisibleFlag.value = true;
			}
		};

		//供应商收藏及取消收藏
		const focusStore = () => {
			if (store.state.loginFlag) {
				//已登录
				let params = {
					isCollect: !goodsDetail.data.storeInf.isFollowStore, // true 收藏  false 取消收藏
					storeIds: goodsDetail.data.storeInf.storeId,
				};
				proxy
					.$post("v3/member/front/followStore/update", params)
					.then((res) => {
						if (res.state == 200) {
							goodsDetail.data.storeInf.isFollowStore = !goodsDetail.data.storeInf.isFollowStore;
							ElMessage.success(res.msg);
						} else {
							ElMessage.error(res.msg);
						}
					});
			} else {
				//未登录提示登录
				loginModalVisibleFlag.value = true;
			}
		}


		const getSeckillInfo = (info) => {
			seckillInfo.value = info
		}







		const kefu = () => {
			if (store.state.loginFlag) {
				let chatInfo = {
					storeId: goodsDetail.data.storeInf.storeId,
					vendorAvatar: goodsDetail.data.storeInf.storeLogo,
					storeName: goodsDetail.data.storeInf.storeName,
					source: `从产品详情页进入 供应商:${goodsDetail.data.storeInf.storeName}`,
					sourceType: 'goods',
					showData: {
						productId: goodsDetail.data.defaultProduct.productId,
						goodsName: goodsDetail.data.goodsName,
						goodsImage: goodsDetail.data.shareImage,
						productPrice: goodsDetail.data.defaultProduct.productPrice,
						specValues: goodsDetail.data.specValues
					}
				}
				store.commit('saveChatBaseInfo', chatInfo)

				let newWin = router.resolve({
					path: '/service',
					query: {
						vid: goodsDetail.data.storeInf.storeId
					}
				})

				window.open(newWin.href, "_blank")
			} else {
				//未登录提示登录
				loginModalVisibleFlag.value = true;
			}
		};

		//进入店铺
		const goStore = () => {
			let tarUrl = router.resolve({
				path: "/store/index",
				query: {
					vid: vid.value,
				},
			});
			window.open(tarUrl.href, "_blank");
		};



		//分享
		const share = (type) => {
			let title = goodsDetail.data.goodsName; //需要分享的标题,这里取商品名字
			let url = goodsDetail.data.shareLink; //分享的地址，用户点击可以进入到该商品
			let content = goodsDetail.data.goodsBrief; //自定义内容,这里取商品广告词
			let targetUrl = ""; //跳转的url地址
			if (type == "weixin") {
				wxShareCode.value = true;
				//微信
				let canvas = qrcanvas({
					data: url, //二维码内容
					size: 100,
					colorDark: "red",
				});
				let share_wx_qrcode = document.getElementById("share_wx_qrcode");
				if (wxShareCode.value && share_wx_qrcode != null && share_wx_qrcode != undefined) {
					document.getElementById("share_wx_qrcode").innerHTML = "";
					document.getElementById("share_wx_qrcode").appendChild(canvas);
				}
			} else if (type == "qzone") {
				wxShareCode.value = false;
				//QQ空间
				targetUrl =
					"https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?title=" +
					encodeURIComponent(title) +
					"&desc=" +
					encodeURIComponent(content || '') +
					"&summary=" +
					encodeURIComponent(content || '') +
					"&url=" +
					encodeURIComponent(url);
				window.open(targetUrl, "_blank");
			} else if (type == "sina") {
				wxShareCode.value = false;
				//新浪微博
				targetUrl =
					"https://service.weibo.com/share/share.php?title=" +
					encodeURIComponent(content || '' + "「" + title + "」" + " 点这里" + url);
				window.open(targetUrl, "_blank");
			}
		};

		const refreshInfo = () => {
			history.go(0)
		}

		const clickAddTo = () => {
			if (!store.state.loginFlag) {
				loginModalVisibleFlag.value = true;
				return
			}


			let checkList = []
			if (goodsDetail.data.specProductList.length) {
				let { chosenSpecList } = proxy.$refs.spec.returnChosenSpec()

				if (!chosenSpecList.length) {
					ElMessage('请选择商品')
					return
				}

				let numMap = chosenSpecList.map(i => i.buyNum)
				let totalNum = numMap.reduce((total, prev) => total + prev)
				if (totalNum < goodsDetail.data.minBuyNum) {
					ElMessage('数量应大于最小起批量')
					return
				}
				checkList = chosenSpecList.map(i => {
					return {
						number: i.buyNum,
						productId: i.productId
					}
				})

				proxy.$refs.AtoProject.proInfo.goodsNum = goodsDetail.data.minBuyNum
				proxy.$refs.AtoProject.proInfo.productIds = chosenSpecList.map(i => `${i.productId}-${i.buyNum}`).join(',')

			} else {
				if (currentSpecNum.value < goodsDetail.data.minBuyNum) {
					ElMessage(`最低限购${goodsDetail.data.minBuyNum}${goodsDetail.data.saleUnit}`)
					return
				}
				checkList = [{ productId: goodsDetail.data.defaultProduct.productId, number: currentSpecNum.value }]
				proxy.$refs.AtoProject.proInfo.goodsNum = currentSpecNum.value
				proxy.$refs.AtoProject.proInfo.productIds = `${productId.value}-${currentSpecNum.value}`
			}
			proxy.$refs.AtoProject.visibleFlag = true
		}


		//行业知识
		const artList = reactive({
			list: [],
			page: {}
		})
		const artListParam = reactive({
			current: 1,
			goodsId: goodsDetail.data.goodsId,
			pageSize: 10,
		})
		const getArtList = () => {
			artListParam.goodsId = goodsDetail.data.goodsId
			proxy.$get('v3/cms/front/information/informationGoodsList', artListParam).then(res => {
				if (res.state == 200) {
					artList.list = res.data.list
					artList.page = res.data.pagination
				}
			})
		}

		const toArtDetail = (item) => {
			let newWin = router.resolve({
				path: '/information/detail',
				query: {
					infoId: item.informationId
				}
			})

			window.open(newWin.href, '_blank')
		}

		const handleCurrentChange = (e) => {
			artListParam.current = Math.floor(e);
			getArtList()
		}


		//前往系列
		const toSeries = () => {
			let newWin = router.resolve({
				path: '/goods/series',
				query: {
					seriesId: goodsDetail.data.seriesId,
					categoryId: goodsDetail.data.categoryId1
				}
			})

			window.open(newWin.href, '_blank')
		}


		//加入对比
		const contrast = () => {
			let item = {
				productImage: goodsDetail.data.defaultProduct.goodsPics[0],
				productId: goodsDetail.data.defaultProduct.productId,
				productName: goodsDetail.data.goodsName,
				specValues: goodsDetail.data.defaultProduct.getSpecValues,
				type: 'product'
			};
			addCompare(item)
		}




		const getEvaluation = (type) => {
			if (!type) {
				evaluationType.value = "";
			} else {
				evaluationType.value = type;
			}
			let params = {
				productId: productId.value,
				current: evaluationCurrent.value,
				pageSize: evaluationPageSize.value,
				type: evaluationType.value,
			};
			proxy.$get("v3/goods/front/goods/comment", params).then((res) => {
				if (res.state == 200) {
					let result = res.data;
					goodsCommentsInfo.data = result;
					score.value = Number(goodsCommentsInfo.data.avgScore)
					goodsCommentsInfo.data && goodsCommentsInfo.data.list && goodsCommentsInfo.data.list.length > 0 && goodsCommentsInfo.data.list.map((commentsItem) => {
						commentsItem.memberName = commentsItem.memberName.slice(0, 1) + '***' + commentsItem.memberName.slice(commentsItem.memberName.length - 1, commentsItem.memberName.length);
					})
				} else {
					ElMessage.error(res.msg);
				}
			});
		};

		//评价列表当前选择页
		const EvaluateCurrentChange = (val) => {
			evaluationCurrent.value = val;
			getEvaluation(evaluationType.value);
		};
        const changeopenParameter = () => {
            parameterShow.value = !parameterShow.value
        }
		//暴露的变量及方法
		return {
            changeopenParameter,
			EvaluateCurrentChange,
			getEvaluation,
			L,
			goodsDetail,
			currentSpecNum,
			addCart,
			addSampleCart,
			goBuy,
			recommendeList,
			goodsDesctionType,
			goodsDescType,
			collectGoods,
			focusStore,
			goGoodsDetail,
			vid,
			cartListData,
			loginModalVisibleFlag,
			closeLoingModal,
			firstLoading,
			container,
			containerTop,
			containCon,
			kefu,
			goStore,
			memberInfo,
			share,
			wxShareCode,
			parameterShow,
			score,
			colors,
			imgSource,
			recomOffShop,
			refreshInfo,
			clickAddTo,
			recomChange,
			changeCarousel,
			recommendIndex,
			artList,
			handleCurrentChange,
			toArtDetail,
			toSeries,
			productPrice,
			qrCodeImage,
			numCheckedTure,
			selectSpecVal,
			specList,
			specProductList,
			editNum,
			goodsCommentsInfo,
			priceRange,
			totalPrice,
			contrast,
			couponList,
			handleCurrentChangeCoupon,
			openCouponModel,
			couponModel,
			isSeckill,
			getSeckillInfo,
			seckillInfo,
			getInitDataStatic,
			changeNum,

			useLoginFlag,
		};
	},
};
</script>

<style lang="scss">
@import "../../style/base.scss";
@import "../../style/goodsDetail.scss";

.orderCode {
	font-size: 14px;
	color: #666666;
}

.sld_summary_goods_left {
	background-image: url(../../assets/detail_bg.png);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.popular_list_empty {
	height: 95px;

	font-size: 14px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #666666;
}

.imageBorder {
	border: 1px solid $colorI;
}

.goods_picture_big {
	// border: 1px solid $colorI;
}

.evaluationes {
	color: #3b4 !important;
}

.instruction_pdf {
	padding: 20px;
	height: 1400px;
}

.common_problem {
	margin-bottom: 30px;

	.common_problem_title {
		margin-bottom: 10px;
		font-size: 16px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 700;
		color: #333333;
	}

	p {
		line-height: 20px;
		font-size: 12px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #666666;
	}
}

.field_content {
	line-height: 25px;
	font-size: 15px;
	word-break: break-all;
}

.foreignList {
	.foreignList_title {
		margin-bottom: 10px;
		font-size: 16px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: #333333;
	}

	.foreign_list_table {
		border: 1px solid $colorD;

		.table_title {
			height: 50px;
			background: #F7F7F7;
		}

		.storeName,
		.goodsSpec {
			font-size: 14px;
			font-family: SourceHanSansCN-Regular, SourceHanSansCN;
			font-weight: 400;
			color: #333333;
			padding-left: 30px;
		}

		.storeName {
			width: 230px;
			border-right: 1px solid $colorD;
			min-height: 50px;
			word-break: break-all;
		}
	}
}
</style>
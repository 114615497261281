<!-- 店铺头部数据（条幅和店铺分类） @zjf-2020-01-04 -->
<template>
    <div class="sld_store_header">
        <div class="container">
            <div class="ld left">
                <router-link tag="a" class="sld_img_center fl" :to="`/`">
                    <img :src="configInfo.main_site_logo" :onerror='defaultImg' alt />
                </router-link>
                <span class="fl line"></span>
                <div class="sld_store_rate fl">
                    <p class="name">{{ storeData.info.storeName ? storeData.info.storeName : '-' }}</p>

                    <p class="rate">{{ L['综合评分']}}：<em>{{ storeData.info.comprehensiveScore }}</em> <i
                            class="sld_sjx"></i>
                    </p>



                    <div class="sld_store_info_more">

                        <div class="top clearfix">
                            <div class="fl">
                                <h4>{{ L['店铺评分']}}</h4>
                                <p>{{ L['描述相符']}}：{{ storeData.info.descriptionScore }}&nbsp;&nbsp;
                                    <span class="score_des">{{
                                        evalutetionStatus(storeData.info.descriptionScore)
                                    }}</span>
                                </p>
                                <p>{{ L['服务态度']}}：{{ storeData.info.serviceScore }}&nbsp;&nbsp;
                                    <span class="score_des">{{ evalutetionStatus(storeData.info.serviceScore) }}</span>
                                </p>
                                <p>{{ L['发货速度']}}：{{ storeData.info.deliverScore }}&nbsp;&nbsp;
                                    <span class="score_des">{{ evalutetionStatus(storeData.info.deliverScore) }}</span>
                                </p>
                            </div>
                            <div class="fr flex_row_center_center">
                                <img :src="storeData.info.storeLogo" alt="">
                            </div>
                        </div>

                        <div class="sld_store_info_more_item">
                            <span>{{L['所在地：']}}</span>
                            {{ storeData.info.address ? storeData.info.address : '--' }}
                        </div>
                        <div class="sld_store_info_more_item">
                            <span>{{L['主营产品：']}}</span>
                            {{ storeData.info.mainBusiness ? storeData.info.mainBusiness : '--' }}
                        </div>

                        <div class="sld_store_info_more_item">
                            <p class="rate">{{ L['联系客服'] }}：
                                <a class="kefu" href="javascript:void(0)" @click="kefu"><i
                                        class="iconfont icontongyong-lianxikefutubiao"></i></a>
                            </p>
                        </div>
                    </div>
                </div>
                <div @click="followStore" v-if="loginFlag" class="sld_store_follow"
                    :class="{ cancle_follow: storeData.info.isFollow }">
                    {{ storeData.info.isFollow ? L['已关注'] : L['关注'] }}
                </div>
            </div>
            <div class="search_wrap clearfix">
                <form class="fl" action="javascript:void(0)" method="get">
                    <input type="text" v-model="keyword" class="text" :placeholder="L['请输入产品名称/型号/物料编码']" autocomplete="off"
                        style="color:rgb(153,153,153);" ref="searchInput" @focus="inputFocus" @input="inputChange"
                        @blur="inputBlur" @keyup.enter="searchStore('keyword')">
                    <div class="button" @click="search1">{{ L['搜索']}}</div>
                </form>
                <div class="button fl" @click="searchStore('keyword')" style="background: #333">{{ L['搜本店']}}</div>
                <div class="search_association" id="searchA" v-show="SAList.length && SAShow">
                    <div class="s_a_item" v-for="(item, index) in SAList" :key="index"
                        @mousedown="searchStore('associ', item.wordsContent)">
                        <!-- 因为click事件与blur事件冲突，blur事件优先，于是换成mousedown -->
                        <div>{{ item.wordsContent }}</div>
                        <div>{{ item.searchGoodsNum }}{{L['个产品']}}</div>
                    </div>
                </div>
            </div>
            <div class="sld_cart_wrap">
                <dl>
                    <dt class="ld cart_icon_text_wrap" style="border-bottom: 1px solid rgb(239, 239, 239);">
                        <span @click="navto('/cart/index')" class="iconfont icongouwuche"></span>
                        <router-link target="_blank" :to="`/cart/index`">
                            {{ L['我的购物车'] }}
                        </router-link>
                        <!-- 没有商品显示0 -->
                        <div class="cart_goods_num">{{
                            cartData.data.availableCartNum ? cartData.data.availableCartNum :
                                0
                        }}</div>
                    </dt>
                    <dd class="cart_more_view">
                        <cart-model></cart-model>
                    </dd>
                </dl>
            </div>
        </div>
        <div class="sld_store_label_nav_wrap">
            <div class="sld_store_label_wrap">
                <img :src="storeData.info.storeBannerPc ? storeData.info.storeBannerPc : defaultStoreBanner" alt="">
            </div>
            <div class="sld_store_nav">
                <ul class="clearfix">
                    <li class="sld_all_store_cat">
                        <p class="all_type" style="font-weight:bold;">
                            <span>{{ L['分类'] }}</span>
                            <i class="iconfont iconziyuan11-copy "></i>
                        </p>
                        <ul class="sld_store_first_cat">
                            <li v-for="(item, index) in storeData.cat" :key="index">
                                <router-link
                                    :to="`/store/product?vid=${vid}&categoryId=${item.categoryId}&g=${item.grade}`">
                                    <div :title="item.categoryName">{{ item.categoryName }}</div>
                                    <i v-if="item.children && item.children.length"
                                        class="iconfont fr iconziyuan111"></i>
                                </router-link>

                                <ul class="sld_store_second_cat" v-if="item.children && item.children.length">
                                    <li v-for="(item_child, index_child) in item.children" :key="index_child">
                                        <router-link
                                            :to="`/store/product?vid=${vid}&categoryId=${item_child.categoryId}&g=${item_child.grade}`">
                                            <div :title="item_child.categoryName">{{ item_child.categoryName }}</div>
                                            <i v-if="item_child.children && item_child.children.length"
                                                class="iconfont fr iconziyuan111"></i>
                                        </router-link>
                                        <ul class="sld_store_third_cat"
                                            v-if="item_child.children && item_child.children.length">
                                            <li v-for="(item_child1, index_child1) in item_child.children"
                                                :key="index_child1">
                                                <router-link
                                                    :to="`/store/product?vid=${vid}&categoryId=${item_child1.categoryId}&g=${item_child1.grade}`">
                                                    <div :title="item_child1.categoryName">{{
                                                        item_child1.categoryName
                                                    }}
                                                    </div>

                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <ul class="sld_store_cat_horizontal">

                        <li :class="{ active: nowPath == '/store/index' }">
                            <router-link :to="`/store/index?vid=${vid}`">{{ L['首页'] }}</router-link>
                        </li>
                        <li :class="{ active: nowPath == '/store/product' && !route.query.categoryId }">
                            <router-link :to="`/store/product?vid=${vid}`">{{L['全部产品']}}</router-link>
                        </li>
                        <li :class="{ active: nowPath == '/store/new' }">
                            <router-link :to="`/store/new?vid=${vid}`">{{L['新品专区']}}</router-link>
                        </li>
                        <li :class="{ active: nowPath == '/store/article/list' }">
                            <router-link :to="`/store/article/list?vid=${vid}`">{{L['行业知识']}}</router-link>
                        </li>
                        <li :class="{ active: (nowPath == '/store/news' || nowPath == '/store/news/info') }">
                            <router-link :to="`/store/news?vid=${vid}`">{{L['企业动态']}}</router-link>
                        </li>
                        <li :class="{ active: nowPath == '/store/introduce' }">
                            <router-link :to="`/store/introduce?vid=${vid}`">{{L['企业介绍']}}</router-link>
                        </li>
                    </ul>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, getCurrentInstance, ref, watchEffect, onMounted } from 'vue';
import { useRouter, useRoute } from "vue-router";
import { useStore } from 'vuex';
import CartModel from '@/components/CartModel';
import { ElMessage } from 'element-plus';

export default {
    name: 'StoreHeaderCat',
    components: { CartModel },
    setup() {
        const defaultStoreBanner = 'this.src="' + require('../../assets/default_store_banner.png') + '"';
        const router = useRouter();
        const nowPath = ref(router.currentRoute.value.path);
        const route = useRoute();
        const vid = ref('');
        const storeData = reactive({ cat: [], info: {} });//店铺数据，cat：店铺分类，info：店铺基本信息
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const keyword = ref(route.query != undefined && route.query.keyword != undefined && route.query.keyword ? route.query.keyword : '');
        const keywordStore = ref('')
        const store = useStore();
        const loginFlag = ref(store.state.loginFlag)
        const cartData = reactive({ data: {} }); //获取vux的store中的购物车数据
        const configInfo = ref(store.state.configInfo)
        const defaultImg = ref('this.src="' + require('../../assets/common_top_logo.png') + '"')
        const SAList = ref([])
        const SAShow = ref(false)


        const evalutetionStatus = (score) => {
            if (score < 2) {
                return L['低']
            } else if (score > 4) {
                return L['高']
            } else {
                return L['中']
            }
        }



        onMounted(() => {

        })
        //获取店铺基本信息
        const getStoreInfoBaseInfo = () => {
            proxy.$get('v3/seller/front/store/detail', { storeId: vid.value }).then(res => {
                if (res.state == 200) {
                    storeData.info = res.data;
                    storeData.info.storeAverageScore = ((res.data.deliverScore * 1 + res.data.descriptionScore * 1 + res.data.serviceScore * 1) / 3).toFixed(1);
                }
            })
        }
        //获取店铺分类数据
        const getStoreCatData = () => {
            proxy
                .$get('v3/goods/front/goods/category/storeCategory', { storeId: vid.value })
                .then(res => {
                    if (res.state == 200) {
                        storeData.cat = res.data;
                    }
                })
        }

        const inputFocus = () => {
            proxy.$refs.searchInput.style.color = '#333'
            SAShow.value = true
            if (keyword.value && SAList.value.length == 0) {
                searchAssociation(keyword.value)
            }
        }

        watchEffect(() => {
            nowPath.value = router.currentRoute.value.path;

            let tmpRoute = router.currentRoute.value;
            if (tmpRoute.query.keyword != undefined) {
                keyword.value = tmpRoute.query.keyword;
            } else {
                keyword.value = '';
            }
            if (tmpRoute.fullPath.indexOf('/goods/detail') > -1) {
                if (tmpRoute.query != undefined && tmpRoute.query.productId != undefined && tmpRoute.query.productId) {
                    //需要根据商品id获取店铺id
                    proxy
                        .$get("v3/goods/front/goods/details", { productId: tmpRoute.query.productId })
                        .then((res) => {
                            if (res.state == 200) {
                                vid.value = res.data.storeInf.storeId;
                                getStoreInfoBaseInfo();
                                getStoreCatData();
                            }
                        })
                }
            }
            if (tmpRoute.fullPath.indexOf('/store/') > -1) {
                if (tmpRoute.query != undefined && tmpRoute.query.vid != undefined && tmpRoute.query.vid) {
                    vid.value = tmpRoute.query.vid;
                    proxy.$sldStatEvent({ behaviorType: 'spv', storeId: vid.value });
                    getStoreInfoBaseInfo();
                    getStoreCatData();
                }
            }
            cartData.data = store.state.cartListData;
            if (store.state.loginFlag) {
                //获取购物车数量
                proxy.$get("v3/business/front/cart/cartNum").then((res) => {
                    if (res.state == 200) {
                        cartData.data.availableCartNum = res.data ? res.data : 0;
                    }
                });
            }
        });
        //搜索事件(搜全站)
        const search1 = () => {
            if (keyword.value) {
                router.push({ path: `/goods/list`, query: { keyword: keyword.value } })
            }
            keyword.value = '';
        }
        //搜索事件(搜本站)
        const searchStore = (type, arg) => {
            if (type == 'keywordStore') {
                router.push({ path: `/store/product`, query: { keyword: keywordStore.value, vid: vid.value } })
                keywordStore.value = '';
            } else if (type == 'keyword') {
                router.push({ path: `/store/product`, query: { keyword: keyword.value, vid: vid.value } })
                keyword.value = '';
            } else {
                router.push({ path: `/goods/list`, query: { keyword: arg, vid: vid.value } })
                keyword.value = '';
            }
        }
        if (vid.value) {
            getStoreInfoBaseInfo();
            getStoreCatData();
        }
        const kefu = () => {
            let chatInfo = {
                storeId: storeData.info.storeId,
                vendorAvatar: storeData.info.storeLogo,
                storeName: storeData.info.storeName,
                source: `pc:${L['从供应商详情页进入 供应商']}:${storeData.info.storeName}`
            }
            store.commit('saveChatBaseInfo', chatInfo)


            let newWin = router.resolve({
                path: '/service',
                query: {
                    vid: storeData.info.storeId
                }
            })

            window.open(newWin.href, "_blank")
        }

        const followStore = () => {
            //关注店铺及取消关注
            if (store.state.loginFlag) {
                //已登录
                let params = {
                    storeIds: storeData.info.storeId,
                    isCollect: !eval(storeData.info.isFollow),
                };
                proxy.$post("v3/member/front/followStore/update", params).then((res) => {
                    if (res.state == 200) {
                        storeData.info.isFollow = !eval(storeData.info.isFollow) ? true : false;
                        if (storeData.info.isFollow) {
                            proxy.$sldStatEvent({ behaviorType: 'fol', storeId: storeData.info.storeId });
                        }
                        ElMessage.success(eval(storeData.info.isFollow) ? L['关注成功'] : L['取消关注成功']);
                    } else {
                        ElMessage.error(eval(storeData.info.isFollow) ? L['关注失败'] : L['取消关注失败']);
                    }
                });
            }
        }

        const searchAssociation = (input) => { //方法无效
            return;
            proxy.$get('v3/goods/front/goods/searchWords/list', {
                keyWord: input
            }).then(res => {
                if (res.state == 200) {
                    SAList.value = res.data
                }
            })
        }

        const inputChange = (e) => {
            let input = e.target.value
            SAShow.value = true

            if (input) {
                searchAssociation(input)
            } else {
                SAList.value = []
            }
        }

        const inputBlur = () => {
            SAShow.value = false
        }

        const navto = (url) => {
            let newWin = router.resolve({
                path: url
            })
            window.open(newWin.href, '_blank');
        };

        return {
            L, storeData, vid, keyword, search1, searchStore, cartData, kefu, defaultStoreBanner,
            configInfo, defaultImg, followStore, loginFlag, keywordStore, inputFocus, inputChange,
            SAList, SAShow, nowPath, inputBlur, route, navto, evalutetionStatus
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/style/store/storeHeader.scss";

.sld_store_follow {
    position: relative;
    float: left;
    margin-left: 15px;
    padding: 7px 9px;
    border-radius: 15px;
    margin-top: 38px;
    color: #fff;
    background-color: $colorMain;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    cursor: pointer;

    &.cancle_follow {
        background: #888888;
    }
}

input::placeholder {

    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgb(153, 153, 153);
}

::-webkit-input-placeholder {

    margin-left: 20px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgb(153, 153, 153);
}

/* 使用webkit内核的浏览器 */
:-moz-placeholder {

    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgb(153, 153, 153);
}

/* Firefox版本19+ */
:-ms-input-placeholder {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgb(153, 153, 153);
}

/* IE浏览器 */

.score_des {
    color: $colorMain;
}
</style>
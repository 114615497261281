<template>
   <div class="goods_list_container">
      <!-- 分类路径 -->
      <storeCateHeader></storeCateHeader>


      <div class="goods_list_banner">
         <!-- 二级分类或者三级分类的组件 -->
         <GoodsListCate2 :cateItem="cate1.child" :cate2Name="catePathName.path[0]" v-if="showIndex == 1"
            @character="character" :storeId="vid" ref="goodsCate2">
         </GoodsListCate2>
         <GoodsListCate2 :cateItem="cate2.child" :cate2Name="catePathName.path[1]" v-if="showIndex == 2" :storeId="vid">
         </GoodsListCate2>
         <GoodsListCate3 :series="true" @chosenAttr="chosenAttr" :categoryid="categoryId3" @contrast="contrast"
            ref="goodsCate3">
         </GoodsListCate3>
      </div>
      <div class="goods_list">
         <!-- 筛选区域 -->
         <div class="sld_screen flex_row_between_center" id="sld_screen">
            <div class="flex_row_start_center">
               <div @click="filterSort(0)" class="screen_item flex_row_center_center"
                  :class="{ btn_sort_default: indexNum == 0 }">
                  {{ L['默认'] }}</div>
               <div class="screen_item flex_row_center_center"
                  :class="{ btn_sort_default: indexNum == 3 || indexNum == 4, sld_price_jt_down: indexNum == 4, sld_price_jt_up: indexNum == 3 }"
                  @click="filterSort(indexNum == 4 ? 3 : 4)">
                  <div class="sld_price">{{ L['价格'] }}</div>
               </div>
               <div @click="filterSort(2)" class="screen_item flex_row_center_center"
                  :class="{ btn_sort: indexNum == 2, btn_sort_default: indexNum == 2 }">
                  {{ L['发货日'] }}
                  <i class="iconfont iconxiangshangjiantou" style="font-size: 12px"></i>
               </div>
               <div class="screen_item_price">
                  <span class="price_git">{{ L['价格'] }}</span>
                  <div class="price_section">
                     <div class="input_box">
                        <input type="number" class="sld_rmb" v-model="lowprice" id="lowprice"
                           @input="priceInput('lowprice', $event)" />
                        <span class="line"></span>
                        <input type="number" class="sld_rmb" v-model="highprice" id="highprice"
                           @input="priceInput('highprice', $event)" />
                        <span class="line1"></span>
                     </div>

                     <div class="ctrl flex_row_between_center">
                        <a class="clearVal" @click="reset">{{ L['清空'] }}</a>
                        <a class="confirm" @click="confirm">{{ L['确定'] }}</a>
                     </div>
                  </div>
               </div>
               <div class="screen_item_sel flex_row_start_center"
                  v-if="memberInfo.memberType == 2 || memberInfo.memberType == 3">
                  <input type="checkbox" v-model="labelCode" id="label" @change="changeBox">
                  <label for="label">
                     <span>{{L['有物料编码']}}</span>
                  </label>
               </div>
            </div>
            <div class="flex-row">
               <div class="sld_goods_num flex_row_center_center">
                  <font color="#121212">{{L['共']}}</font><span style="margin: 0 4px;">{{ goodsData.page.total }}</span>
                  <font color="#121212">{{L['件']}}</font>
               </div>
               <div class="bar flex_row_center_center">
                  <span class="el-icon-arrow-left" @click="recomChange('prev')"></span>
                  <span class="txt21" style="font-size: 13px;">
                     <font color="#285FDE">{{ goodsData.page.total ? goodsData.page.current : 0 }}</font> / <font>
                        {{ Math.ceil(goodsData.page.total / 10) || 0 }}
                     </font>
                  </span>
                  <span class="el-icon-arrow-right" @click="recomChange('next')"></span>
               </div>
            </div>
         </div>

         <!-- 商品列表 -->
         <div class="sld_goods_list" v-if="goodsData.data.length">
            <div class="block13 flex_row_start_center">
               <span class="word89">{{L['图片']}}</span>
               <span class="word90">{{L['产品名称']}}</span>
               <span class="word91">{{L['描述']}}</span>
               <span class="info53">{{L['生产状态']}}</span>
               <span class="word92">{{L['价格']}}</span>
               <span class="word93">{{L['购买数量']}}</span>
            </div>
            <div>
               <proListItem v-for="(item, index) in goodsData.data" @contrast="contrast" @addProJ="addProJ" :key="index"
                  :item="item"></proListItem>
            </div>
         </div>
      </div>

      <!-- 空数据 -->
      <template v-if="!goodsData.data.length">
         <proEmptyStore ref="proEmptyStore"></proEmptyStore>
      </template>



      <!-- 分页 -->
      <div class="flex_row_center_center sld_pagination">
         <el-pagination @current-change="handleCurrentChange" v-model:currentPage="goodsData.page.current"
            :page-size="goodsData.page.pageSize" layout="prev, pager, next, jumper" :total="goodsData.page.total"
            :hide-on-single-page="true">
         </el-pagination>
      </div>

      <SldLoginModal :visibleFlag="loginModalVisibleFlag" @closeLoingModal="closeLoingModal" />
      <AtoProject ref="AtoProject"></AtoProject>

      <loadAnimation1 v-show="showLoading"></loadAnimation1>

   </div>
</template>
<script>
import loadAnimation1 from '@/components/loading_animation1'
import proEmptyStore from '@/components/proEmptyStore'
import { getCurrentInstance, onMounted, reactive, ref, watchEffect, watch, provide, nextTick } from "vue";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import GoodsListCate2 from "@/components/GoodsListCate2";
import GoodsListCate3 from "@/components/GoodsListCate3";
import SldLoginModal from "../../components/loginModal";
import SldCommonEmpty from "../../components/SldCommonEmpty";
import proListItem from '@/components/ProListItem'
import AtoProject from '@/components/AtoProject'
import categoryHeader from '@/components/categoryHeader'
import storeCateHeader from '@/components/storeCateHeader'
import { priceSlct, getCategory } from '@/utils/common.js'
import addCompare from '@/components/comparePanel/compare.js'

export default {
   name: "goodsList",
   components: {
      GoodsListCate2,
      GoodsListCate3,
      SldLoginModal,
      SldCommonEmpty,
      proListItem,
      AtoProject,
      categoryHeader,
      proEmptyStore,
      loadAnimation1,
      storeCateHeader
   },
   setup() {
      //变量------------------------------
      const firstLoading = ref(true); //是否第一次加载
      const skeletonData = reactive({ data: [] });
      const store = useStore();
      const memberInfo = store.state.memberInfo;
      const loginModalVisibleFlag = ref(false); //登录弹框是否显示，默认不显示
      const checkList = ref('')
      const route = useRoute();
      const router = useRouter();
      //初始化的请求参数
      const params = reactive({
         current: 1,
         pageSize: 10
      });
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const goodsData = reactive({ data: [], page: {} });
      const indexNum = ref(0);
      const isEmpty = ref(false);
      const keyword = ref(
         route.query.keyword != undefined && route.query.keyword ? route.query.keyword : ""
      ); //搜索关键词
      const categoryId3 = ref(
         route.query.categoryId != undefined && route.query.categoryId
            ? route.query.categoryId
            : ""
      );
      const emitContraList = reactive({
         list: []
      })
      const showLoading = ref(true)
      provide('contraList', emitContraList)
      const scrollTop = ref(null);
      const labelCode = ref(false)
      const { cate1, cate2, catePathName, cateForm } = getCategory(route)
      const showIndex = ref(0)
      const vid = ref(route.query.vid)
      //方法------------------------------
      const initSkeletonData = () => {
         for (let i = 0; i < 10; i++) {
            skeletonData.data.push({
               goodsName: "",
               goodsImage: "",
               goodsPrice: "",
               saleNum: "",
               activityList: "",
               isFollowGoods: "",
               defaultProductId: "",
            });
         }
      };
      initSkeletonData();

      //初始化数据，进行请求-start
      const getInitData = (params) => {
         let searchParams = { ...params };
         proxy.$get("v3/goods/front/goods/goodsList", searchParams).then((res) => {
            if (res.state == 200) {
               showLoading.value = false
               goodsData.data = res.data.list;
               isEmpty.value = res.data.list.length ? false : true;
               goodsData.page = res.data.pagination;
               // if (firstLoading.value) {
               //    proxy.$refs.cateHeader.init()
               // }
               firstLoading.value = false;
            } else {
               ElMessage(res.msg);
               goodsData.data = [];
               isEmpty.value = true;
               goodsData.page.current = 1;
               goodsData.page.total = 0;
            }

            if (!goodsData.data.length) {
               nextTick(() => {
                  proxy.$refs.proEmptyStore.getStoreList()

               })
            }
         });
      };

      const { highprice, lowprice, confirm, reset } = priceSlct(getInitData, params)

      const categorySearch = (data, val) => {
         //遍历分类列表，找到该categoryId所在的id路径
         for (var i = 0; i < data.length; i++) {
            if (data[i] && data[i].categoryId == val) {
               return [
                  {
                     categoryId: val,
                     categoryName: data[i].categoryName,
                     children: data[i].children,
                  },
               ];
            }
            if (data[i] && data[i].children) {
               var d = categorySearch(data[i].children, val);
               if (d)
                  return d.concat({
                     categoryId: data[i].categoryId,
                     categoryName: data[i].categoryName,
                     children: data[i].children,
                  });
            }
         }
      };
      //获取分类Id数组对应的分类名--end

      const handleCurrentChange = (e) => {
         window.scrollTo(0, scrollTop.value - 40);

         params.current = Math.floor(e);
         getInitData(params);
      };



      const filterSort = (index) => {
         showLoading.value = true
         if (indexNum.value == index) {
            return;
         }
         indexNum.value = index;
         params.sort = index
         showLoading.value = true
         proxy.$refs.goodsCate3.showLoadingMain = true;
         refreshSeriesList()
         refreshAttrList()
         getInitData(params)
      };

      const refreshSeriesList = () => {
         let refObj = proxy.$refs.goodsCate3
         refObj.selectorParam.isHasCode = labelCode.value
         refObj.selectorParam.highPrice = highprice.value
         refObj.selectorParam.lowPrice = lowprice.value
         refObj.selectorParam.sort = indexNum.value
         refObj.getSeries({ categoryId: route.query.categoryId })

      }

      const refreshAttrList = () => {
         let refObj = proxy.$refs.goodsCate3
         refObj.selectorParam.isHasCode = labelCode.value
         refObj.selectorParam.highPrice = highprice.value
         refObj.selectorParam.lowPrice = lowprice.value
         refObj.selectorParam.sort = indexNum.value
         refObj.getInitData()
      }

      const changeBox = (e) => {
         params.current = 1;
         showLoading.value = true
         proxy.$refs.goodsCate3.selectorParam.isHasCode = labelCode.value
         proxy.$refs.goodsCate3.getInitData()
         if (!goodsData.data.length) {
            proxy.$refs.goodsCate3.clearAttr()
         }
         proxy.$refs.goodsCate3.showLoadingMain = true;
         params.isHasCode = labelCode.value
         proxy.$refs.goodsCate3.getSeries({ categoryId: route.query.categoryId })
         getInitData(params)
      }
      //页数改变的方法-end

      //关闭登录弹框
      const closeLoingModal = () => {
         loginModalVisibleFlag.value = false;
      };

      //加入对比
      const contrast = (item) => {
         addCompare(item)

      }

      // 删除或清空对比
      const contraDel = (type, index) => {
         if (type == 'spec') {
            proxy.$refs.goodsCate3.srcDisFls([contraList.value[index]])//当系列的对比时的，对比框删除一项或者全部，系列列表的条目的check置为FALSE
            contraList.value.splice(index, 1)
         } else {
            proxy.$refs.goodsCate3.srcDisFls(contraList.value)
            contraList.value = []
         }
         emitContraList.list = contraList.value

      }

      //计入我的项目
      const addProJ = (item) => {
         proxy.$refs.AtoProject.proInfo.productIds = `${item.productId}-${item.num}`
         proxy.$refs.AtoProject.visibleFlag = true
      }

      //筛选属性
      const chosenAttr = (commonList, resistorList, accurayList) => {
         if (commonList.length) {
            const sorted = groupBy(commonList, (item) => [item.attrId]);
            let attrList = sorted.map(item => {
               let list = item.map(i => i.attrValId).join('-')
               return list
            })
            params.attributeInfo = attrList.join(',')
         } else {
            delete params.attributeInfo
         }

         if (accurayList.length) {
            let list = accurayList.map(i => i.attrValId).join(',')
            params.accuracyIds = list
         } else {
            delete params.accuracyIds
         }

         if (resistorList.length) {
            let list = resistorList.map(i => i.attrValId).join(',')
            params.resistanceIds = list
         } else {
            delete params.resistanceIds
         }
         showLoading.value = true
         proxy.$refs.goodsCate3.showLoadingMain = true;
         setTimeout(() => {
            getInitData(params);
            proxy.$refs.goodsCate2.getCharacter(params)
         }, 150);
      }

      //筛选分组
      const groupBy = (arr, func) => {
         const groups = {};
         arr.forEach((o) => { //注意这里必须是forEach 大写
            const group = JSON.stringify(func(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
         });
         return Object.keys(groups).map(function (group) {
            return groups[group];
         });
      }

      //获取特性
      const character = (list) => {
         showLoading.value = true
         proxy.$refs.goodsCate3.showLoadingMain = true;
         let refObj = proxy.$refs.goodsCate3
         if (list.length) {
            params.characterInfo = list;
            refObj.selectorParam.characterInfo = list;
         } else {
            delete params.characterInfo;
            refObj.selectorParam.characterInfo = ''
         }
         setTimeout(() => {
            refObj.getInitData()
            refObj.getSeries({ categoryId: route.query.categoryId })
            getInitData(params);
         }, 150);
      }

      //监听事件--start

      watch(
         () => route.query.keyword,
         (nv, ov) => {
            if (nv != ov) {
               keyword.value = route.query.keyword;
               lowprice.value = highprice.value = null;
               labelCode.value = false;
               params.keyword = keyword.value;
               delete params.highPrice;
               delete params.lowPrice;
               getInitData(params);
            }
         }
      );

      watch(
         () => route.query.categoryId,
         (nv, ov) => {
            if (nv != ov) {
               categoryId3.value = nv;
               deleteObjWhileChange()
               params.categoryIds = route.query.categoryId;
               showIndex.value = route.query.g
               if (route.query.g == 1) {
                  cateForm(route.query.categoryId, route.query.g)
               }
               showLoading.value = true
               proxy.$refs.goodsCate3.clearPartSelector()
               proxy.$refs.goodsCate3.showLoadingMain = true;
               getInitData(params);
            }
         }
      );



      const deleteObjWhileChange = () => {
         delete params.highPrice;
         delete params.lowPrice;
         delete params.sort
         delete params.attributeInfo
         delete params.characterInfo
         delete params.resistanceIds
         delete params.accuracyIds
         labelCode.value = false;
         lowprice.value = highprice.value = null;
         params.current = 1
      }

      //监听事件--end

      router.afterEach(() => {
         showLoading.value = false;
         nextTick(() => {
            proxy.$refs.goodsCate3.showLoadingMain = false;

         })
      });

      const recomChange = (type) => {
         if (type == 'next') {
            if (params.current == Math.ceil(goodsData.page.total / 10)) {
               return
            }
            params.current++
            getInitData(params)
         } else {
            if (params.current == 1) {
               return
            }
            params.current--
            getInitData(params)
         }
      }


      //监听事件--end

      const priceInput = (type, e) => {
         if (type == 'lowprice') {
            lowprice.value = String(e.target.value).slice(0, 8)
            let low = String(e.target.value).split('.')
            if (low[1] && low[1].length >= 2) lowprice.value = Number(e.target.value).toFixed(2)

         }

         if (type == 'highprice') {
            highprice.value = String(e.target.value).slice(0, 8)

            let high = String(e.target.value).split('.')
            if (high[1] && high[1].length >= 2) highprice.value = Number(e.target.value).toFixed(2)

         }
      }


      onMounted(() => {
         if (route.query.categoryId) {
            params.categoryIds = route.query.categoryId;
         }
         if (route.query.keyword) {
            params.keyword = route.query.keyword;
         }
         if (route.query.vid) {
            params.storeId = route.query.vid;
         }
         if (route.query.goodsIds) {
            params.goodsIds = route.query.goodsIds;
         }
         if (route.query.brandId) {
            params.brandIds = route.query.brandId;
         }

         if (route.query.sort) {
            params.sort = route.query.sort;
            indexNum.value = route.query.sort;
         }
         getInitData(params);

      })
      //返回值
      return {
         goodsData,
         handleCurrentChange,
         L,
         indexNum,
         filterSort,
         isEmpty,
         keyword,
         firstLoading,
         skeletonData,
         loginModalVisibleFlag,
         closeLoingModal,
         categoryId3,
         checkList,
         contrast,
         addProJ,
         chosenAttr,
         changeBox,
         labelCode,
         character,
         highprice,
         lowprice,
         confirm,
         reset,
         cate1, cate2, catePathName, cateForm,
         showIndex,
         memberInfo,
         params,
         recomChange,
         vid,
         priceInput
      };
   },
   //
};
</script>

<style lang="scss" scoped>
@import "../../style/goodsList.scss";
@import "../../style/base.scss";

.goods_list {
   border: 1px solid #DBDBDB;
}

.empty_page {
   flex-direction: column;
   padding: 100px;
}

.flex-col {
   display: flex;
   flex-direction: column;
}

.flex-row {
   display: flex;
   flex-direction: row;
}

.sld_pagination {
   margin-top: 30px;
   margin-bottom: 30px;
}

/*添加css样式*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
}

input[type="number"] {
   -moz-appearance: textfield;
}
</style>
<style>
.el-checkbox__input.is-checked .el-checkbox__inner {
   background-color: $colorMain;
   border-color: $colorMain;
}

.el-checkbox__input.is-checked+.el-checkbox__label {
   color: $colorMain;
}
</style>
<template>
    <div class="index">
        <template v-if="firstLoading">
            <div class="skeleton_banner">
            </div>
            <div class="w_sld_react_1210 adv_04_wrap skeleton">
                <div class="floor_title">
                    <h2>
                        <font>&nbsp;</font>
                        <span></span>
                        <font>&nbsp;</font>
                    </h2>
                </div>
                <div class="floor_goods">
                    <div class="item" v-for="(item_main, index_main) in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                        v-bind:key="index_main">
                        <div class="wrap">
                            <a href="javascript:void(0)" class="example_text"></a>
                            <p class="title"><a href="javascript:void(0)">{{ item_main }}</a></p>
                            <p class="price"><span></span></p>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <SldDiy v-if="!firstLoading && decorateData.data && decorateData.data.length" :decorateData="decorateData"
            @adv19="handleAdv19" :isStore="true" />

        <SldCommonEmpty v-else totalHeight="700" paddingTop="200" :tip="shutDown ? '供应商不存在或已关闭' : '我们正在努力装修中，敬请期待～'" />
    </div>
</template>
<script>
import { reactive, getCurrentInstance, ref } from 'vue'
import SldDiy from '../../components/SldDiy';
import { useRoute, useRouter } from "vue-router";
import SldCommonEmpty from "../../components/SldCommonEmpty";

export default {
    name: "index",
    components: {
        SldDiy,
        SldCommonEmpty,
    },
    setup() {
        const route = useRoute();
        const firstLoading = ref(true);//是否第一次加载
        const router = useRouter();
        const vid = route.query.vid;
        const adv_21_right_icon = require("../../assets/decorate/right_more_icon.png");
        const adv_24_let_default_pic = require("../../assets/decorate/adv_24_let_default_pic.png");
        const decorateData = reactive({ data: [] }); //装修数据
        const shutDown = ref(false)
        const getInitData = () => {
            const { proxy } = getCurrentInstance();
            proxy.$get('v3/system/front/pcDeco/sellerIndex', { storeId: vid }).then(res => {
                firstLoading.value = false;
                if (res.state == 200) {
                    decorateData.data = res.data.data ? JSON.parse(proxy.$quillEscapeToHtml(res.data.data)) : null;
                    if (decorateData.data && decorateData.data.length > 0) {
                        decorateData.data.map(item => {
                            item.json_data = item.json;
                            if (item.json_data.type == 'main_banner') {
                                item.json_data.data = item.json_data.data.filter(i => i.imgUrl);
                            } else if (item.json_data.type == 'adv_19') {
                                item.json_data.data.map(child => {
                                    child.cur_tab = 0;
                                })
                            }
                        })
                    }

                } else if (res.state == 257) {
                    shutDown.value = true
                }
            })
        }
        getInitData();
        return { adv_21_right_icon, adv_24_let_default_pic, decorateData, firstLoading, shutDown };
    },

};
</script>
<style lang="scss" scoped>
@import "../../style/decorate.scss";

.index {
    background: $colorK;
    padding-bottom: 10px;
}

.skeleton_banner {
    width: 100%;
    height: 470px;
    background: $colorSkeleton;
    margin-bottom: 10px;
}

.skeleton {
    &.adv_04_wrap .floor_title h2 span {
        background: $colorSkeleton;
        display: inline-block;
        width: 200px;
    }

    &.adv_04_wrap .floor_goods .item .wrap .title {
        background: $colorSkeleton;
        width: 100%;

        a {
            color: transparent;
        }
    }

    &.adv_04_wrap .floor_goods .item .wrap .price {
        width: 100%;
        height: 25px;
    }

    &.adv_04_wrap .floor_goods .item .wrap .price span {
        display: inline-block;
        width: 70px;
        height: 100%;
        background: $colorSkeleton;
    }
}
</style>
<template>
	<div class="sld_look_log clearfix">
		<!-- 路径导航 -->
		<div class="log_banner clearfix">
			<button class="log_option" @click="logClear" v-show="looklog.log.length">
				{{L['清空']}}
			</button>
		</div>

		<!-- 足迹 -->
		<div class="sld_footprint">
			<h3>{{ L['我的足迹'] }}</h3>
			<div class="block">
				<el-timeline>
					<el-timeline-item :timestamp="time" placement="top" color="#e1251b"
						v-for="({ productLookLogInfoList, time }, index) in looklog.log" :key="index">
						<div class="layer8_box" v-for="goodsItem in productLookLogInfoList"
							:key="goodsItem.goodsId">
							<div class="layer8 flex-row"
								:class="{ gray: (goodsItem.state && goodsItem.state != 3) }">
								<div class="layer9 flex-col" @click="goodsDetail(goodsItem.productId)">
									<img :src="goodsItem.goodsImage" class="mod4 flex-col" />
									<div class="layer99">
										<img :src="goodsItem.goodsImage" />
									</div>
								</div>
								<span class="word37 flex_column_between_start">
									<span class="goodsSpec overflow_ellipsis_two"
										@click="goodsDetail(goodsItem.productId)">{{
											goodsItem.goodsName
										}}</span>

									<span class="goodsSpec overflow_ellipsis_two"
										@click="goodsDetail(goodsItem.productId)">{{
											goodsItem.specValues
										}}</span>

									<span class="product_code overflow_ellipsis_two" v-if="goodsItem.orderCode"
										@click="goodsDetail(goodsItem.productId)">{{L['订货编码']}}:{{
											goodsItem.orderCode
										}}</span>

									<span class="goods_compare" v-if="!goodsItem.state || goodsItem.state == 3"
										@click="contrast(goodsItem)">{{L['加入对比']}}</span>
								</span>
								<div class="layer10 flex-col">
									<el-scrollbar>
										<span v-show="goodsItem.attributeList.length == 0"
											style="text-indent:14px;line-height:128px;">--</span>
										<span class="info26"
											v-for="(attr, attr_index) in goodsItem.attributeList"
											:key="attr_index">
											{{ attr.attributeName }}:{{ attr.attributeValue }}
										</span>
									</el-scrollbar>
								</div>
								<div class="layer11 flex-col">
									<span class="info30">{{ goodsItem.produceStateValue }}</span>
									<span class="info30 info31">{{L['发货日：']}}{{ goodsItem.deliverTime }}{{L['天']}}</span>
								</div>
								<div class="word40 flex_row_center_center">
									<span v-if="goodsItem.productPrice">¥{{
										goodsItem.productPrice.toFixed(2)
									}}</span>
									<div v-else>--</div>
								</div>
								<div class="layer12 flex-col">
									<div class="goods_edit_nem flex_row_center_center">
										<span @click="editNum('reduce', goodsItem)">-</span>
										<input
											:disabled="(goodsItem.state && goodsItem.state != 3) ? true : false"
											type="number" v-model="goodsItem.buyNum"
											@input="editNum('edit', goodsItem, $event)"
											@blur="editNum('blur', goodsItem, $event)" />
										<span @click="editNum('add', goodsItem)">+</span>
										<span class="goods_edit_nem_after">{{ goodsItem.saleUnit }}</span>
									</div>
									<div class="bd7 flex-row">
										<span class="txt34">{{L['起订量']}}{{
											goodsItem.minBuyNum ? goodsItem.minBuyNum :
												1
										}} </span>
										<span class="txt34" style="margin-left:10px;"
											v-if="goodsItem.saleUnitDes">{{
												goodsItem.saleUnitDes
											}}</span>
									</div>
								</div>
								<div class="layer8_btn">
									<div class="layer8_btn_item" @click="addCart(goodsItem)">{{L['加入购物车']}}</div>
									<div class="layer8_btn_item" @click="addSmaple(goodsItem)"
										v-if="memberInfo.memberType == 2 || (memberInfo.memberType == 3 && memberInfo.enterpriseMaster != 1)">
										{{L['加入样品申请单']}}
									</div>
									<div class="layer8_btn_item" @click="addProduct(goodsItem)">
										{{L['加入项目']}}
									</div>
									<div class="layer8_btn_item" @click="drop(goodsItem.logId)">
										{{L['删除']}}
									</div>
								</div>
							</div>
						</div>
					</el-timeline-item>
				</el-timeline>
			</div>
			<SldCommonEmpty v-show="!looklog.log.length" totalWidth="1000" :tip="L['暂无数据']" totalHeight="680"
				paddingTop="250" />
			<!-- 分页 -->
			<div class="flex_row_center_center sld_pagination" v-if="looklog.log.length">
				<el-pagination @current-change="handleCurrentChange" v-model:currentPage="looklog.page.current"
					:page-size="looklog.page.pageSize" layout="prev, pager, next, jumper"
					:total="looklog.page.total" :hide-on-single-page="false">
				</el-pagination>
			</div>
		</div>
		<AtoProject ref="AtoProject"></AtoProject>
	</div>
</template>
<script>
import { ElTimeline, ElTimelineItem, ElMessage } from "element-plus";
import { ref, getCurrentInstance, onMounted, reactive } from "vue";
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import MemberTitle from "@/components/MemberTitle";
import SldCommonEmpty from "@/components/SldCommonEmpty";
import AtoProject from '@/components/AtoProject'
import addCompare from '@/components/comparePanel/compare.js'
export default {
	name: "Footprint",
	components: {
		ElTimeline,
		ElTimelineItem,
		MemberTitle,
		SldCommonEmpty,
		AtoProject,
	},
	setup() {
		const { proxy } = getCurrentInstance();
		const looklog = reactive({
			log: [],
			page: {
				current: 1,
				pageSize: 3,
				total: 0
			}
		});
		const L = proxy.$getCurLanguage();
		const store = useStore();
		const memberInfo = ref(store.state.memberInfo);
		const router = useRouter();
		const cartListData = reactive({ data: store.state.cartListData }); //获取vux的store中的购物车数据
		const page = reactive({
			current: 1,
		});

		const getFooter = () => {
			proxy.$get("v3/member/front/productLookLog/list", looklog.page).then((res) => {
				if (res.state == 200) {
					if (res.data.list.length > 0) {
						res.data.list.map((item) => {
							item.productLookLogInfoList.map((items) => {
								items.buyNum = items.minBuyNum ? items.minBuyNum : 1;
							});
						});
					}
					looklog.log = res.data.list;
					looklog.page.total = res.data.pagination.total;
				}
			});
		};

		const follow = (productId, logic) => {
			//关注商品
			let params = {
				isCollect: logic,
				productIds: productId,
			};
			proxy
				.$post("v3/member/front/followProduct/edit", params)
				.then((res) => {
					if (res.state == 200) {
						getFooter();
					} else {
						ElMessage.error(res.msg);
					}
				})
				.catch((err) => {
					ElMessage.error(err);
				});
		};

		const handleCurrentChange = (e) => {
			//分页点击操作
			page.current = Math.floor(e);
			getFooter();
		};

		const logClear = () => {
			proxy
				.$confirm(L['确定要清空吗?'], L['提示'], {
					confirmButtonText: L['确定'],
					cancelButtonText: L['取消'],
					type: "warning",
				})
				.then(() => {
					proxy.$get("v3/member/front/productLookLog/empty").then((res) => {
						if (res.state == 200) {
							getFooter();
							ElMessage(res.msg);
						}
					});
				});
		};

		const goodsDetail = (id) => {
			router.push({
				path: `/goods/detail`,
				query: {
					productId: id,
				},
			});
		};

		/**编辑购物车商品数量
		 * type:编辑类型 reduce：减  edit：编辑 add：加
		 * curCartItem：编辑的当前项商品
		 * e:事件对象
		 */
		const editNum = (type, curCartItem, e) => {
			let curNumber = curCartItem.buyNum;
			let minBuyNum = curCartItem.minBuyNum ? curCartItem.minBuyNum : 1;
			switch (type) {
				case "reduce": {
					if (curNumber > minBuyNum) {
						curCartItem.buyNum--;
						curNumber--;
					} else {
						curCartItem.buyNum = minBuyNum;
						ElMessage(L['最低限购'] + minBuyNum + L['件!']);
					}
					break;
				}

				case "add": {
					if (curNumber > curCartItem.productStock) {
						ElMessage(L['数量超出购买范围！']);
						curCartItem.buyNum = curCartItem.productStock;
						return;
					} else if (curNumber < minBuyNum) {
						curCartItem.buyNum = minBuyNum;
						ElMessage(L['最低限购'] + minBuyNum + L['件!']);
					} else {
						if (curNumber > 9998) {
							ElMessage(L['超过购买限制!']);
							curCartItem.buyNum = 99999;
							return;
						} else {
							curCartItem.buyNum++;
							curNumber++;
						}
					}
					break;
				}

				case "edit": {
					if (e.data == '.') {
						let num = curCartItem.buyNum.split('.')[0] ?
							curCartItem.buyNum.split('.')[0] : minBuyNum
						curCartItem.buyNum = '';
						setTimeout(() => {
							curCartItem.buyNum = num;
						})
						return;
					}
					curNumber = e.currentTarget.value;

					if (curNumber == 0 && curNumber != "") {
						curCartItem.buyNum = minBuyNum;
						curNumber = minBuyNum;
						return;
					} else if (curNumber == "") {
						return;
					} else {
						curNumber = curNumber.replace(/\D/g, "");
						if (curNumber > curCartItem.productStock) {
							ElMessage(L['数量超出购买范围！']);
							curCartItem.buyNum = curCartItem.productStock;
							return;
						}
						if (curNumber > 9998) {
							ElMessage(L['超过购买限制!']);
							curCartItem.buyNum = 99999;
							return;
						}
					}
					break;
				}

				case "blur": {
					if (curNumber == "") {
						curCartItem.buyNum = minBuyNum;
						return;
					} else if (curNumber < minBuyNum) {
						curCartItem.buyNum = minBuyNum;
						ElMessage(L['最低限购'] + minBuyNum + L['件!']);
					} else if (curNumber > 9998) {
						ElMessage(L['超过购买限制!']);
						curCartItem.buyNum = 99999;
					}
				}
			}
			curNumber = curCartItem.buyNum;
		};

		const addCart = (item) => { //加入购物车
			if (store.state.cartListData) {
				let paramsData = {
					productId: item.productId,
					number: item.buyNum
				}
				proxy.$addCart(paramsData);
			} else {
				let paramsData = {
					goodsDetailData: item,
					number: item.buyNum,
					cartListData: cartListData.data
				}
				proxy.$addCart(paramsData);
			}
		};

		const addSmaple = (item) => { //加入样品申请单
			if (store.state.loginFlag) {
				let paramsData = {
					productId: item.productId,
					number: item.buyNum,
				};
				proxy.$addSampleCart(paramsData);
			}
		};

		const addProduct = (item) => { //加入项目
			proxy.$refs.AtoProject.proInfo.productIds = `${item.productId}-1`
			proxy.$refs.AtoProject.visibleFlag = true
		};

		const drop = (id) => { //删除足迹
			proxy.$confirm(L['确定删除足迹吗?'], L['提示'], {
				confirmButtonText: L['确定'],
				cancelButtonText: L['取消'],
				type: 'warning'
			}).then(() => {
				let param = {
					logIds: id
				};
				proxy
					.$post("v3/member/front/productLookLog/delete", param)
					.then((res) => {
						if (res.state == 200) {
							ElMessage.success(res.msg);
							getFooter();
						}
					})
			})
		};

		const contrast = (item) => {
			let paramsData = {
				productImage: item.productImage,
				productId: item.productId,
				productName: item.goodsName,
				specValues: item.specValues,
				type: 'product'
			};

			addCompare(paramsData)
		};

		onMounted(() => {
			getFooter();
		});

		return {
			L,
			memberInfo,
			looklog,
			handleCurrentChange,
			cartListData,
			follow,
			logClear,
			goodsDetail,
			editNum,
			addCart,
			addSmaple,
			addProduct,
			drop,
			contrast,
		};
	},
};
</script>
<style lang="scss">
@import "../../style/member/footprint.scss";
@import "../../style/base.scss";

.sld_look_log {
	.el-timeline {
		.el-timeline-item:last-child {
			.el-timeline-item__tail {
				display: block;
			}
		}

		.el-timeline-item__tail {
			border-left: 1px solid $colorMain;
		}
	}

	.el-timeline-item {
		padding-bottom: 0;
	}

	.el-timeline-item__node--normal {
		border: 1px solid $colorMain;
		background-color: #ffffff !important;
	}

	.el-timeline-item__wrapper {
		padding-left: 22px;
	}

	.product_code {
		width: 100%;
		line-height: 18px;
		word-break: break-all;
		color: $colorTitle2;
		font-size: 12px;
	}
}
</style>
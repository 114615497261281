<template>
	<div class="sld_collect_wrapper">

		<div class="wrapper_main myorder sld_collect">
			<div class="sld_follow_m">
				<!-- 选择横幅 -->
				<div class="sld_h3_wrap clearfix">
					<h3>{{ 1 === collectIndex ? L['产品收藏列表'] : L['关注的供应商'] }}</h3>
					<div class="sld_option fr" v-show="1 === collectIndex && collectGoodsData.list.length">
						<div class="sld_option_list fl" v-show="optAct" @click="selectAll(true)">
							<img :src="collectGoodsChoose" alt="">
							<span ref="chooseWord">{{L['全选']}}</span>
						</div>
						<div class="sld_option_list fl" v-show="optAct" @click="selectAll(false)">
							<span>{{L['取消全选']}}</span>
						</div>
						<div class="sld_option_list fl" v-show="optAct" @click="cancelCollect">
							<img src="../../assets/member/delete.png" alt="">
							<span>{{ L['取消收藏'] }}</span>
						</div>
					</div>
				</div>
				<div>
					<!-- 产品收藏列表 -->
					<div class="fav_goods_list" v-show="1 === collectIndex">

						<div style="padding:0 10px;">
							<div class="order_title_info flex_row_start_center">
								<div class="time_select">{{ L['产品信息'] }}</div>
								<div class="after">{{L['描述']}}</div>
								<div class="order_price">{{L['生产状态']}}</div>
								<div class="state">{{ L['价格'] }}</div>
								<div class="oprate">{{ L['购买数量'] }}</div>
							</div>
						</div>



						<div class="layer8_box" v-for="goodsItem in collectGoodsData.list"
							:key="goodsItem.goodsId">
							<div class="layer8 flex-row">
								<div class="layer9 flex-col" @click="goodsDetail(goodsItem.productId)">
									<img :src="goodsItem.productImage" class="mod4 flex-col" />
									<div class="layer99">
										<img :src="goodsItem.productImage" />
									</div>
								</div>
								<div class="word37 flex_column_between_start">
									<span class="goodsName overflow_ellipsis_two"
										@click="goodsDetail(goodsItem.productId)">
										{{ goodsItem.goodsName }}
									</span>
									<span class="goodsSpec overflow_ellipsis_two">{{
										goodsItem.specValues
									}}</span>
									<span class="product_code overflow_ellipsis_two"
										v-if="goodsItem.orderCode">{{L['订货编码：']}}{{
											goodsItem.orderCode
										}}</span>


									<span class="goods_compare" v-if="goodsItem.state == 3"
										@click="contrast(goodsItem)">{{L['加入对比']}}</span>
								</div>
								<div class="layer10 flex-col">
									<el-scrollbar>
										<span v-show="goodsItem.attributeList.length == 0"
											style="text-indent:14px;line-height:108px;">--</span>
										<span class="info26"
											v-for="(attr, attr_index) in goodsItem.attributeList"
											:key="attr_index">
											{{ attr.attributeName }}:{{ attr.attributeValue }}
										</span>
									</el-scrollbar>
								</div>
								<div class="layer11 flex-col">
									<span class="info30">{{ goodsItem.produceStateValue }}</span>
									<span class="info30 info31">{{L['发货日：']}}{{ goodsItem.deliverTime }}{{L['天']}}</span>
								</div>
								<span class="word40">
									<span>¥{{ goodsItem.productPrice.toFixed(2) || '--' }}</span>
								</span>
								<div class="layer12 flex-col">
									<div class="goods_edit_nem flex_row_center_center">
										<span @click="editNum('reduce', goodsItem)">-</span>
										<input :disabled="goodsItem.state != 3 ? true : false" type="number"
											v-model="goodsItem.buyNum"
											@input="editNum('edit', goodsItem, $event)"
											@blur="editNum('blur', goodsItem, $event)" />
										<span @click="editNum('add', goodsItem)">+</span>
										<span class="goods_edit_nem_after">{{ goodsItem.saleUnit }}</span>
									</div>
									<div class="bd7 flex-row">
										<span class="txt34">{{L['起订量']}}{{
											goodsItem.minBuyNum ? goodsItem.minBuyNum :
												1
										}} </span>
										<span class="txt34" style="margin-left:10px;"
											v-if="goodsItem.saleUnitDes">{{
												goodsItem.saleUnitDes
											}}</span>
									</div>
								</div>
								<div class="layer8_btn">
									<div v-if="goodsItem.state == 3" class="layer8_btn_item"
										@click="addCart(goodsItem)">
										{{L['加入购物车']}}</div>
									<div class="layer8_btn_item" @click="addSmaple(goodsItem)" v-if="memberInfo.memberType == 2 || (memberInfo.memberType == 3 && memberInfo.enterpriseMaster != 1)
									&& goodsItem.state == 3">
										{{L['加入样品申请单']}}
									</div>
									<div v-if="goodsItem.state == 3" class="layer8_btn_item"
										@click="addProduct(goodsItem)">
										{{L['加入项目']}}
									</div>
									<div class="layer8_btn_item" @click="drop(goodsItem.productId)">
										{{L['取消收藏']}}
									</div>
								</div>
							</div>
						</div>
						<SldCommonEmpty v-show="!collectGoodsData.list.length && !loading" totalWidth="1003"
							:tip="L['暂无收藏产品']" />
					</div>
					<!-- 收藏的店铺 -->
					<div class="fav_store_list" v-show="2 === collectIndex">
						<template v-for="(storeItem, storeIndex) in storeList.data" :key="storeItem.storeId">
							<div class="store_item">
								<div class="store_left">
									<div class="store_left_logo" v-if="storeItem.storeLogo"
										@click="storeInfo(storeItem.storeId)">
										<img :src="storeItem.storeLogo" />
									</div>
									<i v-else class="iconfont icontouxiang iconImg"></i>
									<span @click="storeInfo(storeItem.storeId)">{{
										storeItem.storeName
									}}</span>
									<div class="store_left_btn" @click="cancleStore(storeItem.storeId)">{{L['取消关注']}}
									</div>
								</div>
								<div class="store_right">
									<div class="store_top">
										<div :class="{ active: storeItem.navIndex == 0 }"
											@click="changeNav(storeIndex, 0)">
											{{L['热销推荐']}} ({{ storeItem.goodsList.length }})
										</div>
										<div :class="{ active: storeItem.navIndex == 1 }"
											@click="changeNav(storeIndex, 1)">
											{{L['本月上新']}} ({{ storeItem.newGoodsList.length }})
										</div>
									</div>
									<div class="store_bottom">
										<template v-if="storeItem.navIndex == 0">
											<el-carousel trigger="click" height="260px" arrow="always"
												:autoplay="false" indicator-position="none" :loop="false">
												<el-carousel-item
													v-for="(item, index) in storeItem.goodsCarousel"
													:key="index">
													<div class="store_goods_list">
														<div class="store_goods">
															<div @click="goodsDetail(storeItem.goodsList[index * 2 + item - 1].defaultProductId)"
																:style="`background-image:url(${storeItem.goodsList[index * 2 + item - 1].mainImage})`">

															</div>
															<p @click="goodsDetail(storeItem.goodsList[index * 2 + item - 1].defaultProductId)"
																class="overflow_ellipsis_two"
																style="font-size:15px;">
																{{
																	storeItem.goodsList[index * 2 +
																		item - 1].goodsName
																
																}}</p>


															<p>
																<span
																	v-if="storeItem.goodsList[index * 2 + item - 1].goodsPrice">
																	￥{{
																		storeItem.goodsList[index * 2 +
																			item - 1].goodsPrice.toFixed(2)
																	}}</span>
															<div v-else>--</div>
															</p>
														</div>
														<div class="store_goods"
															v-if="(index * 2 + item) <= storeItem.goodsList.length && storeItem.goodsList[index * 2 + item]">
															<div @click="goodsDetail(storeItem.goodsList[index * 2 + item].defaultProductId)"
																:style="`background-image:url(${storeItem.goodsList[index * 2 + item].mainImage})`">

															</div>
															<p @click="goodsDetail(storeItem.goodsList[index * 2 + item].defaultProductId)"
																class="overflow_ellipsis_two"
																style="font-size:15px;">
																{{
																	storeItem.goodsList[index * 2 +
																		item].goodsName
																
																}}</p>
															<p>
																<span
																	v-if="storeItem.goodsList[index * 2 + item].goodsPrice">
																	￥{{
																		storeItem.goodsList[index * 2
																			+ item].goodsPrice.toFixed(2)
																	}}</span>
															<div v-else>--</div>
															</p>

														</div>
														<div class="store_goods"
															v-if="(index * 2 + item + 1) < storeItem.goodsList.length && storeItem.goodsList[index * 2 + item + 1]">
															<div @click="goodsDetail(storeItem.goodsList[index * 2 + item + 1].defaultProductId)"
																:style="`background-image:url(${storeItem.goodsList[index * 2 + item + 1].mainImage})`">

															</div>
															<p @click="goodsDetail(storeItem.goodsList[index * 2 + item + 1].defaultProductId)"
																class="overflow_ellipsis_two"
																style="font-size:15px;">
																{{
																	storeItem.goodsList[index * 2 +
																		item + 1].goodsName
																
																}}</p>

															<p>
																<span
																	v-if="storeItem.goodsList[index * 2 + item + 1].goodsPrice">
																	￥{{
																		storeItem.goodsList[index * 2 +
																			item + 1].goodsPrice.toFixed(2)
																	}}</span>
															<div class=" type1" v-else>--</div>
															</p>
														</div>
													</div>
												</el-carousel-item>
											</el-carousel>
										</template>
										<template v-else>
											<el-carousel trigger="click" height="260px" arrow="always"
												:autoplay="false" indicator-position="none" :loop="false">
												<el-carousel-item
													v-for="(item, index) in storeItem.newCarousel"
													:key="index">
													<div class="store_goods_list">
														<div class="store_goods">
															<div @click="goodsDetail(storeItem.newGoodsList[index * 2 + item - 1].defaultProductId)"
																:style="`background-image:url(${storeItem.newGoodsList[index * 2 + item - 1].mainImage})`">

															</div>
															<p @click="goodsDetail(storeItem.newGoodsList[index * 2 + item - 1].defaultProductId)"
																class="overflow_ellipsis_two"
																style="font-size:15px;">
																{{
																	storeItem.newGoodsList[index * 2 +
																		item - 1].goodsName
																
																}}</p>


															<p>
																<span
																	v-if="storeItem.newGoodsList[index * 2 + item - 1].goodsPrice">
																	￥{{
																		storeItem.newGoodsList[index * 2 +
																			item - 1].goodsPrice.toFixed(2)
																	}}</span>
															<div class=" type1" v-else>--</div>
															</p>

														</div>
														<div class="store_goods"
															v-if="(index * 2 + item) < storeItem.newGoodsList.length && storeItem.newGoodsList[index * 2 + item]">
															<div @click="goodsDetail(storeItem.newGoodsList[index * 2 + item].defaultProductId)"
																:style="`background-image:url(${storeItem.newGoodsList[index * 2 + item].mainImage})`">

															</div>
															<p @click="goodsDetail(storeItem.newGoodsList[index * 2 + item].defaultProductId)"
																class="overflow_ellipsis_two"
																style="font-size:15px;">
																{{
																	storeItem.newGoodsList[index * 2 +
																		item].goodsName
																
																}}</p>


															<p>
																<span
																	v-if="storeItem.newGoodsList[index * 2 + item].goodsPrice">
																	￥{{
																		storeItem.newGoodsList[index * 2 +
																			item].goodsPrice.toFixed(2)
																	}}</span>
															<div class=" type1" v-else>--</div>
															</p>
														</div>
														<div class="store_goods"
															v-if="(index * 2 + item + 1) < storeItem.newGoodsList.length && storeItem.newGoodsList[index * 2 + item + 1]">
															<div @click="goodsDetail(storeItem.newGoodsList[index * 2 + item + 1].defaultProductId)"
																:style="`background-image:url(${storeItem.newGoodsList[index * 2 + item + 1].mainImage})`">

															</div>
															<p @click="goodsDetail(storeItem.newGoodsList[index * 2 + item + 1].defaultProductId)"
																class="overflow_ellipsis_two"
																style="font-size:15px;">
																{{
																	storeItem.newGoodsList[index * 2 +
																		item + 1].goodsName
																
																}}</p>


															<p>
																<span
																	v-if="storeItem.newGoodsList[index * 2 + item + 1].goodsPrice">
																	￥{{
																		storeItem.newGoodsList[index * 2 +
																			item + 1].goodsPrice.toFixed(2)
																	}}</span>
															<div class=" type1" v-else>--</div>
															</p>
														</div>
													</div>
												</el-carousel-item>
											</el-carousel>
										</template>
									</div>
								</div>
							</div>
						</template>
						<SldCommonEmpty v-show="storeList.data.length == 0 && !loading" totalWidth="1003"
							:tip="L['暂无收藏供应商']" />
					</div>
				</div>

				<!-- 分页 -->
				<div class="flex_row_end_center sld_pagination"
					v-if="(collectIndex === 1 && collectGoodsData.list.length > 0) || (collectIndex === 2 && storeList.data.length > 0)">
					<el-pagination @current-change="handleCurrentChange" v-model:currentPage="pageData.current"
						:page-size="pageData.pageSize" layout="prev, pager, next, jumper" :total="pageData.total"
						:hide-on-single-page="false">
					</el-pagination>
				</div>

			</div>
		</div>
		<AtoProject ref="AtoProject"></AtoProject>



		<loadAnimation v-show="loading"></loadAnimation>


	</div>
</template>
<script>
import { ElMessage } from "element-plus";
import { reactive, getCurrentInstance, onMounted, ref, watchEffect, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex';
import collectStoreItem from '@/components/collectStoreItem'
import MemberTitle from '@/components/MemberTitle';
import SldCommonEmpty from '@/components/SldCommonEmpty'
import AtoProject from '@/components/AtoProject'
import loadAnimation from '@/components/loading_animation1'
import addCompare from '@/components/comparePanel/compare.js'
export default {
	name: 'MemberCollect',
	components: {
		collectStoreItem,
		MemberTitle,
		SldCommonEmpty,
		AtoProject,
		loadAnimation
	},
	setup() {
		const router = useRouter();
		const route = useRoute();
		const store = useStore();
		const memberInfo = ref(store.state.memberInfo);
		const collectIndex = ref(1) //产品收藏列表和店铺的切换标志
		const collectStoreIndex = ref(1)  //切换收藏店铺 普通关注和特别关注 的标志值
		const collectGoodsData = reactive({
			list: []
		}) //产品收藏列表

		const specialFollowStore = ref([])//特殊收藏的店铺
		const commonFollowStore = ref([])//普通收藏的店铺

		const { proxy } = getCurrentInstance()
		const L = proxy.$getCurLanguage()
		const optAct = ref(false)//显示操作
		const chosedItem = ref([])//取消收藏选择的商品集合
		const collectGoodsChoose = ref(require('../../assets/member/chooseAll.png'))
		const cartListData = reactive({ data: store.state.cartListData }); //获取vux的store中的购物车数据
		const collectLength = reactive({
			commonLength: 0,
			specialLength: 0
		})
		const pageData = reactive({
			current: 1,
			pageSize: 10,
			total: 0,
		})
		const params = reactive({
			current: pageData.current,
			pageSize: pageData.pageSize
		})
		const isStore = ref(route.query.type ? true : false);
		const storeList = reactive({ data: [] });
		const loading = ref(true)
		//获取数据
		const getInitData = () => {
			proxy
				.$get('v3/member/front/followProduct/list', params)
				.then(res => {//获取收藏商品信息
					if (res.state == 200) {
						loading.value = false
						if (res.data.list.length > 0) {
							res.data.list.map(item => {
								item.buyNum = item.minBuyNum ? item.minBuyNum : 1;
							})
						}
						collectGoodsData.list = res.data.list;
						pageData.total = res.data.pagination.total;
						params.current = res.data.pagination.current
					}
				})
		};

		const getStoreData = () => {
			proxy.$get('v3/member/front/followStore/list', params).then(res => {//获取收藏店铺信息
				if (res.state == 200) {
					loading.value = false
					if (res.data.list.length > 0) {
						res.data.list.map(item => {
							item.navIndex = 0;
							item.goodsCarousel =
								parseInt(item.goodsList.length / 3) +
								(item.goodsList.length % 3 > 0 ? 1 : 0);
							item.newCarousel =
								parseInt(item.newGoodsList.length / 3) +
								(item.newGoodsList.length % 3 > 0 ? 1 : 0);
						})
					}
					storeList.data = res.data.list;
					pageData.total = res.data.pagination.total;
				}
			})
		};

		const changeNav = (index, navIndex) => { //切换店铺的显示列表
			if (storeList.data[index].navIndex != navIndex) {
				storeList.data[index].navIndex = navIndex;
			}
		}

		// 加入购物车的post操作
		const addCart = (item) => {
			if (store.state.cartListData) {
				let paramsData = {
					productId: item.productId,
					number: item.buyNum
				}
				proxy.$addCart(paramsData);
			} else {
				let paramsData = {
					goodsDetailData: item,
					number: item.buyNum,
					cartListData: cartListData.data
				}
				proxy.$addCart(paramsData);
			}
		};

		//切换收藏商品和店铺
		const switchCollect = (index) => {
			collectIndex.value = index
		};

		const handleCurrentChange = (e) => {
			proxy.$refs.chooseWord.style.color = ""
			params.current = Math.floor(e);
			if (isStore.value) {
				getStoreData()
			} else {
				getInitData(params)
			}
		}

		//切换收藏店铺的特别关注和普通关注
		const switchStoreType = (index) => {
			collectStoreIndex.value = index
		}

		//取消产品收藏的post操作
		const cancelCollect = () => {
			if (chosedItem.value.length > 0) {
				proxy.$confirm(L['确定取消收藏吗?'], L['提示'], {
					confirmButtonText: L['确定'],
					cancelButtonText: L['取消'],
					type: 'warning'
				}).then(() => {
					cancelConfirm()
				})
			} else {
				ElMessage.warning({
					message: L['请选择产品'],
					offset: 95,
					center: true
				})
			}
		}
		//取消收藏的确认事件
		const cancelConfirm = () => {
			let params = {
				isCollect: false,
				productIds: chosedItem.value.join(',')
			}
			proxy.$post('v3/member/front/followProduct/edit', params).then(res => {
				if (res.state == 200) {
					chosedItem.value = []
					getInitData()
					ElMessage(res.msg)
				}
			})
		};

		const cancleStore = (id) => { //取消关注商铺
			proxy.$confirm(L['确定取消关注吗?'], L['提示'], {
				confirmButtonText: L['确定'],
				cancelButtonText: L['取消'],
				type: 'warning'
			}).then((res) => {
				if (res == 'confirm') {
					let params = {
						isCollect: false,
						storeIds: id
					}
					proxy.$post('v3/member/front/followStore/update', params).then(res => {
						if (res.state == 200) {
							getStoreData();
							ElMessage(res.msg);
						}
					})
				}
			})
		};

		const collectStoreUpdate = () => {
			getStoreData()
		}

		//全选或反选操作
		const selectAll = (checks) => {
			if (checks) {
				let listArr = []
				collectGoodsData.list.forEach((item) => {
					listArr.push(item.productId)
				});
				chosedItem.value = listArr
				proxy.$refs.chooseWord.style.color = "#e1251b"
				collectGoodsChoose.value = require('../../assets/member/chooseAll_red.png')
			} else {
				chosedItem.value = []
				proxy.$refs.chooseWord.style.color = ""
				collectGoodsChoose.value = require('../../assets/member/chooseAll.png')
			}
		};

		const goodsDetail = (id) => { //查看商品详情
			router.push({
				path: `/goods/detail`,
				query: {
					productId: id
				}
			})
		};

		const storeInfo = (id) => { //查看店铺详情
			router.push({
				path: '/store/index',
				query: {
					vid: id
				}
			})
		};

		/**编辑购物车商品数量
		 * type:编辑类型 reduce：减  edit：编辑 add：加
		 * curCartItem：编辑的当前项商品
		 * e:事件对象
		 */
		const editNum = (type, curCartItem, e) => {
			if (curCartItem.state != 3) return;
			let curNumber = curCartItem.buyNum;
			let minBuyNum = curCartItem.minBuyNum ? curCartItem.minBuyNum : 1;
			switch (type) {
				case "reduce": {
					if (curNumber > minBuyNum) {
						curCartItem.buyNum--;
						curNumber--;
					} else {
						curCartItem.buyNum = minBuyNum;
						ElMessage(L['最低限购'] + minBuyNum + L['件!']);
					}
					break;
				}

				case "add": {
					if (curNumber > curCartItem.productStock) {
						ElMessage(L['数量超出购买范围！']);
						curCartItem.buyNum = curCartItem.productStock;
						return;
					} else if (curNumber < minBuyNum) {
						curCartItem.buyNum = minBuyNum;
						ElMessage(L['最低限购'] + minBuyNum + L['件!']);
					} else {
						if (curNumber > 9998) {
							ElMessage(L['超过购买限制!']);
							curCartItem.buyNum = 99999;
							return;
						} else {
							curCartItem.buyNum++;
							curNumber++;
						}
					}
					break;
				}

				case "edit": {
					if (e.data == '.') {
						let num = curCartItem.buyNum.split('.')[0] ?
							curCartItem.buyNum.split('.')[0] : minBuyNum;
						curCartItem.buyNum = '';
						setTimeout(() => {
							curCartItem.buyNum = num;
						})
						return;
					}

					curNumber = e.currentTarget.value;
					if (curNumber == 0 && curNumber != "") {
						curCartItem.buyNum = minBuyNum;
						curNumber = minBuyNum;
						return;
					} else if (curNumber == "") {
						return;
					} else if (curNumber < minBuyNum) {
						curCartItem.buyNum = minBuyNum;
						ElMessage(L['最低限购'] + minBuyNum + L['件!']);
						return;
					} else {
						curNumber = curNumber.replace(/\D/g, "");
						if (curNumber > curCartItem.productStock) {
							ElMessage(L['数量超出购买范围！']);
							curCartItem.buyNum = curCartItem.productStock;
							return;
						}
						if (curNumber > 99999) {
							ElMessage(L['超过购买限制!']);
							curCartItem.buyNum = 99999;
							return;
						}
					}
					break;
				}

				case "blur": {
					if (curNumber == "") {
						curCartItem.buyNum = minBuyNum;
						return;
					} else if (curNumber < minBuyNum) {
						curCartItem.buyNum = minBuyNum;
						ElMessage(L['最低限购'] + minBuyNum + L['件!']);
					} else if (curNumber > 9998) {
						curCartItem.buyNum = 99999;
					}
				}
			}
			curNumber = curCartItem.buyNum;
		};

		const addSmaple = (item) => { //加入样品申请单
			if (store.state.loginFlag) {
				let paramsData = {
					productId: item.productId,
					number: item.buyNum,
				};
				proxy.$addSampleCart(paramsData);
			}
		}

		const addProduct = (item) => { //加入项目
			proxy.$refs.AtoProject.proInfo.productIds = `${item.productId}-1`
			proxy.$refs.AtoProject.visibleFlag = true
		}

		const drop = (id) => {
			proxy.$confirm(L['确定取消收藏吗?'], L['提示'], {
				confirmButtonText: L['确定'],
				cancelButtonText: L['取消'],
				type: 'warning'
			}).then(() => {
				let params = {
					isCollect: false,
					productIds: id
				}
				proxy.$post('v3/member/front/followProduct/update', params).then(res => {
					if (res.state == 200) {
						getInitData()
						ElMessage.success(res.msg)
					} else {
						ElMessage.error(res.msg)
					}
				})
			})
		}

		watch(chosedItem, () => {
			if (chosedItem.value.length == collectGoodsData.list.length) {
				proxy.$refs.chooseWord.style.color = "#e1251b"
				collectGoodsChoose.value = require('../../assets/member/chooseAll_red.png')
			} else {
				proxy.$refs.chooseWord.style.color = ""
				collectGoodsChoose.value = require('../../assets/member/chooseAll.png')
			}
		})

		watchEffect(() => {
			if (store.state.memberInfo.memberId) {
				pageData.current = 1;
				pageData.total = 0;
				if (route.query.type == 'store') {
					collectIndex.value = 2;
					getStoreData()
				} else {
					collectIndex.value = 1;
					getInitData(params)
				}
			}
		})

		onMounted(() => {
			pageData.current = 1;
			if (store.state.memberInfo.memberId) {
				if (isStore.value) {
					getStoreData()
				} else {
					getInitData(params)
				}
				if (route.query.type == 'store') {
					collectIndex.value = 2
				} else {
					collectIndex.value = 1
				}
			}
		})


		const contrast = (item) => {
			let paramsData = {
				productImage: item.productImage,
				productId: item.productId,
				productName: item.goodsName,
				specValues: item.specValues,
				type: 'product'
			};
			addCompare(paramsData)
		};



		return {
			collectGoodsData,
			L,
			memberInfo,
			goodsDetail,
			editNum,
			addCart,
			addSmaple,
			addProduct,
			drop,
			collectIndex,
			switchCollect,
			commonFollowStore,
			specialFollowStore,
			optAct,
			cancelCollect,
			chosedItem,
			cancelConfirm,
			collectStoreIndex,
			switchStoreType,
			selectAll,
			cartListData,
			collectLength,
			collectStoreUpdate,
			handleCurrentChange,
			pageData,
			collectGoodsChoose,
			isStore,
			storeList,
			changeNav,
			storeInfo,
			cancleStore,
			contrast,
			loading
		}
	}
}
</script>
<style lang="scss" scoped>
@import "@/style/base.scss";
@import "@/style/member/collect.scss";

.sld_pagination {
	margin-top: 10px;
}
</style>
<style lang="scss">
.store_item .store_bottom .el-carousel__arrow--left {
	left: 6px;
}

.store_item .store_bottom .el-carousel__arrow--right {
	right: 6px;
}

.sld_collect_wrapper .el-pagination {
	background-color: #FFFFFF;
	padding-right: 20px;
	padding-bottom: 10px;
}


.order_title_info {
	width: 100%;
	height: 36px;
	line-height: 36px;
	margin: 20px 0 13px;
	background-color: #f7f7f7;
	color: $colorTitle2;
	cursor: default;

	div {
		text-align: center;
	}

	.time_select {
		width: 100px;
		margin-left: 88px;
		flex-shrink: 0;
	}

	.good_price,
	.num {
		width: 66px;
		margin-left: 200px;
	}

	.num {
		margin-left: 14px;
	}

	.after {
		width: 60px;
		margin-left: 145px;
	}

	.state {
		width: 90px;
		margin-left: 32px;
	}

	.order_price,
	.oprate {
		width: 66px;
		margin-left: 100px;
	}

	.oprate {
		margin-left: 46px;
	}
}
</style>
function EventEmitter() {
     this.listeners = {}
}


EventEmitter.prototype.on = function (event, func) {
     try {
          this.listeners[event].push(func)
     } catch (e) {
          this.listeners[event] = [func]
     }
}

EventEmitter.prototype.emit = function (event, ...args) {
     if (this.listeners[event]) {
          this.listeners[event].forEach(func => {
               // func.apply(null, args)
               func(...args)
          });
     }
}

EventEmitter.prototype.off = function (event) {
     if (this.listeners[event]) {
          delete this.listeners[event]
     }
}

export default new EventEmitter()
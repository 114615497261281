<template>
    <div class="store_news">
        <div class="mod5 flex-col">
          <div class="layer2 flex-row">
            <div class="main2 flex-col"></div>
            <span class="txt6">{{L['动态详情']}}</span>
          </div>
        </div>
        <div class="main">
          <div class="title">{{artDetail.data.title}}</div>
          <div class="time">{{artDetail.data.createTime}}</div>
          <div class="rich_content" :style="artDetail.contentBg">
            <div v-html="artDetail.data.content"></div>
          </div>
        </div>
    </div>
</template>

<script>
import { reactive, getCurrentInstance, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import CartModel from '@/components/CartModel';
import SldCommonEmpty from '@/components/SldCommonEmpty';
import { ElMessage } from 'element-plus';

export default {
    name: 'storeNews',
    components: { 
      CartModel,
      SldCommonEmpty
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const route = useRoute();
        const router = useRouter();
        const artDetail = reactive({data:{
          title: '',
          createTime: '',
          content:'',
          contentBg: '',
        }});

        const getInfo =()=> { //获取动态详情
          let param = {
            newsId: route.query.newsId
          }
          proxy
            .$get('v3/cms/front/companyNews/detail',param)
            .then(res=>{
              if(res.state == 200){
                if(res.data.content){
                  res.data.content = proxy.$quillEscapeToHtml(res.data.content)
                  if (res.data.content.indexOf('<p style="display:none;" data-background="') != -1) {
                    let bg = res.data.content.split('<p style="display:none;" data-background="')[1].split('">')[0]
                    artDetail.contentBg = bg;
                  }
                }
                artDetail.data = res.data;
              }else{
                ElMessage.error(L['企业动态详情获取失败']);
                setTimeout(()=>{
                  window.close();
                }, 1500)
              }
            })
        };
        
        onMounted(()=>{
          getInfo();
        })

        return {
          L,
          artDetail,
        }
    }
}
</script>

<style lang="scss">
@import "@/style/store/newsInfo.scss";
.store_news {
  .rich_content {
    img {
      max-width: 900px;
    }
      
    a {
        display: inline-block;
        margin: 5px auto;
        color: #0000FF;
        text-decoration: underline;
    }

    table {
        border-collapse: collapse;
        padding: 0;
    }
    td,
    th {
        border: 1px solid #DDD;
        padding: 5px 10px;
    }

    ol li,
    ul li {
        list-style: unset;
    }
  }
}
</style>

<template>
     <div :class="{ pro_item: true, flex_row_start_center: true, skelelon: loading }">
          <div class="pro_img" @click="toDetail">
               <div :style="{ backgroundImage: `url(${item.seriesImage})` }"></div>
          </div>
          <div class="pro_info">
               <div class="info_top flex_row_between_center">
                    <div class="pro_name" @click="toDetail" :title="item.seriesName">{{ item.seriesName }}</div>
                    <div class="add_com flex_row_center_center">
                         <slot name="checkbox"></slot>
                    </div>
               </div>
               <div class="info_bottom" @click="toDetail">
                    <div class="word_item flex_row_start_center" v-for="(item, idx) in format" :key="idx">
                         <span class="word_item_m" v-for="(item1, index) in item" :title="title" :key="index">
                              <span>{{ item1.attributeName }}：</span>
                              <span>{{ item1.attributeValue }}</span>
                         </span>
                    </div>

               </div>
          </div>
     </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { ref, computed, watch } from 'vue';
export default {
     props: ['item', 'categoryId', 'loading'],
     setup(props, { emit }) {
          const router = useRouter()
          const toDetail = () => {
               let newWin = router.resolve({
                    path: '/goods/series',
                    query: {
                         seriesId: props.item.seriesId,
                         categoryId: props.categoryId
                    }
               })

               window.open(newWin.href, '_blank')
          }
          const title = computed(() => {
               let lot = props.item.seriesAttributeList.map(i => `${i.attributeName}：${i.attributeValue}`)
               return lot.join(',')
          })

          const firstLoading = ref(true)

          const format = computed(() => {
               let len = 2
               let { seriesAttributeList } = props.item
               var result = [];
               for (var i = 0; i < seriesAttributeList.length; i += len) {
                    result.push(seriesAttributeList.slice(i, i + len));
               }
               return result
          })



          watch(() => props.loading, () => {
               firstLoading.value = props.loading
          })

          return {
               toDetail,
               title,
               format,
               firstLoading
          }
     }
}
</script>

<style lang="scss">
.pro_item {
     padding: 10px;
     width: 375px;
     margin-bottom: 5px;
     margin-right: 3px;
     margin-left: 5px;
     transition: all .3s;
     margin-top: 5px;

     &.skelelon {
          .pro_img {
               background: $colorSkeleton;
          }

          .pro_name {
               width: 100px;
               height: 14px;
               background: $colorSkeleton;


               span {
                    color: $colorSkeleton !important;

               }
          }

          .word_item_m {
               width: 100px;
               height: 14px;
               background: $colorSkeleton;

               span {
                    color: $colorSkeleton !important;

               }
          }
     }

     &:hover {
          box-shadow: 0px 0px 6px 0px rgba(168, 168, 168, 0.42);


          .add_com {
               visibility: visible;
          }
     }

     .pro_img {
          width: 74px;
          height: 74px;
          background: #FFFFFF;
          border: 1px solid $colorI;
          cursor: pointer;

          div {
               width: 100%;
               height: 100%;
               background-position: center;
               background-repeat: no-repeat;
               background-size: contain;
          }
     }

     .pro_name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: bold;
          color: #121212;
          cursor: pointer;

     }

     .add_com {
          visibility: hidden;
     }

     .pro_name:hover {
          text-decoration: underline;
     }

     .pro_info {
          margin-left: 10px;
          width: 267px;
          height: 74px;
          overflow: hidden;

          .info_bottom {
               display: inline-block;
               margin-top: 4px;
               text-overflow: ellipsis;
               word-break: break-all;
               overflow: hidden;
               display: -webkit-box;
               -webkit-box-orient: vertical;
               -webkit-line-clamp: 3;


               .word_item {
                    margin-right: 10px;
                    line-height: 15px;

                    .word_item_m {
                         width: 120px;
                         /* display: flex; */
                         /* flex: 1; */
                         overflow: hidden;
                         text-overflow: ellipsis;
                         white-space: nowrap;
                         margin-right: 10px;

                         span {
                              line-height: 18px;

                              &:first-child {
                                   color: #999;
                              }

                              &:last-child {
                                   color: #121212;
                              }
                         }

                         &:last-child {
                              margin-left: 0;
                         }
                    }
               }
          }
     }
}

.el-checkbox__label {
     line-height: 17px;
     font-size: 12px;
}
</style>
<template>
    <div class="sld_index_wrap">
        <SldCommonEmpty :tip="L['积分商城首页暂未装修']" totalWidth="600" totalHeight="700" paddingTop="300" />
    </div>
</template>

<script>
    import SldCommonEmpty from '../../../components/SldCommonEmpty'
    import { getCurrentInstance, } from 'vue';
    export default {
        name: 'PointIndex',
        components: {
            SldCommonEmpty
        },
        setup() {
          const { proxy } = getCurrentInstance();
          const L = proxy.$getCurLanguage()
          return{
            L
          }
        }
    }
</script>

<style lang="scss">
    .sld_index_wrap {
        width: 1200px;
        margin: 0 auto;
        overflow-x: hidden;
    }
</style>
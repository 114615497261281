// 头部搜索导航栏
<template>
   <div class="sld_home_top_search container flex_row_start_start">
      <div class="ld sld_home_top_search_left">
         <router-link tag="a" class="sld_logo_wrap flex_row_start_center" :to="`/`">
            <img :src="configInfo.main_site_logo" :onerror="defaultImg" alt />
         </router-link>
      </div>
      <div class="sld_seach_wrap">
         <div class="sld_search_wrap_top">
            <div class="sld_search_wrap_top_item line" :class="{ active: searchIndex == 0 }" @click="changeIndex(0)">
               {{ L['产品'] }}
            </div>
            <div class="sld_search_wrap_top_item" :class="{ active: searchIndex == 1 }" @click="changeIndex(1)">
               {{ L['行业知识'] }}
            </div>
         </div>
         <div class="sld_seach_box ld">
            <div class="form" @submit.prevent>
               <el-select class="form_select" v-if="searchIndex == 0 && categories.data.length > 0" v-model="categoriesVal"
                  :placeholder="L['全部分类']" clearable>
                  <el-option v-for="(item, index) in categories.data" :key="item.categoryId" :label="item.categoryName"
                     :value="index">
                  </el-option>
               </el-select>
               <el-select class="form_select" v-else-if="searchIndex == 1 && article.data.length > 0"
                  v-model="categoriesVal" :placeholder="L['全部分类']" clearable>
                  <el-option v-for="(item, index) in article.data" :key="item.categoryId" :label="item.categoryName"
                     :value="index">
                  </el-option>
               </el-select>
               <el-select class="form_select" v-else :placeholder="L['全部分类']" clearable v-model="categoriesVal">
               </el-select>
               <input v-model="keyword" type="text" class="text" autocomplete="off" :placeholder="L['请输入产品名称/型号/物料编码']"
                  @keyup.enter="search" ref="searchInput" @focus="inputFocus" @input="inputChange" @blur="inputBlur"
                  v-if="searchIndex == 0" />
               <input v-model="keyword" type="text" class="text" autocomplete="off" :placeholder="L['请输入文章标题']"
                  @keyup.enter="search" ref="searchInput" @focus="inputFocus" @input="inputChange" @blur="inputBlur"
                  v-else-if="searchIndex == 1" />
               <input type="submit" :value="L['搜索']" class="button" @click="search" />

            </div>
            <div class="hot_search_wrap" v-if="hotList.data.length > 0">
               <el-carousel height="30px" direction="vertical" indicator-position="none">
                  <el-carousel-item v-for="(item, hot_idx) in hotList.data" :key="hot_idx">
                     <template v-for="(items, index) in item.data" :key="index">
                        <span v-if="index < 8" @click="nav(items)" :style="{ color: items.color }">{{
                           items.title
                        }}</span>

                     </template>
                  </el-carousel-item>
               </el-carousel>

            </div>
         </div>
         <div class="search_association" id="searchA" v-show="SAList.length && SAShow">
            <div class="s_a_item" v-for="(item, index) in SAList" :key="index"
               @mousedown="quickSearch(item.wordsContent, 'associ')">
               <!-- 因为click事件与blur事件冲突，blur事件优先，于是换成mousedown -->
               <div>{{ item.wordsContent }}</div>
               <div>{{ item.searchGoodsNum }}{{ L['个产品'] }}</div>
            </div>
         </div>
      </div>
      <div class="search_right_box flex_row_start_center">
         <!-- 设计师/企业用户的子账号 -->
         <div class="sld_cart_wrap"
            v-if="memberInfo.memberType == 2 || memberInfo.memberType == 3 && memberInfo.enterpriseMaster != 1">
            <dl class>
               <dt class="ld cart_icon_text_wrap" style="border-bottom: 1px solid rgb(239, 239, 239)">
                  <span @click="navto('/cart/request')" class="iconfont iconrz3"></span>
                  <router-link target="_blank" :to="`/cart/request`">{{ L['样品申请单'] }}</router-link>
                  <!-- 没有商品显示0 -->
                  <div class="cart_goods_num">
                     {{
                        sampleData.data && sampleData.data.availableSampleCartNum ?
                        sampleData.data.availableSampleCartNum : sampleData.data.totalCheck ?
                           sampleData.data.totalCheck : 0
                     }}
                  </div>
               </dt>
               <dd class="cart_more_view">
                  <sample-cart-model></sample-cart-model>
               </dd>
            </dl>
         </div>
         <div class="sld_cart_wrap" v-else>
            <dl class>
               <!-- <dt class="ld cart_icon_text_wrap flex_row_center_center"
                  style="border-bottom: 1px solid rgb(239, 239, 239)">
                  <img @click="navto('/quickOrder')" class="cart_icon_text_img" src="@/assets/home_sampleCart.png" />
                  <router-link target="_blank" :to="`/quickOrder`">{{ L['快速下单'] }}</router-link>
               </dt> -->
               <dt class="ld cart_icon_text_wrap flex_row_center_center pin-searh"
                  style="border-bottom: 1px solid rgb(239, 239, 239)" @click="showPinModel">
                  <img class="cart_icon_text_img" src="@/assets/iconPark.png" />
                  <a href="javascript:;">{{ L['品牌搜索'] }}</a>
               </dt>
            </dl>
         </div>
         <div class="sld_cart_wrap">
            <dl class>
               <dt class="ld cart_icon_text_wrap" style="border-bottom: 1px solid rgb(239, 239, 239)">
                  <img @click="navto('/cart/index')" class="cart_icon_text_img2" src="@/assets/home_cart.png" />
                  <router-link target="_blank" :to="`/cart/index`">{{ L['我的购物车'] }}</router-link>
                  <!-- 没有商品显示0 -->
                  <div class="cart_goods_num">
                     {{
                        cartData.data && cartData.data.availableCartNum ?
                        cartData.data.availableCartNum : cartData.data.totalCheck ?
                           cartData.data.totalCheck : 0
                     }}
                  </div>
               </dt>
               <dd class="cart_more_view">
                  <cart-model></cart-model>
               </dd>
            </dl>
         </div>
      </div>
      <div v-if="searchBarFixed && route.path != '/goods/detail'" class="container_header">
         <div class="container_header_box flex_row_start_center">
            <div class="sld_cart_wrap fixed_sld_cart_wrap">
               <router-link tag="a" class="sld_logo_wrap flex_row_start_center" :to="`/`">
                  <img :src="configInfo.main_site_logo" :onerror="defaultImg" alt />
               </router-link>
            </div>
            <div class="sld_seach_wrap">
               <div class="sld_seach_box ld">
                  <div class="form">
                     <el-select class="form_select" v-if="searchIndex == 0 && categories.data.length > 0"
                        v-model="categoriesVal" :placeholder="L['全部分类']" clearable>
                        <el-option v-for="(item, index) in categories.data" :key="item.categoryId"
                           :label="item.categoryName" :value="index">
                        </el-option>
                     </el-select>
                     <el-select class="form_select" v-else-if="searchIndex == 1 && article.data.length > 0"
                        v-model="categoriesVal" :placeholder="L['全部分类']" clearable>
                        <el-option v-for="(item, index) in article.data" :key="item.categoryId" :label="item.categoryName"
                           :value="index">
                        </el-option>
                     </el-select>
                     <el-select class="form_select" v-else :placeholder="L['全部分类']" clearable v-model="categoriesVal">
                     </el-select>
                     <input v-model="keyword" type="text" class="text" autocomplete="off" style="color: rgb(153, 153, 153)"
                        :placeholder="L['请输入产品名称/型号/物料编码']" ref="searchInput" @focus="inputFocus" />
                     <input type="submit" :value="L['搜索']" class="button" @click="search" />
                  </div>
               </div>
            </div>
            <div class="search_right_box flex_row_end_center">
               <!-- 设计师/企业用户的子账号 -->
               <div class="sld_cart_wrap"
                  v-if="memberInfo.memberType == 2 || memberInfo.memberType == 3 && memberInfo.enterpriseMaster != 1">
                  <dl class>
                     <dt class="ld cart_icon_text_wrap" style="border-bottom: 1px solid rgb(239, 239, 239)">
                        <span @click="navto('/cart/request')" class="iconfont iconrz3"></span>
                        <router-link target="_blank" :to="`/cart/request`">{{ L['样品申请单'] }}</router-link>
                        <!-- 没有商品显示0 -->
                        <div class="cart_goods_num">
                           {{
                              sampleData.data && sampleData.data.availableSampleCartNum ?
                              sampleData.data.availableSampleCartNum : sampleData.data.totalCheck ?
                                 sampleData.data.totalCheck : 0
                           }}
                        </div>
                     </dt>
                     <dd class="cart_more_view">
                        <sample-cart-model></sample-cart-model>
                     </dd>
                  </dl>
               </div>
               <div class="sld_cart_wrap" v-else>
                  <dl class>
                     <!-- <dt class="ld cart_icon_text_wrap flex_row_center_center"
                        style="border-bottom: 1px solid rgb(239, 239, 239)">
                        <img @click="navto('/quickOrder')" class="cart_icon_text_img"
                           src="@/assets/home_sampleCart.png" />
                        <router-link target="_blank" :to="`/quickOrder`">{{ L['快速下单'] }}</router-link>
                     </dt> -->
                     <dt class="ld cart_icon_text_wrap flex_row_center_center pin-search"
                        style="border-bottom: 1px solid rgb(239, 239, 239)" @click="showPinModel">
                        <img class="cart_icon_text_img" src="@/assets/iconPark.png" />
                        <a href="javascript:;">{{ L['品牌搜索'] }}</a>
                     </dt>
                  </dl>
               </div>
               <div class="sld_cart_wrap">
                  <dl class>
                     <dt class="ld cart_icon_text_wrap" style="border-bottom: 1px solid rgb(239, 239, 239)">
                        <img @click="navto('/cart/index')" class="cart_icon_text_img2" src="@/assets/home_cart.png" />
                        <router-link target="_blank" :to="`/cart/index`">{{ L['我的购物车'] }}</router-link>
                        <!-- 没有商品显示0 -->
                        <div class="cart_goods_num">
                           {{
                              cartData.data && cartData.data.availableCartNum ?
                              cartData.data.availableCartNum : cartData.data.totalCheck ?
                                 cartData.data.totalCheck : 0
                           }}
                        </div>
                     </dt>
                     <dd class="cart_more_view">
                        <cart-model></cart-model>
                     </dd>
                  </dl>
               </div>
            </div>
         </div>
      </div>


   </div>
</template>

<script>
import { reactive, getCurrentInstance, ref, watchEffect, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import CartModel from "@/components/CartModel";
import SampleCartModel from "@/components/SampleCartModel";

export default {
   name: "SldHomeTopSearch",
   components: {
      CartModel,
      SampleCartModel,
   },
   setup() {
      const { proxy } = getCurrentInstance();
      const router = useRouter();
      const route = useRoute();
      const L = proxy.$getCurLanguage();
      const store = useStore();
      const cartData = reactive({ data: {} }); //获取vux的store中的购物车数据
      const sampleData = reactive({ data: {} });
      const logoUrl = "";
      const memberInfo = ref(store.state.memberInfo);
      const configInfo = ref(store.state.configInfo);
      const defaultImg = ref(
         'this.src="' + require("../assets/common_top_logo.png") + '"'
      );
      const keyword = ref(route.query.keyword);
      const searchUrl = require("../assets/header/magnify.png");
      const cartUrl = require("../assets/header/cart.png");
      const picture = require("../assets/picture.png");
      const goods_name = "";
      const searchBarFixed = ref(false);
      const hotList = reactive({ data: [] }); //热门搜索词列表
      const tmpHotList = ref([]);
      const delGoodsCartIds = ref(""); //删除商品的id
      const SAList = ref([]);
      const SAShow = ref(false);
      const categories = reactive({ data: [] }); //产品分类
      const article = reactive({ data: [] }); //行业知识分类
      const categoriesVal = ref("");
      const searchIndex = ref(0);


      onMounted(() => {
         let pathArr = ['/information', '/information/list'];
         searchIndex.value = pathArr.indexOf(router.currentRoute.value.path) != -1 ? 1 : 0;
         getInitData();
         getCategories();

      });

      watch(() => store.state.configInfo, () => {
         configInfo.value = store.state.configInfo
      })

      // 监听商品列表页面是否点击重新选择按钮
      // watch(() => store.state.isagainChoose, (newVal) => {
      //    console.log(newVal, 'new')
      //    if (newVal) {
      //       showPinModel()
      //    } else {
      //       handleClose()
      //    }
      // })


      const getInitData = () => {
         proxy
            .$get("v3/system/front/hotSearchWords/list", { current: 1, pageSize: 5 })
            .then((res) => {
               if (res.state == 200) {
                  let arr = res.data.list.map(i => {
                     return {
                        data: JSON.parse(i.data),
                        wordsId: i.wordsId,
                        state: i.state
                     }
                  })
                  hotList.data = arr;
               }
            })

         return;
         proxy
            .$get("v3/system/front/setting/getSettings?names=hot_search_words")
            .then((res) => {
               if (res.state == 200) {
                  let tmp_data = res.data[0]
                     ? res.data[0].split(",").filter((i) => i != "")
                     : [];
                  hotList.data = tmp_data;
                  tmpHotList.value = tmp_data;
                  hotList.data = hotList.data.map((key) => {
                     if (key.length > 10) {
                        return key.substring(0, 10) + "...";
                     } else {
                        return key;
                     }
                  });
               }
            });
      };

      const cart_num = "";
      watchEffect(() => {
         if (route.query.keyword) {
            keyword.value = route.query.keyword;
         } else {
            keyword.value = "";
         }
         cartData.data = store.state.cartListData;
         sampleData.data = store.state.sampleListData;
         if (store.state.loginFlag) {
            //获取购物车数量
            proxy.$get("v3/business/front/cart/cartNum").then((res) => {
               if (res.state == 200) {
                  cartData.data.availableCartNum = res.data ? res.data : 0;
               }
            });
            if (memberInfo.value.memberType == 2) {
               //获取样品购物车数量
               proxy.$get("v3/business/front/sampleCart/cartNum").then((res) => {
                  if (res.state == 200) {
                     sampleData.data.availableSampleCartNum = res.data ? res.data : 0;
                     store.commit('updateSampleListData', sampleData.data);
                  }
               });
            }
         }
      });

      //搜索事件
      const search = () => {
         proxy.$refs.searchInput.style.color = "rgb(153,153,153)";

         if (categoriesVal.value === 0 || categoriesVal.value > 0) {
            if (searchIndex.value == 0) {
               let item = categories.data[categoriesVal.value]
               let query = {
                  categoryId: item.categoryId,
                  g: item.grade,
                  pid: item.pid
               }
               if (keyword.value) {
                  query.keyword = keyword.value
               }
               //产品分类
               router.push({
                  path: `/goods/list`,
                  query
               });
            } else {
               let item = article.data[categoriesVal.value]
               let query = {
                  categoryId: item.categoryId
               }
               if (keyword.value) {
                  query.keyword = keyword.value
               } else {
                  return
               }
               //行业知识分类
               router.push({
                  path: `/information/list`,
                  query
               });
            }
         } else if (keyword.value) {
            if (searchIndex.value == 0) {
               router.push({
                  path: `/goods/list`,
                  query: { keyword: keyword.value },
               });
            } else {
               router.push({
                  path: `/information/list`,
                  query: { keyword: keyword.value },
               });
            }
         }
      };

      const searchAssociation = (input) => { //方法无效
         return;
         proxy
            .$get("v3/goods/front/goods/searchWords/list", {
               keyWord: input,
            })
            .then((res) => {
               if (res.state == 200) {
                  SAList.value = res.data;
               }
            });
      };

      const inputChange = (e) => {
         let input = e.target.value;
         SAShow.value = true;

         if (input) {
            searchAssociation(input);
         } else {
            SAList.value = [];
         }
      };

      //热门搜索事件
      const quickSearch = (val, type) => {
         if (type == "quick") {
            router.push({
               path: `/goods/list`,
               query: { keyword: val },
            });
         } else {
            if (val == route.query.keyword) {
               keyword.value = val;
            } else {
               router.push({ path: `/goods/list`, query: { keyword: val } });
            }
         }
      };

      const inputFocus = () => {
         proxy.$refs.searchInput.style.color = "#333";
         SAShow.value = true;
         if (keyword.value && SAList.value.length == 0) {
            searchAssociation(keyword.value);
         }
      };

      const inputBlur = () => {
         SAShow.value = false;
      };

      //滚动事件
      const handleScroll = () => {
         var height = 600;
         var scrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop;
         if (scrollTop > height) {
            searchBarFixed.value = true;
         } else {
            searchBarFixed.value = false;
         }
      };
      window.addEventListener("scroll", handleScroll);

      //删除商品 curCartItem: 当前商品信息
      const delCartGoods = (curCartItem) => {
         if (store.state.loginFlag) {
            //已登录
            delGoodsCartIds.value = curCartItem.cartId;
         } else {
            //未登录
            delGoodsCartIds.value = curCartItem.goodsId;
         }
         confirmDelGoodsModel();
      };
      //确定删除商品
      const confirmDelGoodsModel = () => {
         if (store.state.loginFlag) {
            // 已登录
            let params = {
               cartIds: delGoodsCartIds.value,
            };
            proxy
               .$post("v3/business/front/cart/deleteCarts", params)
               .then((res) => {
                  if (res.state == 200) {
                     cartData.data = res.data;
                     ElMessage.success(L['删除成功！']);
                  } else {
                     ElMessage.error(res.msg);
                  }
               });
         } else {
            // 未登录
            //单个商品删除
            cartData.data.storeCartGroupList.map((storeItem, storeIndex) => {
               storeItem.promotionCartGroupList.map((promotItem) => {
                  promotItem.cartList.map((cartItem, cartIndex) => {
                     if (cartItem.goodsId == delGoodsCartIds.value) {
                        promotItem.cartList.splice(cartIndex, 1);
                     }
                  });
                  if (promotItem.cartList.length == 0) {
                     storeItem.promotionCartGroupList = [];
                  }
                  if (storeItem.promotionCartGroupList.length == 0) {
                     cartData.data.storeCartGroupList.splice(storeIndex, 1);
                  }
               });
            });
            ElMessage.success(L['删除成功！']);
         }
         store.commit("updateCartListData", cartData.data);
      };

      // 获取分类
      const getCategories = () => {
         proxy
            .$get('v3/goods/front/goods/category/topCategory')
            .then(res => {
               categories.data = res.data;
               if (route.query.categoryId && res.data.length > 0) {
                  res.data.forEach((item, index) => {
                     if (searchIndex.value === 0 && item.categoryId == route.query.categoryId) {
                        categoriesVal.value = index;
                     }
                  })
               }
            })
         proxy
            .$get("v3/cms/front/information/categoryList", { categoryId: 0 })
            .then((res) => {
               article.data = res.data.list;
               if (route.query.categoryId && res.data.list.length > 0) {
                  res.data.list.forEach((item, index) => {
                     if (searchIndex.value === 1 && item.categoryId == route.query.categoryId) {
                        categoriesVal.value = index;
                     }
                  })
               }
            })
      };

      const changeIndex = (index) => { //切换产品、行业知识
         searchIndex.value = index;
         categoriesVal.value = '';
      };

      const navto = (url) => {
         let newWin = router.resolve({
            path: url
         })
         window.open(newWin.href, '_blank');
      };

      const nav = (item) => {
         proxy.$diyNavTo(item);
      };


      // 跳转品牌搜索页面
      const showPinModel = () => {
         // PinModelFlag.value = true
         router.push({path: '/goods/brandIndex'})
      }


      return {
         L,
         logoUrl,
         searchUrl,
         cartUrl,
         picture,
         goods_name,
         hotList,
         cart_num,
         searchBarFixed,
         keyword,
         search,
         quickSearch,
         delGoodsCartIds,
         delCartGoods,
         cartData,
         sampleData,
         memberInfo,
         configInfo,
         defaultImg,
         inputFocus,
         tmpHotList,
         searchAssociation,
         SAList,
         inputChange,
         inputBlur,
         SAShow,
         categories,
         article,
         categoriesVal,
         getCategories,
         route,
         searchIndex,
         changeIndex,
         navto,
         nav,


         showPinModel,
      };
   },
};
</script>

<style lang="scss" scoped>
.sld_home_top_search {
   height: 99px;
   position: relative;
   z-index: 99;
   width: 1210px;
   margin: 7px auto 15px;
   padding-left: 0px;
   padding-right: 0px;

   .container_header {
      width: 100%;
      position: fixed;
      background-color: #fff;
      left: 0;
      right: 0;
      top: 0;
      z-index: 999;
      border-bottom: 2px solid $colorMain;

      .sld_seach_wrap,
      .sld_cart_wrap {
         margin: 5px;
         position: relative;
      }

      .container_header_box {
         width: 1210px;
         padding-top: 5px;
         padding-bottom: 5px;
         margin: 0 auto;

         .fixed_sld_cart_wrap {
            margin-right: 168px;

            a {
               width: 100%;
               height: 100%;

               img {
                  max-width: 100%;
                  max-height: 100%;
               }
            }
         }

         .sld_cart_wrap {
            dl {
               margin-left: 20px;
            }
         }

         .sld_seach_wrap {
            margin: 0;
            padding: 0;
         }

         .search_right_box {
            .sld_cart_wrap {
               margin: 0;
            }
         }
      }
   }

   .search_right_box {
      width: 376px;
   }

   .container_header {
      -webkit-animation: searchTop 0.5s ease-in-out;
      animation: searchTop 0.5s ease-in-out;
   }

   @-webkit-keyframes searchTop {
      0% {
         top: -50px;
      }

      to {
         top: 0;
      }
   }

   @keyframes searchTop {
      0% {
         top: -50px;
      }

      to {
         top: 0;
      }
   }

   .sld_home_top_search_left {
      position: relative;
      width: 254px;
      margin-top: 11px;

      .sld_logo_wrap {
         display: block;
         width: 170px;
         height: 90px;

         img {
            max-width: 100%;
            max-height: 100%;
         }
      }
   }

   .sld_seach_wrap {
      padding-top: 4px;
      margin-top: 10px;
      position: relative;

      .sld_search_wrap_top {
         position: relative;
         bottom: 1px;
         display: flex;
         align-items: center;

         .sld_search_wrap_top_item {
            text-align: center;
            line-height: 22px;
            margin-right: 10px;
            padding-right: 10px;
            color: $colorF;
            cursor: pointer;

            &.active,
            &:hover {
               color: $colorMain;
               font-weight: 700;
            }
         }

         .line {
            position: relative;

            &:after {
               content: '';
               position: absolute;
               right: -1px;
               top: 50%;
               margin-top: -6px;
               width: 1px;
               height: 12px;
               background: $colorH;
            }
         }
      }

      .sld_seach_box {
         z-index: 11;
         height: 38px;
         border-right: none;

         &.sld_seach_box_fixed {
            display: flex;
            align-items: center;
            height: 38px;
         }

         .form {
            width: 578px;
            border: 2px solid $colorMain;
            overflow: hidden;

            .form_select {
               width: 100px;
            }

            .text {
               height: 36px;
               background-color: #fff;
               width: 375px;
               -webkit-appearance: none;
               -webkit-border-radius: 0;
               height: 34px;
               color: rgb(153, 153, 153);
               padding: 5px 5px 5px 10px;
               background-position: 0 -360px;
               background-color: #fff;
               background-repeat: repeat-x;
               line-height: 20px;
               font-family: arial, "\5b8b\4f53";
               font-size: 12px;
               outline: none;
               border: none;
            }

            input {
               margin: 0;
               padding: 0;
               height: 34px;
               border: 0;
            }

            input::placeholder {
               font-size: 12px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: rgb(153, 153, 153);
            }

            ::-webkit-input-placeholder {
               margin-left: 20px;
               font-size: 12px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: rgb(153, 153, 153);
            }

            /* 使用webkit内核的浏览器 */
            :-moz-placeholder {
               font-size: 12px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: rgb(153, 153, 153);
            }

            /* Firefox版本19+ */
            :-ms-input-placeholder {
               font-size: 12px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: rgb(153, 153, 153);
            }

            /* IE浏览器 */

            .button {
               width: 100px;
               background: $colorMain;
               font-size: 16px;
               font-weight: 600;
               color: #fff;
               float: right;
               cursor: pointer;
               text-align: center;
               height: 34px;
            }
         }

         .hot_search_wrap {
            height: 30px;
            line-height: 30px;
            overflow: hidden;
            white-space: nowrap;

            strong {
               float: left;
               font-weight: 400;
            }

            a {
               color: $colorTitle2;
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;
               word-break: break-all;

               &:link,
               &:visited {
                  float: left;
                  margin-right: 10px;
               }

               &:hover,
               &.active {
                  color: $colorMain;
               }
            }

            span {
               cursor: pointer;
               margin-right: 8px;
            }
         }
      }

      .search_association {
         background: #fff;
         position: absolute;
         top: 60px;
         overflow: hidden;
         position: absolute;
         left: 0;
         width: 580px;
         border: 1px solid #ccc;
         background: #fff;
         z-index: 99;

         .s_a_item {
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            padding: 1px 5px;
            line-height: 24px;
            cursor: pointer;
            font-size: 12px;
            -webkit-font-smoothing: antialiased;
            color: $colorTitle2;

            div:first-child {
               width: 250px;
               white-space: nowrap;
               text-overflow: ellipsis;
               overflow: hidden;
            }

            div:last-child {
               overflow: hidden;
               color: #aaa;
            }

            &:hover {
               background-color: rgb(255, 233, 188);
            }
         }
      }
   }
}

.sld_cart_wrap {
   position: relative;
   z-index: 99;
   height: 40px;
   margin-top: 37px;

   dl {
      margin-left: 20px;
      margin-bottom: 0px;

      .cart_goods_num {
         font: 11px/16px Verdana;
         display: inline-block;
         min-width: 16px;
         height: 16px;
         color: #fff;
         text-align: center;
         background: $colorMain2;
         border: none 0;
         border-radius: 8px;
         margin-left: 6px;
      }

      dt {
         height: 38px;
         padding-left: 15px;
         padding-right: 15px;
         border: 1px solid #e3e3e3;
         background-color: #fff;
         cursor: pointer;
         font-weight: 400;

         .iconfont {
            margin-right: 6px;
            color: $colorMain;
            font-size: 26px;
            font-weight: 600;
            vertical-align: bottom;
            font-family: "iconfont" !important;
            font-size: 17px;
            font-style: normal;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            line-height: 36px;
         }

         &.cart_icon_text_wrap {
            a {
               color: $colorMain;
               font-size: 15px;
               font-weight: 400;
               line-height: 36px;
            }

            .cart_icon_text_img,
            .cart_icon_text_img2 {
               position: relative;
               top: 1px;
               width: 17px;
               margin-right: 6px;
            }

            .cart_icon_text_img2 {
               top: 3px;
            }
         }
      }

      dd {
         .cart_goods {
            dl {
               padding-top: 8px;
            }

            dd {
               &.cart_goods_price {
                  position: static;

                  em {
                     margin-right: 6px;
                     width: auto;
                     color: $colorTitle2;

                     &:nth-child(1) {
                        display: block;
                        font-weight: 600;
                     }

                     &:nth-child(2) {
                        display: block;
                        text-align: right;
                        margin-top: 6px;
                     }
                  }
               }
            }
         }
      }
   }

   dd {
      position: absolute;
      top: 37px;
      right: 0;
      width: 355px;
      border: 1px solid #e3e3e3;
      background: #fff;
      z-index: 1;
   }

   &:hover .cart_more_view {
      display: inline-block;
   }
}

.cart_more_view {
   display: none;

   .empty_cart {
      width: 100%;
      position: relative;

      .empty_cart_line {
         position: absolute;
         width: 163px;
         right: 0;
         height: 2px;
         top: -2px;
         z-index: 999;
         background: #fff;
      }

      .empty_cart_txt {
         padding: 10px;
         color: #999;
      }
   }
}

.ld {
   position: relative;
   zoom: 1;
}

.pin-searh {
   background-color: #285FDE !important;
   display: flex;
   align-items: center;

   img {
      top: -1px !important;
   }

   a {
      color: #fff !important;
   }
}

.cart_data {
   height: 300px;
   display: flex;
   flex-direction: column;
   position: relative;

   .cart_data_title {
      font-weight: 600;
      float: left;
      padding: 7px;
      line-height: 32px;
      height: 32px;
   }

   .cart_list {
      padding: 20px;
      overflow-y: auto;

      .cart_list_pre {
         margin-bottom: 20px;

         .cart_pre_left {
            .cart_pre_img {
               width: 48px;
               height: 48px;
               border: 1px solid #e3e3e3;
               cursor: pointer;

               img {
                  width: 100%;
                  height: 100%;
               }
            }

            .cart_pre_cen {
               width: 150px;
               margin-left: 20px;
               cursor: pointer;

               .cart_pre_name {
                  word-break: break-all;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
               }

               .cart_pre_spec {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  margin-top: 5px;
               }
            }
         }

         .cart_pre_right {
            .cart_pre_price {
               color: $colorTitle2;
            }

            .cart_pre_del {
               color: $colorTitle2;
               cursor: pointer;
               margin-top: 10px;

               &:hover {
                  color: $colorMain;
               }
            }
         }
      }
   }

   .cart_bottom {
      width: 100%;
      height: 44px;
      border: 1px solid #e3e3e3;

      .cart_bottom_left {
         padding-left: 11px;
         height: 44px;

         span {
            color: $colorTitle2;

            &:nth-child(2) {
               margin-left: 5px;
            }
         }
      }

      .cart_bottom_right {
         width: 110px;
         height: 44px;
         text-align: center;
         color: #fff;
         background-color: $colorMain;
         line-height: 44px;
         cursor: pointer;
      }
   }
}
</style>
<style lang="scss">
.sld_home_top_search {
   .form {
      display: flex;
      align-items: center;
   }

   .sld_seach_box {
      .el-select {
         height: 34px;
         line-height: 34px;
      }

      .el-select .el-input {
         width: 100px;
         height: 34px;
      }

      .el-input__suffix,
      .el-input__suffix-inner {
         display: flex;
         align-items: center;
         justify-content: center;
      }

      .el-input--suffix .el-input__inner {
         height: 34px;
         padding-left: 6px;
         padding-right: 25px;
         border-radius: 0;
         padding-bottom: 1px;
         border: none;
         border-right: 1px solid $colorI;
         overflow: hidden;
         white-space: nowrap;
         text-overflow: ellipsis;
      }

      .el-input__suffix-inner {
         position: relative;
      }
   }
}

.el-dialog {
   .el-dialog__title {
      font-size: 14px !important;
      color: #101010;
   }

   .el-dialog__footer {
      text-align: center !important;
      padding-bottom: 50px;
   }

   .el-button--primary:hover {
      background-color: rgba(40, 95, 222, 0.9) !important;
      ;
   }
}


</style>
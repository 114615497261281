<!--
 * @Author: zjf
 * @Date: 2021-01-06 09:31:05
 * @LastEditTime: 2021-01-07 13:51:36
 * @LastEditors: ljp
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/components/MsgLeftNav.vue
-->
<!--消息中心左侧公共导航 @zjf-2021-01-05-->
<template>
    <div class="sld_msg_left_nav">
        <div v-for="(item, index) in noticeList.data" :key="index" class="msg_menu"
            :class="{ active: curNav == item.tplTypeCode }">
            <router-link :to="{ path: item.path, query: { curTplTypeCode: item.tplTypeCode } }" class="menu"
                @click="nav(item.tplTypeCode)">
                <i class="iconfont iconxiaoxi" v-if="item.tplTypeCode == 'msg_all'"></i>
                <i class="iconfont iconshuju-fapiaomingxi" v-else-if="item.tplTypeCode == 'invoice_news'"></i>
                <i class="iconfont iconcaiwu" v-else-if="item.tplTypeCode == 'credit_news'"></i>
                <i class="iconfont iconchangguiqingdan" v-else-if="item.tplTypeCode == 'order_news'"></i>
                <i class="iconfont iconziyuan3" v-else-if="item.tplTypeCode == 'certification_news'"></i>
                <i class="iconfont iconshouhoutuikuan" v-else-if="item.tplTypeCode == 'after_sale_news'"></i>
                <i class="iconfont icona-shezhi" v-else-if="item.tplTypeCode == 'setting'"></i>
                <i class="iconfont iconxuanxingyouhua" v-else-if="item.tplTypeCode == 'demand_news'"></i>
                <i class="iconfont iconwujiaoxing1" v-else-if="item.tplTypeCode == 'sample_apply_news'"></i>
                <i class="iconfont iconziyuan14" v-else-if="item.tplTypeCode == 'industry_news'"></i>
                <i class="iconfont iconchanggoushangpin" v-else-if="item.tplTypeCode == 'goods_new'"></i>
                <i class="iconfont iconxiaoxi2" v-else-if="item.tplTypeCode == 'appointment_news'"></i>
                <i class="iconfont iconziyuan114" v-else-if="item.tplTypeCode == 'system_news'"></i>

                {{ item.msgName }}
            </router-link>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, watchEffect } from 'vue';
import { ElMessage } from 'element-plus';
import { useRouter, useRoute } from "vue-router";

export default {
    name: 'MsgLeftNav',
    components: {},
    setup() {
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const noticeList = reactive({ data: [] }); //消息类型列表
        const curNav = ref('msg_all'); //当前选中的nav
        const router = useRouter();
        const route = useRoute()
        onMounted(() => {
            proxy.$getUserInfo();
        });
        //监听路由的实时变化
        watchEffect(() => {
            let tmpPath = router.currentRoute.value.fullPath;

            if (tmpPath == '/msg/setting') {
                curNav.value = 'setting';
            } else {
                curNav.value = route.query.curTplTypeCode
            }
        })
        //获取消息类型列表
        const getMsgType = () => {
            proxy.$get('v3/msg/front/msg/msgListNum').then((res) => {
                if (res.state == 200) {
                    let result = res.data;
                    noticeList.data = [
                        {
                            msgName: L['消息列表'],
                            tplTypeCode: "msg_all",
                            path: '/msg/list'
                        }
                    ]
                    result.map((noticeItem, noticeIndex) => {
                        noticeItem.path = '/msg/list';
                        noticeList.data.push(noticeItem);
                    })
                    noticeList.data.push({
                        msgName: L['接收设置'],
                        tplTypeCode: 'setting',
                        path: '/msg/setting'
                    })

                } else {
                    ElMessage.error(res.msg);
                }
            })
        };
        getMsgType();
        const nav = (code) => {
            curNav.value = code;
        };
        return {
            L,
            noticeList,
            nav,
            curNav
        };
    }
}
</script>

<style lang="scss" scoped>
.sld_msg_left_nav {
    background-color: #fff;
    color: $colorTitle2;
    font-size: 12px;
    border: 1px solid #EBEBEB;
    border-top: 1px solid #fff;
    margin-top: -2px;
    padding-bottom: 100px;
    font-family: MicrosoftYaHei;
    width: 180px;
    float: left;
    margin-bottom: 20px;

    .msg_menu {
        height: 52px;
        line-height: 52px;
        background-color: #fff;

        a {
            display: inline-block;
            height: 100%;
            width: 100%;
            color: #666666;
            font-size: 16px;
            padding-left: 20px;
            border-left: 2px solid transparent;
        }

        i {
            color: #888888;
            font-size: 20px;
            margin-right: 10px;
            font-style: normal;
            -webkit-font-smoothing: antialiased;
        }
    }

    .active {
        background-color: $colorI;
        border-left: 2px solid $colorMain !important;
    }
}
</style>
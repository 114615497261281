<!--
 * @Author: your name
 * @Date: 2020-12-29 16:05:36
 * @LastEditTime: 2024-01-31 15:00:39
 * @LastEditors: cuihaopeng cuihaopeng@slodon.cn
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/components/NavCatHeader.vue
-->
<template>
   <div class="nav_cat">
      <div class="header">
         <div class="product_sort" @click="toGoodsList">
            <img :src="sortUrl" alt />
            <span class="sort">{{L['全部产品分类']}}</span>
            <CategorySort />
         </div>
         <div v-if="bannerImg.data.imgUrl && bannerImg.data.show_switch" class="banner_right" @click="navTo"
            :style="`background-image:url(${bannerImg.data.imgUrl});`"></div>
         <!-- <BannerRight /> -->
         <nav>
            <li v-if="showIndex"><a href="/">{{L['首页']}}</a></li>
            <li v-for="(item,index) in navList.data" :key="index">
               <a href="javascript:void(0)" @click="navClick(item)">
                  {{item.navName}}
                  <img v-if="item.navIconUrl" class="nav_img" :src="item.navIconUrl" />
               </a>
            </li>
         </nav>
      </div>

   </div>

</template>

<script>
   import { useRouter, useRoute } from "vue-router"; //引入路由
   import { reactive, getCurrentInstance, onMounted, ref, watch } from "vue";
   import CategorySort from './CategorySort'
   import BannerRight from './BannerRight'
   export default {
      name: "NavCatHeader",
      components: {
         CategorySort,
         BannerRight
      },
      setup() {
         const router = useRouter(); //使用路由
         const route = useRoute()
         const showIndex = ref(true)
         const navList = reactive({ data: [] });
         const { proxy } = getCurrentInstance();
         const L = proxy.$getCurLanguage()
         const sortUrl = require("@/assets/header/sort.png");
         const bannerImg = reactive({ data: {} });

         const getBannerImg = () => { //获取广告图
            proxy
               .$get("v3/system/front/firstAdv/detail", {
                  code: 'pc_mall_top_right_adv'
               })
               .then(res => {
                  if (res.state == 200) {
                     bannerImg.data = JSON.parse(proxy.$quillEscapeToHtml(res.data));
                  }
               });
         };

         const toGoodsList = () => {
            router.push("/goods/Category"); //push产品分类路由
         };

         const getNavData = () => {
            proxy.$get("v3/system/front/navigation/list").then(res => {
               if (res.state == 200) {
                  navList.data = res.data;
               }
            });
         };

         const navClick = val => {
            proxy.$diyNavTo(JSON.parse(proxy.$quillEscapeToHtml(val.data)));
         };

         const navTo = () => {
            proxy.$diyNavTo(bannerImg.data);
         };

         watch(() => route.path, () => {
            operateDom()
         })

         const operateDom = () => {
            let path = route.path;
            let dom = document.getElementById('category_sort')
            let dom2 = document.getElementById('banner_right')
            if (path == '/') {
               dom.style.display = 'block'
               dom2.style.display = 'block'
               showIndex.value = false
            } else if (path == '/goods/Category') {
               dom.style.display = 'none'
               dom2.style.display = 'none'
            }
         }

         onMounted(() => {
            operateDom()
            getBannerImg();
            getNavData();
         })

         return { navList, sortUrl, toGoodsList, navClick, showIndex, bannerImg, navTo, L };
      }
   };
</script>

<style lang="scss" scoped>
   .header {
      position: relative;
      width: 1210px;
      height: 45px;
      margin: 0 auto;
      display: flex;
      overflow: visible;

      .product_sort {
         position: relative;
         overflow: visible;
         width: 200px;
         height: 100%;
         background: $colorMain;
         color: #fff;
         font-size: 16px;
         letter-spacing: 3px;
         line-height: 30px;
         display: flex;
         align-items: center;
         flex-wrap: wrap;
         cursor: pointer;
         box-sizing: border-box;
         flex-shrink: 0;
         border-radius: 3px 3px 0 0;

         &:hover {
            #category_sort {
               display: block;
            }
         }

         img {
            width: 12px;
            height: 12px;
            margin-left: 20px;
            margin-right: 16px;
         }
      }

      .banner_right {
         position: absolute;
         top: -37px;
         right: 0;
         z-index: 9;
         width: 240px;
         height: 80px;
         color: #333333;
         background: #ffffff;
         font-size: 16px;
         font-family: Source Han Sans CN;
         font-weight: 500;
         cursor: pointer;
         background-repeat: no-repeat;
         background-position: bottom;
         background-size: contain;
      }

      nav {
         width: 1013px;
         margin-left: 8px;
         display: flex;
         align-items: center;
         line-height: 30px;
         overflow: hidden;
         flex-wrap: wrap;

         a {
            position: relative;
            display: inline-block;
            width: max-content;
            height: 45px;
            line-height: 45px;
            color: #333333;
            font-size: 15px;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            margin-left: 20px;
            padding: 0 7px;
            border-bottom: 3px solid transparent;
            font-weight: bold;

            .nav_img {
               position: absolute;
               top: 34%;
               right: -13px;
               transform: translateY(-50%);
               z-index: 2;
               max-width: 22px;
            }
         }

         a:hover {
            color: $colorMain;
            border-bottom-color: $colorMain;
         }
      }
   }
</style>
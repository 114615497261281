<template>
     <div class="pro_list_item flex_row_start_center">
          <div class="block144 flex_row_start_center">
               <div class="bd12 flex_row_center_center" :style="{ backgroundImage: `url(${item.goodsImage})` }"
                    @click="toDetail">
                    <div class="bd122">
                         <img :src="item.goodsImage" />
                    </div>
               </div>
               <div class="bd13 flex-col">
                    <span class="word94" @click="toDetail" :title="item.goodsName">{{ item.goodsName }}</span>
                    <span class="txt48" @click="emitContrast">{{L['加入对比']}}</span>
               </div>
               <div class="bd14 flex_column_center_start">
                    <div v-if="item.attributeList.length" class="bd14_box flex-col">
                         <el-scrollbar>
                              <span class="word96" v-for="(attr, idx) in item.attributeList" :key="idx"
                                   :title="attr.attributeValue">
                                   <span style="color: #90929E;">{{ attr.attributeName }}:{{ ' ' }}</span>
                                   <span>{{ attr.attributeValue }}</span>
                              </span>
                         </el-scrollbar>
                    </div>
                    <div v-else>
                         <span class="word96">--</span>
                    </div>
               </div>
               <!-- flex-col -->
               <div class="bd15 flex_column_center_center">
                    <span class="info54">{{
                         item.produceState == 1 ? L['现货'] : item.produceState == 2 ? L['半成品'] : L['待产']
                    }}</span>
                    <span class="word100">{{L['发货日：']}}{{ item.deliverTime }}{{L['天']}}</span>
               </div>
               <div class="word101" v-if="useLoginFlag">
                    <span>¥{{ item.goodsPrice ? item.goodsPrice.toFixed(2) : 0 }}</span>

                    <div v-if="item.activityList && item.activityList.length">
                         <div class="pro_tag" v-for="(item, index) in item.activityList" :key="index">
                              {{ item.promotionName }}
                         </div>
                    </div>
               </div>
               <div class="word101" v-else>
                    登录后显示价格
               </div>
               <div class="bd16 flex_column_center_center">
                    <div class="section25 flex_row_center_start">
                         <div class="flex_column_center_center">
                              <div class="goods_edit_nem1 flex_row_center_center">
                                   <span @click="editNum('minus')">-</span>
                                   <input type="number" v-model="num" @input="editNum('edit')"
                                        @blur="editNum('blur')" />
                                   <span @click="editNum('plus')">+</span>
                              </div>
                              <div class="section29 flex_row_start_center">
                                   <span class="txt51 alert" v-if="num < item.minBuyNum">{{L['最低限购']}}{{ item.minBuyNum }}</span>
                                   <span class="txt51" v-else>{{L['起订量']}}{{ item.minBuyNum }}</span>
                              </div>
                              <div class="section29 flex_row_start_center">
                                   <span class="txt51">{{ item.saleUnitDes }}</span>
                              </div>
                         </div>

                         <span class="txt50">{{ item.saleUnit }}</span>
                    </div>

               </div>
               <div class="bd17 flex-col">
                    <button class="section30 flex_row_center_center" @click="addCart"><span
                              class="word103">{{L['加入购物车']}}</span></button>
                    <div class="section31 flex-col" @click="addSampleCart"
                         v-if="memberInfo.memberType == 2 || (memberInfo.memberType == 3 && memberInfo.enterpriseMaster != 1)">
                         <span class="txt53">{{L['加入样品申请单']}}</span>
                    </div>
                    <button v-if="memberInfo.memberType" class="section32 flex-col" @click="emitAddPro"><span
                              class="info57">{{L['加入项目']}}</span></button>
               </div>
          </div>

          <SldLoginModal :visibleFlag="loginModalVisibleFlag" @closeLoingModal="closeLoingModal"
               @refreshInfo="refreshInfo" />

     </div>
</template>
<script>
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
import { getCurrentInstance, onMounted, reactive, ref, watchEffect, watch, computed } from "vue";
export default {
     props: ['item'],
     setup(props, { emit }) {
          const { proxy } = getCurrentInstance();
          const L = proxy.$getCurLanguage()
          const router = useRouter()
          const toDetail = () => {
               let newWin = router.resolve({
                    path: '/goods/detail',
                    query: {
                         productId: props.item.defaultProductId
                    }
               })
               window.open(newWin.href, '_blank')
          }
          const store = useStore();
          const memberInfo = ref(store.state.memberInfo);
          const num = ref(props.item.minBuyNum ? props.item.minBuyNum : 1)
          const cartListData = reactive({ data: store.state.cartListData }); //获取vux的store中的购物车数据
          const loginModalVisibleFlag = ref(false)

          const useLoginFlag = ref(store.state.loginFlag);
          const goodsSpec = computed(() => {
               if (props.item.goodsSpec.length > 39) {
                    return props.item.goodsSpec.slice(0, 39) + '...'
               } else {
                    return props.item.goodsSpec
               }
          })
          const emitContrast = () => {
               let paramsData = {
                    productImage: props.item.goodsImage,
                    productId: props.item.defaultProductId,
                    productName: props.item.goodsName,
                    specValues: props.item.specValues,
                    type: 'product'
               };
               emit('contrast', paramsData)
          }

          const emitAddPro = () => {
               if (num.value < props.item.minBuyNum) {
                    ElMessage(L['数量小于最小起订量'])
                    return
               }
               let param = {
                    productId: props.item.defaultProductId,
                    num: num.value
               }
               emit('addProJ', param)
          }

          //加入购物车
          const addCart = () => {
               if (store.state.loginFlag) {
                    if (num.value < props.item.minBuyNum) {
                         ElMessage(L['数量小于最小起订量'])
                         return
                    }
                    let paramsData = {
                         productId: props.item.defaultProductId,
                         number: num.value,
                    };
                    proxy.$addCart(paramsData);
               } else {
                    loginModalVisibleFlag.value = true
                    // ElMessage('请登录')
               }
          };

          watch(() => props.item, (nv, ov) => {
               num.value = nv.minBuyNum
          })

          const editNum = (type) => {
               switch (type) {
                    case 'minus': {
                         if (num.value <= props.item.minBuyNum) {
                              ElMessage(`${L['最低限购']}${props.item.minBuyNum}`)
                              return
                         } else {
                              num.value--
                         }
                         break
                    }
                    case 'edit': {
                         if ((!/^[1-9]\d*$/.test(num.value) && num.value)) {
                              num.value = props.item.minBuyNum
                         }

                         if (num.value > 99999) {
                              num.value = 99999
                              ElMessage(L['超过购买限制'])
                         }

                         break
                    }
                    case 'plus': {
                         num.value++

                         if (num.value > 99999) {
                              num.value = 99999
                              ElMessage(L['超过购买限制'])
                         }

                         break;
                    }

                    case 'blur': {
                         if (num.value <= props.item.minBuyNum) {
                              num.value = props.item.minBuyNum
                         }
                    }
               }
          }


          //加入样品申请单
          const addSampleCart = () => {
               if (store.state.loginFlag) {
                    if (num.value < props.item.minBuyNum) {
                         ElMessage(L['数量小于最小起订量'])
                         return
                    }
                    let paramsData = {
                         productId: props.item.defaultProductId,
                         number: num.value,
                    };
                    proxy.$addSampleCart(paramsData);
               } else {
                    loginModalVisibleFlag.value = true

               }


          };

          //关闭登录弹框
          const closeLoingModal = () => {
               loginModalVisibleFlag.value = false;
          };

          const refreshInfo = () => {
               history.go(0)
          }
          return {
               L,
               cartListData,
               toDetail,
               emitContrast,
               emitAddPro,
               addSampleCart,
               addCart,
               num,
               editNum,
               memberInfo,
               goodsSpec,
               loginModalVisibleFlag,
               closeLoingModal,
               refreshInfo,
               useLoginFlag,
          }
     }
}
</script>

<style lang="scss">
.pro_list_item {
     border-top: 1px solid #F4F4F4;
}

.alert {
     color: $colorPrice !important;
}

.goods_edit_nem1 {

     background: #ffffff;
     border: 1px solid #dddddd;

     span {
          width: 26px;
          height: 30px;
          background: #fff;
          text-align: center;
          display: block;
          line-height: 30px;
          cursor: pointer;
     }

     input {
          width: 44px;
          height: 30px;
          border: none;
          border-left: 1px solid #dddddd;
          border-right: 1px solid #dddddd;
          text-align: center;
          line-height: 30px;
     }

     /*** 消除input元素 type="number" 时默认的 加减按钮*/
     input[type="number"]::-webkit-inner-spin-button,
     input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
     }

     /*** 消除input元素 type="number" 时默认的 加减按钮---moz版*/
     input[type="number"] {
          -moz-appearance: textfield;
     }
}


.block144 {
     width: 100%;
     padding: 20px 0;

     &:hover {
          background: #F6F9FF;
     }

     .bd12 {
          position: relative;
          width: 106px;
          height: 106px;
          background-color: $colorJ;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          margin: 3px 20px 0;
          overflow: hidden;
          cursor: pointer;

          &:hover {
               overflow: unset;

               .bd122 {
                    opacity: 1;
               }
          }

          .bd122 {
               position: absolute;
               left: 136px;
               z-index: 999;
               opacity: 0;
               transition: all .5s ease;
               background: #fff;
               border: 1px solid #f0f0f0;
               box-shadow: rgb(0 0 0 / 15%) 3px 3px 10px;

               img {
                    max-width: 200px;
                    max-height: 200px;
                    border-radius: 8px;
               }
          }
     }

     .bd13 {
          width: 255px;
          height: 84px;
          margin: 14px 0 0 14px;

          .word94 {
               width: 255px;
               height: 38px;
               color: rgba(51, 51, 51, 1);
               font-size: 14px;
               font-family: SourceHanSansCN-Regular;
               line-height: 19px;
               cursor: pointer;
               display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
		word-wrap: break-word;//英文和数字超出省略会失效，使用这个可以解决

               &:hover {
                    color: $colorMain;
               }
          }

          .txt48 {
               width: 48px;
               height: 14px;
               cursor: pointer;
               display: block;
               overflow-wrap: break-word;
               color: $colorMain;
               font-size: 12px;
               border-bottom: 1px solid $colorMain;
               font-family: SourceHanSansCN-Regular;
               white-space: nowrap;
               text-align: left;
               align-self: flex-start;
               margin-top: 34px;
          }
     }

     .bd14 {
          width: 138px;
          height: 112px;
          margin-left: 25px;

          .bd14_box {
               width: 138px;
               height: 112px;
          }

          .word96 {
               color: #121212;
               font-size: 12px;
               margin-top: 6px;
               overflow: hidden;
               text-overflow: ellipsis;
               display: -webkit-box;
               -webkit-line-clamp: 2;
               -webkit-box-orient: vertical;
               line-height: 16px;
          }
     }

     .bd15 {
          width: 75px;
          height: 100%;
          margin-left: 53px;

          .info54 {
               width: 26px;
               height: 12px;
               display: block;
               overflow-wrap: break-word;
               color: #121212;
               font-size: 12px;
               font-family: SourceHanSansCN-Regular;
               white-space: nowrap;
               line-height: 12px;
               // text-align: left;
               // align-self: flex-start;
               // text-align: center;
               // justify-content: center;
          }

          .word100 {

               display: block;
               overflow-wrap: break-word;
               color: #121212;
               font-size: 12px;
               font-family: SourceHanSansCN-Regular;
               word-break: break-all;
               line-height: 16px;
               text-align: left;
               margin-top: 8px;
          }
     }

     .word101 {
          display: block;
          width: 50px;
          font-weight: bold;
          color: rgba(243, 2, 19, 1);
          font-size: 12px;
          text-align: center;
          margin-left: 58px;
     }

     .bd16 {
          width: 122px;
          height: 46px;
          margin-left: 50px;

          .section25 {
               width: 122px;
               height: 30px;
               justify-content: space-between;

               .section26 {
                    height: 30px;
                    border-radius: 2px;
                    border: 1px solid rgba(221, 221, 221, 1);
                    background-color: rgba(255, 255, 255, 1);
                    width: 98px;
                    align-items: flex-start;
                    padding-left: 8px;
                    position: relative;

                    .section27 {
                         width: 64px;
                         height: 30px;
                         justify-content: space-between;

                         .info55 {
                              width: 5px;
                              height: 12px;
                              display: block;
                              overflow-wrap: break-word;
                              color: rgba(204, 204, 204, 1);
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular;
                              white-space: nowrap;
                              line-height: 12px;
                              text-align: left;
                              margin-top: 8px;
                         }

                         .main21 {
                              height: 30px;
                              border: 1px solid rgba(221, 221, 221, 1);
                              width: 48px;
                              justify-content: center;
                              align-items: center;

                              .word102 {
                                   width: 14px;
                                   height: 12px;
                                   display: block;
                                   overflow-wrap: break-word;
                                   color: rgba(51, 51, 51, 1);
                                   font-size: 12px;
                                   font-family: SourceHanSansCN-Regular;
                                   white-space: nowrap;
                                   line-height: 12px;
                                   text-align: left;
                              }
                         }
                    }

                    .section28 {
                         height: 30px;
                         border-radius: 0 2px 2px 0;
                         border: 1px solid rgba(221, 221, 221, 1);
                         width: 26px;
                         justify-content: center;
                         align-items: center;
                         position: absolute;
                         left: 72px;
                         top: 0;

                         .info56 {
                              width: 7px;
                              height: 12px;
                              display: block;
                              overflow-wrap: break-word;
                              color: rgba(34, 34, 34, 1);
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular;
                              white-space: nowrap;
                              line-height: 12px;
                              text-align: left;
                         }
                    }
               }

               .txt50 {
                    width: 12px;
                    height: 12px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(68, 68, 68, 1);
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular;
                    white-space: nowrap;
                    line-height: 12px;
                    text-align: left;
                    margin-top: 7px;
               }
          }

          .section29 {

               margin-top: 4px;


               .txt51 {
                    word-break: break-all;
                    color: #90929E;
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular;
                    line-height: 16px;
                    text-align: left;
               }

               .txt52 {
                    width: 60px;
                    height: 12px;
                    display: block;
                    overflow-wrap: break-word;
                    color: #90929E;
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular;
                    white-space: nowrap;
                    line-height: 12px;
                    text-align: left;
               }
          }
     }

     .bd17 {
          width: 95px;
          height: 90px;
          margin-left: 76px;
          align-items: center;
          justify-content: center;

          .section30 {
               height: 28px;
               background: $colorMain;
               width: 95px;
               cursor: pointer;
               border: none;
               outline: none;

               .word103 {
                    width: 60px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(255, 255, 255, 1);
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular;
                    white-space: nowrap;
                    line-height: 18px;
                    text-align: left;
               }
          }

          .section31 {
               height: 28px;
               border: 1px solid rgba(226, 226, 226, 1);
               background: #fff;
               align-self: flex-start;
               margin-top: 6px;
               width: 97px;
               display: flex;
               justify-content: center;
               align-items: center;
               cursor: pointer;

               .txt53 {
                    width: 84px;
                    height: 12px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(51, 51, 51, 0.79);
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular;
                    white-space: nowrap;
                    line-height: 12px;
               }
          }

          .section32 {
               height: 28px;
               border: 1px solid rgba(226, 226, 226, 1);
               background: #fff;
               align-self: flex-start;
               margin-top: 3px;
               width: 95px;
               display: flex;
               justify-content: center;
               align-items: center;
               cursor: pointer;

               .info57 {
                    width: 48px;
                    height: 12px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(51, 51, 51, 0.79);
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular;
                    white-space: nowrap;
                    line-height: 12px;
               }
          }
     }
}

.pro_tag {
     display: inline-block;
     margin-top: 5px;
     padding: 2px 7px;
     border: 1px solid $colorMain2;
     border-radius: 6px;
}
</style>
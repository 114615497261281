//购物车页面
<template>
	<div class="sld_cart_index">
		<div class="cart_content" v-if="
	cartData.data &&
	((cartData.data.storeSampleCartGroupList &&
		cartData.data.storeSampleCartGroupList.length > 0))">
			<!-- 购物车列表顶部标题 start-->
			<div class="cart_title flex_row_start_center">
				<div class="cart_title_pre">
				</div>
				<div class="cart_title_pre">
					<span>{{ L['产品信息'] }}</span>
				</div>
				<div class="cart_title_pre">
				</div>
				<div class="cart_title_pre">
					<span>{{ L['数量'] }}</span>
				</div>
				<div class="cart_title_pre">
					<span>{{ L['单价'] }}</span>
				</div>

				<div class="cart_title_pre">
					<span>{{ L['小计（元）'] }}</span>
				</div>
				<div class="cart_title_pre">
					<span>{{ L['操作'] }}</span>
				</div>
			</div>
			<!-- 购物车列表顶部标题 end-->
			<!-- 购物车列表 start -->
			<div class="cart_lists" v-show="cartData.data">
				<div class="cart_lists_pre"
					v-for="(cartGroupItem, cartGroupIndex) in firstLoading ? skelelonData : cartData.data.storeSampleCartGroupList"
					:key="cartGroupIndex" :class="{ skelelon_loading: firstLoading }">
					<!-- 店铺信息 start -->
					<div class="store_info flex_row_start_center">
						<!-- 可选择 -->
						<img src="@/assets/cart/sel.png" alt="" class="store_sel"
							v-if="cartGroupItem.checkedAll && !cartGroupItem.lackAll"
							@click="changeSelectState('store', cartGroupItem.checkedAll, cartGroupItem)" />
						<img src="@/assets/cart/no_sel.png" alt="" class="store_sel"
							v-if="!cartGroupItem.checkedAll && !cartGroupItem.lackAll"
							@click="changeSelectState('store', cartGroupItem.checkedAll, cartGroupItem)" />
						<!-- 全部无货，不可选择 -->
						<img src="@/assets/cart/no_sel.png" alt="" class="store_sel"
							v-if="cartGroupItem.lackAll" />
						<router-link target="_blank" :to="`/store/index?vid=${cartGroupItem.storeId}`"
							class="store_des flex_row_start_center cursor_pointer">
							<span>{{ cartGroupItem.storeName }}</span>
							<img class="right_img" src="@/assets/cart/right_down.png" alt="" />
							<img class="store_chat_img" @click="service(cartGroupItem.storeId)"
								:src="storeImg" />

						</router-link>


						<div class="store_request" v-if="cartGroupItem.sampleOrderApplyInfo">
							<span>{{L['本月剩余可申请次数：']}}{{
								cartGroupItem.sampleOrderApplyInfo.remainderApplyFrequency
							}}{{L['次']}}</span>
							<span>{{L['本月剩余申请金额：']}}{{L['¥']}}{{ cartGroupItem.sampleOrderApplyInfo.remainderApplyAmount }}</span>
							<span>{{L['本月可申请总金额：']}}{{L['¥']}}{{ cartGroupItem.sampleOrderApplyInfo.applyAmount }}</span>
						</div>

					</div>
					<!-- 店铺信息 end -->
					<!-- 商品信息 start -->
					<div class="goods_con">

						<!-- 商品列表 start -->
						<div class="goods_list">
							<div class="goods_pre"
								:class="{ flex_row_start_start: 4 > 1, flex_row_start_center: 4 == 1 }"
								v-for="(cartItem, cartIndex) in cartGroupItem.storeGoodsList" :key="cartIndex">

								<!-- 可选择 1-正常状态，2-商品失效（已删除），3-商品无货-->
								<div class="flex_row_start_center">
									<div class="goods_pre_sel">
										<img src="@/assets/cart/sel.png" alt="" class="store_sel"
											v-if="cartItem.checkedAll && cartItem.productState != 3"
											@click="changeSelectState('goods', cartItem.checkedAll, cartItem)" />
										<img src="@/assets/cart/no_sel.png" alt="" class="store_sel"
											v-if="!cartItem.checkedAll && cartItem.productState != 3"
											@click="changeSelectState('goods', cartItem.checkedAll, cartItem)" />
										<!-- 不可选择 无货-->
										<img src="@/assets/cart/no_sel.png" alt="" class="store_sel"
											v-if="cartItem.productState == 3" />

									</div>
									<!-- 商品信息 -->
									<div :style="{ backgroundImage: 'url(' + cartItem.mainImage + ')' }"
										class="goods_img"
										:class="{ no_stock_img: cartItem.productState == 3 }"
										@click="goGoodsDetail(cartItem.goodsCartList[0].productId)">
									</div>
									<div class="goods_des flex_column_between_start"
										@click="goGoodsDetail(cartItem.goodsCartList[0].productId)">
										<p class="goods_name"
											:class="{ no_stock_name: cartItem.productState == 3 }"
											@click="goGoodsDetail(cartItem.goodsCartList[0].productId)">
											{{ cartItem.goodsName }}
										</p>
										<p class="goods_des_bottom">
											<text @click="goGoodsDetail(ccartItem.goodsCartList[0].productId)">{{
												cartItem.produceState == 1
													? L['现货'] : cartItem.produceState == 2 ? L['半成品'] : L['待产']
											}}</text>
											<span @click="goGoodsDetail(cartItem.goodsCartList[0].productId)">{{L['发货日']}}:
												{{ cartItem.deliverTime }}{{L['天']}}</span>
										</p>

										<p class="goods_des_bottom" v-if="cartItem.saleUnitDes">
											{{ cartItem.saleUnitDes }}
										</p>

									</div>
								</div>

								<div class="sku_list">
									<div class="sku_item flex_row_start_center"
										v-for="(item, index) in cartItem.goodsCartList" :key="index">
										<!-- 单个sku规格 -->
										<div class="goods_sku flex_row_start_start">
											<div class="goods_pre_sel">
												<div v-if="item.productState != 3">
													<img src="@/assets/cart/sel.png" alt=""
														class="store_sel" v-if="item.isChecked"
														@click="changeSelectState('spec', item.isChecked, item)" />
													<img src="@/assets/cart/no_sel.png" alt=""
														class="store_sel" v-if="!item.isChecked"
														@click="changeSelectState('spec', item.isChecked, item)" />
												</div>

												<!-- 不可选择 无货-->

												<div class="invalid_sku" v-if="item.productState == 3">
													{{L['失效']}}</div>
											</div>

											<div class="sku_spec">
												<div class="sku_value">{{ item.specValues || L['默认'] }}</div>
												<div class="product_code">{{L['订货编码：']}}{{ item.orderCode }}</div>
											</div>
										</div>

										<!-- 数量 -->
										<div class="goods_num flex_column_center_start">
											<div class="flex_row_start_center">
												<div class="goods_edit_nem flex_row_center_center">
													<span @click="editNum('reduce', item)">-</span>
													<input type="number" v-model="item.buyNum"
														@input="editNum('edit', item, $event)"
														@blur="editNum('blur', item, $event)" />
													<span @click="editNum('add', item)">+</span>
												</div>
												<span class="goods_edit_nem_after" v-if="item.saleUnit">{{
													item.saleUnit
												}}</span>
												<div class="goods_stocks" v-if="item.productState != 3">
													<span>({{L['起订量']}} {{ item.minBuyNum }})</span>
												</div>
											</div>


											<div class="goods_stocks goods_stocks_no"
												v-if="item.productState == 3">
												{{ L['库存不足'] }}
											</div>
											<div class="goods_stocks goods_stocks_red"
												v-else-if="(item.buyNum > item.productStock) && (item.produceState == 1)">
												{{ L['剩余'] }} {{ item.productStock }} {{ L['件'] }}
											</div>
										</div>
										<!-- 单价 -->
										<div class="goods_price_prom">
											<div class="goods_price flex_row_center_center"
												:class="{ no_stock_price: item.productState == 3 }">
												<span v-if="item.goodsPrice">{{L['￥']}}{{
													item.goodsPrice ?
														item.goodsPrice.toFixed(2) : 0
												}}</span>
												<div class="need_inquire type1" v-else></div>
											</div>
										</div>
										<!-- 小计 -->
										<div class="subtotal">{{
											item.totalAmount
												? `${L['￥']}${item.totalAmount.toFixed(2)}` : L['需询价']
										}}</div>
										<!-- 操作 -->
										<div class="goods_btn flex_row_center_start">
											<span class="goods_collect" @click="moveCartGoods(item.cartId)"
												v-if="token">{{ L['移入收藏夹'] }}</span>
											<el-popconfirm :title="L['确定删除选中产品？']"
												@confirm="delCartGoods('goods', item)"
												:confirmButtonText="L['确定']" :cancelButtonText="L['取消']">
												<template #reference>
													<span class="goods_del">{{ L['删除'] }}</span>
												</template>
											</el-popconfirm>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 商品列表 end -->
					</div>
					<!-- 商品信息 end -->
				</div>
				<!-- 失效商品 start -->
				<div class="invalid_goods" v-if="cartData.data.invalidList && cartData.data.invalidList.length > 0">
					<div class="invalid_goods_title">{{ L['失效产品'] }}</div>
					<div class="invalid_goods_list">
						<div class="invalid_goods_pre flex_row_start_center"
							v-for="(invalidItem, invalidIndex) in cartData.data.invalidList" :key="invalidIndex">
							<span class="invalid_tips">{{ L['失效'] }}</span>
							<img src="@/assets/cart/no_sel.png" alt="" class="store_sel" />
							<div :style="{ backgroundImage: 'url(' + invalidItem.goodsImage + ')' }"
								class="invalid_img"></div>
							<div class="invalid_des">
								<p class="invalid_name">{{ invalidItem.goodsName }}</p>
								<p class="invalid_spec">{{ invalidItem.specValues }}</p>
								<p class="invalid_des_bottom">
									{{
										invalidItem.produceState == 1 ? L['现货'] : invalidItem.produceState == 2 ?
											L['半成品']
											: L['待产']
									}}
									<span>{{L['发货日']}}: {{ invalidItem.deliverTime }}{{L['天']}}</span>
								</p>
							</div>
							<div class="invalid_message">
								<el-scrollbar>
									<div v-for="(arrItem, arr_index) in invalidItem.attributeList"
										:key="arr_index">
										{{ arrItem.attributeName }}：{{ arrItem.attributeValue }}
									</div>
									<div class="invalid_message_none"
										v-show="invalidItem.attributeList.length == 0">--
									</div>
								</el-scrollbar>
							</div>
							<div class="invalid_price flex_row_center_center">
								<span v-if="invalidItem.goodsPrice">
									{{L['￥']}}{{ invalidItem.goodsPrice ? invalidItem.goodsPrice.toFixed(2) : '--' }}
								</span>

							</div>
							<div class="invalid_num flex_column_center_center">
								<div class="invalid_edit_num flex_row_center_center">
									<span>-</span>
									<input type="number" disabled v-model="invalidItem.buyNum" />
									<span>+</span>
									<span class="invalid_edit_num_after">{{ invalidItem.saleUnit }}</span>
								</div>
							</div>
							<div class="invalid_subtotal">{{L['￥']}}{{
								invalidItem.totalAmount ?
									invalidItem.totalAmount.toFixed(2) : 0
							}}
							</div>
							<div class="invalid_btn flex_column_center_start">
								<span class="invalid_collect" v-if="token">{{ L['移入收藏夹'] }}</span>
								<span class="invalid_del" @click="delCartGoods('goods', invalidItem)">{{
									L['删除']
								}}</span>
							</div>
						</div>
					</div>
				</div>
				<!-- 失效商品 -->
			</div>
			<!-- 购物车列表 end -->
			<!-- 操作按钮 start -->
			<div class="options_btn flex_row_between_center" ref="optionsBtn">
				<div class="options_btn_left flex_row_start_center">

					<div class="del_all cursor_pointer" @click="delBatchGoods"
						v-if="cartData.data.storeSampleCartGroupList.length > 0">
						{{ L['删除选中的产品'] }}
					</div>
					<div class="del_all_invalid cursor_pointer" @click="clearInvalidGoods">
						{{ L['清空失效的产品'] }}
					</div>
				</div>
				<div class="options_right flex_row_start_center">
					<div class="options_sel_num">
						{{ L['已选择'] }}<span>{{ cartData.data.totalCheck }}</span>{{ L['件产品'] }}
					</div>
					<div class="options_sel_price" v-if="!cartData.data.hasInquire">
						<div class="options_sel_price_top flex_row_center_center">
							{{L['样品价值']}}:<span>{{L['￥']}}{{ cartData.data.totalAmount }}</span>
						</div>
						<div class="options_sel_tips" v-if="cartData.data.sampleOrderApplyInfo && (cartData.data.sampleOrderApplyInfo.remainderApplyFrequency < 1 ||
						cartData.data.sampleOrderApplyInfo.remainderApplyAmount < cartData.data.totalAmount)">
							<span>
								{{L['本月剩余额度']}}: {{ cartData.data.sampleOrderApplyInfo.remainderApplyAmount < 0 ? L['超额'] : '' }}{{L['¥']}}{{
									Math.abs(cartData.data.sampleOrderApplyInfo.remainderApplyAmount)
								}} <text
									v-if="cartData.data.sampleOrderApplyInfo.remainderApplyAmount <= 0">
									,{{L['可申请额度不足']}}</text>
							</span>
							<span>
								{{L['本月剩余可申请次数']}}: {{
									cartData.data.sampleOrderApplyInfo.remainderApplyFrequency < 0 ? L['超额'] : ''
								}}{{ Math.abs(cartData.data.sampleOrderApplyInfo.remainderApplyFrequency) }}{{L['次']}} <text
									v-if="cartData.data.sampleOrderApplyInfo.remainderApplyFrequency <= 0">
									,{{L['可申请次数不足']}}</text>
							</span>
						</div>
					</div>
					<div class="go_buy cursor_pointer"
						:class="{ active: cartData.data.totalAmount > 0 || cartData.data.hasInquire }"
						@click="goBuy">
						{{ L['申请'] }}
					</div>
				</div>
			</div>
			<!-- 操作按钮 end -->
		</div>
		<div class="cart_content_no flex_column_center_center" v-show="
	!cartData.data || JSON.stringify(cartData.data) == '{}' ||
	(cartData.data &&
		cartData.data.storeSampleCartGroupList &&
		cartData.data.storeSampleCartGroupList.length == 0)">
			<img src="@/assets/no_cart.png" alt="" />
			<p>{{ L['这里空空如也，快去首页逛逛吧～'] }}</p>
			<span @click="goIndex">{{ L['去首页'] }}</span>
		</div>
		<!-- 清空失效商品 弹框 start -->
		<el-dialog :title="L['提示']" v-model="clearInvalidGoodsModel" width="20%" center top="30vh">
			<div style="margin:0 auto; text-align: center">{{ L['确定清空失效产品？'] }}</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="cancelClearInvalidModel">{{ L['取 消'] }}</el-button>
					<el-button type="primary" @click="confirmClearInvalidModel">{{ L['确 定'] }}</el-button>
				</span>
			</template>
		</el-dialog>
		<!-- 清空失效商品 弹框 end -->

		<!-- 删除所选商品 弹框 start -->
		<el-dialog :title="L['提示']" v-model="chosenGoodsModel" width="20%" center top="30vh">
			<div style="margin:0 auto; text-align: center">{{ L['确定删除选中产品？'] }}</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="chosenGoodsModel = false">{{ L['取 消'] }}</el-button>
					<el-button type="primary" @click="delCartGoods('batch')">{{ L['确 定'] }}</el-button>
				</span>
			</template>
		</el-dialog>
		<!-- 删除所选商品 弹框 end -->



		<!-- 推荐商品 start -->
		<recommend-list :firstLoading="firstLoading"></recommend-list>
		<!-- 推荐商品 end -->

		<!-- 登录弹框 start -->
		<SldLoginModal :visibleFlag="loginModalVisibleFlag" @closeLoingModal="closeLoingModal" />
		<!-- 登录弹框 end -->

		<!-- 底部操作悬浮框 start -->
		<div class="bottom_options" v-if="optionsBtnShow">
			<div class="bottom_options_con flex_row_between_center">
				<div class="options_btn_left flex_row_start_center">
					<div class="del_all cursor_pointer" @click="delBatchGoods">
						{{ L['删除选中的产品'] }}
					</div>
					<div class="del_all_invalid cursor_pointer" @click="clearInvalidGoods">
						{{ L['清空失效的产品'] }}
					</div>
				</div>
				<div class="options_right flex_row_start_center">
					<div class="options_sel_num">
						{{ L['已选择'] }}<span>{{ cartData.data.totalCheck }}</span>{{ L['件产品'] }}
					</div>
					<div class="options_sel_price" v-if="!cartData.data.hasInquire">
						<div class="options_sel_price_top flex_row_center_center">
							{{L['样品价值']}}:<span>{{L['￥']}}{{ cartData.data.totalAmount }}</span>
						</div>
					</div>
					<div class="go_buy cursor_pointer" @click="goBuy" :class="{
						active: (cartData.data.totalAmount > 0 || cartData.data.hasInquire) && (!cartData.data.sampleOrderApplyInfo || (cartData.data.sampleOrderApplyInfo && cartData.data.sampleOrderApplyInfo.remainderApplyFrequency > 0
							|| cartData.data.sampleOrderApplyInfo.remainderApplyAmount >= cartData.data.totalAmount))
					}">
						{{ L['申请'] }}
					</div>
				</div>
			</div>

		</div>
		<!-- 底部操作悬浮框 end -->

	</div>
</template>

<script>
import { reactive, getCurrentInstance, ref, watchEffect, onMounted } from "vue";
import {
	ElMessage,
	ElDialog,
	ElButton,
	ElPopover,
	ElPopconfirm,
} from "element-plus";
import { useRouter } from "vue-router";
import RecommendList from "../../components/RecommendList.vue";
import CouponItem from "@/components/CouponItem";
import { useStore } from "vuex";
import SldLoginModal from "../../components/loginModal";
export default {
	name: "CartIndex",
	components: {
		ElDialog,
		ElButton,
		ElPopover,
		ElPopconfirm,
		RecommendList,
		CouponItem,
		SldLoginModal,
	},
	setup() {
		const router = useRouter();
		const { proxy } = getCurrentInstance();
		const L = proxy.$getCurLanguage();
		const store = useStore();
		const storeImg = require('../../assets/cart/storeChat.png');
		const token = ref(store.state.loginFlag); //登录缓存是否登录
		const loginModalVisibleFlag = ref(false); //登录弹框是否显示，默认不显示
		const cartData = reactive({ data: store.state.sampleListData }); //购物车数据信息
		const clearInvalidGoodsModel = ref(false); //清空失效商品对话框是否显示
		const delGoodsCartIds = ref(""); //要删除的商品的购物车的id集
		const delType = ref("goods"); //要删除的商品类型：goods：单个商品删除 batch:批量删除
		const curPromotion = ref(-1); //当前选择的商品的活动的促销的id
		const curPromotionCartItem = reactive({ data: {} }); //当前选择的要换促销的商品
		const couponModel = ref(false); //优惠券弹框是否显示
		const couponList = reactive({ data: {} }); //当前店铺优惠券列表数据
		const cartListData = reactive({ data: store.state.sampleListData }); //获取vux的store中的购物车数据
		const optionsBtnTop = ref(0); //底部操作按钮元素的top值
		const windowHeight = ref(0); //屏幕元素的高度
		const optionsBtnShow = ref(false); //底部操作按钮悬浮框是否显示
		const optionsBtn = ref(null); //获取底部操作按钮元素
		const curPromotionGoodsProductId = ref(''); //当前点击的促销活动的商品
		const curCouponPage = ref(1); //当前为第一页优惠券
		const couponPageSize = ref(6); //优惠券默认一页显示6条数据
		const curStoreId = ref(-1); //当前打开的优惠券弹框的店铺id
		const elementScrollTop = ref(0); //页面滚动高度
		const chosenGoodsModel = ref(false)
		const firstLoading = ref(true);
		const skelelonData = ref([]);

		onMounted(() => {
			window.addEventListener('scroll', scrollHandle); //绑定页面滚动事件
			for (let i = 0; i < 2; i++) {
				skelelonData.value.push({
					storeCartList: [{}]
				})
			}
		});
		//页面监听token是否更新
		watchEffect(() => {
			token.value = store.state.loginFlag;
			cartData.data = store.state.sampleListData;
			//实时获取屏幕的高度
			windowHeight.value = document.documentElement.clientHeight;

			if (optionsBtn.value != null && optionsBtn.value != undefined) {
				optionsBtnTop.value = optionsBtn.value.offsetTop;
				if (optionsBtnTop.value > windowHeight.value) {
					optionsBtnShow.value = true;
				} else {
					optionsBtnShow.value = false;
				}
				if (elementScrollTop.value + windowHeight.value > optionsBtnTop.value) {
					optionsBtnShow.value = false;
				} else {
					optionsBtnShow.value = true;
				}
			}
		});
		const scrollHandle = (e) => {
			elementScrollTop.value = e.srcElement.scrollingElement.scrollTop;
			// elementScrollTop.value = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
		};

		//已选择商品的件数,计算合计(未登录使用)
		const colculationOperation = () => {
			if (JSON.stringify(cartData.data) != "{}" && cartData.data != undefined) {
				cartData.data.totalCheck = 0;
				cartData.data.totalAmount = 0;
				cartData.data &&
					cartData.data.storeSampleCartGroupList &&
					cartData.data.storeSampleCartGroupList.map((storeItem) => {
						storeItem.storeCartList.map((cartItem) => {
							cartItem.subtotal = (Number(cartItem.buyNum) * cartItem.totalAmount).toFixed(2);
							if (cartItem.isChecked == 1) {
								cartData.data.totalCheck++;
								cartData.data.totalAmount += Number(cartItem.subtotal);
							}
						});
					});
			}
		};

		//更新本地缓存购物车数据
		const updateLocCartData = (cartData) => {
			//将购物车数据更新到vuex中的store中
			store.commit("updateSampleListData", cartData);
		};

		//获取购物车数据
		const getCartData = () => {
			if (store.state.loginFlag) {
				//已登录
				let params = {};
				proxy
					.$get("v3/business/front/sampleCart/cartList", params)
					.then((res) => {
						if (firstLoading.value) {
							firstLoading.value = false;
						}
						if (res.state == 200) {
							cartData.data = proxy.$calculateSubtotal(res.data);
						} else {
							ElMessage.error(res.msg);
						}
					})
					.then(() => {
						store.commit("updateSampleListData", cartData.data); //将购物车数据存储到vuex的store中
					});
			} else {
				//未登录
				if (firstLoading.value) {
					firstLoading.value = false;
				}
				cartData.data = proxy.$calculateSubtotal(cartListData.data);
				colculationOperation();
			}
			updateLocCartData(cartData.data);
		};
		getCartData();

		/**编辑购物车商品数量
		 * type:编辑类型 reduce：减  edit：编辑 add：加
		 * curCartItem：编辑的当前项商品
		 * e:事件对象
		 */
		const editNum = (type, curCartItem, e) => {
			let curNumber = curCartItem.buyNum;
			let minBuyNum = 1;
			let maxMin = Math.min(curCartItem.produceState != 1 ? 99999 : curCartItem.productStock, 99999)

			switch (type) {
				case 'reduce': {
					if (curNumber > minBuyNum) {
						curCartItem.buyNum--;
						curNumber--
					} else {
						curCartItem.buyNum = minBuyNum;
						ElMessage(L['最低申请'] + minBuyNum + L['件!']);
					}
					break
				}

				case 'add': {

					curCartItem.buyNum++;
					curNumber++
					if (curNumber > maxMin) {
						ElMessage(L['数量超出限制!']);
						curCartItem.buyNum = maxMin;

					}


					break;
				}

				case 'edit': {
					if (e.data == '.') {
						let num = curCartItem.buyNum.split('.')[0] ?
							curCartItem.buyNum.split('.')[0] : minBuyNum
						curCartItem.buyNum = '';
						setTimeout(() => {
							curCartItem.buyNum = num;
						})
						return;
					}
					curNumber = e.currentTarget.value;
					if (curNumber == 0 && curNumber != '') {
						curCartItem.buyNum = minBuyNum;
						curNumber = minBuyNum
						return
					} else if (curNumber == '') {
						return
					} else {
						curNumber = curNumber.replace(/\D/g, "");
						if (curNumber > maxMin) {
							ElMessage(L['数量超出范围！']);
							curCartItem.buyNum = maxMin
							return
						}

					}
					break
				}

				case 'blur': {
					if (curNumber == '') {
						curCartItem.buyNum = minBuyNum
					} else if (curNumber < minBuyNum) {
						curCartItem.buyNum = minBuyNum;
						ElMessage(L['最低申请'] + minBuyNum + L['件!']);
					} else if (curNumber > maxMin) {
						ElMessage("数量超过限制!");
						curCartItem.buyNum = maxMin;
					}
				}
			}

			curNumber = curCartItem.buyNum

			if (store.state.loginFlag) {
				//已登录
				let params = {
					cartId: curCartItem.cartId, //商品购物车id
					number: curNumber, //修改后商品数量
				};

				proxy.$post("v3/business/front/sampleCart/changeNum", params).then((res) => {
					if (res.state == 200) {
						cartData.data = proxy.$calculateSubtotal(res.data);
						updateLocCartData(cartData.data);
					} else {
						ElMessage.error(res.msg);
					}
				});
			} else {
				//未登录
				cartData.data.storeSampleCartGroupList &&
					cartData.data.storeSampleCartGroupList.map((storeItem) => {
						storeItem.storeCartList.map((cartItem) => {
							cartItem.promotionModel = false
							if (curCartItem.productId == cartItem.productId) {
								cartItem.buyNum = curCartItem.buyNum;
							}
						});
					});
				colculationOperation();
				updateLocCartData(cartData.data);
			}

		};

		//当前店铺及购物车的选中状态(未登录使用)
		const curStoreCartSelChecked = () => {
			let curSelStore = []; //当前店铺未选中
			cartData.data.storeSampleCartGroupList.map((storeItem) => {
				curSelStore = storeItem.storeCartList.filter(
					(cartItem) => cartItem.isChecked == 0
				);
				if (curSelStore.length > 0) {
					storeItem.checkedAll = false;
				} else {
					storeItem.checkedAll = true;
				}
			});
			let curCartData = []; //当前购物车是否全选或全不选
			curCartData = cartData.data.storeSampleCartGroupList.filter(
				(storeItem) => !storeItem.checkedAll
			);
			if (curCartData.length > 0) {
				cartData.data.checkedAll = false;
			} else {
				cartData.data.checkedAll = true;
			}
		};

		/**选中状态的更改
		 * type ： goods:商品， store :店铺 all,全部（全选或者全不选）
		 * isCheckedState：商品：当前商品的选中状态 店铺:店铺的选中状态
		 * curCartStoreGoodsId：商品：当前商品购物车信息 店铺：storeId
		 */
		const changeSelectState = (type, isCheckedState, curCartStoreGoodsId) => {
			if (store.state.loginFlag) {
				//已登录
				let cartIds = ""; //商品的购物车id集
				if (type == 'spec') {
					cartIds = curCartStoreGoodsId.cartId
				} else if (type == "goods") {
					//选择商品
					let { goodsCartList } = curCartStoreGoodsId
					cartIds = goodsCartList.map(i => i.cartId).join(',')
				} else if (type == "store") {
					//选择店铺
					let { storeGoodsList } = curCartStoreGoodsId
					cartIds = storeGoodsList.map(i => i.goodsCartList).flat(1).map(s => s.cartId).join(',')
				} else if (type == "all") {
					//全选或全不选
					cartData.data.storeSampleCartGroupList.map((storeItem) => {
						storeItem.storeGoodsList.map((cartItem) => {
							cartItem.goodsCartList.forEach(item => {
								cartIds += item.cartId + ",";
							})
						});
					});
					cartIds = cartIds.substring(0, cartIds.length - 1);
				}
				let params = {
					cartIds: cartIds, //购物车id集合
					checked: isCheckedState ? 0 : 1, //是否选中：0=全不选、1=全选中
				};
				proxy.$post("v3/business/front/sampleCart/checkedCarts", params).then((res) => {
					if (res.state == 200) {
						cartData.data = res.data
						updateLocCartData(cartData.data);
					} else {
						ElMessage.error(res.msg);
					}
				});
			}
		};

		//移入收藏夹 cartId: 商品购物车id集(当前商品购物车id)
		const moveCartGoods = (cartId) => {
			let params = {
				cartIds: cartId,
			};
			proxy
				.$post("v3/business/front/sampleCart/moveToCollection", params)
				.then((res) => {
					if (res.state == 200) {
						cartData.data = res.data;
						cartData.data = proxy.$calculateSubtotal(cartData.data);
						ElMessage.success(L['移入收藏夹成功！']);
						store.commit('updateSampleListData', cartData.data);
					} else {
						ElMessage.error(res.msg);
					}
				});
		};

		//删除商品 type:'goods'单个商品 curCartItem: 当前商品信息    batch:批量
		const delCartGoods = (type, curCartItem) => {
			if (store.state.loginFlag) {
				//已登录
				if (type == "goods") {
					delGoodsCartIds.value = curCartItem.cartId;
					curCartItem.promotionModel = false
				} else if (type == "batch") {
					let delGoodsList = []
					cartData.data.storeSampleCartGroupList.map((storeItem) => {
						storeItem.storeGoodsList.map((cartItem) => {
							let checkList = cartItem.goodsCartList.filter(i => i.isChecked).map(i => i.cartId)
							delGoodsList = delGoodsList.concat(checkList)
						});
					});
					delGoodsCartIds.value = delGoodsList.join(',')
				}
			}
			chosenGoodsModel.value = false
			confirmDelGoodsModel();
		};

		//确定删除商品
		const confirmDelGoodsModel = () => {
			if (store.state.loginFlag) {
				// 已登录
				let params = {
					cartIds: delGoodsCartIds.value,
				};
				proxy
					.$post("v3/business/front/sampleCart/deleteCarts", params)
					.then((res) => {
						if (res.state == 200) {
							cartData.data = res.data;
							ElMessage.success(L['删除成功！']);
							proxy.$getLoginCartListData();
							proxy.$getLoginSampleListData();
						} else {
							ElMessage.error(res.msg);
						}
					});
			} else {
				// 未登录
				if (delType.value == "goods") {
					//单个商品删除
					cartData.data.storeSampleCartGroupList.map((storeItem, storeIndex) => {
						storeItem.storeCartList.map((cartItem, cartIndex) => {
							if (cartItem.goodsId == delGoodsCartIds.value) {
								storeItem.storeCartList.splice(cartIndex, 1);
							}
						});
						if (storeItem.storeCartList.length == 0) {
							storeItem.promotionCartGroupList = [];
						}
						if (storeItem.promotionCartGroupList.length == 0) {
							cartData.data.storeSampleCartGroupList.splice(storeIndex, 1);
						}
					});
				} else if (delType.value == "batch") {
					//批量删除
					let curSelGoods = []; //选中的商品
					cartData.data.storeSampleCartGroupList.map((storeItem) => {
						curSelGoods = storeItem.storeCartList.filter(
							(cartItem) => cartItem.isChecked == 1
						);
					});
					cartData.data.storeSampleCartGroupList.map((storeItem, storeIndex) => {
						storeItem.storeCartList.map((cartItem, cartIndex) => {
							curSelGoods.map((curSelGoodsItem) => {
								if (cartItem.goodsId == curSelGoodsItem.goodsId) {
									storeItem.storeCartList.splice(cartIndex, 1);
								}
							});
						});
						if (storeItem.storeCartList.length == 0) {
							storeItem.promotionCartGroupList = [];
						}
						if (storeItem.promotionCartGroupList.length == 0) {
							cartData.data.storeSampleCartGroupList.splice(storeIndex, 1);
						}
					});
					curStoreCartSelChecked();
				}
				ElMessage.success(L['删除成功！']);
			}
			updateLocCartData(cartData.data);
		};

		//打开清空失效商品弹框
		const clearInvalidGoods = () => {
			clearInvalidGoodsModel.value = true;
		};
		//确定清空失效商品
		const confirmClearInvalidModel = () => {
			let params = {};
			proxy.$post("v3/business/front/sampleCart/emptyInvalid", params).then((res) => {
				if (res.state == 200) {
					cartData.data.invalidList = [];
					ElMessage.success(L['清空失效产品成功！']);
				} else {
					ElMessage.error(res.msg);
				}
			});
			clearInvalidGoodsModel.value = false;
		};
		//取消清空失效商品
		const cancelClearInvalidModel = () => {
			clearInvalidGoodsModel.value = false;
		};

		//去商品详情页
		const goGoodsDetail = (productId) => {
			proxy.$goGoodsDetail(productId);
		};

		//去结算
		const goBuy = () => {
			if (store.state.loginFlag) {
				if (cartData.data.totalCheck > 0) {
					router.push({ path: "/buy/request", query: { ifcart: true, sample: true } });
				} else {
					ElMessage(L['结算产品不能为空！']);
				}
			} else {
				//未登录提示登录
				loginModalVisibleFlag.value = true;
			}
		};
		//关闭登录弹框
		const closeLoingModal = () => {
			loginModalVisibleFlag.value = false;
		};

		//修改选择促销活动 e:v-model的值，cartItem,当前项商品
		const changePromotion = (e, curCartItem) => {
			curPromotion.value = e;
			curPromotionCartItem.data = curCartItem;
		};

		//点击促销，显示促销选择弹框
		const promotionShow = (cartItem) => {
			curPromotionGoodsProductId.value = cartItem.productId;
			curPromotion.value = cartItem.promotionId;
			cartData.data && cartData.data.storeSampleCartGroupList && cartData.data.storeSampleCartGroupList.map((storeItem) => {
				storeItem.storeCartList.map(cartItem => {
					cartItem.promotionModel = false;
				})
				let curPromoGoods = [];
				curPromoGoods = storeItem.storeCartList.filter((cartItem) => cartItem.productId == curPromotionGoodsProductId.value);
				if (curPromoGoods && curPromoGoods.length > 0) {
					curPromoGoods[0].promotionModel = true;
				}
			});
		};

		//确定选择促销活动
		const selPromotion = () => {
			if (JSON.stringify(curPromotionCartItem.data) != "{}") {
				let curPromotionItem = [];
				curPromotionItem = curPromotionCartItem.data.promotionList.filter(
					(promotionItem) => promotionItem.promotionId == curPromotion.value
				);
				if (curPromotionItem.length > 0) {
					let params = {
						cartId: curPromotionCartItem.data.cartId,
						promotionDescription: curPromotionItem[0].promotionDes.replace(/<font color='red'>|<\/font>/g, ''),
						promotionId: curPromotionItem[0].promotionId,
						promotionType: curPromotionItem[0].promotionType,
					};
					proxy
						.$post("v3/business/front/cart/changePromotion", params)
						.then((res) => {
							if (res.state == 200) {
								cartData.data = res.data;
								cartData.data = proxy.$calculateSubtotal(cartData.data);
							} else {
								ElMessage.error(res.msg);
							}
						});
				}
			}
		};

		//去首页
		const goIndex = () => {
			router.push({ path: "/" });
		};


		const delBatchGoods = () => {
			let { totalCheck } = cartData.data
			if (totalCheck == 0) {
				ElMessage.warning(L['请选择要删除的产品'])
			} else {
				chosenGoodsModel.value = true
			}
		}

		const service = (id) => { //客服
			let newWin = router.resolve({
				path: '/service',
				query: {
					vid: id
				}
			})
			window.open(newWin.href, '_blank');
		}

		//暴露的变量及方法
		return {
			L,
			token,
			storeImg,
			cartData,
			editNum,
			clearInvalidGoods,
			clearInvalidGoodsModel,
			confirmClearInvalidModel,
			cancelClearInvalidModel,
			changeSelectState,
			confirmDelGoodsModel,
			moveCartGoods,
			delCartGoods,
			delGoodsCartIds,
			goGoodsDetail,
			goBuy,
			changePromotion,
			curPromotion,
			selPromotion,
			curPromotionCartItem,
			goIndex,
			couponModel,
			couponList,
			cartListData,
			loginModalVisibleFlag,
			closeLoingModal,
			optionsBtn,
			optionsBtnTop,
			windowHeight,
			optionsBtnShow,
			promotionShow,
			curPromotionGoodsProductId,
			curCouponPage,
			couponPageSize,
			curStoreId,
			elementScrollTop,
			delBatchGoods,
			chosenGoodsModel,
			firstLoading,
			skelelonData,
			service,
		};
	},
};
</script>

<style lang="scss" scoped>
@import "../../style/base.scss";
@import "../../style/requestForm.scss";
</style>
<style lang="scss">
.promotion_btn {
	span {
		width: 40px;
		height: 20px;
		background: $colorMain;
		border-radius: 3px;
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #ffffff;
		display: inline-block;
		text-align: center;
		line-height: 20px;
		font-weight: 600;
		cursor: pointer;

		&:nth-child(2) {
			margin-left: 40px;
			background: #ffffff;
			color: $colorMain;
		}
	}
}

.el-overlay {
	background-color: rgba(0, 0, 0, 0.3);
}
</style>